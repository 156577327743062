import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

import OnboardingSteps from './OnboardingSteps';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Grid, Paper } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { userManagementActionCreators } from '../../../store/UserManagement';
import { connect } from 'react-redux';
import stringLookup from '../../../stringslookup';
import { datasetsActionCreators } from '../../../store/Datasets';

const styles = (theme) => ({
	rootContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '95vh',
	},
	root: {
		width: '90%',
		backgroundColor: '#26324e',
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
	},
	dialogTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	dialogContent: { height: '540px' },
	dumaLogo: {
		width: theme.spacing(20),
		height: theme.spacing(20),
		marginTop: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(23),
			height: theme.spacing(23),
			marginTop: 0,
		},
	},
	dumaTitle: {
		color: 'white',
		fontWeight: 500,
		fontSize: 35,
		// fontFamily: 'Lucida Sans Unicode',
		marginBottom: theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			fontWeight: 400,
			fontSize: 44,
			marginBottom: 0,
		},
	},
});

class EmbedOnboardingScreen extends Component {
	state = {
		fullName: '',
		emailAddress: '',
		phoneNumber: '',
		webAddress: '',
		organizationName: '',
		isSuccessSnackbarOpen: false,
		isSignupError: false,
		selectedDatasets: [],
	};

	componentDidMount() {
		this.loadAllDatasets();
	}

	loadAllDatasets = async () => {
		const { getAllDatasets } = this.props;

		await getAllDatasets();
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleSubmit = async () => {
		const { signUpForIncidents } = this.props;
		const {
			fullName,
			emailAddress,
			phoneNumber,
			webAddress,
			organizationName,
			selectedDatasets,
		} = this.state;
		const { handleSuccessSnackbarState, handleErrorSnackbarState } = this;

		const environment = stringLookup.baseUrl.includes('duma.siasareport.com')
			? 'Production'
			: stringLookup.baseUrl.includes('beta.siasareport.com')
			? 'Beta'
			: 'Development';

		const payload = {
			name: fullName,
			organizationName,
			webAddress,
			contact: phoneNumber,
			email: emailAddress,
			environment,
			datasetIds: selectedDatasets.map((x) => x.value),
		};

		let isSuccess = false;

		await signUpForIncidents(payload);

		if (this.props.signingUpForIncidentsError == null) {
			isSuccess = true;

			handleSuccessSnackbarState(true);

			return isSuccess;
		} else {
			handleErrorSnackbarState(true);
			return isSuccess;
		}
	};

	handleSuccessSnackbarState = (state) => {
		this.setState({ isSuccessSnackbarOpen: state });
	};

	handleErrorSnackbarState = (state) => {
		this.setState({ isSignupError: state });
	};

	handleDatasetsSelectionChange = (datasets) => {
		this.setState({ selectedDatasets: datasets });
	};

	render() {
		const {
			classes,
			signingUpForIncidents,
			signingUpForIncidentsError,
			allDatasets,
		} = this.props;
		const {
			fullName,
			emailAddress,
			phoneNumber,
			webAddress,
			organizationName,
			isSuccessSnackbarOpen,
			isSignupError,
			selectedDatasets,
		} = this.state;
		const {
			handleChange,
			handleSubmit,
			handleSuccessSnackbarState,
			handleErrorSnackbarState,
			handleDatasetsSelectionChange,
		} = this;

		return (
			<div className={classes.rootContainer}>
				<Paper className={classes.root}>
					<Grid container>
						<Grid
							item
							xs={12}
							sm={5}
							style={{
								margin: 'auto',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<img
								alt='Logo'
								src='/DumaLight.png'
								className={classes.dumaLogo}
							/>
							<Typography className={classes.dumaTitle}>DUMA</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							sm={7}
							style={{
								backgroundColor: 'white',
								padding: 16,
								paddingBottom: 32,
							}}
						>
							<Typography
								variant='h6'
								style={{
									marginTop: 16,
									marginLeft: 24,
									fontSize: 22,
									fontWeight: 600,
									// textAlign: 'center',
								}}
							>
								Sign Up
							</Typography>

							<OnboardingSteps
								handleChange={handleChange}
								fullName={fullName}
								emailAddress={emailAddress}
								phoneNumber={phoneNumber}
								webAddress={webAddress}
								organizationName={organizationName}
								handleSubmit={handleSubmit}
								signingUpForIncidents={signingUpForIncidents}
								isSuccessSnackbarOpen={isSuccessSnackbarOpen}
								handleSuccessSnackbarState={handleSuccessSnackbarState}
								handleErrorSnackbarState={handleErrorSnackbarState}
								isSignupError={isSignupError}
								signingUpForIncidentsError={signingUpForIncidentsError}
								allDatasets={allDatasets}
								handleDatasetsSelectionChange={handleDatasetsSelectionChange}
								selectedDatasets={selectedDatasets}
							/>
						</Grid>
					</Grid>
				</Paper>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.userManagement,
	...state.datasets,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		Object.assign({}, userManagementActionCreators, datasetsActionCreators),
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withMobileDialog()(withStyles(styles)(EmbedOnboardingScreen)));
