import stringLookup from './../stringslookup';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';
import { fetchSecured, fetchDefault, postSecured, post } from '../fetchUtils';
import { addDays } from '../_lib/timeUtil';

const REQUEST_FILTER_KEYWORDS_TYPE = 'REQUEST_FILTER_KEYWORDS';
const RECEIVE_FILTER_KEYWORDS_TYPE = 'RECEIVE_FILTER_KEYWORDS';
const RECEIVE_FILTER_KEYWORDS_ERROR_TYPE = 'RECEIVE_FILTER_KEYWORDS_ERROR';

const SET_ANALYTICS_DRAWER_OPEN_TYPE = 'SET_ANALYTICS_DRAWER_OPEN';
const SET_DOMAIN_FILTERS_TYPE = 'SET_DOMAIN_FILTERS';
const SET_KEYWORD_FILTERS_TYPE = 'SET_KEYWORD_FILTERS';
const SET_NLP_FILTER_TYPE = 'SET_NLP_FILTER';

const SET_MAP_BOX_KEY_TYPE = 'SET_MAPBOX_KEY';

const REQUEST_FILTER_CATEGORIES_TYPE = 'REQUEST_FILTER_CATEGORIES';
const RECEIVE_FILTER_CATEGORIES_TYPE = 'RECEIVE_FILTER_CATEGORIES';
const RECEIVE_FILTER_CATEGORIES_ERROR_TYPE = 'RECEIVE_FILTER_CATEGORIES_ERROR';
const SET_CATEGORY_FILTERS_TYPE = 'SET_CATEGORY_FILTERS';
const CLEAR_CATEGORY_FILTERS_TYPE = 'CLEAR_CATEGORY_FILTERS';
const REQUEST_FILTER_CATEGORIES_LIST_TYPE = 'REQUEST_FILTER_CATEGORIES_LIST';
const RECEIVE_FILTER_CATEGORIES_LIST_TYPE = 'RECEIVE_FILTER_CATEGORIES_LIST';
const RECEIVE_FILTER_CATEGORIES_LIST_ERROR_TYPE = 'RECEIVE_FILTER_CATEGORIESS_LIST_ERROR';

const REQUEST_FILTER_TOPICS_TYPE = 'REQUEST_FILTER_TOPICS';
const RECEIVE_FILTER_TOPICS_TYPE = 'RECEIVE_FILTER_TOPICS';
const RECEIVE_FILTER_TOPICS_ERROR_TYPE = 'RECEIVE_FILTER_TOPICS_ERROR';
const SET_TOPIC_FILTERS_TYPE = 'SET_TOPIC_FILTERS';

const REQUEST_FILTER_SUBTOPICS_TYPE = 'REQUEST_FILTER_SUBTOPICS';
const RECEIVE_FILTER_SUBTOPICS_TYPE = 'RECEIVE_FILTER_SUBTOPICS';
const RECEIVE_FILTER_SUBTOPICS_ERROR_TYPE = 'RECEIVE_FILTER_SUBTOPICS_ERROR';
const SET_SUBTOPIC_FILTERS_TYPE = 'SET_SUBTOPIC_FILTERS';

const SET_MEDIA_TYPE_FILTER_TYPE = 'SET_MEDIA_TYPE_FILTER';
const SET_DAYS_TO_CONSIDER_TYPE = 'SET_DAYS_TO_CONSIDER';
const SET_START_DATE_TYPE = 'SET_START_DATE';
const SET_END_DATE_TYPE = 'SET_END_DATE';
const SET_ENTITY_TYPE_FILTER_TYPE = 'SET_ENTITY_TYPE_FILTER';
const SET_CONTENT_TAB_TYPE = 'SET_CONTENT_TAB';
const SET_SELECTED_SIDE_GRID_TAB_TYPE = 'SET_SELECTED_SIDE_GRID_TAB';

const REQUEST_COUNTY_DATA_TYPE = 'REQUEST_COUNTY_DATA';
const RECEIVE_COUNTY_DATA_TYPE = 'RECEIVE_COUNTY_DATA';
const RECEIVE_COUNTY_DATA_ERROR_TYPE = 'RECEIVE_COUNTY_DATA_ERROR';

const updateCovid19MetricsType = 'UPDATE_COVID_19';
const updateCovid19MetricsCompleteType = 'UPDATE_COVID_19_COMPLETE';

const REQUEST_COUNTY_METRICS_TYPE = 'REQUEST_COUNTY_METRICS';
const RECEIVE_COUNTY_METRICS_TYPE = 'RECEIVE_COUNTY_METRICS';
const RECEIVE_COUNTY_METRICS_ERROR_TYPE = 'RECEIVE_COUNTY_METRICS_ERROR';

const REQUEST_SPREAD_SIMULATION_TYPE = 'REQUEST_SPREAD_SIMULATION';
const RECEIVE_SPREAD_SIMULATION_TYPE = 'RECEIVE_SPREAD_SIMULATION';
const RECEIVE_SPREAD_SIMULATION_ERROR_TYPE = 'RECEIVE_SPREAD_SIMULATION_ERROR';

const REQUEST_INTERVENTION_SIMULATION_TYPE = 'REQUEST_INTERVENTION_SIMULATION';
const RECEIVE_INTERVENTION_SIMULATION_TYPE = 'RECEIVE_INTERVENTION_SIMULATION';
const RECEIVE_INTERVENTION_SIMULATION_ERROR_TYPE =
	'RECEIVE_INTERVENTION_SIMULATION_ERROR';

const REQUEST_CAPACITY_SIMULATION_TYPE = 'REQUEST_CAPACITY_SIMULATION';
const RECEIVE_CAPACITY_SIMULATION_TYPE = 'RECEIVE_CAPACITY_SIMULATION';
const RECEIVE_CAPACITY_SIMULATION_ERROR_TYPE = 'RECEIVE_CAPACITY_SIMULATION_ERROR';

const REQUEST_CAPACITY_INTERVENTION_TYPE = 'REQUEST_CAPACITY_INTERVENTION';
const RECEIVE_CAPACITY_INTERVENTION_TYPE = 'RECEIVE_CAPACITY_INTERVENTION';
const RECEIVE_CAPACITY_INTERVENTION_ERROR_TYPE = 'RECEIVE_CAPACITY_INTERVENTION_ERROR';

const REQUEST_PROJECT_DOWNLOAD_TYPE = 'REQUEST_PROJECT_DOWNLOAD';
const RECEIVE_PROJECT_DOWNLOAD_TYPE = 'RECEIVE_PROJECT_DOWNLOAD';
const RECEIVE_PROJECT_DOWNLOAD_ERROR_TYPE = 'RECEIVE_PROJECT_DOWNLOAD_ERROR';

const REQUEST_KENYA_COUNTIES_DATA_TYPE = 'REQUEST_KENYA_COUNTIES_DATA';
const RECEIVE_KENYA_COUNTIES_DATA_TYPE = 'RECEIVE_KENYA_COUNTIES_DATA';
const RECEIVE_KENYA_COUNTIES_DATA_ERROR_TYPE = 'RECEIVE_KENYA_COUNTIES_DATA_ERROR';

const SET_RELATED_SUBTOPICS_FILTER_TYPE = 'SET_RELATED_SUBTOPICS_FILTER';
const SET_SEARCH_TEXT_TYPE = 'SET_SEARCH_TEXT';
const SET_SEARCH_INPUT_VALUE_TYPE = 'SET_SEARCH_INPUT_VALUE';

const RESET_FILTERS_TYPE = 'RESET_FILTERS';
const TRACK_EXTERNAL_LINKS_TYPE = 'TRACK_EXTERNAL_LINKS';

const REQUEST_HATE_SPEECH_COUNTY_DATA_TYPE = 'REQUEST_HATE_SPEECH_COUNTY_DATA';
const RECEIVE_HATE_SPEECH_COUNTY_DATA_TYPE = 'RECEIVE_HATE_SPEECH_COUNTY_DATA';
const RECEIVE_HATE_SPEECH_COUNTY_DATA_ERROR_TYPE =
	'RECEIVE_HATE_SPEECH_COUNTY_DATA_ERROR';

const initialState = {
	filterKeywordsLoading: false,
	availableKeywords: [],
	keywordFilters: [],

	filterCategoriesLoading: false,
	availableCategories: [],
	categoryFilters: [],

	filterTopicsLoading: false,
	availableTopics: [],
	topicFilters: [],

	filtersubtopicsLoading: false,
	availableSubtopics: [],
	subtopicFilters: [],

	NLPFilter: '',

	subtopicIdFilter: '',
	domainFilter: '',
	relatedSubtopicFilter: null,
	mapboxKey: null,

	mediaTypeFilter: 'all',
	entityTypeFilter: 'All',
	dashboardTab: 'chatbot',
	analyticsDrawerOpen: true,

	startDate: addDays(new Date(), -7),
	endDate: new Date(),
	disableCustomDateRange: false,
	daysToConsider: 7,
	searchText: '',
	searchInputValue: '',

	countyData: [],
	countyDataError: false,
	countyDataLoading: false,
	countyMetrics: null,
	spreadData: [],
	simulationDataLoading: false,
	spreadDataError: false,
	capacityInterventionData: [],
	capacityInterventionDataError: false,
	interventionData: [],
	interventionDataError: false,
	capacityData: [],
	capacityDataError: false,
	projectDataBlob: null,
	projectDownloadError: false,
	kenyaCountiesData: null,
	kenyaCountiesDataLoading: false,
	externalLinkTracked: false,
	hateSpeechCountyData: [],
	hateSpeechCountyDataError: false,
	hateSpeechCountyDataLoading: false,
	selectedSideGridTab: null,
};

export const analyticsActionCreators = {
	loadKeywordSuggestions:
		(
			searchString,
			mediaType,
			excludeKeywords,
			topics,
			subtopics,
			startDate,
			endDate,
			openSearch
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_FILTER_KEYWORDS_TYPE });

			const url = new URL(
				'/api/analytics/keywordsuggestions',
				stringLookup.baseUrl
			);

			if (mediaType == 'images') {
				url.searchParams.append('mediaType', 'newsOutlets');
			} else {
				url.searchParams.append('mediaType', mediaType);
			}

			url.searchParams.append('suggestionCount', 10);
			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			topics.forEach((x) => {
				url.searchParams.append('topics', x);
			});

			subtopics.forEach((x) => {
				url.searchParams.append('subtopics', x);
			});

			excludeKeywords.forEach((ex) => {
				url.searchParams.append('exclude', ex);
			});

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (openSearch) {
				url.searchParams.append('openSearch', openSearch);
			}

			try {
				const res = await fetchSecured(url, 'keyword-suggestion');
				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: RECEIVE_FILTER_KEYWORDS_TYPE,
						availableKeywords: data,
					});
				} else {
					dispatch({ type: RECEIVE_FILTER_KEYWORDS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_FILTER_KEYWORDS_ERROR_TYPE });
			}
		},

	setAnalyticsDrawerOpen: (analyticsDrawerOpen) => async (dispatch, _) => {
		dispatch({
			type: SET_ANALYTICS_DRAWER_OPEN_TYPE,
			analyticsDrawerOpen,
		});
	},

	setKeywordFilters:
		(filters = []) =>
		async (dispatch, _) => {
			dispatch({
				type: SET_KEYWORD_FILTERS_TYPE,
				keywordFilters: filters,
			});
		},

	setNLPFilter: (filter) => async (dispatch, _) => {
		dispatch({ type: SET_NLP_FILTER_TYPE, NLPFilter: filter });
	},

	setDomainFilter: (filter) => async (dispatch, _) => {
		dispatch({ type: SET_DOMAIN_FILTERS_TYPE, domainFilter: filter });
	},

	setRelatedSubtopicsFilter: (filter) => async (dispatch, _) => {
		dispatch({
			type: SET_RELATED_SUBTOPICS_FILTER_TYPE,
			relatedSubtopicFilter: filter,
		});
	},

	resetFilters: (_) => async (dispatch, _) => {
		dispatch({ type: RESET_FILTERS_TYPE });
	},

	setMapboxKey: () => async (dispatch, getState) => {
		try {
			const url = new URL(`${stringLookup.baseUrl}/api/external/apikeys`);
			url.searchParams.append('keyName', 'MapBox');

			var res = await fetchSecured(url);
			if (res.ok) {
				var data = await res.json();
				dispatch({ type: SET_MAP_BOX_KEY_TYPE, key: data.key });
			}
		} catch (err) {}
	},

	loadCategoryListSuggestions:
		(page = 1, pageSize = 50, mediaType, startDate, endDate, openSearch) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_FILTER_CATEGORIES_LIST_TYPE });

			const url = new URL('/api/analytics/categorieslist', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (mediaType) {
				if (mediaType == 'images') {
					url.searchParams.append('mediaType', 'newsOutlets');
				} else {
					url.searchParams.append('mediaType', mediaType);
				}
			}

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (openSearch) {
				url.searchParams.append('openSearch', openSearch);
			}

			try {
				const categoriesResponse = await fetchDefault(url);
				if (categoriesResponse.ok) {
					const categoriesRes = await categoriesResponse.json();
					dispatch({
						type: RECEIVE_FILTER_CATEGORIES_LIST_TYPE,
						availableCategories: categoriesRes,
					});
				} else {
					dispatch({
						type: RECEIVE_FILTER_CATEGORIES_LIST_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_FILTER_CATEGORIES_LIST_ERROR_TYPE });
			}
		},

	loadCategorySuggestions:
		(page = 1, pageSize = 50, orgId, mediaType) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_FILTER_CATEGORIES_TYPE });

			const url = new URL('/api/categories', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (orgId) {
				url.searchParams.append('OrganisationId', orgId);
			}

			if (mediaType) {
				url.searchParams.append('mediaType', mediaType);
			}

			try {
				const categoriesResponse = await fetchDefault(url);
				if (categoriesResponse.ok) {
					const categoriesRes = await categoriesResponse.json();
					dispatch({
						type: RECEIVE_FILTER_CATEGORIES_TYPE,
						availableCategories: categoriesRes.categoryViewModels,
					});
				} else {
					dispatch({ type: RECEIVE_FILTER_CATEGORIES_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_FILTER_CATEGORIES_ERROR_TYPE });
			}
		},

	loadTopicSuggestions:
		(
			searchString,
			mediaType,
			excludeTopics,
			startDate,
			endDate,
			categories,
			openSearch
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_FILTER_TOPICS_TYPE });

			const url = new URL('/api/analytics/topiclist', stringLookup.baseUrl);
			url.searchParams.append('suggestionCount', 10);
			if (mediaType == 'images') {
				url.searchParams.append('mediaType', 'newsOutlets');
			} else {
				url.searchParams.append('mediaType', mediaType);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			categories.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			excludeTopics.forEach((ex) => {
				url.searchParams.append('exclude', ex);
			});

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (openSearch) {
				url.searchParams.append('openSearch', openSearch);
			}

			try {
				const res = await fetchDefault(url, 'topic-suggestion');
				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: RECEIVE_FILTER_TOPICS_TYPE,
						availableTopics: data,
					});
				} else {
					dispatch({ type: RECEIVE_FILTER_TOPICS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_FILTER_TOPICS_ERROR_TYPE });
			}
		},
	loadSubtopicSuggestions:
		(
			searchString,
			mediaType,
			excludeSubtopics,
			topics,
			startDate,
			endDate,
			categories,
			openSearch
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_FILTER_SUBTOPICS_TYPE });

			const url = new URL('/api/analytics/subtopiclist', stringLookup.baseUrl);
			url.searchParams.append('suggestionCount', 10);
			if (mediaType == 'images') {
				url.searchParams.append('mediaType', 'newsOutlets');
			} else {
				url.searchParams.append('mediaType', mediaType);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			excludeSubtopics.forEach((ex) => {
				url.searchParams.append('exclude', ex);
			});

			categories.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topics.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (openSearch) {
				url.searchParams.append('openSearch', openSearch);
			}

			try {
				const res = await fetchDefault(url, 'sub-topic-suggestion');
				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: RECEIVE_FILTER_SUBTOPICS_TYPE,
						availableSubtopics: data,
					});
				} else {
					dispatch({ type: RECEIVE_FILTER_SUBTOPICS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_FILTER_SUBTOPICS_ERROR_TYPE });
			}
		},
	setCategoryFilters:
		(filters = []) =>
		async (dispatch, _) => {
			dispatch({
				type: SET_CATEGORY_FILTERS_TYPE,
				categoryFilters: filters,
			});
		},
	setTopicFilters:
		(filters = []) =>
		async (dispatch, _) => {
			dispatch({ type: SET_TOPIC_FILTERS_TYPE, topicFilters: filters });
		},
	setSubtopicFilters:
		(filters = []) =>
		async (dispatch, _) => {
			dispatch({
				type: SET_SUBTOPIC_FILTERS_TYPE,
				subtopicFilters: filters,
			});
		},
	clearCategoryFilters: () => async (dispatch, _) => {
		dispatch({ type: CLEAR_CATEGORY_FILTERS_TYPE });
	},
	setDashboardTab: (value) => async (dispatch, _) => {
		dispatch({ type: SET_CONTENT_TAB_TYPE, dashboardTab: value });
	},
	setMediaTypeFilter: (filter) => async (dispatch, _) => {
		dispatch({ type: SET_MEDIA_TYPE_FILTER_TYPE, mediaTypeFilter: filter });
	},
	setEntityTypeFilter: (filter) => async (dispatch, _) => {
		dispatch({
			type: SET_ENTITY_TYPE_FILTER_TYPE,
			entityTypeFilter: filter,
		});
	},

	setStartDate:
		(startDate, disableCustomDateRange = false) =>
		async (dispatch, getState) => {
			dispatch({
				type: SET_START_DATE_TYPE,
				startDate: startDate,
				disableCustomDateRange: disableCustomDateRange,
			});
		},
	setEndDate:
		(endDate, disableCustomDateRange = false) =>
		async (dispatch, getState) => {
			dispatch({
				type: SET_END_DATE_TYPE,
				endDate: endDate,
				disableCustomDateRange: disableCustomDateRange,
			});
		},
	setDaysToConsider: (days) => async (dispatch, getState) => {
		dispatch({ type: SET_DAYS_TO_CONSIDER_TYPE, daysToConsider: days });
	},

	setSearchText: (searchText) => async (dispatch, getState) => {
		dispatch({ type: SET_SEARCH_TEXT_TYPE, searchText });
	},

	setSearchInputValue: (inputValue) => async (dispatch) => {
		dispatch({ type: SET_SEARCH_INPUT_VALUE_TYPE, searchInputValue: inputValue });
	},

	setSelectedSideGridTab: (selectedSideGridTab) => async (dispatch) => {
		dispatch({ type: SET_SELECTED_SIDE_GRID_TAB_TYPE, selectedSideGridTab });
	},

	validateKeywordFilters:
		(mediaType, keywords, topics, subtopics, startDate, endDate) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_FILTER_KEYWORDS_TYPE });

			const url = new URL(
				'/api/analytics/validatekeywordfilter',
				stringLookup.baseUrl
			);

			if (mediaType === 'images') {
				url.searchParams.append('mediaType', 'newsOutlets');
			} else {
				url.searchParams.append('mediaType', mediaType);
			}

			topics.forEach((x) => {
				url.searchParams.append('topics', x);
			});

			subtopics.forEach((x) => {
				url.searchParams.append('subtopics', x);
			});

			keywords.forEach((ex) => {
				url.searchParams.append('keywords', ex);
			});

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			try {
				const res = await fetchSecured(url, 'keyword-filter-validation');
				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: SET_KEYWORD_FILTERS_TYPE,
						keywordFilters: data,
					});
				}
			} catch (err) {
				console.log(err);
			}
		},

	validateSubtopicFilters: (topics, subtopics) => async (dispatch, getState) => {
		dispatch({ type: REQUEST_FILTER_KEYWORDS_TYPE });

		const url = new URL(
			'/api/analytics/validatesubtopicfilter',
			stringLookup.baseUrl
		);

		topics.forEach((x) => {
			url.searchParams.append('topics', x);
		});

		subtopics.forEach((x) => {
			url.searchParams.append('subtopics', x);
		});

		try {
			const res = await fetchSecured(url, 'subtopic-filter-validation');
			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: SET_SUBTOPIC_FILTERS_TYPE,
					subtopicFilters: data,
				});
			}
		} catch (err) {
			console.log(err);
		}
	},

	getCountyData: () => async (dispatch, getState) => {
		dispatch({ type: REQUEST_COUNTY_DATA_TYPE });

		const url = new URL(`${stringLookup.baseUrl}/api/covid/map`);

		try {
			var res = await fetchDefault(url);

			if (res.ok) {
				var data = await res.json();
				dispatch({ type: RECEIVE_COUNTY_DATA_TYPE, countyData: data });
			} else {
				dispatch({ type: RECEIVE_COUNTY_DATA_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_COUNTY_DATA_ERROR_TYPE });
		}
	},

	addCovid19Update: (covid19Update) => async (dispatch, getState) => {
		dispatch({ type: updateCovid19MetricsType });
		const url = new URL('/api/covid', stringLookup.baseUrl);

		try {
			const Response = await postSecured(url, covid19Update);

			if (Response.ok) {
				dispatch({ type: updateCovid19MetricsCompleteType });
			} else {
				dispatch({ type: updateCovid19MetricsCompleteType });
			}
		} catch (err) {
			dispatch({ type: updateCovid19MetricsCompleteType });
		}
	},

	getCountyMetricsByDate: (county, date) => async (dispatch, getState) => {
		dispatch({ type: REQUEST_COUNTY_METRICS_TYPE });

		const url = new URL(`${stringLookup.baseUrl}/api/covid`);
		url.searchParams.append('filter', county);
		url.searchParams.append('date', date.toISOString());

		try {
			var res = await fetchDefault(url);
			if (res.ok) {
				var metricsResponse = await res.json();
				dispatch({
					type: RECEIVE_COUNTY_METRICS_TYPE,
					countyMetrics: metricsResponse,
				});
			} else {
				dispatch({ type: RECEIVE_COUNTY_METRICS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_COUNTY_METRICS_ERROR_TYPE });
		}
	},

	getSpreadSimulation:
		(
			daysToSimulate,
			population,
			initialInfections,
			IncubPeriod,
			DurMildInf,
			FracSevere,
			DurHosp,
			DurAsym,
			FracCritical,
			TimeICUDeath,
			ProbDeath,
			b0,
			b1,
			b2,
			b3,
			be,
			AllowAsym,
			AllowPresym,
			FracAsym,
			PresymPeriod
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_SPREAD_SIMULATION_TYPE });

			const url = new URL(`${stringLookup.baseUrl}/api/covid/simulation/spread`);
			url.searchParams.append('days_to_simulate', daysToSimulate);
			url.searchParams.append('total_population', population);
			url.searchParams.append('initial_number_of_infections', initialInfections);
			url.searchParams.append('IncubPeriod', IncubPeriod);
			url.searchParams.append('DurMildInf', DurMildInf);
			url.searchParams.append('FracSevere', FracSevere);
			url.searchParams.append('DurHosp', DurHosp);
			url.searchParams.append('DurAsym', DurAsym);
			url.searchParams.append('FracCritical', FracCritical);
			url.searchParams.append('TimeICUDeath', TimeICUDeath);
			url.searchParams.append('ProbDeath', ProbDeath);
			url.searchParams.append('b0', b0);
			url.searchParams.append('b1', b1);
			url.searchParams.append('b2', b2);
			url.searchParams.append('b3', b3);
			url.searchParams.append('be', be);
			url.searchParams.append('AllowAsym', AllowAsym);
			url.searchParams.append('AllowPresym', AllowPresym);
			url.searchParams.append('FracAsym', FracAsym);
			url.searchParams.append('PresymPeriod', PresymPeriod);

			try {
				var res = await fetchDefault(url);

				if (res.ok) {
					var spreadResponse = await res.json();
					dispatch({
						type: RECEIVE_SPREAD_SIMULATION_TYPE,
						spreadData: spreadResponse,
					});
				} else {
					dispatch({ type: RECEIVE_SPREAD_SIMULATION_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SPREAD_SIMULATION_ERROR_TYPE });
			}
		},

	getInterventionSimulation:
		(
			daysToSimulate,
			population,
			initialInfections,
			IncubPeriod,
			DurMildInf,
			FracSevere,
			DurHosp,
			DurAsym,
			FracCritical,
			TimeICUDeath,
			ProbDeath,
			b0,
			b1,
			b2,
			b3,
			be,
			AllowAsym,
			AllowPresym,
			FracAsym,
			PresymPeriod,
			Tint,
			Tend
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_INTERVENTION_SIMULATION_TYPE });

			const url = new URL(
				`${stringLookup.baseUrl}/api/covid/simulation/intervention`
			);
			url.searchParams.append('days_to_simulate', daysToSimulate);
			url.searchParams.append('total_population', population);
			url.searchParams.append('initial_number_of_infections', initialInfections);
			url.searchParams.append('IncubPeriod', IncubPeriod);
			url.searchParams.append('DurMildInf', DurMildInf);
			url.searchParams.append('FracSevere', FracSevere);
			url.searchParams.append('DurHosp', DurHosp);
			url.searchParams.append('DurAsym', DurAsym);
			url.searchParams.append('FracCritical', FracCritical);
			url.searchParams.append('TimeICUDeath', TimeICUDeath);
			url.searchParams.append('ProbDeath', ProbDeath);
			url.searchParams.append('b0', b0);
			url.searchParams.append('b1', b1);
			url.searchParams.append('b2', b2);
			url.searchParams.append('b3', b3);
			url.searchParams.append('be', be);
			url.searchParams.append('AllowAsym', AllowAsym);
			url.searchParams.append('AllowPresym', AllowPresym);
			url.searchParams.append('FracAsym', FracAsym);
			url.searchParams.append('PresymPeriod', PresymPeriod);
			url.searchParams.append('Tint', Tint);
			url.searchParams.append('Tend', daysToSimulate);

			try {
				var res = await fetchDefault(url);

				if (res.ok) {
					var interventionResponse = await res.json();
					dispatch({
						type: RECEIVE_INTERVENTION_SIMULATION_TYPE,
						interventionData: interventionResponse,
					});
				} else {
					dispatch({
						type: RECEIVE_INTERVENTION_SIMULATION_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INTERVENTION_SIMULATION_ERROR_TYPE });
			}
		},

	getCapacitySimulation:
		(
			daysToSimulate,
			population,
			initialInfections,
			IncubPeriod,
			DurMildInf,
			FracSevere,
			DurHosp,
			DurAsym,
			FracCritical,
			TimeICUDeath,
			ProbDeath,
			b0,
			b1,
			b2,
			b3,
			be,
			AllowAsym,
			AllowPresym,
			FracAsym,
			PresymPeriod,
			Tint,
			Tend,
			ICUBedper,
			ICUBedOcc,
			HospBedOcc,
			HospBedper
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_CAPACITY_SIMULATION_TYPE });

			const url = new URL(
				`${stringLookup.baseUrl}/api/covid/simulation/hospitalcapacity`
			);
			url.searchParams.append('days_to_simulate', daysToSimulate);
			url.searchParams.append('N', population);
			url.searchParams.append('InitInf', initialInfections);
			url.searchParams.append('IncubPeriod', IncubPeriod);
			url.searchParams.append('DurMildInf', DurMildInf);
			url.searchParams.append('FracSevere', FracSevere);
			url.searchParams.append('DurHosp', DurHosp);
			url.searchParams.append('DurAsym', DurAsym);
			url.searchParams.append('FracCritical', FracCritical);
			url.searchParams.append('TimeICUDeath', TimeICUDeath);
			url.searchParams.append('ProbDeath', ProbDeath);
			url.searchParams.append('b0', b0);
			url.searchParams.append('b1', b1);
			url.searchParams.append('b2', b2);
			url.searchParams.append('b3', b3);
			url.searchParams.append('be', be);
			url.searchParams.append('AllowAsym', AllowAsym);
			url.searchParams.append('AllowPresym', AllowPresym);
			url.searchParams.append('FracAsym', FracAsym);
			url.searchParams.append('PresymPeriod', PresymPeriod);
			url.searchParams.append('Tint', Tint);
			url.searchParams.append('Tmax', daysToSimulate);
			url.searchParams.append('ICUBedper', ICUBedper);
			url.searchParams.append('ICUBedOcc', ICUBedOcc);
			url.searchParams.append('HospBedOcc', HospBedOcc);
			url.searchParams.append('HospBedper', HospBedper);

			try {
				var res = await fetchDefault(url);

				if (res.ok) {
					var capacityResponse = await res.json();
					dispatch({
						type: RECEIVE_CAPACITY_SIMULATION_TYPE,
						capacityData: capacityResponse,
					});
				} else {
					dispatch({ type: RECEIVE_CAPACITY_SIMULATION_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_CAPACITY_SIMULATION_ERROR_TYPE });
			}
		},

	getCapacityInterventionSimulation:
		(
			daysToSimulate,
			population,
			initialInfections,
			IncubPeriod,
			DurMildInf,
			FracSevere,
			DurHosp,
			DurAsym,
			FracCritical,
			TimeICUDeath,
			ProbDeath,
			b0,
			b1,
			b2,
			b3,
			be,
			AllowAsym,
			AllowPresym,
			FracAsym,
			PresymPeriod,
			Tint,
			Tend,
			ICUBedper,
			ICUBedOcc,
			HospBedOcc,
			HospBedper
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_CAPACITY_INTERVENTION_TYPE });

			const url = new URL(
				`${stringLookup.baseUrl}/api/covid/simulation/hospitalcapacity-intervention`
			);
			url.searchParams.append('days_to_simulate', daysToSimulate);
			url.searchParams.append('N', population);
			url.searchParams.append('InitInf', initialInfections);
			url.searchParams.append('IncubPeriod', IncubPeriod);
			url.searchParams.append('DurMildInf', DurMildInf);
			url.searchParams.append('FracSevere', FracSevere);
			url.searchParams.append('DurHosp', DurHosp);
			url.searchParams.append('DurAsym', DurAsym);
			url.searchParams.append('FracCritical', FracCritical);
			url.searchParams.append('TimeICUDeath', TimeICUDeath);
			url.searchParams.append('ProbDeath', ProbDeath);
			url.searchParams.append('b0', b0);
			url.searchParams.append('b1', b1);
			url.searchParams.append('b2', b2);
			url.searchParams.append('b3', b3);
			url.searchParams.append('be', be);
			url.searchParams.append('AllowAsym', AllowAsym);
			url.searchParams.append('AllowPresym', AllowPresym);
			url.searchParams.append('FracAsym', FracAsym);
			url.searchParams.append('PresymPeriod', PresymPeriod);
			url.searchParams.append('Tint', Tint);
			url.searchParams.append('Tmax', daysToSimulate);
			url.searchParams.append('ICUBedper', ICUBedper);
			url.searchParams.append('ICUBedOcc', ICUBedOcc);
			url.searchParams.append('HospBedOcc', HospBedOcc);
			url.searchParams.append('HospBedper', HospBedper);

			try {
				var res = await fetchDefault(url);

				if (res.ok) {
					var capacityResponse = await res.json();
					dispatch({
						type: RECEIVE_CAPACITY_INTERVENTION_TYPE,
						capacityInterventionData: capacityResponse,
					});
				} else {
					dispatch({
						type: RECEIVE_CAPACITY_INTERVENTION_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_CAPACITY_INTERVENTION_ERROR_TYPE });
			}
		},

	downloadProjectReport: (startDate, endDate) => async (dispatch) => {
		dispatch({ type: REQUEST_PROJECT_DOWNLOAD_TYPE });
		setGlobalLoad(dispatch);
		const url = new URL('https://kazimtaani.siasareport.com/api/projects/report');
		url.searchParams.append('from', startDate.toISOString());
		url.searchParams.append('to', endDate.toISOString());

		try {
			var downloadResponse = await fetch(url);
			if (downloadResponse.ok) {
				const downloadBlob = await downloadResponse.blob();
				dispatch({
					type: RECEIVE_PROJECT_DOWNLOAD_TYPE,
					projectDataBlob: downloadBlob,
				});
			} else {
				dispatch({ type: RECEIVE_PROJECT_DOWNLOAD_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_PROJECT_DOWNLOAD_ERROR_TYPE });
		}

		unsetGlobalLoad(dispatch);
	},

	loadKenyaCountiesData: () => async (dispatch, getState) => {
		if (getState().analytics.kenyaCountiesDataLoading) {
			return;
		}

		if (getState().analytics.kenyaCountiesData) {
			return;
		}

		dispatch({ type: REQUEST_KENYA_COUNTIES_DATA_TYPE });
		const uriPath = stringLookup.kenyaCountiesDataPath;

		try {
			const url = new URL(uriPath);
			let response = await fetch(url);

			if (response.ok) {
				const kenyaCountiesData = await response.json();
				console.log(kenyaCountiesData);
				dispatch({
					type: RECEIVE_KENYA_COUNTIES_DATA_TYPE,
					kenyaCountiesData,
				});
			} else {
				dispatch({ type: RECEIVE_KENYA_COUNTIES_DATA_ERROR_TYPE });
			}
		} catch (err) {
			console.log(err.message);
			dispatch({ type: RECEIVE_KENYA_COUNTIES_DATA_ERROR_TYPE });
		}
	},

	trackExternalLinks: (externalUrl) => async (dispatch) => {
		try {
			const url = new URL(`${stringLookup.baseUrl}/api/redirect`);
			url.searchParams.append('url', externalUrl);
			url.searchParams.append('trackOnly', true);

			var res = await fetchDefault(url);
			if (res.ok) {
				dispatch({ type: TRACK_EXTERNAL_LINKS_TYPE });
			}
		} catch (err) {}
	},

	getHateSpeechCountyData: () => async (dispatch, getState) => {
		dispatch({ type: REQUEST_HATE_SPEECH_COUNTY_DATA_TYPE });

		const url = new URL(`${stringLookup.baseUrl}/api/hatespeech/map`);

		try {
			var res = await fetchDefault(url);

			if (res.ok) {
				var data = await res.json();
				dispatch({
					type: RECEIVE_HATE_SPEECH_COUNTY_DATA_TYPE,
					hateSpeechCountyData: data,
				});
			} else {
				dispatch({ type: RECEIVE_HATE_SPEECH_COUNTY_DATA_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_HATE_SPEECH_COUNTY_DATA_ERROR_TYPE });
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === TRACK_EXTERNAL_LINKS_TYPE) {
		return {
			...state,
			externalLinkTracked: true,
		};
	}

	if (action.type === REQUEST_FILTER_KEYWORDS_TYPE) {
		return {
			...state,
			filterKeywordsLoading: true,
		};
	}

	if (action.type === RECEIVE_FILTER_KEYWORDS_TYPE) {
		return {
			...state,
			filterKeywordsLoading: false,
			availableKeywords: action.availableKeywords,
		};
	}

	if (action.type === RECEIVE_FILTER_KEYWORDS_ERROR_TYPE) {
		return {
			...state,
			filterKeywordsLoading: false,
		};
	}

	if (action.type === SET_KEYWORD_FILTERS_TYPE) {
		return {
			...state,
			keywordFilters: action.keywordFilters,
			//subtopicIdFilter: action.subtopicIdFilter
		};
	}

	if (action.type === SET_NLP_FILTER_TYPE) {
		return {
			...state,
			NLPFilter: action.NLPFilter,
		};
	}

	if (action.type === SET_DOMAIN_FILTERS_TYPE) {
		return {
			...state,
			domainFilter: action.domainFilter,
		};
	}

	if (action.type === SET_RELATED_SUBTOPICS_FILTER_TYPE) {
		return {
			...state,
			relatedSubtopicFilter: action.relatedSubtopicFilter,
			NLPFilter: '',
		};
	}

	if (action.type === SET_MAP_BOX_KEY_TYPE) {
		return { ...state, mapboxKey: action.key };
	}

	if (action.type === REQUEST_FILTER_CATEGORIES_TYPE) {
		return {
			...state,
			filterCategoriesLoading: true,
		};
	}

	if (action.type === RECEIVE_FILTER_CATEGORIES_TYPE) {
		return {
			...state,
			filterCategoriesLoading: false,
			availableCategories: action.availableCategories,
		};
	}

	if (action.type === RECEIVE_FILTER_CATEGORIES_ERROR_TYPE) {
		return {
			...state,
			filterCategoriesLoading: false,
		};
	}

	if (action.type === REQUEST_FILTER_CATEGORIES_LIST_TYPE) {
		return {
			...state,
			filterCategoriesLoading: true,
		};
	}

	if (action.type === RECEIVE_FILTER_CATEGORIES_LIST_TYPE) {
		return {
			...state,
			filterCategoriesLoading: false,
			availableCategories: action.availableCategories,
		};
	}

	if (action.type === RECEIVE_FILTER_CATEGORIES_LIST_ERROR_TYPE) {
		return {
			...state,
			filterCategoriesLoading: false,
		};
	}

	if (action.type === SET_CATEGORY_FILTERS_TYPE) {
		return {
			...state,
			categoryFilters: action.categoryFilters,
			topicFilters: [],
			subtopicFilters: [],
			keywordFilters: [],
			NLPFilter: '',
			relatedSubtopicFilter: null,
			domainFilter: '',
		};
	}

	if (action.type === CLEAR_CATEGORY_FILTERS_TYPE) {
		return {
			...state,
			categoryFilters: [],
			topicFilters: [],
			keywordFilters: [],
			NLPFilter: '',
			relatedSubtopicFilter: null,
			domainFilter: '',
		};
	}

	if (action.type === REQUEST_FILTER_TOPICS_TYPE) {
		return {
			...state,
			filterTopicsLoading: true,
		};
	}

	if (action.type === RECEIVE_FILTER_TOPICS_TYPE) {
		return {
			...state,
			filterTopicsLoading: false,
			availableTopics: action.availableTopics,
		};
	}

	if (action.type === RECEIVE_FILTER_TOPICS_ERROR_TYPE) {
		return {
			...state,
			filterTopicsLoading: false,
		};
	}

	if (action.type === SET_TOPIC_FILTERS_TYPE) {
		return {
			...state,
			topicFilters: action.topicFilters,
			subtopicFilters: [],
			keywordFilters: [],
			relatedSubtopicFilter: null,
			NLPFilter: '',
		};
	}

	if (action.type === REQUEST_FILTER_SUBTOPICS_TYPE) {
		return {
			...state,
			filterSubtopicsLoading: true,
		};
	}

	if (action.type === RECEIVE_FILTER_SUBTOPICS_TYPE) {
		return {
			...state,
			filterSubtopicsLoading: false,
			availableSubtopics: action.availableSubtopics,
		};
	}

	if (action.type === RECEIVE_FILTER_SUBTOPICS_ERROR_TYPE) {
		return {
			...state,
			filterSubtopicsLoading: false,
		};
	}

	if (action.type === SET_SUBTOPIC_FILTERS_TYPE) {
		return {
			...state,
			subtopicFilters: action.subtopicFilters,
			relatedSubtopicFilter: null,
			NLPFilter: '',
		};
	}

	if (action.type === SET_CONTENT_TAB_TYPE) {
		return {
			...state,
			dashboardTab: action.dashboardTab,
		};
	}

	if (action.type === SET_ANALYTICS_DRAWER_OPEN_TYPE) {
		return {
			...state,
			analyticsDrawerOpen: action.analyticsDrawerOpen,
		};
	}

	if (action.type === SET_MEDIA_TYPE_FILTER_TYPE) {
		return {
			...state,
			mediaTypeFilter: action.mediaTypeFilter,
			// dashboardTab: action.mediaTypeFilter,
		};
	}

	if (action.type === SET_ENTITY_TYPE_FILTER_TYPE) {
		return {
			...state,
			entityTypeFilter: action.entityTypeFilter,
		};
	}

	if (action.type === SET_START_DATE_TYPE) {
		return {
			...state,
			startDate: action.startDate,
			disableCustomDateRange: action.disableCustomDateRange,
		};
	}

	if (action.type === SET_END_DATE_TYPE) {
		return {
			...state,
			endDate: action.endDate,
			disableCustomDateRange: action.disableCustomDateRange,
		};
	}

	if (action.type === SET_DAYS_TO_CONSIDER_TYPE) {
		return {
			...state,
			daysToConsider: action.daysToConsider,
		};
	}

	if (action.type === SET_SEARCH_TEXT_TYPE) {
		return {
			...state,
			searchText: action.searchText,
			searchInputValue: '',
		};
	}

	if (action.type === SET_SEARCH_INPUT_VALUE_TYPE) {
		return {
			...state,
			searchInputValue: action.searchInputValue,
		};
	}

	if (action.type === SET_SELECTED_SIDE_GRID_TAB_TYPE) {
		return {
			...state,
			selectedSideGridTab: action.selectedSideGridTab,
		};
	}

	if (action.type === REQUEST_COUNTY_DATA_TYPE) {
		return {
			...state,
			countyDataLoading: true,
		};
	}

	if (action.type === RECEIVE_COUNTY_DATA_TYPE) {
		return {
			...state,
			countyDataLoading: false,
			countyData: action.countyData,
		};
	}

	if (action.type === RECEIVE_COUNTY_DATA_ERROR_TYPE) {
		return {
			...state,
			countyDataLoading: false,
			countyDataError: true,
		};
	}

	if (action.type === REQUEST_COUNTY_METRICS_TYPE) {
		return {
			...state,
			countyDataLoading: true,
		};
	}

	if (action.type === RECEIVE_COUNTY_METRICS_TYPE) {
		return {
			...state,
			countyDataLoading: false,
			countyMetrics: action.countyMetrics,
		};
	}

	if (action.type === RECEIVE_COUNTY_METRICS_ERROR_TYPE) {
		return {
			...state,
			countyDataLoading: false,
			countyDataError: true,
		};
	}

	if (action.type === REQUEST_SPREAD_SIMULATION_TYPE) {
		return {
			...state,
			simulationDataLoading: true,
		};
	}

	if (action.type === RECEIVE_SPREAD_SIMULATION_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			spreadData: action.spreadData,
		};
	}

	if (action.type === RECEIVE_SPREAD_SIMULATION_ERROR_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			spreadDataError: true,
		};
	}

	if (action.type === REQUEST_CAPACITY_SIMULATION_TYPE) {
		return {
			...state,
			simulationDataLoading: true,
		};
	}

	if (action.type === RECEIVE_CAPACITY_SIMULATION_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			capacityData: action.capacityData,
		};
	}

	if (action.type === RECEIVE_CAPACITY_SIMULATION_ERROR_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			capacityDataError: true,
		};
	}

	if (action.type === REQUEST_CAPACITY_INTERVENTION_TYPE) {
		return {
			...state,
			simulationDataLoading: true,
		};
	}

	if (action.type === RECEIVE_CAPACITY_INTERVENTION_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			capacityInterventionData: action.capacityInterventionData,
		};
	}

	if (action.type === RECEIVE_CAPACITY_INTERVENTION_ERROR_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			capacityInterventionDataError: true,
		};
	}

	if (action.type === REQUEST_INTERVENTION_SIMULATION_TYPE) {
		return {
			...state,
			simulationDataLoading: true,
		};
	}

	if (action.type === RECEIVE_INTERVENTION_SIMULATION_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			interventionData: action.interventionData,
		};
	}

	if (action.type === RECEIVE_INTERVENTION_SIMULATION_ERROR_TYPE) {
		return {
			...state,
			simulationDataLoading: false,
			interventionDataError: true,
		};
	}

	if (action.type === RECEIVE_PROJECT_DOWNLOAD_TYPE) {
		return {
			...state,
			projectDataBlob: action.projectDataBlob,
		};
	}

	if (action.type === RECEIVE_PROJECT_DOWNLOAD_ERROR_TYPE) {
		return {
			...state,
			projectDownloadError: true,
		};
	}

	if (action.type === REQUEST_KENYA_COUNTIES_DATA_TYPE) {
		return {
			...state,
			kenyaCountiesDataLoading: true,
		};
	}

	if (action.type === RECEIVE_KENYA_COUNTIES_DATA_TYPE) {
		return {
			...state,
			kenyaCountiesDataLoading: false,
			kenyaCountiesData: action.kenyaCountiesData,
		};
	}

	if (action.type === RECEIVE_KENYA_COUNTIES_DATA_ERROR_TYPE) {
		return {
			...state,
			kenyaCountiesDataLoading: false,
		};
	}

	if (action.type === REQUEST_HATE_SPEECH_COUNTY_DATA_TYPE) {
		return {
			...state,
			hateSpeechCountyDataLoading: true,
		};
	}
	if (action.type === RECEIVE_HATE_SPEECH_COUNTY_DATA_TYPE) {
		return {
			...state,
			hateSpeechCountyDataLoading: false,
			hateSpeechCountyData: action.hateSpeechCountyData,
		};
	}
	if (action.type === RECEIVE_HATE_SPEECH_COUNTY_DATA_ERROR_TYPE) {
		return {
			...state,
			hateSpeechCountyDataLoading: false,
			hateSpeechCountyDataError: true,
		};
	}

	if (action.type === RESET_FILTERS_TYPE) {
		return {
			...state,
			categoryFilters: [],
			topicFilters: [],
			subtopicFilters: [],
			keywordFilters: [],
			NLPFilter: '',
			relatedSubtopicFilter: null,
			domainFilter: '',
			mediaTypeFilter: 'all',
			entityTypeFilter: 'All',
			startDate: addDays(new Date(), -30),
			endDate: new Date(),
			daysToConsider: 30,
			searchText: '',
		};
	}
	return state;
};
