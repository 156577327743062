import stringLookup from '../stringslookup';
import { fetchSecured, postSecured, deleteSecured } from '../fetchUtils';

const requestIgnoredHostsType = 'REQUEST_CONFIG_IGNOREDHOSTS';
const receiveIgnoredHostsType = 'RECEIVE_CONFIG_IGNOREDHOSTS';
const receiveIgnoredHostsErrorType = 'RECEIVE_CONFIG_IGNOREDHOSTS_ERROR';
const addIgnoredHostType = "ADD_CONFIG_IGNOREDHOSTS'";
const addIgnoredHostCompleteType = 'ADD_CONFIG_IGNOREDHOSTS_COMPLETE';
const deleteIgnoredHostType = 'DELETE_CONFIG_IGNOREDHOSTS';
const deleteIgnoredHostCompleteType = 'DELETE_CONFIG_IGNOREDHOSTS_COMPLETE';

const REQUEST_IGNORED_IMAGES_DOMAINS_TYPE =
	'REQUEST_IGNORED_IMAGES_DOMAINS_TYPE';
const RECEIVE_IGNORED_IMAGES_DOMAINS_SUCCESS_TYPE =
	'RECEIVE_IGNORED_IMAGES_DOMAINS_SUCCESS_TYPE';
const RECEIVE_IGNORED_IMAGES_DOMAINS_ERROR_TYPE =
	'RECEIVE_IGNORED_IMAGES_DOMAINS_ERROR_TYPE';

const ADD_IGNORED_IMAGES_DOMAIN_TYPE = 'ADD_IGNORED_IMAGES_DOMAIN_TYPE';
const ADD_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE =
	'ADD_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE';
const ADD_IGNORED_IMAGES_DOMAIN_ERROR_TYPE =
	'ADD_IGNORED_IMAGES_DOMAIN_ERROR_TYPE';

const DELETE_IGNORED_IMAGES_DOMAIN_TYPE = 'DELETE_IGNORED_IMAGES_DOMAIN_TYPE';
const DELETE_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE =
	'DELETE_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE';
const DELETE_IGNORED_IMAGES_DOMAIN_ERROR_TYPE =
	'DELETE_IGNORED_IMAGES_DOMAIN_ERROR_TYPE';

const initialIgnoredHostsState = {
	ignoredHosts: [],
	totalIgnoredHosts: 0,
	ignoredHostsLoading: false,
	addingIgnoredHost: false,
	deletingIgnoredHost: false,

	ignoredImageDomainsLoading: false,
	ignoredImageDomainsError: false,
	ignoredImageDomains: [],
	totalIgnoredImageDomains: 0,
};

export const actionCreators = {
	requestIgnoredHosts: (page, pageSize) => async (dispatch, getState) => {
		if (getState().configurationIgnoredHosts.ignoredHostsLoading) {
			return;
		}

		dispatch({ type: requestIgnoredHostsType });

		const url = new URL('/api/crawler/IgnoredHost', stringLookup.baseUrl);
		url.searchParams.append('page', page);
		url.searchParams.append('pageSize', pageSize);

		try {
			const ignoredHostsResponse = await fetchSecured(url);

			if (ignoredHostsResponse.ok) {
				const ignoredHostsRes = await ignoredHostsResponse.json();
				dispatch({
					type: receiveIgnoredHostsType,
					ignoredHosts: ignoredHostsRes.data,
					totalIgnoredHosts: ignoredHostsRes.total,
				});
			} else {
				dispatch({ type: receiveIgnoredHostsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveIgnoredHostsErrorType });
		}
	},
	addIgnoredHost: (ignoredHost) => async (dispatch, getState) => {
		if (getState().configurationIgnoredHosts.addingIgnoredHost) {
			return;
		}

		dispatch({ type: addIgnoredHostType });
		const url = new URL('/api/crawler/IgnoredHost', stringLookup.baseUrl);

		try {
			const ignoredHostsResponse = await postSecured(url, ignoredHost);

			if (ignoredHostsResponse.ok) {
				//const ignoredHostsRes = await ignoredHostsResponse.json();
				dispatch({ type: addIgnoredHostCompleteType });
			} else {
				dispatch({ type: addIgnoredHostCompleteType });
			}
		} catch (err) {
			dispatch({ type: addIgnoredHostCompleteType });
		}
	},
	deleteIgnoredHost: (host) => async (dispatch, getState) => {
		if (getState().configurationIgnoredHosts.deletingIgnoredHost) {
			return;
		}

		dispatch({ type: deleteIgnoredHostType });

		const url = new URL('/api/crawler/ignoredhost', stringLookup.baseUrl);

		try {
			const deleteResponse = await deleteSecured(url, host);

			if (deleteResponse.ok) {
				dispatch({ type: deleteIgnoredHostCompleteType });
			} else {
				dispatch({ type: deleteIgnoredHostCompleteType });
			}
		} catch (err) {
			dispatch({ type: deleteIgnoredHostCompleteType });
		}
	},
	requestIgnoredImageDomains:
		(page, pageSize) => async (dispatch, getState) => {
			if (
				getState().configurationIgnoredHosts.ignoredImageDomainsLoading
			) {
				return;
			}

			dispatch({ type: REQUEST_IGNORED_IMAGES_DOMAINS_TYPE });

			const url = new URL(
				'/api/images/ignored-domains',
				stringLookup.baseUrl
			);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const ignoredImageDomains = await res.json();

					dispatch({
						type: RECEIVE_IGNORED_IMAGES_DOMAINS_SUCCESS_TYPE,
						ignoredImageDomains: ignoredImageDomains.data,
						totalIgnoredImageDomains: ignoredImageDomains.total,
					});
				} else {
					dispatch({
						type: RECEIVE_IGNORED_IMAGES_DOMAINS_ERROR_TYPE,
					});
				}
			} catch (e) {
				dispatch({
					type: RECEIVE_IGNORED_IMAGES_DOMAINS_ERROR_TYPE,
				});
			}
		},
	addIgnoredImagesDomains: (domain) => async (dispatch, getState) => {
		if (getState().configurationIgnoredHosts.ignoredImageDomainsLoading) {
			return;
		}

		dispatch({ type: ADD_IGNORED_IMAGES_DOMAIN_TYPE });

		const url = new URL(
			'/api/images/ignored-domains',
			stringLookup.baseUrl
		);

		try {
			const res = await postSecured(url, domain);

			if (res.ok) {
				dispatch({
					type: ADD_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE,
				});
			} else {
				dispatch({
					type: ADD_IGNORED_IMAGES_DOMAIN_ERROR_TYPE,
				});
			}
		} catch (e) {
			dispatch({
				type: ADD_IGNORED_IMAGES_DOMAIN_ERROR_TYPE,
			});
		}
	},

	deleteIgnoredImagesDomain: (domainId) => async (dispatch, getState) => {
		if (getState().configurationIgnoredHosts.ignoredImageDomainsLoading) {
			return;
		}

		dispatch({ type: DELETE_IGNORED_IMAGES_DOMAIN_TYPE });

		const url = new URL(
			`/api/images/ignored-domains/${domainId}`,
			stringLookup.baseUrl
		);

		try {
			const res = await deleteSecured(url);

			if (res.ok) {
				dispatch({
					type: DELETE_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE,
				});
			} else {
				dispatch({
					type: DELETE_IGNORED_IMAGES_DOMAIN_ERROR_TYPE,
				});
			}
		} catch (e) {
			dispatch({
				type: DELETE_IGNORED_IMAGES_DOMAIN_ERROR_TYPE,
			});
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialIgnoredHostsState;

	if (action.type === requestIgnoredHostsType) {
		return {
			...state,
			ignoredHostsLoading: true,
		};
	}

	if (action.type === receiveIgnoredHostsType) {
		return {
			...state,
			ignoredHosts: action.ignoredHosts,
			totalIgnoredHosts: action.totalIgnoredHosts,
			ignoredHostsLoading: false,
		};
	}
	if (action.type === receiveIgnoredHostsErrorType) {
		return {
			...state,
			ignoredHostsLoading: false,
		};
	}

	if (action.type === addIgnoredHostType) {
		return {
			...state,
			addingIgnoredHost: true,
		};
	}

	if (action.type === addIgnoredHostCompleteType) {
		return {
			...state,
			addingIgnoredHost: false,
		};
	}

	if (action.type === deleteIgnoredHostType) {
		return {
			...state,
			deletingIgnoredHost: true,
		};
	}

	if (action.type === deleteIgnoredHostCompleteType) {
		return {
			...state,
			deletingIgnoredHost: false,
		};
	}

	if (action.type === REQUEST_IGNORED_IMAGES_DOMAINS_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: true,
		};
	}

	if (action.type === RECEIVE_IGNORED_IMAGES_DOMAINS_SUCCESS_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: false,
			ignoredImageDomainsError: false,
			ignoredImageDomains: action.ignoredImageDomains,
			totalIgnoredImageDomains: action.totalIgnoredImageDomains,
		};
	}

	if (action.type === RECEIVE_IGNORED_IMAGES_DOMAINS_ERROR_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: false,
			ignoredImageDomainsError: true,
		};
	}

	if (action.type === ADD_IGNORED_IMAGES_DOMAIN_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: true,
		};
	}

	if (action.type === ADD_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: false,
		};
	}

	if (action.type === ADD_IGNORED_IMAGES_DOMAIN_ERROR_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: false,
			ignoredImageDomainsError: true,
		};
	}

	if (action.type === DELETE_IGNORED_IMAGES_DOMAIN_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: true,
		};
	}

	if (action.type === DELETE_IGNORED_IMAGES_DOMAIN_SUCCESS_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: false,
		};
	}

	if (action.type === DELETE_IGNORED_IMAGES_DOMAIN_ERROR_TYPE) {
		return {
			...state,
			ignoredImageDomainsLoading: false,
			ignoredImageDomainsError: true,
		};
	}

	return state;
};
