import stringLookup from '../stringslookup';
import { addDays } from '../_lib/timeUtil';
import {
	fetchSecured,
	postSecured,
	deleteSecured,
	putSecured,
	fetchDefault,
} from './../fetchUtils';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';

const requestSubTopicsType = 'REQUEST_CONFIG_SUBTOPICS';
const receiveSubTopicsType = 'RECEIVE_CONFIG_SUBTOPICS';
const receiveSubTopicsErrorType = 'RECEIVE_CONFIG_SUBTOPICS_FETCH_ERROR';

const requestAllSubTopicsType = 'REQUEST_CONFIG_ALL_SUBTOPICS';
const receiveAllSubTopicsType = 'RECEIVE_CONFIG_ALL_SUBTOPICS';
const receiveAllSubTopicsErrorType = 'RECEIVE_CONFIG_ALL_SUBTOPICS_FETCH_ERROR';

const addSubTopicType = 'ADD_CONFIG_SUBTOPICS';
const addSubTopicCompleteType = 'ADD_CONFIG_SUBTOPICS_COMPLETE';
const addSubTopicErrorType = 'ADD_CONFIG_SUBTOPICS_ERROR';

const editSubTopicType = 'EDIT_CONFIG_SUBTOPICS';
const editSubTopicCompleteType = 'EDIT_CONFIG_SUBTOPICS_COMPLETE';

const deleteSubTopicType = 'DELETE_CONFIG_SUBTOPICS';
const deleteSubTopicCompleteType = 'DELETE_CONFIG_SUBTOPICS_COMPLETE';
const deleteSubTopicErrorType = 'DELETE_SUBTOPIC_ERROR_TYPE';

const requestTopicListType = 'REQUEST_CONFIG_TOPICS_LIST';
const receiveTopicListType = 'RECEIVE_CONFIG_TOPICS_LIST';
const receiveTopicListErrorType = 'REQUEST_CONFIG_TOPICS_LIST_ERROR';

const modifySubTopic400ErrorType = 'MODIFY_SUBTOPICS_400_Error_COMPLETE';
const modifySubTopic400ErrorReset = 'MODIFY_SUBTOPICS_400_Error_Reset';

const requestEntityTypesListType = 'REQUEST_ENTITY_TYPE_LIST';
const receiveEntityTypesListType = 'RECEIVE_ENTITY_TYPE_LIST';
const receiveEntityTypesListErrorType = 'RECEIVE_ENTITY_TYPE_LIST_ERROR';

const requestSubtopicType = 'REQUEST_SUBTOPIC';
const receiveSubtopicType = 'RECEIVE_SUBTOPIC';
const receiveSubtopicErrorType = 'RECEIVE_SUBTOPIC_FETCH_ERROR';

const requestSubTopicsWithFiltersType = 'REQUEST_SUBTOPICS_WITH_FILTERS';
const receiveSubTopicsWithFiltersType = 'RECEIVE_SUBTOPICS_WITH_FILTERS';
const receiveSubTopicsWithFiltersErrorType =
	'RECEIVE_SUBTOPICS_WITH_FILTERS_ERROR';

const requestSubtopicTimelineType = 'REQUEST_SUBTOPIC_TIMELINE';
const receiveSubtopicTimelineType = 'RECEIVE_SUBTOPIC_TIMELINE';
const receiveSubtopicTimelineErrorType = 'RECEIVE_SUBTOPIC_TIMELINE_ERROR';

const SET_SUBTOPIC_DATA_START_DATE = 'SET_SUBTOPIC_DATA_START_DATE';
const SET_SUBTOPIC_DATA_END_DATE = 'SET_SUBTOPIC_DATA_END_DATE';

const requestSubtopicRecompilationType = 'REQUEST_SUBTOPIC_RECOMPILATION';
const receiveSubtopicRecompilationType = 'RECEIVE_SUBTOPIC_RECOMPILATION';
const receiveSubtopicRecompilationErrorType =
	'RECEIVE_SUBTOPIC_RECOMPILATION_ERROR';

const requestSubtopicCrawlType = 'REQUEST_SUBTOPIC_CRAWL';
const receiveSubtopicCrawlType = 'RECEIVE_SUBTOPIC_CRAWL';
const receiveSubtopicCrawlErrorType = 'RECEIVE_SUBTOPIC_CRAWL_ERROR';

const requestSubtopicStatusChangeType = 'REQUEST_SUBTOPIC_STATUS_CHANGE';
const subtopicStatusChangeCompleteType = 'SUBTOPIC_STATUS_CHANGE_COMPLETE';
const subtopicStatusChangeErrorType = 'SUBTOPIC_STATUS_CHANGE_ERROR';

const requestSubtopicRecompilationProgressType =
	'REQUEST_SUBTOPIC_RECOMPILATION_PROGRESS';
const receiveSubtopicRecompilationProgressType =
	'RECEIVE_SUBTOPIC_RECOMPILATION_PROGRESS';
const receiveSubtopicRecompilationProgressErrorType =
	'RECEIVE_SUBTOPIC_RECOMPILATION_PROGRESS_ERROR';

const requestSubtopicCrawlProgressType = 'REQUEST_SUBTOPIC_CRAWL_PROGRESS';
const receiveSubtopicCrawlProgressType = 'RECEIVE_SUBTOPIC_CRAWL_PROGRESS';
const receiveSubtopicCrawlProgressErrorType =
	'RECEIVE_SUBTOPIC_CRAWL_PROGRESS_ERROR';

const requestWebpageRecompilationType = 'REQUEST_WEBPAGE_RECOMPILATION';
const receiveWebpageRecompilationType = 'RECEIVE_WEBPAGE_RECOMPILATION';
const receiveWebpageRecompilationErrorType =
	'RECEIVE_WEBPAGE_RECOMPILATION_ERROR';

const requestWebpageRecompilationProgressType =
	'REQUEST_WEBPAGE_RECOMPILATION_PROGRESS';
const receiveWebpageRecompilationProgressType =
	'RECEIVE_WEBPAGE_RECOMPILATION_PROGRESS';
const receiveWebpageRecompilationProgressErrorType =
	'RECEIVE_WEBPAGE_RECOMPILATION_PROGRESS_ERROR';

const CLEAR_SUBTOPIC_DATA = 'CLEAR_SUBTOPIC_DATA';
const SET_SELECTED_STATUS_TYPE = 'SET_SELECTED_STATUS';
const SET_SELECTED_CAN_CRAWL_TYPE = 'SET_SELECTED_CAN_CRAWL';

const requestSubTopicsWithTwitterHandleType =
	'REQUEST_SUBTOPIC_WITH_TWITTER_HANDLE_TYPE';
const receiveSubTopicsWithTwitterHandleType =
	'RECEIVE_SUBTOPICS_WITH_TWITTER_HANDLE_TYPE';
const receiveSubTopicsWithTwitterHandleErrorType =
	'RECEIVE_SUBTOPICS_WITH_TWITTER_HANDLE_ERROR_TYPE';

const requestDetailedSubtopicsType = 'REQUEST_DETAILED_SUBTOPICS_TYPE	';
const receiveDetailedSubtopicsType = 'RECEIVE_DETAILED_SUBTOPICS_TYPE	';
const receiveDetailedSubtopicsErrorType =
	'REQUEST_DETAILED_SUBTOPICS_ERROR_TYPE	';

const requestBulkSubtopicsEditType = 'REQUEST_BULK_SUBTOPICS_EDIT_TYPE';
const receiveBulkSubtopicsEditType = 'RECEIVE_BULK_SUBTOPICS_EDIT_TYPE';
const receiveBulkSubtopicsEditErrorType =
	'RECEIVE_BULK_SUBTOPICS_EDIT_ERROR_TYPE';

const initialSubTopicsState = {
	subTopics: [],
	totalSubTopics: 0,
	subTopicsLoading: false,
	addingSubTopic: false,
	addSubtopicError: false,
	addedSubtopicId: null,
	deletingSubTopic: false,
	isSuccessfulDeletion: false,
	isDeletionError: false,
	topicsList: [],
	topicsListLoading: false,
	editingSubTopic: false,
	validationError: null,
	entityTypesLoading: false,
	entityTypesList: [],
	allSubTopics: [],
	allSubTopicsLoading: false,
	subtopic: null,
	subtopicLoading: false,
	subtopicError: false,
	subtopicsWithFilters: [],
	subtopicsWithFiltersLoading: false,
	subtopicsWithFiltersError: false,
	subtopicTimelineLoading: false,
	subtopicTimelineError: false,
	subtopicTimeline: {},
	subtopicMediaTypesBreakdown: {},
	subtopicDataEndDate: new Date(),
	subtopicDataStartDate: addDays(new Date(), -30),
	isCustomDate: false,
	isRecompilingSubtopic: false,
	subtopicRecompilationData: null,
	isRecompilationError: false,
	isChangingStatus: false,
	statusChangeError: false,
	selectedStatus: 'all',
	selectedCanCrawl: 'all',
	isCrawlingSubtopic: false,
	requestingSubtopicCrawl: false,
	isCrawlError: false,
	subtopicCrawlData: null,
	isRecompilingWebpage: false,
	webpageRecompilationData: null,

	subtopicsWithTwitterHandleLoading: false,
	subtopicsWithTwitterHandle: null,
	subtopicsWithTwitterHandleError: false,

	detailedSubtopics: [],
	detailedSubtopicsLoading: false,
	detailedSubtopicsLoadingError: false,

	editingBulkSubtopics: false,
	editingBulkSubtopicsError: false,
};

export const subTopicsActionCreators = {
	setSubtopicDataStartDate:
		(startDate, isCustomDate) => async (dispatch, getState) => {
			dispatch({
				type: SET_SUBTOPIC_DATA_START_DATE,
				isCustomDate: isCustomDate,
				subtopicDataStartDate: startDate,
			});
		},

	setSubtopicDataEndDate:
		(endDate, isCustomDate) => async (dispatch, getState) => {
			dispatch({
				type: SET_SUBTOPIC_DATA_END_DATE,
				isCustomDate: isCustomDate,
				subtopicDataEndDate: endDate,
			});
		},

	setSelectedStatus: (selectedStatus) => async (dispatch, getState) => {
		dispatch({
			type: SET_SELECTED_STATUS_TYPE,
			selectedStatus: selectedStatus,
		});
	},

	setSelectedCanCrawl: (selectedCanCrawl) => async (dispatch, getState) => {
		dispatch({
			type: SET_SELECTED_CAN_CRAWL_TYPE,
			selectedCanCrawl: selectedCanCrawl,
		});
	},

	clearSubtopicData: () => (dispatch) => {
		dispatch({ type: CLEAR_SUBTOPIC_DATA });
	},

	requestSubTopicById: (subtopicId) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.subtopicLoading) {
			return;
		}

		dispatch({ type: requestSubtopicType });
		// setGlobalLoad(dispatch);
		const url = new URL(
			`/api/crawler/subTopic/${subtopicId}`,
			stringLookup.baseUrl
		);

		try {
			// const subTopicResponse = await fetchSecured(url);

			const subTopicResponse = await fetchDefault(url);
			if (subTopicResponse.ok) {
				const subTopicRes = await subTopicResponse.json();

				dispatch({
					type: receiveSubtopicType,
					subtopic: subTopicRes,
				});
			} else {
				dispatch({ type: receiveSubtopicErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveSubtopicErrorType });
		}

		// unsetGlobalLoad(dispatch);
	},

	requestAllSubTopics: () => async (dispatch, getState) => {
		if (getState().configurationSubtopics.allSubTopicsLoading) {
			return;
		}

		dispatch({ type: requestAllSubTopicsType });
		const url = new URL(
			'/api/crawler/subTopic/allsubtopics',
			stringLookup.baseUrl
		);

		try {
			const allSubTopicsResponse = await fetchSecured(url);

			if (allSubTopicsResponse.ok) {
				const allSubTopicsRes = await allSubTopicsResponse.json();

				dispatch({
					type: receiveAllSubTopicsType,
					allSubTopics: allSubTopicsRes,
				});
			} else {
				dispatch({ type: receiveAllSubTopicsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveAllSubTopicsErrorType });
		}
	},

	requestSubTopicsWithFilters: () => async (dispatch, getState) => {
		if (getState().configurationSubtopics.subtopicsWithFiltersLoading) {
			return;
		}

		dispatch({ type: requestSubTopicsWithFiltersType });
		const url = new URL(
			'/api/crawler/subTopic/allsubtopics',
			stringLookup.baseUrl
		);
		url.searchParams.append('withExcludes', true);

		try {
			const subTopicsResponse = await fetchSecured(url);

			if (subTopicsResponse.ok) {
				const subTopicsRes = await subTopicsResponse.json();

				dispatch({
					type: receiveSubTopicsWithFiltersType,
					subtopicsWithFilters: subTopicsRes,
				});
			} else {
				dispatch({ type: receiveSubTopicsWithFiltersErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveSubTopicsWithFiltersErrorType });
		}
	},

	requestSubTopicsWithTwitterHandle: (handle) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.subtopicsWithTwitterHandleLoading) {
			return;
		}

		dispatch({ type: requestSubTopicsWithTwitterHandleType });
		const url = new URL(
			'/api/crawler/subTopic/allsubtopics',
			stringLookup.baseUrl
		);
		url.searchParams.append('handle', handle);

		try {
			const subTopicsResponse = await fetchSecured(url);

			if (subTopicsResponse.ok) {
				const subTopicsRes = await subTopicsResponse.json();

				dispatch({
					type: receiveSubTopicsWithTwitterHandleType,
					subtopicsWithTwitterHandle: subTopicsRes,
				});
			} else {
				dispatch({ type: receiveSubTopicsWithTwitterHandleErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveSubTopicsWithTwitterHandleErrorType });
		}
	},

	requestSubTopics:
		(
			page,
			pageSize,
			filter = '',
			subtopics = [],
			organisationId = null,
			status = null,
			canCrawl = null
		) =>
		async (dispatch, getState) => {
			if (getState().configurationSubtopics.subTopicsLoading) {
				return;
			}

			dispatch({ type: requestSubTopicsType });

			const url = new URL('/api/crawler/SubTopic', stringLookup.baseUrl);

			if (organisationId !== null && organisationId !== 'all') {
				url.searchParams.append('organisationId', organisationId);
			}

			if (status !== null && status !== 'all') {
				url.searchParams.append('status', status);
			}

			if (canCrawl !== null && canCrawl !== 'all') {
				url.searchParams.append('canCrawl', canCrawl);
			}

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (filter.length !== 0) {
				url.searchParams.append('filter', filter);
			}

			if (subtopics.length !== 0) {
				if (subtopics.includes('none')) {
					url.searchParams.append('none', true);
				} else {
					subtopics.forEach((subtopics) => {
						url.searchParams.append('Subtopics', subtopics);
					});
				}
			}

			try {
				const subTopicsResponse = await fetchSecured(url);

				if (subTopicsResponse.ok) {
					const subTopicsRes = await subTopicsResponse.json();
					dispatch({
						type: receiveSubTopicsType,
						subTopics: subTopicsRes.data,
						totalSubTopics: subTopicsRes.total,
					});
				} else {
					dispatch({ type: receiveSubTopicsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveSubTopicsErrorType });
			}
		},

	addSubTopic: (subTopic) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().configurationSubtopics.addingSubTopic) {
			return metadata;
		}

		dispatch({ type: addSubTopicType });
		const url = new URL('/api/crawler/subTopic', stringLookup.baseUrl);

		try {
			const subTopicsResponse = await postSecured(url, subTopic);

			if (subTopicsResponse.ok) {
				const subTopicsRes = await subTopicsResponse.json();
				dispatch({
					type: addSubTopicCompleteType,
					addedSubtopicId: subTopicsRes.id,
				});
				metadata.success = true;
				metadata.subtopicId = subTopicsRes.id;
			} else if (subTopicsResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await subTopicsResponse.json();
				dispatch({
					type: modifySubTopic400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: addSubTopicErrorType });
			}
		} catch (err) {
			dispatch({ type: addSubTopicErrorType });
		}

		return metadata;
	},

	editSubTopic: (subTopic) => async (dispatch, getState) => {
		const metadata = {};

		if (getState().configurationSubtopics.editingSubTopic) {
			return metadata;
		}

		dispatch({ type: editSubTopicType });
		const url = new URL('/api/crawler/subTopic', stringLookup.baseUrl);

		try {
			const subTopicsResponse = await putSecured(url, subTopic);

			if (subTopicsResponse.ok) {
				//const subTopicsRes = await subTopicsResponse.json();
				metadata.success = true;
				dispatch({ type: editSubTopicCompleteType });
			} else if (subTopicsResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await subTopicsResponse.json();
				dispatch({
					type: modifySubTopic400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: editSubTopicCompleteType });
			}
		} catch (err) {
			dispatch({ type: editSubTopicCompleteType });
		}

		return metadata;
	},

	editBulkSubtopics: (subtopics) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.editingBulkSubtopics) {
			return;
		}

		dispatch({ type: requestBulkSubtopicsEditType });
		const url = new URL('/api/crawler/subTopic/bulk', stringLookup.baseUrl);

		try {
			const subTopicsResponse = await putSecured(url, subtopics);

			if (subTopicsResponse.ok) {
				dispatch({ type: receiveBulkSubtopicsEditType });
			} else {
				dispatch({ type: receiveBulkSubtopicsEditErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveBulkSubtopicsEditErrorType });
		}
	},

	deleteSubTopic: (id) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.deletingSubTopic) {
			return;
		}

		dispatch({ type: deleteSubTopicType });

		const url = new URL(`/api/crawler/SubTopic/${id}`, stringLookup.baseUrl);

		try {
			const deleteResponse = await deleteSecured(url);
			if (deleteResponse.ok) {
				dispatch({
					type: deleteSubTopicCompleteType,
				});
			} else {
				dispatch({
					type: deleteSubTopicErrorType,
				});
			}
		} catch (err) {
			dispatch({
				type: deleteSubTopicErrorType,
			});
		}
	},

	requestTopicsList: (_) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.topicsListLoading) {
			return;
		}

		dispatch({ type: requestTopicListType });

		const url = new URL('/api/crawler/topic/alltopics', stringLookup.baseUrl);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({ type: receiveTopicListType, topicsList: data });
			} else {
				dispatch({ type: receiveTopicListErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveTopicListErrorType });
		}
	},

	requestEntityTypes: (_) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.entityTypesLoading) {
			return;
		}

		dispatch({ type: requestEntityTypesListType });

		const url = new URL(
			'/api/crawler/subtopic/entitytypes',
			stringLookup.baseUrl
		);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: receiveEntityTypesListType,
					entityTypesList: data,
				});
			} else {
				dispatch({ type: receiveEntityTypesListErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveEntityTypesListErrorType });
		}
	},

	getSubtopicTimeline:
		(subtopicId, startDate, endDate) => async (dispatch, getState) => {
			if (getState().configurationSubtopics.subtopicTimelineLoading) {
				return;
			}

			dispatch({ type: requestSubtopicTimelineType });

			const url = new URL(
				'/api/crawler/subtopic/timeline',
				stringLookup.baseUrl
			);

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('subtopic', subtopicId);

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					let report = {};
					let breakdown = new Object();

					data.forEach((x) => {
						if (x.mediaType !== 'Video') {
							report[x.mediaType] = x.timeline;
							breakdown[x.mediaType] = {
								dailyAverage: x.dailyAverage,
								total: x.total,
							};
						}
					});

					dispatch({
						type: receiveSubtopicTimelineType,
						subtopicTimeline: report,
						subtopicMediaTypesBreakdown: breakdown,
					});
				} else {
					dispatch({ type: receiveSubtopicTimelineErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveSubtopicTimelineErrorType });
			}
		},

	initiateSubtopicRecompile:
		(recompilationPayload) => async (dispatch, getState) => {
			if (getState().configurationSubtopics.isRecompilingSubtopic) {
				return;
			}

			dispatch({ type: requestSubtopicRecompilationType });

			const url = new URL(
				'/api/crawler/subtopic/recompile',
				stringLookup.baseUrl
			);

			try {
				const res = await postSecured(url, recompilationPayload);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: receiveSubtopicRecompilationType,
						subtopicRecompilationData: data,
					});
				} else {
					dispatch({ type: receiveSubtopicRecompilationErrorType });
				}
			} catch (err) {
				dispatch({
					type: receiveSubtopicRecompilationErrorType,
				});
			}
		},

	getSubtopicRecompilationData: (subtopicId) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.isRecompilingSubtopic) {
			return;
		}

		dispatch({ type: requestSubtopicRecompilationProgressType });

		const url = new URL(
			'/api/crawler/subtopic/recompile',
			stringLookup.baseUrl
		);
		url.searchParams.append('subtopicId', subtopicId);

		try {
			const res = await fetchSecured(url);
			if (res.status === 200) {
				const data = await res.json();

				dispatch({
					type: receiveSubtopicRecompilationProgressType,
					subtopicRecompilationData: data,
				});
			} else {
				dispatch({
					type: receiveSubtopicRecompilationProgressErrorType,
				});
			}
		} catch (err) {
			dispatch({
				type: receiveSubtopicRecompilationProgressErrorType,
			});
		}
	},

	initiateWebpageRecompile:
		(recompilationPayload) => async (dispatch, getState) => {
			if (getState().configurationSubtopics.isRecompilingWebpage) {
				return;
			}

			dispatch({ type: requestWebpageRecompilationType });

			const url = new URL(
				'/api/crawler/subtopic/recompile',
				stringLookup.baseUrl
			);

			try {
				const res = await postSecured(url, recompilationPayload);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: receiveWebpageRecompilationType,
						webpageRecompilationData: data,
					});
				} else {
					dispatch({ type: receiveWebpageRecompilationErrorType });
				}
			} catch (err) {
				dispatch({
					type: receiveWebpageRecompilationErrorType,
				});
			}
		},

	getWebpageRecompilationData: (webpageId) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.isRecompilingWebpage) {
			return;
		}

		dispatch({ type: requestWebpageRecompilationProgressType });

		const url = new URL(
			'/api/crawler/subtopic/recompile',
			stringLookup.baseUrl
		);
		url.searchParams.append('webpageId', webpageId);

		try {
			const res = await fetchSecured(url);
			if (res.status === 200) {
				const data = await res.json();

				dispatch({
					type: receiveWebpageRecompilationProgressType,
					webpageRecompilationData: data,
				});
			} else {
				dispatch({
					type: receiveWebpageRecompilationProgressErrorType,
				});
			}
		} catch (err) {
			dispatch({
				type: receiveWebpageRecompilationProgressErrorType,
			});
		}
	},

	resetValidationMessage: () => async (dispatch, getState) => {
		dispatch({ type: modifySubTopic400ErrorReset });
	},

	changeSubtopicStatus:
		(subtopicStatusChange) => async (dispatch, getState) => {
			if (getState().configurationSubtopics.isChangingStatus) {
				return;
			}

			dispatch({ type: requestSubtopicStatusChangeType });

			const url = new URL(
				'/api/crawler/subtopic/state-change',
				stringLookup.baseUrl
			);

			try {
				const res = await postSecured(url, subtopicStatusChange);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: subtopicStatusChangeCompleteType,
					});
				} else {
					dispatch({ type: subtopicStatusChangeErrorType });
				}
			} catch (err) {
				dispatch({
					type: subtopicStatusChangeErrorType,
				});
			}
		},

	crawlSubtopic: (crawlBody) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.requestingSubtopicCrawl) return;

		dispatch({ type: requestSubtopicCrawlType });

		const url = new URL(
			'/api/crawler/subtopic/dynamic-crawl',
			stringLookup.baseUrl
		);

		try {
			const res = await postSecured(url, crawlBody);

			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: receiveSubtopicCrawlType,
					subtopicCrawlData: data,
				});
			} else {
				dispatch({ type: receiveSubtopicCrawlErrorType });
			}
		} catch (err) {
			dispatch({
				type: receiveSubtopicCrawlErrorType,
			});
		}
	},

	getSubtopicCrawlProgress: (subtopicId) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.isCrawlingSubtopic) return;

		dispatch({ type: requestSubtopicCrawlProgressType });

		const url = new URL(
			`/api/crawler/subtopic/${subtopicId}/dynamic-crawl`,
			stringLookup.baseUrl
		);

		try {
			const res = await fetchSecured(url);
			if (res.ok) {
				const data = res.status === 200 ? await res.json() : null;
				dispatch({
					type: receiveSubtopicCrawlProgressType,
					subtopicCrawlData: data,
				});
			} else {
				dispatch({
					type: receiveSubtopicCrawlProgressErrorType,
				});
			}
		} catch (err) {
			dispatch({
				type: receiveSubtopicCrawlProgressErrorType,
			});
		}
	},

	getDetailedSubtopics: (subtopicIds) => async (dispatch, getState) => {
		if (getState().configurationSubtopics.detailedSubtopicsLoading) return;

		dispatch({ type: requestDetailedSubtopicsType });

		const url = new URL('/api/crawler/subtopic/', stringLookup.baseUrl);

		const page = 1;
		const pageSize = subtopicIds.length;

		url.searchParams.append('page', page);
		url.searchParams.append('pageSize', pageSize);

		if (subtopicIds.length !== 0) {
			subtopicIds.forEach((id) => {
				url.searchParams.append('Subtopics', id);
			});
		}

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: receiveDetailedSubtopicsType,
					detailedSubtopics: data?.data,
				});
			} else {
				dispatch({
					type: receiveDetailedSubtopicsErrorType,
				});
			}
		} catch (err) {
			dispatch({
				type: receiveSubtopicCrawlProgressErrorType,
			});
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialSubTopicsState;

	if (action.type === SET_SUBTOPIC_DATA_START_DATE) {
		return {
			...state,
			isCustomDate: action.isCustomDate,
			subtopicDataStartDate: action.subtopicDataStartDate,
		};
	}

	if (action.type === SET_SUBTOPIC_DATA_END_DATE) {
		return {
			...state,
			isCustomDate: action.isCustomDate,
			subtopicDataEndDate: action.subtopicDataEndDate,
		};
	}

	if (action.type === SET_SELECTED_STATUS_TYPE) {
		return {
			...state,
			selectedStatus: action.selectedStatus,
		};
	}

	if (action.type === SET_SELECTED_CAN_CRAWL_TYPE) {
		return {
			...state,
			selectedCanCrawl: action.selectedCanCrawl,
		};
	}

	if (action.type === CLEAR_SUBTOPIC_DATA) {
		return {
			...state,
			subtopic: null,
		};
	}

	if (action.type === requestAllSubTopicsType) {
		return {
			...state,
			allSubTopicsLoading: true,
		};
	}

	if (action.type === receiveAllSubTopicsType) {
		return {
			...state,
			allSubTopics: action.allSubTopics,
			validationError: null,
			allSubTopicsLoading: false,
		};
	}

	if (action.type === receiveAllSubTopicsErrorType) {
		return {
			...state,
			allSubTopicsLoading: false,
		};
	}

	if (action.type === requestSubTopicsType) {
		return {
			...state,
			subTopicsLoading: true,
		};
	}
	if (action.type === receiveSubTopicsType) {
		return {
			...state,
			subTopics: action.subTopics,
			totalSubTopics: action.totalSubTopics,
			validationError: null,
			subTopicsLoading: false,
		};
	}
	if (action.type === receiveSubTopicsErrorType) {
		return {
			...state,
			subTopicsLoading: false,
		};
	}

	if (action.type === addSubTopicType) {
		return {
			...state,
			addingSubTopic: true,
			addSubtopicError: false,
			addedSubtopicId: null,
		};
	}
	if (action.type === addSubTopicCompleteType) {
		return {
			...state,
			addingSubTopic: false,
			addSubtopicError: false,
			addedSubtopicId: action.addedSubtopicId,
		};
	}
	if (action.type === addSubTopicErrorType) {
		return {
			...state,
			addingSubTopic: false,
			addSubtopicError: true,
		};
	}

	if (action.type === editSubTopicType) {
		return {
			...state,
			editingSubTopic: true,
		};
	}

	if (action.type === editSubTopicCompleteType) {
		return {
			...state,
			editingSubTopic: false,
		};
	}
	if (action.type === deleteSubTopicType) {
		return {
			...state,
			deletingSubTopic: true,
		};
	}
	if (action.type === deleteSubTopicCompleteType) {
		return {
			...state,
			deletingSubTopic: false,
			isDeletionError: false,
			isSuccessfulDeletion: true,
		};
	}

	if (action.type === deleteSubTopicErrorType) {
		return {
			...state,
			isDeletionError: true,
			isSuccessfulDeletion: false,
			deletingSubTopic: false,
		};
	}
	if (action.type === requestTopicListType) {
		return {
			...state,
			topicsListLoading: true,
		};
	}
	if (action.type === receiveTopicListType) {
		return {
			...state,
			topicsListLoading: false,
			topicsList: action.topicsList,
		};
	}
	if (action.type === receiveTopicListErrorType) {
		return {
			...state,
			topicsListLoading: false,
		};
	}

	if (action.type === modifySubTopic400ErrorType) {
		return {
			...state,
			validationError: action.validationError,
			editingSubTopic: false,
			addingSubTopic: false,
		};
	}
	if (action.type === modifySubTopic400ErrorReset) {
		return {
			...state,
			validationError: null,
		};
	}

	if (action.type === requestEntityTypesListType) {
		return {
			...state,
			entityTypesLoading: true,
		};
	}
	if (action.type === receiveEntityTypesListType) {
		return {
			...state,
			entityTypesLoading: false,
			entityTypesList: action.entityTypesList,
		};
	}
	if (action.type === receiveTopicListErrorType) {
		return {
			...state,
			entityTypesLoading: false,
		};
	}

	if (action.type === requestSubtopicType) {
		return {
			...state,
			subtopic: null,
			subtopicLoading: true,
			subtopicError: false,
		};
	}

	if (action.type === receiveSubtopicType) {
		return {
			...state,
			subtopic: action.subtopic,
			subtopicLoading: false,
		};
	}

	if (action.type === receiveSubtopicErrorType) {
		return {
			...state,
			subtopicLoading: false,
			subtopicError: true,
		};
	}

	if (action.type === requestSubTopicsWithFiltersType) {
		return {
			...state,
			subtopicsWithFiltersLoading: true,
			subtopicsWithFiltersError: false,
		};
	}

	if (action.type === receiveSubTopicsWithFiltersType) {
		return {
			...state,
			subtopicsWithFilters: action.subtopicsWithFilters,
			subtopicsWithFiltersLoading: false,
		};
	}

	if (action.type === receiveSubTopicsWithFiltersErrorType) {
		return {
			...state,
			subtopicsWithFiltersLoading: false,
			subtopicsWithFiltersError: true,
		};
	}

	if (action.type === requestSubtopicTimelineType) {
		return {
			...state,
			subtopicTimelineLoading: true,
			subtopicTimelineError: false,
		};
	}

	if (action.type === receiveSubtopicTimelineType) {
		return {
			...state,
			subtopicTimelineLoading: false,
			subtopicTimeline: action.subtopicTimeline,
			subtopicMediaTypesBreakdown: action.subtopicMediaTypesBreakdown,
		};
	}

	if (action.type === receiveSubtopicTimelineErrorType) {
		return {
			...state,
			subtopicTimelineLoading: false,
			subtopicTimelineError: true,
		};
	}

	if (action.type === requestSubtopicRecompilationType) {
		return {
			...state,
			isRecompilingSubtopic: true,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveSubtopicRecompilationType) {
		return {
			...state,
			subtopicRecompilationData: action.subtopicRecompilationData,
			isRecompilingSubtopic: false,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveSubtopicRecompilationErrorType) {
		return {
			...state,
			isRecompilingSubtopic: false,
			isRecompilationError: true,
		};
	}

	if (action.type === requestSubtopicRecompilationProgressType) {
		return {
			...state,
			isRecompilingSubtopic: true,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveSubtopicRecompilationProgressType) {
		return {
			...state,
			subtopicRecompilationData: action.subtopicRecompilationData,
			isRecompilingSubtopic: false,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveSubtopicRecompilationProgressErrorType) {
		return {
			...state,
			isRecompilingSubtopic: false,
			isRecompilationError: true,
			subtopicRecompilationData: null,
		};
	}

	if (action.type === requestSubtopicStatusChangeType) {
		return {
			...state,
			isChangingStatus: true,
			statusChangeError: false,
		};
	}
	if (action.type === subtopicStatusChangeCompleteType) {
		return {
			...state,
			isChangingStatus: false,
		};
	}
	if (action.type === subtopicStatusChangeErrorType) {
		return {
			...state,
			isChangingStatus: false,
			statusChangeError: true,
		};
	}

	if (action.type === requestSubtopicCrawlType) {
		return {
			...state,
			requestingSubtopicCrawl: true,
			isCrawlError: false,
		};
	}
	if (action.type === receiveSubtopicCrawlType) {
		return {
			...state,
			subtopicCrawlData: action.subtopicCrawlData,
			isCrawlingSubtopic: false,
			requestingSubtopicCrawl: false,
		};
	}
	if (action.type === receiveSubtopicCrawlErrorType) {
		return {
			...state,
			isCrawlingSubtopic: false,
			isCrawlError: true,
		};
	}

	if (action.type === requestSubtopicCrawlProgressType) {
		return {
			...state,
			isCrawlingSubtopic: true,
			isCrawlError: false,
		};
	}
	if (action.type === receiveSubtopicCrawlProgressType) {
		return {
			...state,
			subtopicCrawlData: action.subtopicCrawlData,
			isCrawlingSubtopic: false,
		};
	}
	if (action.type === receiveSubtopicCrawlProgressErrorType) {
		return {
			...state,
			isCrawlingSubtopic: false,
			isCrawlError: true,
		};
	}

	if (action.type === requestWebpageRecompilationType) {
		return {
			...state,
			isRecompilingWebpage: true,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveWebpageRecompilationType) {
		return {
			...state,
			webpageRecompilationData: action.webpageRecompilationData,
			isRecompilingWebpage: false,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveWebpageRecompilationErrorType) {
		return {
			...state,
			isRecompilingWebpage: false,
			isRecompilationError: true,
		};
	}

	if (action.type === requestWebpageRecompilationProgressType) {
		return {
			...state,
			isRecompilingWebpage: true,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveWebpageRecompilationProgressType) {
		return {
			...state,
			webpageRecompilationData: action.webpageRecompilationData,
			isRecompilingWebpage: false,
			isRecompilationError: false,
		};
	}
	if (action.type === receiveWebpageRecompilationProgressErrorType) {
		return {
			...state,
			isRecompilingWebpage: false,
			isRecompilationError: true,
			webpageRecompilationData: null,
		};
	}

	if (action.type === requestSubTopicsWithTwitterHandleType) {
		return {
			...state,
			subtopicsWithTwitterHandleLoading: true,
		};
	}

	if (action.type === receiveSubTopicsWithTwitterHandleType) {
		return {
			...state,
			subtopicsWithTwitterHandleLoading: false,
			subtopicsWithTwitterHandle: action.subtopicsWithTwitterHandle,
		};
	}

	if (action.type === receiveSubTopicsWithTwitterHandleErrorType) {
		return {
			...state,
			subtopicsWithTwitterHandleLoading: false,
			subtopicsWithTwitterHandleError: true,
		};
	}

	if (action.type === requestDetailedSubtopicsType) {
		return {
			...state,
			detailedSubtopics: [],
			detailedSubtopicsLoading: true,
			detailedSubtopicsLoadingError: false,
		};
	}

	if (action.type === receiveDetailedSubtopicsType) {
		return {
			...state,
			detailedSubtopics: action.detailedSubtopics,
			detailedSubtopicsLoading: false,
			detailedSubtopicsLoadingError: false,
		};
	}

	if (action.type === receiveDetailedSubtopicsErrorType) {
		return {
			...state,
			detailedSubtopicsLoading: false,
			detailedSubtopicsLoadingError: true,
		};
	}

	if (action.type === requestBulkSubtopicsEditType) {
		return {
			...state,
			editingBulkSubtopics: true,
			editingBulkSubtopicsError: false,
		};
	}

	if (action.type === receiveBulkSubtopicsEditType) {
		return {
			...state,
			editingBulkSubtopics: false,
			editingBulkSubtopicsError: false,
		};
	}

	if (action.type === receiveBulkSubtopicsEditErrorType) {
		return {
			...state,
			editingBulkSubtopics: false,
			editingBulkSubtopicsError: true,
		};
	}

	return state;
};
