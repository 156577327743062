import stringLookup from './../stringslookup';
import { fetchSecured, putSecured, patchSecured, postSecured } from '../fetchUtils';

const addNarrativeType = 'ADD_NARRATIVE';
const addNarrativeCompleteType = 'ADD_CONFIG_TOPICS_COMPLETE';

const requestNarrativeType = 'REQUEST_CONFIG_NARRATIVE';
const receiveNarrativeType = 'RECEIVE_CONFIG_NARRATIVE';
const receiveNarrativeErrorType = 'RECEIVE_CONFIG_NARRATIVE_FETCH_ERROR';
const deleteNarrativeType = 'DELETE_CONFIG_NARRATIVE';
const deleteNarrativeCompleteType = 'DELETE_CONFIG_NARRATIVE_COMPLETE';

const modifyNarrative400ErrorType = 'MODIFY_NARRATIVE_400_Error_COMPLETE';
const modifyNarrative400ErrorReset = 'MODIFY_NARRATIVE_400_Error_Reset';
const initialNarrativeState = {
	narratives: [],
	totalNarratives: 0,
	narrativesLoading: false,
	addingNarrative: false,
	editingNarrative: false,
	deletingNarrative: false,
	validationError: null,
};

export const narrativeActionCreators = {
	resetValidationMessage: () => async (dispatch, getState) => {
		dispatch({ type: modifyNarrative400ErrorReset });
	},

	requestNarrative: () => async (dispatch, getState) => {
		if (getState().configurationNarratives.narrativesLoading) {
			return;
		}

		dispatch({ type: requestNarrativeType });

		const url = new URL('/api/category', stringLookup.machineLearningApi);

		try {
			const narResponse = await fetchSecured(url);

			if (narResponse.ok) {
				const narRes = await narResponse.json();
				dispatch({
					type: receiveNarrativeType,
					narratives: narRes,
					totalNarratives: narRes,
				});
			} else {
				dispatch({ type: receiveNarrativeErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveNarrativeErrorType });
		}
	},
	editNar:
		(nar, mode = 'ADD') =>
		async (dispatch, getState) => {
			const metadata = {};
			if (getState().configurationTopics.addingNar) {
				return metadata;
			}

			dispatch({ type: addNarrativeType });
			const url = new URL('/api/category/', stringLookup.machineLearningApi);

			try {
				const narResponse =
					mode === 'ADD'
						? await postSecured(url, nar)
						: await putSecured(url, nar);

				if (narResponse.ok) {
					dispatch({ type: addNarrativeCompleteType });
				} else if (narResponse.status === 400) {
					metadata._400Error = true;

					const _400ResMessage = await narResponse.json();
					dispatch({
						type: modifyNarrative400ErrorType,
						validationError: _400ResMessage.message,
					});
				} else {
					dispatch({ type: addNarrativeCompleteType });
				}
			} catch (err) {
				dispatch({ type: addNarrativeCompleteType });
			}

			return metadata;
		},
};

export const reducer = (state, action) => {
	state = state || initialNarrativeState;

	if (action.type === requestNarrativeType) {
		return {
			...state,
			narrativesLoading: true,
		};
	}

	if (action.type === receiveNarrativeType) {
		return {
			...state,
			narratives: action.narratives,
			totalNarratives: action.totalNarratives,
			narrativesLoading: false,
			validationError: null,
		};
	}
	if (action.type === receiveNarrativeErrorType) {
		return {
			...state,
			narrativesLoading: false,
		};
	}

	if (action.type === addNarrativeType) {
		return {
			...state,
			addingNar: true,
		};
	}

	if (action.type === addNarrativeCompleteType) {
		return {
			...state,
			addingNar: false,
		};
	}

	// if (action.type === deleteTopicType) {
	// 	return {
	// 		...state,
	// 		deletingTopic: true
	// 	};
	// }

	// if (action.type === deleteTopicCompleteType) {
	// 	return {
	// 		...state,
	// 		deletingTopic: false
	// 	};
	// }

	if (action.type === modifyNarrative400ErrorType) {
		return {
			...state,
			validationError: action.validationError,
			addingNar: false,
		};
	}

	// if (action.type === modifyNarrative400ErrorReset) {
	//     return {
	//         ...state,
	//         validationError: null,
	//     }
	// }

	return state;
};
