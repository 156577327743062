import { fetchSecured, postSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const REQUEST_TEXT_READER_CLASSIFIER_TYPE =
	'REQUEST_TEXT_READER_CLASSIFIER_TYPE';
const RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_TYPE =
	'RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_TYPE';
const RECEIVE_TEXT_READER_CLASSIFIER_ERROR_TYPE =
	'RECEIVE_TEXT_READER_CLASSIFIER_ERROR_TYPE';

const REQUEST_CRAWLERS_TYPE = 'REQUEST_CRAWLERS_TYPE';
const RECEIVE_CRAWLERS_SUCCESS_TYPE = 'RECEIVE_CRAWLERS_SUCCESS_TYPE';
const RECEIVE_CRAWLERS_ERROR_TYPE = 'RECEIVE_CRAWLERS_ERROR_TYPE';

const REQUEST_TEXT_READER_CLASSIFIER_POST_TYPE =
	'REQUEST_TEXT_READER_CLASSIFIER_POST_TYPE';
const RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_POST_TYPE =
	'RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_POST_TYPE';
const RECEIVE_TEXT_READER_CLASSIFIER_ERROR_POST_TYPE =
	'RECEIVE_TEXT_READER_CLASSIFIER_ERROR_POST_TYPE';

const initialState = {
	textReaderClassifier: null,
	textReaderClassifierLoading: false,
	textReaderClassifierError: false,

	crawlers: null,
	crawlersLoading: false,
	crawlersError: false,
};

export const textReaderActionCreators = {
	getCrawlers: () => async (dispatch, getState) => {
		if (getState().textReader.crawlersLoading) {
			return;
		}

		dispatch({ type: REQUEST_CRAWLERS_TYPE });

		try {
			const url = new URL(
				'/api/crawler/textreader/crawlers',
				stringLookup.baseUrl
			);

			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: RECEIVE_CRAWLERS_SUCCESS_TYPE,
					crawlers: data,
				});
			} else {
				dispatch({ type: RECEIVE_CRAWLERS_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: RECEIVE_CRAWLERS_ERROR_TYPE });
		}
	},
	getActiveTextReaderClassifier:
		(crawlerType) => async (dispatch, getState) => {
			if (getState().textReader.textReaderClassifierLoading) {
				return;
			}

			dispatch({ type: REQUEST_TEXT_READER_CLASSIFIER_TYPE });

			try {
				const url = new URL('/api/crawler/textreader', stringLookup.baseUrl);

				if (crawlerType) {
					url.searchParams.append('crawlerType', crawlerType);
				}

				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_TYPE,
						textReaderClassifier: data,
					});
				} else {
					dispatch({ type: RECEIVE_TEXT_READER_CLASSIFIER_ERROR_TYPE });
				}
			} catch (error) {
				dispatch({ type: RECEIVE_TEXT_READER_CLASSIFIER_ERROR_TYPE });
			}
		},

	postTextReaderClassifier: (payload) => async (dispatch, getState) => {
		if (getState().textReader.textReaderClassifierLoading) {
			return;
		}

		dispatch({ type: REQUEST_TEXT_READER_CLASSIFIER_POST_TYPE });

		try {
			const url = new URL('/api/crawler/textreader', stringLookup.baseUrl);

			const res = await postSecured(url, payload);

			if (res.ok) {
				dispatch({
					type: RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_POST_TYPE,
				});
			} else {
				dispatch({ type: RECEIVE_TEXT_READER_CLASSIFIER_ERROR_POST_TYPE });
			}
		} catch (error) {
			dispatch({ type: RECEIVE_TEXT_READER_CLASSIFIER_ERROR_POST_TYPE });
		}
	},
};

const textReaderReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_TEXT_READER_CLASSIFIER_TYPE:
			return {
				...state,
				textReaderClassifierLoading: true,
				textReaderClassifierError: false,
			};
		case RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_TYPE:
			return {
				...state,
				textReaderClassifierLoading: false,
				textReaderClassifierError: false,
				textReaderClassifier: action.textReaderClassifier,
			};
		case RECEIVE_TEXT_READER_CLASSIFIER_ERROR_TYPE:
			return {
				...state,
				textReaderClassifierLoading: false,
				textReaderClassifierError: true,
			};
		case REQUEST_TEXT_READER_CLASSIFIER_POST_TYPE:
			return {
				...state,
				textReaderClassifierLoading: true,
				textReaderClassifierError: false,
			};
		case RECEIVE_TEXT_READER_CLASSIFIER_SUCCESS_POST_TYPE:
			return {
				...state,
				textReaderClassifierLoading: false,
				textReaderClassifierError: false,
			};
		case RECEIVE_TEXT_READER_CLASSIFIER_ERROR_POST_TYPE:
			return {
				...state,
				textReaderClassifierLoading: false,
				textReaderClassifierError: true,
			};

		case REQUEST_CRAWLERS_TYPE:
			return {
				...state,
				crawlersLoading: true,
				crawlersError: false,
			};
		case RECEIVE_CRAWLERS_SUCCESS_TYPE:
			return {
				...state,
				crawlersLoading: false,
				crawlersError: false,
				crawlers: action.crawlers,
			};
		case RECEIVE_CRAWLERS_ERROR_TYPE:
			return {
				...state,
				crawlersLoading: false,
				crawlersError: true,
			};
		default:
			return state;
	}
};

export default textReaderReducer;
