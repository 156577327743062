import stringLookup from '../stringslookup';
import {
	KaziMtaaniPost,
	KaziMtaaniPut,
	kaziMtaaniFetch,
	kaziMtaaniDelete,
} from '../fetchUtils';

const requestSupervisorsType = 'REQUEST_SUPERVISOR_LIST';
const receiveSupervisorsType = 'RECEIVE_SUPERVISOR_LIST';
const receiveSupervisorsErrorType = 'RECEIVE_SUPERVISOR_LIST_ERROR';

const requestLocationsType = 'REQUEST_LOCATIONS_LIST';
const receiveLocationsType = 'RECEIVE_LOCATIONS_LIST';
const receiveLocationsErrorType = 'RECEIVE_LOCATIONS_LIST_ERROR';

const requestAddSupervisorType = 'REQUEST_ADD_SUPERVISOR';
const addSupervisorCompleteType = 'ADD_SUPERVISOR_COMPLETE';
const addSupervisorErrorType = 'ADD_SUPERVISOR_ERROR';

const requestDeleteSupervisorType = 'REQUEST_DELETE_SUPERVISOR';
const deleteSupervisorCompleteType = 'DELETE_SUPERVISOR_COMPLETE';
const deleteSupervisorErrorType = 'DELETE_SUPERVISOR_ERROR';

const requestUpdateSupervisorType = 'REQUEST_UPDATE_SUPERVISOR';
const updateSupervisorCompleteType = 'UPDATE_SUPERVISOR_COMPLETE';
const updateSupervisorErrorType = 'UPDATE_SUPERVISOR_ERROR';

const requestWorkersType = 'REQUEST_WORKERS_LIST';
const receiveWorkersType = 'RECEIVE_WORKERS_LIST';
const receiveWorkersErrorType = 'RECEIVE_WORKERS_LIST_ERROR';

const requestDeleteWorkerType = 'REQUEST_DELETE_WORKER';
const deleteWorkerCompleteType = 'DELETE_WORKER_COMPLETE';
const deleteWorkerErrorType = 'DELETE_WORKER_ERROR';

const requestUpdateWorkerType = 'REQUEST_UPDATE_WORKER';
const updateWorkerCompleteType = 'UPDATE_WORKER_COMPLETE';
const updateWorkerErrorType = 'UPDATE_WORKER_ERROR';

const requestCohortsType = 'REQUEST_COHORTS_LIST';
const receiveCohortsType = 'RECEIVE_COHORTS_LIST';
const receiveCohortsErrorType = 'RECEIVE_COHORTS_LIST_ERROR';

const requestCohortCyclesType = 'REQUEST_COHORT_CYCLES';
const receiveCohortCyclesType = 'RECEIVE_COHORT_CYCLES';
const receiveCohortCyclesErrorType = 'RECEIVE_COHORT_CYCLES_ERROR';

const requestApprovalResidentsType = 'REQUEST_APPROVAL_RESIDENTS';
const receiveApprovalResidentsType = 'RECEIVE_APPROVAL_RESIDENTS';
const receiveApprovalResidentsErrorType = 'RECEIVE_APPROVAL_RESIDENTS';

const requestCountySummaryType = 'REQUEST_COUNTY_SUMMARY';
const receiveCountySummaryType = 'RECEIVE_COUNTY_SUMMARY';
const receiveCountySummaryErrorType = 'RECEIVE_COUNTY_SUMMARY_ERROR';

const requestNHPSummaryType = 'REQUEST_NHP_SUMMARY';
const receiveNHPSummaryType = 'RECEIVE_NHP_SUMMARY';
const receiveNHPSummaryErrorType = 'RECEIVE_NHP_SUMMARY_ERROR';

const requestExecutiveSummaryType = 'REQUEST_EXECUTIVE_SUMMARY';
const receiveExecutiveSummaryType = 'RECEIVE_EXECUTIVE_SUMMARY';
const receiveExecutiveSummaryErrorType = 'RECEIVE_EXECUTIVE_SUMMARY_ERROR';

const requestApproveCountyType = 'REQUEST_APPROVE_COUNTY';
const approveCountyCompleteType = 'APPROVE_COUNTY_COMPLETE';
const approveCountyErrorType = 'APPROVE_COUNTY_ERROR';

const requestApproveNHPType = 'REQUEST_APPROVE_NHP';
const approveNHPCompleteType = 'APPROVE_NHP_COMPLETE';
const approveNHPErrorType = 'APPROVE_NHP_ERROR';

const requestApproveExecutiveType = 'REQUEST_APPROVE_EXECUTIVE';
const approveExecutiveCompleteType = 'APPROVE_EXECUTIVE_COMPLETE';
const approveExecutiveErrorType = 'APPROVE_EXECUTIVE_ERROR';

const requestAmmendResidentType = 'REQUEST_AMMEND_RESIDENT';
const ammendResidentCompleteType = 'AMMEND_RESIDENT_COMPLETE';
const ammendResidentErrorType = 'AMMEND_RESIDENT_ERROR';

const requestPermissionsType = 'REQUEST_KM_PERMISSIONS';
const receivePermissionsType = 'RECEIVE_KM_PERMISSIONS';
const receivePermissionsErrorType = 'RECEIVE_KM_PERMISSIONS_ERROR';

const requestImportSupervisorType = 'REQUEST_IMPORT_SUPERVISOR';
const receiveImportSupervisorType = 'RECEIVE_IMPORT_SUPERVISOR';
const receiveImportSupervisorErrorType = 'RECEIVE_IMPORT_SUPERVISOR_ERROR';

const requestDownloadApprovalsType = 'REQUEST_DOWNLOAD_APPROVALS';
const receiveDownloadApprovalsType = 'RECEIVE_DOWNLOAD_APPROVALS';
const receiveDownloadApprovalsErrorType = 'RECEIVE_DOWNLOAD_APPROVALS_ERROR';

const SET_COHORT_CYCLE_TYPE = 'SET_COHORT_CYCLE';

const initialState = {
	supervisors: [],
	totalSupervisors: 0,
	supervisorListLoading: false,
	locationsLoading: false,
	counties: [],
	selectedGroup: '',
	addingSupervisor: false,
	deletingSupervisor: false,
	updatingSupervisor: false,
	isRejected: false,
	rejectionReason: '',
	workers: [],
	totalWorkers: 0,
	workersListLoading: false,
	updatingWorker: false,
	deletingWorker: false,
	updateWorkerRejected: false,
	duplicateWorkerId: false,
	cohortsLoading: false,
	cohorts: [],
	cohortCycles: [],
	cohortCyclesLoading: false,
	approvalResidents: [],
	totalApprovalResidents: 0,
	approvalResidentsLoading: false,
	countySummary: [],
	countySummaryLoading: false,
	totalCountySummary: 0,
	nhpSummary: [],
	nhpSummaryLoading: false,
	totalNhpSummary: 0,
	availablePermissions: [],
	countyErrorMessage: '',
	countyApprovalError: false,
	executiveSummary: [],
	executiveSummaryLoading: false,
	totalExecutiveSummary: 0,
	nhpApprovalError: false,
	nhpErrorMessage: '',
	ammendmentError: false,
	ammendmentErrorMessage: '',
	executiveApprovalError: false,
	executiveErrorMessage: '',
	kaziMtaaniUserProfile: null,
	approvalRecordsDownloading: false,
	approvalRecordsDownload: [],
	approvalDownloadError: false,
	approvalDownloadErrorMessage: '',
	cohortCycle: null,
	cohortStartDate: null,
	cohortEndDate: null,
};

export const kaziMtaaniManagementActionCreators = {
	requestSupervisorsList:
		(page = 1, pageSize = 10, counties, searchString) =>
		async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.supervisorListLoading) {
				return;
			}

			dispatch({ type: requestSupervisorsType });

			const url = new URL('/api/supervisors', stringLookup.kaziMtaaniUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (counties) {
				counties.forEach((c) => {
					url.searchParams.append('counties', c);
				});
			}

			if (searchString && searchString.length > 0) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveSupervisorsType,
						supervisors: data.results,
						totalSupervisors: data.total,
					});
				} else {
					dispatch({ type: receiveSupervisorsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveSupervisorsErrorType });
			}
		},

	requestWorkersList:
		(page = 1, pageSize = 10, counties, searchString) =>
		async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.workersListLoading) {
				return;
			}

			dispatch({ type: requestWorkersType });

			const url = new URL('/api/resident', stringLookup.kaziMtaaniUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (counties) {
				counties.forEach((c) => {
					url.searchParams.append('county', c);
				});
			}

			if (searchString && searchString.length > 0) {
				url.searchParams.append('SearchString', searchString);
			}

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveWorkersType,
						workers: data.results,
						totalWorkers: data.total,
					});
				} else {
					dispatch({ type: receiveWorkersErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveWorkersErrorType });
			}
		},

	requestCountiesList: () => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.locationsLoading) {
			return;
		}

		dispatch({ type: requestLocationsType });

		const url = new URL('/api/locations/counties', stringLookup.kaziMtaaniUrl);

		try {
			const res = await kaziMtaaniFetch(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({ type: receiveLocationsType, counties: data });
			} else {
				dispatch({ type: receiveLocationsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveLocationsErrorType });
		}
	},

	requestCohortsList:
		(page = 1, pageSize = 20) =>
		async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.cohortsLoading) {
				return;
			}

			dispatch({ type: requestCohortsType });

			const url = new URL('/api/cohorts', stringLookup.kaziMtaaniUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({ type: receiveCohortsType, cohorts: data.results });
				} else {
					dispatch({ type: receiveCohortsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveCohortsErrorType });
			}
		},

	requestCohortCyclesList: () => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.cohortCyclesLoading) {
			return;
		}

		dispatch({ type: requestCohortCyclesType });

		const url = new URL('/api/cohorts/timeline', stringLookup.kaziMtaaniUrl);

		try {
			const res = await kaziMtaaniFetch(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({ type: receiveCohortCyclesType, cohortCycles: data });
			} else {
				dispatch({ type: receiveCohortCyclesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveCohortCyclesErrorType });
		}
	},

	requestKaziMtaaniPermissions: () => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.locationsLoading) {
			return;
		}

		dispatch({ type: requestPermissionsType });

		const url = new URL('/api/supervisors/permissions', stringLookup.kaziMtaaniUrl);

		try {
			const res = await kaziMtaaniFetch(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({ type: receivePermissionsType, availablePermissions: data });
			} else {
				dispatch({ type: receivePermissionsErrorType });
			}
		} catch (err) {
			dispatch({ type: receivePermissionsErrorType });
		}
	},

	importKaziMtaaniSupervisor: () => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.supervisorListLoading) {
			return;
		}

		dispatch({ type: requestImportSupervisorType });

		const url = new URL('/api/import', stringLookup.kaziMtaaniUrl);

		try {
			const res = await kaziMtaaniFetch(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: receiveImportSupervisorType,
					kaziMtaaniUserProfile: data,
				});
			} else {
				dispatch({ type: receiveImportSupervisorErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveImportSupervisorErrorType });
		}
	},

	requestCountySummary:
		(
			page = 1,
			pageSize = 7,
			status = 'paymentApproval_Initiated',
			startDate,
			endDate
		) =>
		async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.countySummaryLoading) {
				return;
			}

			dispatch({ type: requestCountySummaryType });

			const url = new URL(
				'/api/countyapproval/summary',
				stringLookup.kaziMtaaniUrl
			);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('status', status);

			if (startDate) {
				url.searchParams.append('from', startDate);
			}

			if (endDate) {
				url.searchParams.append('to', endDate);
			}

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveCountySummaryType,
						countySummary: data.results,
						totalCountySummary: data.total,
					});
				} else {
					const data = await res.json();
					dispatch({
						type: receiveCountySummaryErrorType,
						countySummaryErrorMessage: data.description,
					});
				}
			} catch (err) {
				dispatch({
					type: receiveCountySummaryErrorType,
					countySummaryErrorMessage: err.message,
				});
			}
		},

	requestExecutiveSummary:
		(page = 1, pageSize = 5, startDate, endDate, approved = true) =>
		async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.executiveSummaryLoading) {
				return;
			}

			dispatch({ type: requestExecutiveSummaryType });

			const url = new URL('/api/executiveapproval', stringLookup.kaziMtaaniUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			// url.searchParams.append("approved", approved);

			if (startDate) {
				url.searchParams.append('from', startDate);
			}

			if (endDate) {
				url.searchParams.append('to', endDate);
			}

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveExecutiveSummaryType,
						executiveSummary: data.results,
						totalExecutiveSummary: data.total,
					});
				} else {
					dispatch({ type: receiveExecutiveSummaryErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveExecutiveSummaryErrorType });
			}
		},

	requestNHPSummary:
		(page = 1, pageSize = 5, startDate, endDate, approved = true) =>
		async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.nhpSummaryLoading) {
				return;
			}

			dispatch({ type: requestNHPSummaryType });

			const url = new URL('/api/nationalapproval', stringLookup.kaziMtaaniUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			// url.searchParams.append("approved", approved);

			if (startDate) {
				url.searchParams.append('from', startDate);
			}

			if (endDate) {
				url.searchParams.append('to', endDate);
			}

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveNHPSummaryType,
						nhpSummary: data.results,
						totalNhpSummary: data.total,
					});
				} else {
					dispatch({ type: receiveNHPSummaryErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveNHPSummaryErrorType });
			}
		},

	requestCountyResidentsList:
		(page = 1, pageSize = 10, CohortCycleId, searchString) =>
		async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.approvalResidentsLoading) {
				return;
			}

			dispatch({ type: requestApprovalResidentsType });

			const url = new URL('/api/countyapproval', stringLookup.kaziMtaaniUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('CohortCycleId', CohortCycleId);

			if (searchString && searchString.length > 0) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveApprovalResidentsType,
						approvalResidents: data.results,
						totalApprovalResidents: data.total,
					});
				} else {
					dispatch({ type: receiveApprovalResidentsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveApprovalResidentsErrorType });
			}
		},

	downloadCountyApprovalRecords:
		(CohortCycleId, CohortCycleStatus, ReportKind) => async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.approvalRecordsDownloading) {
				return;
			}

			dispatch({ type: requestDownloadApprovalsType });

			const url = new URL('/api/reports', stringLookup.kaziMtaaniUrl);

			if (CohortCycleId) {
				url.searchParams.append('CohortCycleId', CohortCycleId);
			}

			if (CohortCycleStatus) {
				url.searchParams.append('CohortCycleStatus', CohortCycleStatus);
			}

			if (ReportKind) {
				url.searchParams.append('ReportKind', ReportKind);
			}

			try {
				const res = await kaziMtaaniFetch(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveDownloadApprovalsType,
						approvalRecordsDownload: data,
					});
				} else {
					const data = await res.json();
					dispatch({
						type: receiveDownloadApprovalsErrorType,
						approvalDownloadErrorMessage: data.description,
						rejectionReason: data.description,
					});
				}
			} catch (err) {
				dispatch({
					type: receiveDownloadApprovalsErrorType,
					approvalDownloadErrorMessage: err.message,
					rejectionReason: err.message,
				});
			}
		},

	addSupervisor: (supervisor) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.addingSupervisor) {
			return;
		}

		dispatch({ type: requestAddSupervisorType });

		const url = new URL('/api/supervisors', stringLookup.kaziMtaaniUrl);

		try {
			const res = await KaziMtaaniPost(url, [supervisor]);

			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: addSupervisorCompleteType,
					isRejected: data[0].isRejected,
					rejectionReason:
						data[0].rejectionReason.length > 0
							? data[0].rejectionReason[0]
							: '',
				});
			} else {
				const data = await res.json();
				dispatch({
					type: addSupervisorErrorType,
					isRejected: true,
					rejectionReason: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: addSupervisorErrorType,
			});
		}
	},

	deleteSupervisor: (supervisorId) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.deletingSupervisor) {
			return;
		}

		dispatch({ type: requestDeleteSupervisorType });

		const url = new URL(
			`/api/supervisors/${supervisorId}`,
			stringLookup.kaziMtaaniUrl
		);

		try {
			const res = await kaziMtaaniDelete(url);

			if (res.ok) {
				dispatch({ type: deleteSupervisorCompleteType });
			} else {
				dispatch({ type: deleteSupervisorErrorType });
			}
		} catch (err) {
			dispatch({ type: deleteSupervisorErrorType });
		}
	},

	deleteWorker: (workerId) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.deletingWorker) {
			return;
		}

		dispatch({ type: requestDeleteWorkerType });

		const url = new URL(`/api/resident/${workerId}`, stringLookup.kaziMtaaniUrl);

		try {
			const res = await kaziMtaaniDelete(url);

			if (res.ok) {
				dispatch({ type: deleteWorkerCompleteType });
			} else {
				dispatch({ type: deleteWorkerErrorType });
			}
		} catch (err) {
			dispatch({ type: deleteWorkerErrorType });
		}
	},

	editSupervisor: (supervisor) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.updatingSupervisor) {
			return;
		}

		dispatch({ type: requestUpdateSupervisorType });

		const url = new URL('/api/supervisors', stringLookup.kaziMtaaniUrl);

		try {
			const res = await KaziMtaaniPut(url, supervisor);

			if (res.ok) {
				dispatch({
					type: updateSupervisorCompleteType,
					isRejected: false,
				});
			} else {
				const data = await res.json();
				dispatch({
					type: updateSupervisorErrorType,
					isRejected: true,
					rejectionReason: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: updateSupervisorErrorType,
				isRejected: true,
				rejectionReason: err.message,
			});
		}
	},

	editWorker: (worker) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.updatingWorker) {
			return;
		}

		dispatch({ type: requestUpdateWorkerType });

		const url = new URL('/api/resident', stringLookup.kaziMtaaniUrl);

		try {
			const res = await KaziMtaaniPut(url, worker);

			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: updateWorkerCompleteType,
					duplicateWorkerId: data.rejected,
					rejectionReason: data.rejectionReason,
				});
			} else {
				const data = await res.json();
				dispatch({
					type: updateWorkerErrorType,
					rejectionReason: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: updateWorkerErrorType,
				rejectionReason: 'Operation Failed!',
			});
		}
	},

	approveCounty: (county) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.countySummaryLoading) {
			return;
		}

		dispatch({ type: requestApproveCountyType });

		const url = new URL('/api/countyapproval', stringLookup.kaziMtaaniUrl);

		try {
			const res = await KaziMtaaniPost(url, county);

			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: approveCountyCompleteType,
				});
			} else {
				const data = await res.json();
				dispatch({
					type: approveCountyErrorType,
					countyErrorMessage: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: approveCountyErrorType,
				countyErrorMessage: err.message,
			});
		}
	},

	approveNHP: (county) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.nhpSummaryLoading) {
			return;
		}

		dispatch({ type: requestApproveNHPType });

		const url = new URL('/api/nationalapproval', stringLookup.kaziMtaaniUrl);

		try {
			const res = await KaziMtaaniPost(url, county);

			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: approveNHPCompleteType,
				});
			} else {
				const data = await res.json();
				dispatch({
					type: approveNHPErrorType,
					nhpErrorMessage: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: approveNHPErrorType,
				nhpErrorMessage: err.message,
			});
		}
	},

	approveExecutive: (executiveApproval) => async (dispatch, getState) => {
		if (getState().kaziMtaaniManagement.executiveSummaryLoading) {
			return;
		}

		dispatch({ type: requestApproveExecutiveType });

		const url = new URL('/api/executiveapproval', stringLookup.kaziMtaaniUrl);

		try {
			const res = await KaziMtaaniPost(url, executiveApproval);

			if (res.ok) {
				const data = await res.json();
				dispatch({
					type: approveExecutiveCompleteType,
				});
			} else {
				const data = await res.json();
				dispatch({
					type: approveExecutiveErrorType,
					executiveErrorMessage: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: approveExecutiveErrorType,
				executiveErrorMessage: err.message,
			});
		}
	},

	ammendResidentRecord:
		(residentId, attendanceAmendment) => async (dispatch, getState) => {
			if (getState().kaziMtaaniManagement.approvalResidentsLoading) {
				return;
			}

			dispatch({ type: requestAmmendResidentType });

			const url = new URL(
				`/api/countyapproval/${residentId}/amend`,
				stringLookup.kaziMtaaniUrl
			);

			try {
				const res = await KaziMtaaniPost(url, attendanceAmendment);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: ammendResidentCompleteType,
					});
				} else {
					const data = await res.json();
					dispatch({
						type: ammendResidentErrorType,
						ammendmentErrorMessage: data.description,
					});
				}
			} catch (err) {
				dispatch({
					type: ammendResidentErrorType,
					ammendmentErrorMessage: err.message,
				});
			}
		},

	setCohortCycle: (cohortCycle) => async (dispatch, _) => {
		dispatch({
			type: SET_COHORT_CYCLE_TYPE,
			cohortCycle: cohortCycle,
			cohortStartDate: cohortCycle && cohortCycle.dates[0].start,
			cohortEndDate: cohortCycle && cohortCycle.dates[0].end,
		});
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === requestSupervisorsType) {
		return {
			...state,
			supervisorListLoading: true,
		};
	}
	if (action.type === receiveSupervisorsType) {
		return {
			...state,
			supervisors: action.supervisors,
			totalSupervisors: action.totalSupervisors,
			supervisorListLoading: false,
		};
	}
	if (action.type === receiveSupervisorsErrorType) {
		return {
			...state,
			supervisorListLoading: false,
		};
	}

	if (action.type === requestImportSupervisorType) {
		return {
			...state,
			supervisorListLoading: true,
		};
	}
	if (action.type === receiveImportSupervisorType) {
		return {
			...state,
			kaziMtaaniUserProfile: action.kaziMtaaniUserProfile,
			supervisorListLoading: false,
		};
	}
	if (action.type === receiveImportSupervisorErrorType) {
		return {
			...state,
			supervisorListLoading: false,
		};
	}

	if (action.type === requestLocationsType) {
		return {
			...state,
			locationsLoading: true,
		};
	}
	if (action.type === receiveLocationsType) {
		return {
			...state,
			counties: action.counties,
			locationsLoading: false,
		};
	}
	if (action.type === receiveLocationsErrorType) {
		return {
			...state,
			locationsLoading: false,
		};
	}

	if (action.type === requestAddSupervisorType) {
		return {
			...state,
			addingSupervisor: true,
		};
	}
	if (action.type === addSupervisorCompleteType) {
		return {
			...state,
			addingSupervisor: false,
			isRejected: action.isRejected,
			rejectionReason: action.rejectionReason,
		};
	}
	if (action.type === addSupervisorErrorType) {
		return {
			...state,
			addingSupervisor: false,
			isRejected: action.isRejected,
			rejectionReason: action.rejectionReason,
		};
	}

	if (action.type === requestDeleteSupervisorType) {
		return {
			...state,
			deletingSupervisor: true,
		};
	}
	if (action.type === deleteSupervisorCompleteType) {
		return {
			...state,
			deletingSupervisor: false,
		};
	}
	if (action.type === deleteSupervisorErrorType) {
		return {
			...state,
			deletingSupervisor: false,
		};
	}

	if (action.type === requestUpdateSupervisorType) {
		return {
			...state,
			updatingSupervisor: true,
		};
	}
	if (action.type === updateSupervisorCompleteType) {
		return {
			...state,
			updatingSupervisor: false,
			isRejected: action.isRejected,
		};
	}
	if (action.type === updateSupervisorErrorType) {
		return {
			...state,
			updatingSupervisor: false,
			isRejected: action.isRejected,
			rejectionReason: action.rejectionReason,
		};
	}

	if (action.type === requestWorkersType) {
		return {
			...state,
			workersListLoading: true,
		};
	}
	if (action.type === receiveWorkersType) {
		return {
			...state,
			workers: action.workers,
			totalWorkers: action.totalWorkers,
			workersListLoading: false,
		};
	}
	if (action.type === receiveWorkersErrorType) {
		return {
			...state,
			workersListLoading: false,
		};
	}

	if (action.type === requestDeleteWorkerType) {
		return {
			...state,
			deletingWorker: true,
		};
	}
	if (action.type === deleteWorkerCompleteType) {
		return {
			...state,
			deletingWorker: false,
		};
	}
	if (action.type === deleteWorkerErrorType) {
		return {
			...state,
			deletingWorker: false,
		};
	}

	if (action.type === requestUpdateWorkerType) {
		return {
			...state,
			updatingWorker: true,
		};
	}
	if (action.type === updateWorkerCompleteType) {
		return {
			...state,
			updatingWorker: false,
			duplicateWorkerId: action.duplicateWorkerId,
			updateWorkerRejected: false,
			rejectionReason: action.rejectionReason,
		};
	}
	if (action.type === updateWorkerErrorType) {
		return {
			...state,
			updatingWorker: false,
			duplicateWorkerId: false,
			updateWorkerRejected: true,
			rejectionReason: action.rejectionReason,
		};
	}

	if (action.type === requestCohortsType) {
		return {
			...state,
			cohortsLoading: true,
		};
	}
	if (action.type === receiveCohortsType) {
		return {
			...state,
			cohorts: action.cohorts,
			cohortsLoading: false,
		};
	}
	if (action.type === receiveCohortsErrorType) {
		return {
			...state,
			cohortsLoading: false,
		};
	}

	if (action.type === requestCohortCyclesType) {
		return {
			...state,
			cohortCyclesLoading: true,
		};
	}
	if (action.type === receiveCohortCyclesType) {
		return {
			...state,
			cohortCycles: action.cohortCycles,
			cohortCyclesLoading: false,
		};
	}
	if (action.type === receiveCohortCyclesErrorType) {
		return {
			...state,
			cohortCyclesLoading: false,
		};
	}

	if (action.type === requestApprovalResidentsType) {
		return {
			...state,
			approvalResidentsLoading: true,
		};
	}
	if (action.type === receiveApprovalResidentsType) {
		return {
			...state,
			approvalResidents: action.approvalResidents,
			approvalResidentsLoading: false,
			totalApprovalResidents: action.totalApprovalResidents,
		};
	}
	if (action.type === receiveApprovalResidentsErrorType) {
		return {
			...state,
			approvalResidentsLoading: false,
		};
	}

	if (action.type === requestCountySummaryType) {
		return {
			...state,
			countySummaryLoading: true,
		};
	}
	if (action.type === receiveCountySummaryType) {
		return {
			...state,
			countySummary: action.countySummary,
			countySummaryLoading: false,
			totalCountySummary: action.totalCountySummary,
		};
	}
	if (action.type === receiveCountySummaryErrorType) {
		return {
			...state,
			countySummaryLoading: false,
			countySummaryErrorMessage: action.countySummaryErrorMessage,
		};
	}

	if (action.type === requestNHPSummaryType) {
		return {
			...state,
			nhpSummaryLoading: true,
		};
	}
	if (action.type === receiveNHPSummaryType) {
		return {
			...state,
			nhpSummary: action.nhpSummary,
			nhpSummaryLoading: false,
			totalNhpSummary: action.totalNhpSummary,
		};
	}
	if (action.type === receiveNHPSummaryErrorType) {
		return {
			...state,
			nhpSummaryLoading: false,
		};
	}

	if (action.type === requestExecutiveSummaryType) {
		return {
			...state,
			executiveSummaryLoading: true,
		};
	}
	if (action.type === receiveExecutiveSummaryType) {
		return {
			...state,
			executiveSummary: action.executiveSummary,
			executiveSummaryLoading: false,
			totalExecutiveSummary: action.totalExecutiveSummary,
		};
	}
	if (action.type === receiveExecutiveSummaryErrorType) {
		return {
			...state,
			executiveSummaryLoading: false,
		};
	}

	if (action.type === requestApproveCountyType) {
		return {
			...state,
			countySummaryLoading: true,
		};
	}
	if (action.type === approveCountyCompleteType) {
		return {
			...state,
			countySummaryLoading: false,
			countyApprovalError: false,
		};
	}
	if (action.type === approveCountyErrorType) {
		return {
			...state,
			countySummaryLoading: false,
			countyApprovalError: true,
			countyErrorMessage: action.countyErrorMessage,
		};
	}

	if (action.type === requestApproveNHPType) {
		return {
			...state,
			nhpSummaryLoading: true,
		};
	}
	if (action.type === approveNHPCompleteType) {
		return {
			...state,
			nhpSummaryLoading: false,
			nhpApprovalError: false,
		};
	}
	if (action.type === approveNHPErrorType) {
		return {
			...state,
			nhpSummaryLoading: false,
			nhpApprovalError: true,
			nhpErrorMessage: action.nhpErrorMessage,
		};
	}

	if (action.type === requestApproveExecutiveType) {
		return {
			...state,
			executiveSummaryLoading: true,
		};
	}
	if (action.type === approveExecutiveCompleteType) {
		return {
			...state,
			executiveSummaryLoading: false,
			executiveApprovalError: false,
		};
	}
	if (action.type === approveExecutiveErrorType) {
		return {
			...state,
			executiveSummaryLoading: false,
			executiveApprovalError: true,
			executiveErrorMessage: action.executiveErrorMessage,
		};
	}

	if (action.type === receivePermissionsType) {
		return {
			...state,
			availablePermissions: action.availablePermissions,
		};
	}

	if (action.type === requestAmmendResidentType) {
		return {
			...state,
			approvalResidentsLoading: true,
		};
	}
	if (action.type === ammendResidentCompleteType) {
		return {
			...state,
			approvalResidentsLoading: false,
			ammendmentError: false,
			ammendmentErrorMessage: action.ammendmentErrorMessage,
		};
	}
	if (action.type === ammendResidentErrorType) {
		return {
			...state,
			approvalResidentsLoading: false,
			ammendmentError: true,
			ammendmentErrorMessage: action.ammendmentErrorMessage,
		};
	}

	if (action.type === requestDownloadApprovalsType) {
		return {
			...state,
			approvalRecordsDownloading: true,
		};
	}
	if (action.type === receiveDownloadApprovalsType) {
		return {
			...state,
			approvalRecordsDownloading: false,
			approvalDownloadError: false,
			approvalRecordsDownload: action.approvalRecordsDownload,
		};
	}
	if (action.type === receiveDownloadApprovalsErrorType) {
		return {
			...state,
			approvalRecordsDownloading: false,
			approvalDownloadError: true,
			approvalDownloadErrorMessage: action.approvalDownloadErrorMessage,
			rejectionReason: action.rejectionReason,
			approvalRecordsDownload: [],
		};
	}

	if (action.type === SET_COHORT_CYCLE_TYPE) {
		return {
			...state,
			cohortCycle: action.cohortCycle,
			cohortStartDate: action.cohortStartDate,
			cohortEndDate: action.cohortEndDate,
		};
	}

	return state;
};
