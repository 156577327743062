import stringLookup from './../stringslookup';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';
import { fetchSecured, fetchDefault } from '../fetchUtils';

const REQUEST_INCIDENCE_REPORT_TYPE = 'REQUEST_INCIDENCE_REPORT';
const RECEIVE_INCIDENCE_REPORT_TYPE = 'RECEIVE_INCIDENCE_REPORT';
const RECEIVE_INCIDENCE_REPORT_ERROR_TYPE = 'RECEIVE_INCIDENCE_REPORT_ERROR';

const REQUEST_INCIDENCE_SUMMARY_TYPE = 'REQUEST_INCIDENCE_SUMMARY';
const RECEIVE_INCIDENCE_SUMMARY_TYPE = 'RECEIVE_INCIDENCE_SUMMARY';
const RECEIVE_INCIDENCE_SUMMARY_ERROR_TYPE = 'RECEIVE_INCIDENCE_SUMMARY_ERROR';

const REQUEST_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE =
	'REQUEST_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE';
const RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE =
	'RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE';
const RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_ERROR_TYPE =
	'RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_ERROR_TYPE';

const REQUEST_INCIDENCE_CLUSTERS_TYPE = 'REQUEST_INCIDENCE_CLUSTERS_TYPE';
const RECEIVE_INCIDENCE_CLUSTERS_TYPE = 'RECEIVE_INCIDENCE_CLUSTERS_TYPE';
const RECEIVE_INCIDENCE_CLUSTERS_ERROR_TYPE =
	'RECEIVE_INCIDENCE_CLUSTERS_ERROR_TYPE';

const REQUEST_INCIDENCE_WEAPONS_TYPE = 'REQUEST_INCIDENCE_WEAPONS';
const RECEIVE_INCIDENCE_WEAPONS_TYPE = 'RECEIVE_INCIDENCE_WEAPONS';

const REQUEST_INCIDENCE_ACTORS_TYPE = 'REQUEST_INCIDENCE_ACTORS';
const RECEIVE_INCIDENCE_ACTORS_TYPE = 'RECEIVE_INCIDENCE_ACTORS';

const REQUEST_INCIDENCE_TARGETS_TYPE = 'REQUEST_INCIDENCE_TARGETS';
const RECEIVE_INCIDENCE_TARGETS_TYPE = 'RECEIVE_INCIDENCE_TARGETS';

const REQUEST_INCIDENCE_TIMELINE_TYPE = 'REQUEST_INCIDENCE_TIMELINE';
const RECEIVE_INCIDENCE_TIMELINE_TYPE = 'RECEIVE_INCIDENCE_TIMELINE';
const RECEIVE_INCIDENCE_TIMELINE_ERROR_TYPE =
	'RECEIVE_INCIDENCE_TIMELINE_ERROR';

const SET_INCIDENTS_VISUALIZATION_TYPE_TYPE =
	'SET_INCIDENTS_VISUALIZATION_TYPE';

const REQUEST_INCIDENCE_VISUALIZATIONS_TYPE =
	'REQUEST_INCIDENCE_VISUALIZATIONS';
const RECEIVE_INCIDENCE_VISUALIZATIONS_TYPE =
	'RECEIVE_INCIDENCE_VISUALIZATIONS';
const RECEIVE_INCIDENCE_VISUALIZATIONS_ERROR_TYPE =
	'RECEIVE_INCIDENCE_VISUALIZATIONS_ERROR';

const REQUEST_CHOROPLETH_DATA_TYPE = 'REQUEST_CHOROPLETH_DATA';
const RECEIVE_CHOROPLETH_DATA_TYPE = 'RECEIVE_CHOROPLETH_DATA';
const RECEIVE_CHOROPLETH_DATA_ERROR_TYPE = 'RECEIVE_CHOROPLETH_DATA_ERROR';

const REQUEST_INCIDENCE_DOWNLOAD_TYPE = 'REQUEST_INCIDENCE_DOWNLOAD';
const RECEIVE_INCIDENCE_DOWNLOAD_TYPE = 'RECEIVE_INCIDENCE_DOWNLOAD';
const RECEIVE_INCIDENCE_DOWNLOAD_ERROR_TYPE =
	'RECEIVE_INCIDENCE_DOWNLOAD_ERROR';

const REQUEST_INCIDENCE_TYPES_COUNT_TYPE = 'REQUEST_INCIDENCE_TYPES_COUNT';
const RECEIVE_INCIDENCE_TYPES_COUNT_TYPE = 'RECEIVE_INCIDENCE_TYPES_COUNT';
const RECEIVE_INCIDENCE_TYPES_COUNT_ERROR_TYPE =
	'RECEIVE_INCIDENCE_TYPES_COUNT_ERROR';

const REQUEST_INCIDENTS_REGIONS_TYPE = 'REQUEST_INCIDENTS_REGIONS';
const RECEIVE_INCIDENTS_REGIONS_TYPE = 'RECEIVE_INCIDENTS_REGIONS';
const RECEIVE_INCIDENTS_REGIONS_ERROR_TYPE = 'RECEIVE_INCIDENTS_REGIONS_ERROR';

const REQUEST_REGION_CHOROPLETH_DATA_TYPE = 'REQUEST_REGION_CHOROPLETH_DATA';
const RECEIVE_REGION_CHOROPLETH_DATA_TYPE = 'RECEIVE_REGION_CHOROPLETH_DATA';
const RECEIVE_REGION_CHOROPLETH_DATA_ERROR_TYPE =
	'RECEIVE_REGION_CHOROPLETH_DATA_ERROR';

const REQUEST_HEAT_MAP_COUNTRIES_TYPE = 'REQUEST_HEAT_MAP_COUNTRIES';
const RECEIVE_HEAT_MAP_COUNTRIES_TYPE = 'RECEIVE_HEAT_MAP_COUNTRIES';
const RECEIVE_HEAT_MAP_COUNTRIES_ERROR_TYPE =
	'RECEIVE_HEAT_MAP_COUNTRIES_ERROR';

const SET_CUSTOM_INCIDENT_TYPE_COLORS_TYPE = 'SET_CUSTOM_INCIDENT_TYPE_COLORS';
const SET_CUSTOM_ACTOR_COLORS_TYPE = 'SET_CUSTOM_ACTOR_COLORS';
const SET_CUSTOM_IMPACT_VECTOR_COLORS_TYPE = 'SET_CUSTOM_IMPACT_VECTOR_COLORS';
const SET_CUSTOM_TARGET_TYPE_COLORS_TYPE = 'SET_CUSTOM_TARGET_TYPE_COLORS';
const SET_CUSTOM_INCIDENT_NUMERIC_COLORS_TYPE =
	'SET_CUSTOM_INCIDENT_NUMERIC_COLORS';

const requestIncidentsTimelapseDataType =
	'REQUEST_INCIDENTS_TIMELAPSE_DATA_TYPE';
const receiveIncidentsTimelapseDataType =
	'RECEIVE_INCIDENTS_TIMELAPSE_DATA_TYPE';
const receiveIncidentsTimelapseDataErrorType =
	'RECEIVE_INCIDENTS_TIMELAPSE_DATA_ERROR_TYPE';

const initialState = {
	incidentLocations: [],
	numberOfLocations: 0,
	numberOfIncidents: 0,
	centerPosition: [0.105587, 1.645304],
	incidentLocationsLoading: false,
	incidenceError: false,
	summary: null,
	summaryLoading: false,
	updatedIncidentsLoading: false,
	updatedIncidentsSummary: [],
	clustersData: null,
	clustersLoading: false,
	weapons: [],
	actors: [],
	targets: [],
	incidentsTimeline: null,
	incidentsDateHistograms: [],
	incidentsTimelineLoading: false,
	choroplethCountries: null,
	choroplethDataLoading: false,
	incidentDataBlob: null,
	incidentDownloadError: false,
	incidentsVisualizationType: 'incident_locations',
	incidentsVisualizations: [],
	incidentsVisualizationsError: false,
	incidentsVisAttacks: {},
	incidentsVisualizationsLoading: false,
	incidentTypesCount: null,
	incidentTypesCountLoading: false,
	incidentTypesCountError: false,
	incidentRegionsLoading: false,
	incidentRegionsError: true,
	incidentRegions: [],
	regionChoroplethData: null,
	regionChoroplethDataLoading: false,
	heatMapCountries: [],
	heatMapCountriesLoading: false,
	incidentCount: 0,
	locationCount: 0,
	customIncidentTypeColors: {},
	customActorColors: {},
	customImpactVectorColors: {},
	customTargetTypeColors: {},
	customIncidentNumericColors: {},

	incidentsTimelapseDataLoading: false,
	incidentsTimelapseData: [],
	incidentsTimelapseError: false,
};

export const incidenceReportActionCreators = {
	loadIncidenceLocations:
		(
			startDate,
			endDate,
			weaponFilter,
			actorsFilter,
			targetFilter,
			incidentTypes,
			countriesFilter,
			geofenceBounds,
			regionsFilter,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			locationFilter,
			searchString,
			actorSubtopics
		) =>
		async (dispatch, getState) => {
			//if (getState().incidenceTimeline.incidentLocationsLoading) {
			//    return;
			//}

			dispatch({ type: REQUEST_INCIDENCE_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/incidentsmap', stringLookup.baseUrl);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				const filteredActors = actorsFilter.filter(
					(a) => !actorSubtopics.find((as) => as.name === a)
				);
				filteredActors.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (locationFilter) {
				url.searchParams.append('locationFilter', locationFilter);
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const reportResponse = await fetchSecured(
					url,
					'loadIncidenceLocations'
				);
				if (reportResponse.ok) {
					const incidencePayload = await reportResponse.json();
					dispatch({
						type: RECEIVE_INCIDENCE_REPORT_TYPE,
						incidentLocations: incidencePayload.locations,
						numberOfLocations: incidencePayload.numberOfLocations,
						numberOfIncidents: incidencePayload.numberOfIncidents,
						centerPosition:
							incidencePayload.numberOfLocations === 0
								? [0.105587, 1.645304]
								: [
										parseFloat(incidencePayload.centerLatitude),
										parseFloat(incidencePayload.centerLongitude),
								  ],
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadIncidentsRegions:
		(
			startDate,
			endDate,
			weaponFilter,
			actorsFilter,
			targetFilter,
			incidentTypes,
			countriesFilter,
			geofenceBounds,
			regionsFilter,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			actorSubtopics
		) =>
		async (dispatch, getState) => {
			if (getState().incidenceTimeline.incidentRegionsLoading) {
				return;
			}

			dispatch({ type: REQUEST_INCIDENTS_REGIONS_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/incidentregions',
				stringLookup.baseUrl
			);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}
			if (actorsFilter?.length > 0) {
				const filteredActors = actorsFilter.filter(
					(a) => !actorSubtopics.find((as) => as.name === a)
				);
				filteredActors.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}
			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const regionsRes = await fetchSecured(url, 'loadIncidenceRegions');
				if (regionsRes.ok) {
					const regionsPayload = await regionsRes.json();
					dispatch({
						type: RECEIVE_INCIDENTS_REGIONS_TYPE,
						incidentRegions: regionsPayload.aggregations,
						incidentCount: regionsPayload.incidentCount,
						locationCount: regionsPayload.locationCount,
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENTS_REGIONS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENTS_REGIONS_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadIncidenceTimeline:
		(
			startDate,
			endDate,
			weaponFilter,
			date,
			actorsFilter,
			targetFilter,
			incidentTypes,
			countriesFilter,
			locationFilter,
			geofenceBounds,
			locationCoordinates,
			regionsFilter,
			canQueryWithLocationAndCoordinates,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			actorSubtopics
		) =>
		async (dispatch, getState) => {
			if (getState().incidenceTimeline.incidentsTimelineLoading) {
				return;
			}

			dispatch({ type: REQUEST_INCIDENCE_TIMELINE_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/incidentstimeline',
				stringLookup.baseUrl
			);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				const filteredActors = actorsFilter.filter(
					(a) => !actorSubtopics.find((as) => as.name === a)
				);
				filteredActors.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			if (date) {
				url.searchParams.append('date', date);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			// if (locationFilter) {
			// 	url.searchParams.append('locationFilter', locationFilter);
			// }

			if (canQueryWithLocationAndCoordinates) {
				url.searchParams.append('locationFilter', locationFilter);
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (locationCoordinates && !canQueryWithLocationAndCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const reportResponse = await fetchSecured(url, 'loadIncidenceTimeline');
				if (reportResponse.ok) {
					const incidencePayload = await reportResponse.json();
					var report = {};
					incidencePayload.timelines.forEach((x) => {
						report[x.name] = x.timelines;
					});
					dispatch({
						type: RECEIVE_INCIDENCE_TIMELINE_TYPE,
						incidentsTimeline: report,
						incidentsDateHistograms: incidencePayload.aggregation,
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_TIMELINE_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_TIMELINE_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadIncidenceWeapons:
		(
			startDate,
			endDate,
			incidentTypes,
			datasetFilter,
			countriesFilter,
			geofenceBounds,
			locationCoordinates,
			regionsFilter
		) =>
		async (dispatch) => {
			dispatch({ type: REQUEST_INCIDENCE_WEAPONS_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/incidents/weapontypes',
				stringLookup.baseUrl
			);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}
			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (locationCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const reportResponse = await fetchSecured(url, 'loadIncidenceWeapons');
				if (reportResponse.ok) {
					const weaponsPayload = await reportResponse.json();
					dispatch({
						type: RECEIVE_INCIDENCE_WEAPONS_TYPE,
						weapons: weaponsPayload,
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadIncidenceActors: (startDate, endDate) => async (dispatch) => {
		dispatch({ type: REQUEST_INCIDENCE_ACTORS_TYPE });
		setGlobalLoad(dispatch);

		const url = new URL(
			'/api/analytics/incidents/actors',
			stringLookup.baseUrl
		);
		url.searchParams.append('startDate', startDate.toISOString());
		url.searchParams.append('endDate', endDate.toISOString());

		try {
			const reportResponse = await fetchSecured(url, 'loadIncidenceActors');
			if (reportResponse.ok) {
				const actorsPayload = await reportResponse.json();
				dispatch({
					type: RECEIVE_INCIDENCE_ACTORS_TYPE,
					actors: actorsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
		}

		unsetGlobalLoad(dispatch);
	},

	loadIncidenceTargets: (startDate, endDate) => async (dispatch) => {
		dispatch({ type: REQUEST_INCIDENCE_TARGETS_TYPE });
		setGlobalLoad(dispatch);

		const url = new URL(
			'/api/analytics/incidents/targets',
			stringLookup.baseUrl
		);
		url.searchParams.append('startDate', startDate.toISOString());
		url.searchParams.append('endDate', endDate.toISOString());

		try {
			const reportResponse = await fetchSecured(url, 'loadIncidenceTargets');
			if (reportResponse.ok) {
				const targetsPayload = await reportResponse.json();
				dispatch({
					type: RECEIVE_INCIDENCE_TARGETS_TYPE,
					targets: targetsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_INCIDENCE_REPORT_ERROR_TYPE });
		}

		unsetGlobalLoad(dispatch);
	},

	loadIncidentsSummary:
		(
			page,
			pageSize,
			startDate,
			endDate,
			searchString = null,
			weaponFilter = null,
			locationFilter = null,
			countriesFilter = [],
			dateFilter,
			orderBy,
			sortDirection,
			actorsFilter,
			targetFilter,
			incidentTypes,
			geofenceBounds,
			locationCoordinates,
			regionsFilter,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			actorSubtopics
		) =>
		async (dispatch) => {
			dispatch({ type: REQUEST_INCIDENCE_SUMMARY_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL(
				'/api/analytics/incidents/summary',
				stringLookup.baseUrl
			);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				const filteredActors = actorsFilter.filter(
					(a) => !actorSubtopics.find((as) => as.name === a)
				);
				filteredActors.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			// if (locationFilter) {
			// 	url.searchParams.append('locationFilter', locationFilter);
			// }

			if (locationCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const summaryResponse = await fetchSecured(url, 'loadIncidentsSummary');
				if (summaryResponse.ok) {
					const summary = await summaryResponse.json();
					dispatch({ type: RECEIVE_INCIDENCE_SUMMARY_TYPE, summary: summary });
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_SUMMARY_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_SUMMARY_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadIncidentsSummaryByClusterIds:
		(
			page,
			pageSize,
			startDate,
			endDate,
			searchString = null,
			weaponFilter = null,
			locationFilter = null,
			countriesFilter = [],
			dateFilter,
			orderBy,
			sortDirection,
			actorsFilter,
			targetFilter,
			incidentTypes,
			geofenceBounds,
			locationCoordinates,
			regionsFilter,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			clusterIds
		) =>
		async (dispatch) => {
			dispatch({ type: REQUEST_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL(
				'/api/analytics/incidents/summary',
				stringLookup.baseUrl
			);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				actorsFilter.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			// if (locationFilter) {
			// 	url.searchParams.append('locationFilter', locationFilter);
			// }

			if (locationCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (actorsFilter?.length === 0 && subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			if (clusterIds?.length > 0) {
				clusterIds.forEach((x) => {
					url.searchParams.append('ids', x);
				});
			}

			try {
				const summaryResponse = await fetchSecured(
					url,
					'loadIncidentsSummaryByClusterId'
				);
				if (summaryResponse.ok) {
					const summary = await summaryResponse.json();
					dispatch({
						type: RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE,
						updatedIncidentsSummary: summary,
					});
				} else {
					dispatch({
						type: RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	// this action might look like a duplicate but I added it to
	// prevent conflict in state between the 'moveToCluster' component and the summary grid
	loadIncidentsClusters:
		(
			page,
			pageSize,
			startDate,
			endDate,
			searchString = null,
			weaponFilter = null,
			locationFilter = null,
			countriesFilter = [],
			dateFilter,
			orderBy,
			sortDirection,
			actorsFilter,
			targetFilter,
			incidentTypes,
			geofenceBounds,
			locationCoordinates,
			regionsFilter,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter
		) =>
		async (dispatch) => {
			dispatch({ type: REQUEST_INCIDENCE_CLUSTERS_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL(
				'/api/analytics/incidents/summary',
				stringLookup.baseUrl
			);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				actorsFilter.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			// if (locationFilter) {
			// 	url.searchParams.append('locationFilter', locationFilter);
			// }

			if (locationCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (actorsFilter?.length === 0 && subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const summaryResponse = await fetchSecured(
					url,
					'loadIncidentsClusters'
				);
				if (summaryResponse.ok) {
					const summary = await summaryResponse.json();
					dispatch({
						type: RECEIVE_INCIDENCE_CLUSTERS_TYPE,
						clustersData: summary,
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_CLUSTERS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_CLUSTERS_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	downloadIncidentsSummary:
		(
			startDate,
			endDate,
			searchString = null,
			weaponFilter = null,
			locationFilter = null,
			countriesFilter = [],
			dateFilter,
			orderBy,
			sortDirection,
			actorsFilter,
			targetFilter,
			incidentTypes,
			geofenceBounds,
			locationCoordinates,
			regionsFilter,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter
		) =>
		async (dispatch) => {
			dispatch({ type: REQUEST_INCIDENCE_DOWNLOAD_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL('/api/analytics/incidents/csv', stringLookup.baseUrl);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				actorsFilter.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			// if (locationFilter) {
			// 	url.searchParams.append('locationFilter', locationFilter);
			// }

			if (locationCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (actorsFilter?.length === 0 && subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const downloadResponse = await fetchSecured(
					url,
					'downloadIncidentsSummary'
				);
				if (downloadResponse.ok) {
					const downloadBlob = await downloadResponse.blob();
					dispatch({
						type: RECEIVE_INCIDENCE_DOWNLOAD_TYPE,
						incidentDataBlob: downloadBlob,
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_DOWNLOAD_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_DOWNLOAD_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	setIncidentsVisualizationType:
		(incidentsVisualizationType) => async (dispatch, _) => {
			dispatch({
				type: SET_INCIDENTS_VISUALIZATION_TYPE_TYPE,
				incidentsVisualizationType,
			});
		},

	loadIncidenceVisualizations:
		(
			visualizationType,
			startDate,
			endDate,
			weaponFilter,
			date,
			actorsFilter,
			targetFilter,
			incidentTypes,
			countriesFilter,
			locationFilter,
			geofenceBounds,
			locationCoordinates,
			regionsFilter,
			canQueryWithLocationAndCoordinates,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			actorSubtopics
		) =>
		async (dispatch, getState) => {
			// if (getState().incidenceTimeline.incidentsVisualizationsLoading) {
			// 	return;
			// }

			dispatch({ type: REQUEST_INCIDENCE_VISUALIZATIONS_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/incidents/visualizations',
				stringLookup.baseUrl
			);
			url.searchParams.append('visualizationType', visualizationType);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				const filteredActors = actorsFilter.filter(
					(a) => !actorSubtopics.find((as) => as.name === a)
				);
				filteredActors.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			if (date) {
				url.searchParams.append('date', date);
			}

			if (incidentTypes?.length > 0) {
				incidentTypes.forEach((x) => {
					url.searchParams.append('incidentTypes', x);
				});
			}

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			// if (locationFilter) {
			// 	url.searchParams.append('locationFilter', locationFilter);
			// }

			if (canQueryWithLocationAndCoordinates) {
				url.searchParams.append('locationFilter', locationFilter);
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (locationCoordinates && !canQueryWithLocationAndCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const reportResponse = await fetchSecured(
					url,
					'loadIncidenceVisualizations'
				);
				if (reportResponse.ok) {
					const incidencePayload = await reportResponse.json();
					var report = {};
					// incidencePayload.attacks.forEach((x) => {
					// 	report[x.name] = x.timeline;
					// });
					dispatch({
						type: RECEIVE_INCIDENCE_VISUALIZATIONS_TYPE,
						incidentsVisualizations: incidencePayload,
						incidentsVisAttacks: report,
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_VISUALIZATIONS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_VISUALIZATIONS_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadChoroplethData: () => async (dispatch, getState) => {
		if (getState().incidenceTimeline.choroplethDataLoading) {
			return;
		}

		if (getState().incidenceTimeline.choroplethCountries) {
			return;
		}

		dispatch({ type: REQUEST_CHOROPLETH_DATA_TYPE });

		const uriPath = stringLookup.choroplethPath;
		try {
			//var cache = await caches.open("map-cache");
			//var cacheResponse = await cache.match(uriPath);

			//if (!cacheResponse) {
			//    await cache.add(uriPath);
			//    response = await cache.match(uriPath);
			//}

			const url = new URL(uriPath);
			var response = await fetch(url);

			if (response.ok) {
				const choroplethCountries = await response.json();
				dispatch({
					type: RECEIVE_CHOROPLETH_DATA_TYPE,
					choroplethCountries: choroplethCountries,
				});
			} else {
				dispatch({ type: RECEIVE_CHOROPLETH_DATA_ERROR_TYPE });
			}
		} catch (err) {
			console.log(err.message);
			dispatch({ type: RECEIVE_CHOROPLETH_DATA_ERROR_TYPE });
		}
	},

	loadIncidentTypesCount:
		(
			startDate,
			endDate,
			weaponFilter = null,
			locationFilter = null,
			countriesFilter = [],
			actorsFilter,
			targetFilter,
			geofenceBounds,
			locationCoordinates,
			regionsFilter,
			canQueryWithLocationAndCoordinates,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			actorSubtopics
		) =>
		async (dispatch) => {
			dispatch({ type: REQUEST_INCIDENCE_TYPES_COUNT_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL(
				'/api/analytics/incidentsmap/incidenttypes/summary',
				stringLookup.baseUrl
			);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			if (weaponFilter) {
				url.searchParams.append('weaponFilter', weaponFilter);
			}

			if (actorsFilter?.length > 0) {
				const filteredActors = actorsFilter.filter(
					(a) => !actorSubtopics.find((as) => as.name === a)
				);
				filteredActors.forEach((x) => {
					url.searchParams.append('actorFilter', x);
				});
			}

			if (targetFilter) {
				url.searchParams.append('targetFilter', targetFilter);
			}

			if (incidentNumericFilter) {
				url.searchParams.append('eventNumberType', incidentNumericFilter);
			}

			// if (locationFilter) {
			// 	url.searchParams.append('locationFilter', locationFilter);
			// }

			if (datasetFilter) {
				url.searchParams.append('datasetsFilter', datasetFilter);
			}

			if (canQueryWithLocationAndCoordinates) {
				url.searchParams.append('locationFilter', locationFilter);
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (locationCoordinates && !canQueryWithLocationAndCoordinates) {
				url.searchParams.append('latitude', locationCoordinates[0]);
				url.searchParams.append('longitude', locationCoordinates[1]);
			}

			if (regionsFilter?.length > 0) {
				regionsFilter.forEach((x) => {
					url.searchParams.append('regionsFilter', x);
				});
			}

			if (countriesFilter?.length > 0) {
				countriesFilter.forEach((x) => {
					url.searchParams.append('countriesFilter', x);
				});
			}

			if (subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (geofenceBounds?.NorthLatitude) {
				url.searchParams.append('NorthLatitude', geofenceBounds?.NorthLatitude);
			}

			if (geofenceBounds?.EastLongitude) {
				url.searchParams.append('EastLongitude', geofenceBounds?.EastLongitude);
			}

			if (geofenceBounds?.SouthLatitude) {
				url.searchParams.append('SouthLatitude', geofenceBounds?.SouthLatitude);
			}

			if (geofenceBounds?.WestLongitude) {
				url.searchParams.append('WestLongitude', geofenceBounds?.WestLongitude);
			}

			try {
				const response = await fetchSecured(url, 'loadIncidentTypesCount');
				if (response.ok) {
					const incidentTypesCount = await response.json();
					dispatch({
						type: RECEIVE_INCIDENCE_TYPES_COUNT_TYPE,
						incidentTypesCount: incidentTypesCount,
					});
				} else {
					dispatch({ type: RECEIVE_INCIDENCE_TYPES_COUNT_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_INCIDENCE_TYPES_COUNT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadHeatMapCountries: () => async (dispatch, getState) => {
		if (getState().incidenceTimeline.heatMapCountriesLoading) {
			return;
		}

		dispatch({ type: REQUEST_HEAT_MAP_COUNTRIES_TYPE });

		try {
			const url = new URL(
				'/api/analytics/incidentregions/countries',
				stringLookup.baseUrl
			);
			let response = await fetchSecured(url, 'loadHeatMapCountries');

			if (response.ok) {
				const heatMapCountries = await response.json();
				dispatch({
					type: RECEIVE_HEAT_MAP_COUNTRIES_TYPE,
					heatMapCountries,
				});
			} else {
				dispatch({ type: RECEIVE_HEAT_MAP_COUNTRIES_ERROR_TYPE });
			}
		} catch (err) {
			console.log(err.message);
			dispatch({ type: RECEIVE_HEAT_MAP_COUNTRIES_ERROR_TYPE });
		}
	},

	loadRegionChoroplethData: (regionDataPath) => async (dispatch, getState) => {
		if (getState().incidenceTimeline.regionChoroplethDataLoading) {
			return;
		}

		dispatch({ type: REQUEST_REGION_CHOROPLETH_DATA_TYPE });

		try {
			const url = new URL(regionDataPath);
			let response = await fetch(url);

			if (response.ok) {
				const regionChoroplethData = await response.json();
				dispatch({
					type: RECEIVE_REGION_CHOROPLETH_DATA_TYPE,
					regionChoroplethData,
				});
			} else {
				dispatch({ type: RECEIVE_REGION_CHOROPLETH_DATA_ERROR_TYPE });
			}
		} catch (err) {
			console.log(err.message);
			dispatch({ type: RECEIVE_REGION_CHOROPLETH_DATA_ERROR_TYPE });
		}
	},

	setCustomIncidentTypeColors:
		(customIncidentTypeColors) => async (dispatch) => {
			dispatch({
				type: SET_CUSTOM_INCIDENT_TYPE_COLORS_TYPE,
				customIncidentTypeColors,
			});
		},

	setCustomActorColors: (customActorColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_ACTOR_COLORS_TYPE,
			customActorColors,
		});
	},

	setCustomImpactVectorColors:
		(customImpactVectorColors) => async (dispatch) => {
			dispatch({
				type: SET_CUSTOM_IMPACT_VECTOR_COLORS_TYPE,
				customImpactVectorColors,
			});
		},

	setCustomTargetTypeColors: (customTargetTypeColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_TARGET_TYPE_COLORS_TYPE,
			customTargetTypeColors,
		});
	},

	setCustomIncidentNumericColors:
		(customIncidentNumericColors) => async (dispatch) => {
			dispatch({
				type: SET_CUSTOM_INCIDENT_NUMERIC_COLORS_TYPE,
				customIncidentNumericColors,
			});
		},

	loadTimelapseData:
		(
			startDate,
			endDate,
			weaponFilter,
			actorsFilter,
			targetFilter,
			incidentTypes,
			countriesFilter,
			geofenceBounds,
			regionsFilter,
			datasetFilter,
			subtopicFilters,
			incidentNumericFilter,
			actorSubtopics
		) =>
		async (dispatch, getState) => {
			if (getState().incidenceTimeline.incidentsTimelapseDataLoading) {
				return;
			}

			dispatch({ type: requestIncidentsTimelapseDataType });

			try {
				const url = new URL(
					'/api/analytics/incidentregions/timelapse',
					stringLookup.baseUrl
				);

				url.searchParams.append('startDate', startDate.toISOString());
				url.searchParams.append('endDate', endDate.toISOString());

				if (weaponFilter) {
					url.searchParams.append('weaponFilter', weaponFilter);
				}
				if (actorsFilter?.length > 0) {
					const filteredActors = actorsFilter.filter(
						(a) => !actorSubtopics.find((as) => as.name === a)
					);
					filteredActors.forEach((x) => {
						url.searchParams.append('actorFilter', x);
					});
				}
				if (targetFilter) {
					url.searchParams.append('targetFilter', targetFilter);
				}

				if (incidentNumericFilter) {
					url.searchParams.append('eventNumberType', incidentNumericFilter);
				}

				if (incidentTypes?.length > 0) {
					incidentTypes.forEach((x) => {
						url.searchParams.append('incidentTypes', x);
					});
				}

				if (datasetFilter) {
					url.searchParams.append('datasetsFilter', datasetFilter);
				}

				if (countriesFilter?.length > 0) {
					countriesFilter.forEach((x) => {
						url.searchParams.append('countriesFilter', x);
					});
				}

				if (regionsFilter?.length > 0) {
					regionsFilter.forEach((x) => {
						url.searchParams.append('regionsFilter', x);
					});
				}

				if (subtopicFilters?.length > 0) {
					subtopicFilters.forEach((s) => {
						url.searchParams.append('subtopics', s);
					});
				}

				if (geofenceBounds?.NorthLatitude) {
					url.searchParams.append(
						'NorthLatitude',
						geofenceBounds?.NorthLatitude
					);
				}

				if (geofenceBounds?.EastLongitude) {
					url.searchParams.append(
						'EastLongitude',
						geofenceBounds?.EastLongitude
					);
				}

				if (geofenceBounds?.SouthLatitude) {
					url.searchParams.append(
						'SouthLatitude',
						geofenceBounds?.SouthLatitude
					);
				}

				if (geofenceBounds?.WestLongitude) {
					url.searchParams.append(
						'WestLongitude',
						geofenceBounds?.WestLongitude
					);
				}

				const res = await fetchSecured(url, 'incidentsTimelapse');

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveIncidentsTimelapseDataType,
						incidentsTimelapseData: data,
					});
				} else {
					dispatch({ type: receiveIncidentsTimelapseDataErrorType });
				}
			} catch (error) {
				dispatch({ type: receiveIncidentsTimelapseDataErrorType });
			}
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === REQUEST_INCIDENCE_REPORT_TYPE) {
		return {
			...state,
			incidentLocationsLoading: true,
		};
	}

	if (action.type === REQUEST_INCIDENCE_TIMELINE_TYPE) {
		return {
			...state,
			incidentsTimelineLoading: true,
			incidenceTimeline: null,
		};
	}
	if (action.type === RECEIVE_INCIDENCE_TIMELINE_TYPE) {
		return {
			...state,
			incidentsTimeline: action.incidentsTimeline,
			incidentsDateHistograms: action.incidentsDateHistograms,
			incidentsTimelineLoading: false,
		};
	}
	if (action.type === RECEIVE_INCIDENCE_TIMELINE_ERROR_TYPE) {
		return {
			...state,
			incidentsTimelineLoading: false,
			incidenceError: true,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_REPORT_TYPE) {
		return {
			...state,
			incidentLocations: action.incidentLocations,
			numberOfLocations: action.numberOfLocations,
			numberOfIncidents: action.numberOfIncidents,
			centerPosition: action.centerPosition,
			incidentLocationsLoading: false,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_REPORT_ERROR_TYPE) {
		return {
			...state,
			incidentLocationsLoading: false,
			incidenceError: true,
		};
	}

	if (action.type === REQUEST_INCIDENCE_SUMMARY_TYPE) {
		return {
			...state,
			summaryLoading: true,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_SUMMARY_TYPE) {
		return {
			...state,
			summary: action.summary,
			summaryLoading: false,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			summaryLoading: false,
		};
	}

	if (action.type === REQUEST_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE) {
		return {
			...state,
			updatedIncidentsLoading: true,
			updatedIncidentsSummary: [],
		};
	}

	if (action.type === RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_TYPE) {
		return {
			...state,
			updatedIncidentsSummary: action.updatedIncidentsSummary,
			updatedIncidentsLoading: false,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_SUMMARY_BY_CLUSTER_ID_ERROR_TYPE) {
		return {
			...state,
			updatedIncidentsLoading: false,
		};
	}

	if (action.type === REQUEST_INCIDENCE_CLUSTERS_TYPE) {
		return {
			...state,
			clustersLoading: true,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_CLUSTERS_TYPE) {
		return {
			...state,
			clustersData: action.clustersData,
			clustersLoading: false,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_CLUSTERS_ERROR_TYPE) {
		return {
			...state,
			clustersLoading: false,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_WEAPONS_TYPE) {
		return {
			...state,
			weapons: action.weapons,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_ACTORS_TYPE) {
		return {
			...state,
			actors: action.actors,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_TARGETS_TYPE) {
		return {
			...state,
			targets: action.targets,
		};
	}

	if (action.type === SET_INCIDENTS_VISUALIZATION_TYPE_TYPE) {
		return {
			...state,
			incidentsVisualizationType: action.incidentsVisualizationType,
		};
	}

	if (action.type === REQUEST_INCIDENCE_VISUALIZATIONS_TYPE) {
		return {
			...state,
			incidentsVisualizationsLoading: true,
			incidentsVisualizations: [],
			incidentsVisAttacks: {},
			incidentsVisualizationsError: false,
		};
	}
	if (action.type === RECEIVE_INCIDENCE_VISUALIZATIONS_TYPE) {
		return {
			...state,
			incidentsVisualizationsLoading: false,
			incidentsVisualizations: action.incidentsVisualizations,
			incidentsVisAttacks: action.incidentsVisAttacks,
		};
	}
	if (action.type === RECEIVE_INCIDENCE_VISUALIZATIONS_ERROR_TYPE) {
		return {
			...state,
			incidentsVisualizationsLoading: false,
			incidentsVisualizations: [],
			incidentsVisAttacks: {},
			incidentsVisualizationsError: true,
		};
	}

	if (action.type === REQUEST_CHOROPLETH_DATA_TYPE) {
		return {
			...state,
			choroplethDataLoading: true,
		};
	}

	if (action.type === RECEIVE_CHOROPLETH_DATA_TYPE) {
		return {
			...state,
			choroplethDataLoading: false,
			choroplethCountries: action.choroplethCountries,
		};
	}

	if (action.type === RECEIVE_CHOROPLETH_DATA_ERROR_TYPE) {
		return {
			...state,
			choroplethDataLoading: false,
		};
	}

	if (action.type === RECEIVE_INCIDENCE_DOWNLOAD_TYPE) {
		return {
			...state,
			incidentDataBlob: action.incidentDataBlob,
		};
	}
	if (action.type === RECEIVE_INCIDENCE_DOWNLOAD_ERROR_TYPE) {
		return {
			...state,
			incidentDownloadError: true,
		};
	}

	if (action.type === REQUEST_INCIDENCE_TYPES_COUNT_TYPE) {
		return {
			...state,
			incidentTypesCountLoading: true,
			incidentTypesCountError: false,
		};
	}
	if (action.type === RECEIVE_INCIDENCE_TYPES_COUNT_TYPE) {
		return {
			...state,
			incidentTypesCount: action.incidentTypesCount,
			incidentTypesCountLoading: false,
		};
	}
	if (action.type === RECEIVE_INCIDENCE_TYPES_COUNT_ERROR_TYPE) {
		return {
			...state,
			incidentTypesCountLoading: false,
			incidentTypesCountError: true,
		};
	}

	if (action.type === REQUEST_INCIDENTS_REGIONS_TYPE) {
		return {
			...state,
			incidentRegionsLoading: true,
			incidentRegionsError: false,
		};
	}
	if (action.type === RECEIVE_INCIDENTS_REGIONS_TYPE) {
		return {
			...state,
			incidentRegionsLoading: false,
			incidentRegions: action.incidentRegions,
			incidentCount: action.incidentCount,
			locationCount: action.locationCount,
		};
	}
	if (action.type === RECEIVE_INCIDENTS_REGIONS_ERROR_TYPE) {
		return {
			...state,
			incidentRegionsLoading: false,
			incidentRegionsError: true,
		};
	}

	if (action.type === REQUEST_REGION_CHOROPLETH_DATA_TYPE) {
		return {
			...state,
			regionChoroplethDataLoading: true,
		};
	}
	if (action.type === RECEIVE_REGION_CHOROPLETH_DATA_TYPE) {
		return {
			...state,
			regionChoroplethDataLoading: false,
			regionChoroplethData: action.regionChoroplethData,
		};
	}
	if (action.type === RECEIVE_REGION_CHOROPLETH_DATA_ERROR_TYPE) {
		return {
			...state,
			regionChoroplethDataLoading: false,
		};
	}

	if (action.type === REQUEST_HEAT_MAP_COUNTRIES_TYPE) {
		return {
			...state,
			heatMapCountriesLoading: true,
		};
	}
	if (action.type === RECEIVE_HEAT_MAP_COUNTRIES_TYPE) {
		return {
			...state,
			heatMapCountriesLoading: false,
			heatMapCountries: action.heatMapCountries,
		};
	}
	if (action.type === RECEIVE_HEAT_MAP_COUNTRIES_ERROR_TYPE) {
		return {
			...state,
			heatMapCountriesLoading: false,
		};
	}

	if (action.type === SET_CUSTOM_INCIDENT_TYPE_COLORS_TYPE) {
		return {
			...state,
			customIncidentTypeColors: action.customIncidentTypeColors,
		};
	}

	if (action.type === SET_CUSTOM_ACTOR_COLORS_TYPE) {
		return {
			...state,
			customActorColors: action.customActorColors,
		};
	}

	if (action.type === SET_CUSTOM_IMPACT_VECTOR_COLORS_TYPE) {
		return {
			...state,
			customImpactVectorColors: action.customImpactVectorColors,
		};
	}

	if (action.type === SET_CUSTOM_TARGET_TYPE_COLORS_TYPE) {
		return {
			...state,
			customTargetTypeColors: action.customTargetTypeColors,
		};
	}

	if (action.type === SET_CUSTOM_INCIDENT_NUMERIC_COLORS_TYPE) {
		return {
			...state,
			customIncidentNumericColors: action.customIncidentNumericColors,
		};
	}

	if (action.type === requestIncidentsTimelapseDataType) {
		return {
			...state,
			incidentsTimelapseDataLoading: true,
			incidentsTimelapseError: false,
		};
	}

	if (action.type === receiveIncidentsTimelapseDataType) {
		return {
			...state,
			incidentsTimelapseDataLoading: false,
			incidentsTimelapseError: false,
			incidentsTimelapseData: action.incidentsTimelapseData,
		};
	}

	if (action.type === receiveIncidentsTimelapseDataErrorType) {
		return {
			...state,
			incidentsTimelapseDataLoading: false,
			incidentsTimelapseError: true,
		};
	}

	return state;
};
