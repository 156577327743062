import { fetchSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const REQUEST_WEBPAGE_DETAILS = 'REQUEST_WEBPAGE_DETAILS';
const RECEIVE_WEBPAGE_DETAILS_SUCCESS = 'RECEIVE_WEBPAGE_DETAILS_SUCCESS';
const RECEIVE_WEBPAGE_DETAILS_ERROR = 'RECEIVE_WEBPAGE_DETAILS_ERROR';

const initialState = {
	webpageTextSegmentsLoading: false,
	webpageTextSegments: null,
	webpageTextSegmentsError: false,
};

export const webpagesActionCreators = {
	getWebpageTextSegments:
		(webpageUrl, classifierId, mlAction, mlResult) =>
		async (dispatch, getState) => {
			if (getState().webpages.webpageTextSegmentsLoading) {
				return;
			}

			dispatch({ type: REQUEST_WEBPAGE_DETAILS });

			try {
				const url = new URL(
					'/api/crawler/webpages/crawl',
					stringLookup.baseUrl
				);

				url.searchParams.append('url', webpageUrl);

				if (classifierId) {
					url.searchParams.append('classifierId', classifierId);
				}

				if (mlResult) {
					url.searchParams.append('label', mlResult);
				}

				if (mlAction) {
					url.searchParams.append('action', mlAction);
				}

				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: RECEIVE_WEBPAGE_DETAILS_SUCCESS,
						webpageTextSegments: data,
					});
				} else {
					dispatch({ type: RECEIVE_WEBPAGE_DETAILS_SUCCESS });
				}
			} catch (error) {
				dispatch({ type: RECEIVE_WEBPAGE_DETAILS_ERROR });
			}
		},
};

const webpagesReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_WEBPAGE_DETAILS:
			return {
				...state,
				webpageTextSegmentsLoading: true,
				webpageTextSegmentsError: false,
			};
		case RECEIVE_WEBPAGE_DETAILS_SUCCESS:
			return {
				...state,
				webpageTextSegmentsLoading: false,
				webpageTextSegmentsError: false,
				webpageTextSegments: action.webpageTextSegments,
			};
		case RECEIVE_WEBPAGE_DETAILS_ERROR:
			return {
				...state,
				webpageTextSegmentsLoading: false,
				webpageTextSegmentsError: true,
			};
		default:
			return state;
	}
};

export default webpagesReducer;
