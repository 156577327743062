import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import React from "react";
import LinearLoading from "./../../../util/LinearLoading";
import { throttle } from "../../../../_lib/functions";
import SearchIcon from "@material-ui/icons/Search";

const styles = (theme) => ({
	selectAll: {
		marginLeft: theme.spacing.unit * 2,
	},
	selectAllLabel: {
		paddingLeft: theme.spacing.unit * 1.5,
	},
	searchTextField: {
		width: 200,
		position: "absolute",
		right: 12,
		top: 22,
	},
});

class SelectContactsDialog extends React.Component {
	state = {
		selectedContacts: [],
		contactsError: true,
		allChecked: false,
		searchString: "",
	};

	toggleSelectAll = () => {
		const { allChecked } = this.state;
		if (allChecked) {
			this.setState({ selectedContacts: [], allChecked: false });
		} else {
			const checkedContacts = this.props.contacts.map(
				(contact) => contact.id
			);
			this.setState({
				selectedContacts: checkedContacts,
				allChecked: true,
			});
		}
	};

	toggleSelectedContact = (contactId) => {
		const { selectedContacts } = this.state;
		const currentIndex = selectedContacts.indexOf(contactId);
		const newContactSelected = [...selectedContacts];

		if (currentIndex === -1) {
			newContactSelected.push(contactId);
		} else {
			newContactSelected.splice(currentIndex, 1);
		}

		const allChecked =
			this.props.contacts.length === newContactSelected.length;

		this.setState(
			{ selectedContacts: newContactSelected, allChecked: allChecked },
			() =>
				console.log("Selected Contacts: " + this.state.selectedContacts)
		);
	};

	loadFilteredContacts = async () => {
		await this.props.getAllContacts(this.state.searchString);
	};

	handleSearchInput = (input) => {
		const that = this;
		this.setState({ searchString: input }, async () => {
			await throttle(that.loadFilteredContacts, 1000)();
		});
	};

	render() {
		const {
			classes,
			fullScreen,
			open,
			handleClose,
			contacts,
			handleSubmitRequest,
			contactsLoading,
		} = this.props;

		const { selectedContacts, contactsError, allChecked, searchString } =
			this.state;

		const { toggleSelectedContact, toggleSelectAll, handleSearchInput } =
			this;

		const contactSelected = (contactId) =>
			selectedContacts.indexOf(contactId) !== -1;

		return (
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<div style={{ height: 3 }}>
					<LinearLoading active={contactsLoading} />
				</div>
				<DialogTitle id="responsive-dialog-title">
					<Grid container>
						<Grid item style={{ flexGrow: 1 }}>
							Contacts
						</Grid>
						<Grid item>
							<TextField
								className={classes.searchTextField}
								id="chats-search-input"
								placeholder="Search"
								value={searchString}
								// variant="outlined"
								onChange={(e) =>
									handleSearchInput(e.target.value)
								}
								InputProps={{
									startAdornment: (
										<InputAdornment position="end">
											<SearchIcon
												style={{ marginRight: 12 }}
											/>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</DialogTitle>
				<Divider />
				<DialogContent style={{ height: 600, width: 500 }}>
					{/* <div className={classes.selectAll}>
                            <Typography variant="body2">
                                <Checkbox
                                    checked={allChecked}
                                    disableRipple
                                    disabled={false}
                                    onClick={() => toggleSelectAll()}
                                />
                                <span className={classes.selectAllLabel}> Select all </span>
                            </Typography>
                        </div> */}
					<List dense>
						{contacts.map((c) => {
							return (
								<ListItem
									disabled={false}
									disableRipple
									key={c.id}
									dense
									button
									onClick={() => toggleSelectedContact(c.id)}
								>
									<Checkbox
										checked={contactSelected(c.id)}
										tabIndex={-1}
										disableRipple
										disabled={false}
									/>
									<ListItemText
										primary={<span> {c.name} </span>}
									/>
								</ListItem>
							);
						})}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Back
					</Button>
					<Button
						onClick={() => handleSubmitRequest(selectedContacts)}
						color="default"
						disabled={false}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles)(withMobileDialog()(SelectContactsDialog));
