import { fetchSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const REQUEST_YOUTUBE_CHANNEL = 'REQUEST_YOUTUBE_CHANNEL';
const RECEIVE_YOUTUBE_CHANNEL_SUCCESS = 'RECEIVE_YOUTUBE_CHANNEL_SUCCESS';
const RECEIVE_YOUTUBE_CHANNEL_ERROR = 'RECEIVE_YOUTUBE_CHANNEL_ERROR';

const initialState = {
	isYoutubeChannelLoading: false,
	channelDetails: null,
	isChannelLoadingError: false,
};

export const YoutubeActionCreators = {
	getYoutubeChannel: (payload) => async (dispatch, getState) => {
		if (getState().isYoutubeChannelLoading) {
			return;
		}

		dispatch({ type: REQUEST_YOUTUBE_CHANNEL });

		try {
			const url = new URL('/api/v1/youtube/channels', stringLookup.baseUrl);

			if (payload.channelName && payload.channelName.length > 0) {
				url.searchParams.append('channelName', payload.channelName);
			}

			if (payload.channelId && payload.channelId.length > 0) {
				url.searchParams.append('channelId', payload.channelId);
			}

			const res = await fetchSecured(url);

			if (res.ok) {
				const channelDetails = await res.json();

				dispatch({ type: RECEIVE_YOUTUBE_CHANNEL_SUCCESS, channelDetails });
			} else {
				dispatch({ type: RECEIVE_YOUTUBE_CHANNEL_ERROR });
			}
		} catch (error) {
			dispatch({ type: RECEIVE_YOUTUBE_CHANNEL_ERROR });
		}
	},
};

//reducer
const youtubeReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_YOUTUBE_CHANNEL:
			return {
				...state,
				isYoutubeChannelLoading: true,
			};
		case RECEIVE_YOUTUBE_CHANNEL_SUCCESS:
			return {
				...state,
				isYoutubeChannelLoading: false,
				channelDetails: action.channelDetails,
			};
		case RECEIVE_YOUTUBE_CHANNEL_ERROR:
			return {
				...state,
				isYoutubeChannelLoading: false,
				isChannelLoadingError: true,
				channelDetails: null,
			};
		default:
			break;
	}

	return state;
};

export default youtubeReducer;
