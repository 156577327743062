import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { authActionCreators } from './store/Auth';
import { getLoggedInuser } from './authUtils';
import { Auth } from '@aws-amplify/auth';
import FallbackLoader from './components/util/FallbackLoader';
import EmbedOnboardingScreen from './components/SignUp/embedOnboardingScreen';

const SignIn = lazy(() => import('./components/SignIn'));
const AppLoggedIn = lazy(() => import('./AppLoggedIn'));
const AppLandingPage = lazy(() => import('./AppLandingPage'));
const SignUp = lazy(() => import('./components/SignUp'));

const styles = (theme) => ({
	'@global': {
		// MUI typography elements use REMs, so you can scale the global
		// font size by setting the font-size on the <html> element.
		html: {
			fontSize: 14,
			// [theme.breakpoints.up("sm")]: {
			// 	fontSize: 18,
			// },
			// [theme.breakpoints.up("md")]: {
			// 	fontSize: 20,
			// },
			// [theme.breakpoints.up("lg")]: {
			// 	fontSize: 24,
			// },
		},
	},
});

class App extends React.Component {
	componentWillMount = async () => {
		//check user logged in
		const user = await getLoggedInuser();

		if (user == null) {
			await this.props.invokeSignedOut();
		}
	};

	verifyGuestApp = () => {
		const { location } = this.props;
		if (location.pathname.includes('/default')) {
			Auth.signOut();
			return true;
		} else {
			return false;
		}
	};

	// verifyCovid19App = () => {
	// 	const { location } = this.props;
	// 	if (location.pathname.includes('/covid19')) {
	// 		return true;
	// 	}

	// 	return false;
	// };

	userLoggedIn = async () => {
		const user = await getLoggedInuser();
		if (user !== null) {
			return true;
		} else {
			return false;
		}
	};

	render() {
		const customTheme = createTheme({
			palette: {
				primary: {
					main: this.props.organisationColor ?? '#26324e',
				},
				secondary: {
					main: '#e57373',
					contrastText: '#fff',
				},
			},
		});

		const defaultRoute = (
			<Route
				exact
				path='/'
				render={(routeParams) => {
					return <AppLandingPage {...routeParams} />;
				}}
			/>
		);

		const signUpRoute = (
			<Route
				exact
				path='/signup/:userId'
				render={({ match, location }) => {
					const userId = match.params.userId;
					return <SignUp url={match.url} userId={userId} />;
				}}
			/>
		);

		// new users creating organizations with incidents feature
		const incidentsAccessSignUpRoute = (
			<Route
				exact
				path='/signup'
				render={(props) => {
					return <EmbedOnboardingScreen url={props.match.url} {...props} />;
				}}
			/>
		);

		// const loginExactRoute = (
		// 	<Route
		// 		exact
		// 		path="/login"
		// 		render={({ match, location }) => {
		// 			return <SignIn url={match.url} />;
		// 		}}
		// 	/>
		// );

		const loginRoute = (
			<Route
				path='/login'
				render={(props) => {
					return <SignIn url={props.match.url} {...props} />;
				}}
			/>
		);

		const guestRoute = this.verifyGuestApp() && (
			<Route path='/:defaultOrganisation' component={AppLandingPage} />
		);

		const loggedInRoute = !this.verifyGuestApp() && (
			<Route path='/:organisationId' component={AppLoggedIn} />
		);

		return (
			<ThemeProvider theme={customTheme}>
				<Suspense
					fallback={
						<div style={{ paddingTop: '10vh' }}>
							<FallbackLoader />
						</div>
					}
				>
					<Switch>
						{defaultRoute}
						{incidentsAccessSignUpRoute}
						{signUpRoute}
						{/* {loginExactRoute} */}
						{loginRoute}
						{guestRoute}
						{loggedInRoute}
					</Switch>
				</Suspense>
			</ThemeProvider>
		);
	}
}

export default withRouter(
	connect(
		(state) => {
			return {
				signedIn: state.auth.signedIn,
				organisationId: state.auth.organisationId,
				organisationColor: state.auth.organisationColor,
			};
		},
		(dispatch) => bindActionCreators(Object.assign({}, authActionCreators), dispatch)
	)(withStyles(styles, { withTheme: true })(App))
);
