import { deleteSecured, fetchSecured, postSecured, putSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const requestRankingWeightsType = 'REQUEST_RANKING_WEIGHTS';
const receiveRankingWeightsType = 'RECEIVE_RANKING_WEIGHTS';
const receiveRankingWeightsErrorType = 'RECEIVE_RANKING_WEIGHTS_ERROR';

const requestDocumentRankingType = 'REQUEST_DOCUMENT_RANKING';
const receiveDocumentRankingType = 'RECEIVE_DOCUMENT_RANKING';
const receiveDocumentRankingErrorType = 'RECEIVE_DOCUMENT_RANKING_ERROR';

const updateRankingWeightsType = 'UPDATE_RANKING_WEIGHTS';
const updateRankingWeightsCompleteType = 'UPDATE_RANKING_WEIGHTS_COMPLETE';
const updateRankingWeightsErrorType = 'UPDATE_RANKING_WEIGHTS_ERROR';

const initialState = {
	rankingWeightsLoading: false,
	rankingWeightsError: false,
	rankingWeights: [],
	documentRankingLoading: false,
	documentRankingError: false,
	documentRanking: [],
	updatingRankingWeights: false,
	updatingRankingWeightsErrorMessage: '',
};

export const rankingActionCreators = {
	getRankingWeights: () => async (dispatch, getState) => {
		if (getState().ranking.rankingWeightsLoading) {
			return;
		}

		dispatch({ type: requestRankingWeightsType });

		const url = new URL('/api/v1/ranking', stringLookup.baseUrl);

		try {
			const res = await fetchSecured(url);
			if (res.ok) {
				const rankingResponse = await res.json();
				dispatch({
					type: receiveRankingWeightsType,
					rankingWeights: rankingResponse,
				});
			} else {
				dispatch({ type: receiveRankingWeightsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveRankingWeightsErrorType });
		}
	},

	getDocumentRanking: (webpageId) => async (dispatch, getState) => {
		if (getState().ranking.documentRankingLoading) {
			return;
		}

		dispatch({ type: requestDocumentRankingType });
		const url = new URL('/api/v1/ranking/document', stringLookup.baseUrl);
		url.searchParams.append('id', webpageId);

		try {
			const res = await fetchSecured(url);
			if (res.ok) {
				const rankingResponse = await res.json();
				dispatch({
					type: receiveDocumentRankingType,
					documentRanking: rankingResponse,
				});
			} else {
				dispatch({ type: receiveDocumentRankingErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveDocumentRankingErrorType });
		}
	},

	updateRankingWeights: (rankingWeights) => async (dispatch, getState) => {
		if (getState().ranking.updatingRankingWeights) {
			return;
		}

		dispatch({ type: updateRankingWeightsType });
		const url = new URL('/api/v1/ranking/', stringLookup.baseUrl);
		const body = { weights: [rankingWeights] };

		try {
			const res = await putSecured(url, body);

			if (res.ok) {
				dispatch({
					type: updateRankingWeightsCompleteType,
				});
			} else if (res.status === 400) {
				const _400ResMessage = await res.json();
				dispatch({
					type: updateRankingWeightsErrorType,
					updatingRankingWeightsErrorMessage: _400ResMessage.description,
				});
			}
		} catch (err) {
			dispatch({
				type: updateRankingWeightsErrorType,
				updatingRankingWeightsErrorMessage: err.message,
			});
		}
	},
};

export const reducer = (state = initialState, action) => {
	if (action.type === requestRankingWeightsType) {
		return {
			...state,
			rankingWeightsLoading: true,
			rankingWeightsError: false,
		};
	}
	if (action.type === receiveRankingWeightsType) {
		return {
			...state,
			rankingWeightsLoading: false,
			rankingWeights: action.rankingWeights,
		};
	}
	if (action.type === receiveRankingWeightsErrorType) {
		return {
			...state,
			rankingWeightsLoading: false,
			rankingWeightsError: true,
		};
	}

	if (action.type === requestDocumentRankingType) {
		return {
			...state,
			documentRankingLoading: true,
			documentRankingError: false,
		};
	}
	if (action.type === receiveDocumentRankingType) {
		return {
			...state,
			documentRankingLoading: false,
			documentRanking: action.documentRanking,
		};
	}
	if (action.type === receiveDocumentRankingErrorType) {
		return {
			...state,
			documentRankingLoading: false,
			documentRankingError: true,
		};
	}

	if (action.type === updateRankingWeightsType) {
		return {
			...state,
			updatingRankingWeights: true,
			updatingRankingWeightsError: false,
			updatingRankingWeightsErrorMessage: '',
			updatingRankingWeightsSuccess: false,
		};
	}
	if (action.type === updateRankingWeightsCompleteType) {
		return {
			...state,
			updatingRankingWeights: false,
			updatingRankingWeightsSuccess: true,
		};
	}
	if (action.type === updateRankingWeightsErrorType) {
		return {
			...state,
			updatingRankingWeights: false,
			updatingRankingWeightsError: true,
			updatingRankingWeightsErrorMessage: action.updatingRankingWeightsErrorMessage,
		};
	}

	return state;
};
