import stringLookup from '../stringslookup';
import {
	fetchSecured,
	postSecured,
	deleteSecured,
	putSecured,
} from './../fetchUtils';

const requestCategoriesType = 'REQUEST_CONFIG_CATEGORIES';
const receiveCategoriesType = 'RECEIVE_CONFIG_CATEGORIES';
const receiveCategoriesErrorType = 'RECEIVE_CONFIG_CATEGORIES_FETCH_ERROR';

const requestAllCategoriesType = 'REQUEST_CONFIG_ALL_CATEGORIES';
const receiveAllCategoriesType = 'RECEIVE_CONFIG_ALL_CATEGORIES';
const receiveAllCategoriesErrorType =
	'RECEIVE_CONFIG_ALL_CATEGORIES_FETCH_ERROR';

const addCategoryType = 'ADD_CONFIG_CATEGORIES';
const addCategoryCompleteType = 'ADD_CONFIG_CATEGORIES_COMPLETE';

const editCategoryType = 'EDIT_CONFIG_CATEGORIES';
const editCategoryCompleteType = 'EDIT_CONFIG_CATEGORIES_COMPLETE';

const deleteCategoryType = 'DELETE_CONFIG_CATEGORIES';
const deleteCategoryCompleteType = 'DELETE_CONFIG_CATEGORIES_COMPLETE';

const requestTopicListType = 'REQUEST_CONFIG_TOPICS_LIST';
const receiveTopicListType = 'RECEIVE_CONFIG_TOPICS_LIST';
const receiveTopicListErrorType = 'REQUEST_CONFIG_TOPICS_LIST_ERROR';

const modifyCategory400ErrorType = 'MODIFY_CATEGORIES_400_Error_COMPLETE';
const modifyCategory400ErrorReset = 'MODIFY_CATEGORIES_400_Error_Reset';

const requestEntityTypesListType = 'REQUEST_ENTITY_TYPE_LIST';
const receiveEntityTypesListType = 'RECEIVE_ENTITY_TYPE_LIST';
const receiveEntityTypesListErrorType = 'RECEIVE_ENTITY_TYPE_LIST_ERROR';

const initialCategoriesState = {
	categories: [],
	allCategories: [],
	totalCategories: 0,
	categoriesLoading: false,
	categoriesError: false,
	allCategoriesLoading: false,
	addingCategory: false,
	deletingCategory: false,
	topicsList: [],
	topicsListLoading: false,
	editingCategory: false,
	validationError: null,
	entityTypesLoading: false,
	entityTypesList: [],
};

export const categoriesActionCreators = {
	requestAllCategoriesList: orgId => async (dispatch, getState) => {
		if (getState().configurationCategories.allCategoriesLoading) {
			return;
		}
		dispatch({ type: requestAllCategoriesType });
		const url = new URL('/api/categories', stringLookup.baseUrl);
		url.searchParams.append('all', true);
		url.searchParams.append('OrganisationId', orgId);

		try {
			const allCategoriesResponse = await fetchSecured(url);

			if (allCategoriesResponse.ok) {
				const allCategoriesRes = await allCategoriesResponse.json();
				dispatch({
					type: receiveAllCategoriesType,
					allCategories: allCategoriesRes.categoryViewModels,
				});
			} else {
				dispatch({ type: receiveAllCategoriesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveAllCategoriesErrorType });
		}
	},
	requestCategoriesList: (
		page,
		pageSize,
		orgId,
		searchString = '',
		includeUnassigned = false,
	) => async (dispatch, getState) => {
		if (getState().configurationCategories.categoriesLoading) {
			return;
		}

		dispatch({ type: requestCategoriesType });

		const url = new URL('/api/categories', stringLookup.baseUrl);
		url.searchParams.append('page', page);
		url.searchParams.append('pageSize', pageSize);
		url.searchParams.append('OrganisationId', orgId);

		if (searchString.length !== 0) {
			url.searchParams.append('searchString', searchString);
		}

		if (includeUnassigned) {
			url.searchParams.append('includeunassigned', includeUnassigned);
		}

		try {
			const categoriesResponse = await fetchSecured(url);

			if (categoriesResponse.ok) {
				const categoriesRes = await categoriesResponse.json();
				dispatch({
					type: receiveCategoriesType,
					categories: categoriesRes.categoryViewModels,
					totalCategories: categoriesRes.count,
				});
			} else {
				dispatch({ type: receiveCategoriesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveCategoriesErrorType });
		}
	},

	addCategory: category => async (dispatch, getState) => {
		const metadata = {};
		if (getState().configurationCategories.addingCategory) {
			return metadata;
		}

		dispatch({ type: addCategoryType });
		const url = new URL('/api/categories', stringLookup.baseUrl);

		try {
			const categoriesResponse = await postSecured(url, category);

			if (categoriesResponse.ok) {
				dispatch({ type: addCategoryCompleteType });
			} else if (categoriesResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await categoriesResponse.json();
				dispatch({
					type: modifyCategory400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: addCategoryCompleteType });
			}
		} catch (err) {
			dispatch({ type: addCategoryCompleteType });
		}

		return metadata;
	},

	editCategory: (category, categoryId) => async (dispatch, getState) => {
		const metadata = {};

		if (getState().configurationCategories.editingCategory) {
			return metadata;
		}

		dispatch({ type: editCategoryType });
		const url = new URL(
			`/api/categories/${categoryId}/update`,
			stringLookup.baseUrl,
		);

		try {
			const categoriesResponse = await postSecured(url, category);

			if (categoriesResponse.ok) {
				dispatch({ type: editCategoryCompleteType });
			} else if (categoriesResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await categoriesResponse.json();
				dispatch({
					type: modifyCategory400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: editCategoryCompleteType });
			}
		} catch (err) {
			dispatch({ type: editCategoryCompleteType });
		}

		return metadata;
	},

	deleteCategory: categoryId => async (dispatch, getState) => {
		if (getState().configurationCategories.deletingCategory) {
			return;
		}

		dispatch({ type: deleteCategoryType });

		const url = new URL('/api/categories', stringLookup.baseUrl);
		url.searchParams.append('id', categoryId);

		try {
			const deleteResponse = await deleteSecured(url);
			if (deleteResponse.ok) {
				dispatch({ type: deleteCategoryCompleteType });
			} else {
				dispatch({ type: deleteCategoryCompleteType });
			}
		} catch (err) {
			dispatch({ type: deleteCategoryCompleteType });
		}
	},

	requestTopicsList: _ => async (dispatch, getState) => {
		if (getState().configurationCategories.topicsListLoading) {
			return;
		}

		dispatch({ type: requestTopicListType });

		const url = new URL('/api/crawler/topic/alltopics', stringLookup.baseUrl);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({ type: receiveTopicListType, topicsList: data });
			} else {
				dispatch({ type: receiveTopicListErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveTopicListErrorType });
		}
	},
	requestEntityTypes: _ => async (dispatch, getState) => {
		if (getState().configurationCategories.entityTypesLoading) {
			return;
		}

		dispatch({ type: requestEntityTypesListType });

		const url = new URL(
			'/api/crawler/subtopic/entitytypes',
			stringLookup.baseUrl,
		);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({ type: receiveEntityTypesListType, entityTypesList: data });
			} else {
				dispatch({ type: receiveEntityTypesListErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveEntityTypesListErrorType });
		}
	},
	resetValidationMessage: () => async (dispatch, getState) => {
		dispatch({ type: modifyCategory400ErrorReset });
	},
};

export const reducer = (state, action) => {
	state = state || initialCategoriesState;

	if (action.type === requestAllCategoriesType) {
		return {
			...state,
			allCategoriesLoading: true,
		};
	}
	if (action.type === receiveAllCategoriesType) {
		return {
			...state,
			allCategories: action.allCategories,
			allCategoriesLoading: false,
		};
	}
	if (action.type === receiveCategoriesErrorType) {
		return {
			...state,
			allCategoriesLoading: false,
		};
	}

	if (action.type === requestCategoriesType) {
		return {
			...state,
			categoriesLoading: true,
		};
	}
	if (action.type === receiveCategoriesType) {
		return {
			...state,
			categories: action.categories,
			totalCategories: action.totalCategories,
			categoriesLoading: false,
		};
	}
	if (action.type === receiveCategoriesErrorType) {
		return {
			...state,
			categoriesLoading: false,
			categoriesError: false,
		};
	}

	if (action.type === addCategoryType) {
		return {
			...state,
			addingCategory: true,
		};
	}
	if (action.type === addCategoryCompleteType) {
		return {
			...state,
			addingCategory: false,
		};
	}
	if (action.type === editCategoryType) {
		return {
			...state,
			editingCategory: true,
		};
	}

	if (action.type === editCategoryCompleteType) {
		return {
			...state,
			editingCategory: false,
		};
	}
	if (action.type === deleteCategoryType) {
		return {
			...state,
			deletingCategory: true,
		};
	}
	if (action.type === deleteCategoryCompleteType) {
		return {
			...state,
			deletingCategory: false,
		};
	}

	if (action.type === requestTopicListType) {
		return {
			...state,
			topicsListLoading: true,
		};
	}
	if (action.type === receiveTopicListType) {
		return {
			...state,
			topicsListLoading: false,
			topicsList: action.topicsList,
		};
	}
	if (action.type === receiveTopicListErrorType) {
		return {
			...state,
			topicsListLoading: false,
		};
	}

	if (action.type === modifyCategory400ErrorType) {
		return {
			...state,
			validationError: action.validationError,
			editingCategory: false,
			addingCategory: false,
		};
	}
	if (action.type === modifyCategory400ErrorReset) {
		return {
			...state,
			validationError: null,
		};
	}

	if (action.type === requestEntityTypesListType) {
		return {
			...state,
			entityTypesLoading: true,
		};
	}
	if (action.type === receiveEntityTypesListType) {
		return {
			...state,
			entityTypesLoading: false,
			entityTypesList: action.entityTypesList,
		};
	}
	if (action.type === receiveTopicListErrorType) {
		return {
			...state,
			entityTypesLoading: false,
		};
	}

	return state;
};
