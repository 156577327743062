import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
	progress: {
		padding: 0,
		margin: 0,
		width: '100%',
		height: 3,
	},
	loadingContainer: {
		height: 3,
	},
};

class LinearLoading extends React.Component {
	render() {
		const { classes, active } = this.props;
		return (
			<div className={classes.loadingContainer}>
				{' '}
				<Fade
					in={active}
					style={{ transitionDelay: active ? '200ms' : '0ms' }}
					unmountOnExit
				>
					<LinearProgress className={classes.progress} color='primary' />
				</Fade>
			</div>
		);
	}
}

export default withStyles(styles)(LinearLoading);
