import { getLoggedInuser } from '../authUtils';
import { fetchSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const signedInRegisteredType = 'LOGGED_IN';
const signedOutRegisteredType = 'SIGNED_OUT';
const authorizedDetectedType = 'AUTHORIZED';
const unauthorizedDetectedType = 'UNAUTHORIZED';
const organisationsReceivedType = 'ORGS_RECEIVED';
const organisationsReceivedErrorType = 'ORGS_RECEIVED_ERROR';
const requestSchemeType = 'REQUEST_ORG_SCHEME';
const receiveSchemeType = 'RECEIVE_ORG_SCHEME';
const receiveSchemeErrorType = 'RECEIVE_ORG_SCHEME_ERROR';
const SET_CURRENT_ORG_COLOR_TYPE = 'SET_CURRENT_ORG_COLOR';
const SET_SELECTED_ORG_TYPE = 'SET_SELECTED_ORG';
const SET_ORG_ERROR_TYPE = 'SET_ORG_ERROR';
const setAuthTokenExpiredType = 'SET_AUTH_TOKEN_EXPIRED';

const initialState = {
	signedIn: false,
	username: '',
	roles: [],
	organisationId: null,
	organisationAllowedFeatures: [],
	accessDenied: false,
	redirectUrl: '/',
	userOrganisations: [],
	userOrgsError: false,
	organisationColor: '#26324e',
	organisationLogo: null,
	organisationName: null,
	schemeLoading: false,
	selectedOrganisationId: localStorage.getItem('orgId'),
	orgError: false,
	authTokenExpired: false,
};

export const authActionCreators = {
	setCurrentOrgColor: (color) => async (dispatch, getState) => {
		dispatch({
			type: SET_CURRENT_ORG_COLOR_TYPE,
			organisationColor: color,
		});
	},

	setSelectedOrgId: (selectedOrgId) => async (dispatch, getState) => {
		dispatch({
			type: SET_SELECTED_ORG_TYPE,
			selectedOrganisationId: selectedOrgId,
		});
	},

	setOrgError: (bool) => async (dispatch, getState) => {
		dispatch({
			type: SET_ORG_ERROR_TYPE,
			orgError: bool,
		});
	},

	getUserOrganisations: (_) => async (dispatch, _) => {
		var loggedInUser = await getLoggedInuser();

		if (!loggedInUser) {
			return;
		}

		const orgRes = await fetchSecured(
			`/api/multitenancy/users/${loggedInUser.username}/organisations`,
			'user-signin-organisations'
		);
		if (orgRes.ok) {
			const organisations = await orgRes.json();
			dispatch({
				type: organisationsReceivedType,
				userOrganisations: organisations,
			});
		} else {
			dispatch({ type: organisationsReceivedErrorType });
		}
	},

	getOrgScheme: (organisationId) => async (dispatch, getState) => {
		var loggedInUser = await getLoggedInuser();

		if (!loggedInUser) {
			return;
		}

		if (!organisationId) {
			return;
		}

		dispatch({ type: requestSchemeType });

		const url = new URL(
			`/api/multitenancy/organisations/${organisationId}/scheme`,
			stringLookup.baseUrl
		);

		try {
			const schemeResponse = await fetchSecured(url, 'getOrgScheme');

			if (schemeResponse.ok) {
				const schemeRes = await schemeResponse.json();
				dispatch({
					type: receiveSchemeType,
					organisationColor: schemeRes.colour,
					organisationLogo: schemeRes.logo,
					organisationName: schemeRes.organisation,
				});
			} else {
				dispatch({ type: receiveSchemeErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveSchemeErrorType });
		}
	},

	invokeSignedIn: (organisation) => async (dispatch, _) => {
		var loggedInUser = await getLoggedInuser();

		if (!loggedInUser) {
			return;
		}

		if (!organisation) {
			return;
		}

		var organisationFeatures = [];
		const fetRes = await fetchSecured(
			`/api/multitenancy/organisations/${organisation}/features`,
			'organisation-signin-features'
		);
		if (fetRes.ok) {
			organisationFeatures = await fetRes.json();
		}

		localStorage.setItem('orgId', organisation);

		dispatch({
			type: signedInRegisteredType,
			username: loggedInUser.username,
			roles: loggedInUser.roles,
			organisationId: organisation,
			organisationAllowedFeatures: organisationFeatures,
		});
	},

	invokeSignedOut: (_) => (dispatch, _) => {
		dispatch({ type: signedOutRegisteredType });
		localStorage.removeItem('orgId');
	},

	authorize: (feature, role, redirectUrl) => (dispatch, getState) => {
		const roles = getState().auth.roles;
		const signedIn = getState().auth.signedIn;
		const organisationAllowedFeatures = getState().auth.organisationAllowedFeatures;
		const accessDenied = getState().auth.accessDenied;

		const roleAuhorized = role == null ? true : roles.includes(role);
		const featureAuthorized = organisationAllowedFeatures.some((x) => x === feature);

		const authorized = roleAuhorized && featureAuthorized;

		if (signedIn && authorized) {
			dispatch({ type: authorizedDetectedType });
		} else if (!authorized) {
			if (!accessDenied) {
				//already anauthorized
				dispatch({
					type: unauthorizedDetectedType,
					redirectUrl: redirectUrl,
				});
			}
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === signedInRegisteredType) {
		return {
			...state,
			signedIn: true,
			authTokenExpired: false,
			username: action.username,
			roles: action.roles,
			organisationId: action.organisationId,
			organisationAllowedFeatures: action.organisationAllowedFeatures,
		};
	}

	if (action.type === signedOutRegisteredType) {
		return {
			...state,
			signedIn: false,
			username: '',
			roles: [],
			organisationId: null,
			organisationAllowedFeatures: [],
			organisationColor: '#26324e',
			organisationLogo: null,
			organisationName: null,
			schemeLoading: false,
		};
	}

	if (action.type === authorizedDetectedType) {
		return {
			...state,
			accessDenied: false,
			redirectUrl: '/',
		};
	}

	if (action.type === unauthorizedDetectedType) {
		return {
			...state,
			accessDenied: true,
			redirectUrl: action.redirectUrl,
		};
	}

	if (action.type === organisationsReceivedType) {
		return {
			...state,
			userOrganisations: action.userOrganisations,
		};
	}

	if (action.type === organisationsReceivedErrorType) {
		return {
			...state,
			userOrgsError: true,
		};
	}

	if (action.type === requestSchemeType) {
		return {
			...state,
			schemeLoading: true,
		};
	}

	if (action.type === receiveSchemeType) {
		return {
			...state,
			organisationColor: action.organisationColor,
			organisationLogo: action.organisationLogo,
			organisationName: action.organisationName,
			schemeLoading: false,
		};
	}

	if (action.type === receiveSchemeErrorType) {
		return {
			...state,
			schemeLoading: false,
		};
	}

	if (action.type === SET_CURRENT_ORG_COLOR_TYPE) {
		return {
			...state,
			organisationColor: action.organisationColor,
		};
	}

	if (action.type === SET_SELECTED_ORG_TYPE) {
		return {
			...state,
			selectedOrganisationId: action.selectedOrganisationId,
		};
	}

	if (action.type === SET_ORG_ERROR_TYPE) {
		return {
			...state,
			orgError: action.orgError,
		};
	}

	if (action.type === setAuthTokenExpiredType) {
		return {
			...state,
			authTokenExpired: action.authTokenExpired,
		};
	}

	return state;
};
