const stringLookup = {
	reportingRole: 'duma.reports',
	mlAdminRole: 'ml.admin',
	adminRole: 'duma.admin',
	advancedAdminRole: 'duma.advanced_admin',
	system_admin: 'duma.system_admin',
	organisation_admin: 'duma.organisation_admin',
	kaziMtaaniRole: 'kazimtaani.user',
	kaziMtaaniAnalytics: 'kazimtaani.analytics',
	kaziMtaaniWorkforce: 'kazimtaani.workforce',
	analyticsWorkbench: 'duma.analytics_workbench',
	userInvitationRole: 'duma.user_invitation',
	filesPermission: 'duma.files',
	notificationsPermission: 'duma.notifications',
	notificationsAppEventsRole: 'duma.notifications_app_events',
	notificationsBulletinsRole: 'duma.notifications_bulletins',
	agreementsPermission: 'duma.agreements',
	subtopicApprovalPermission: 'duma.topic_approval',
	ignoredDomainsPermission: 'duma.ignored_domains',
	dynamicCrawlPermission: 'duma.dynamic_crawl',
	recompilePermission: 'duma.recompile',
	rankingPermission: 'duma.ranking',
	websitesPermission: 'duma.websites',
	incidentTypesCreationPermission: 'duma.incident_types_addition',
	incidentEditingPermission: 'duma.incident_editing',
	crawlerManagementPermission: 'duma.crawler_management',
	languageCenterSentencesPermission: 'duma.language_center_sentences',
	newsFeature: 'News',
	analyticsFeature: 'Analytics',
	incidentsFeature: 'Incidents',
	messagingFeature: 'Messaging',
	crawlerSettingsFeature: 'Crawler Settings',
	clientManagementFeature: 'Client Management',
	advancedSettingsFeature: 'Advanced Settings',
	kaziMtaaniFeature: 'Kazi Mtaani',
	categoriesFeature: 'Category Settings',
	FilesFeature: 'Files',
	MLFeature: 'ML',
	HateSpeechFeature: 'Hate Speech',
	datasetsManagementPermission: 'duma.datasets_management',
	chatbotPermission: 'duma.chatbot',
	directMessagesPermission: 'duma.direct_messages',
	geotaggedImagesPermission: 'duma.geotagged_images',
	mediaTypes: ['all', 'newsOutlets', 'socialMedia', 'video', 'files'],
	incidentTypes: [
		{
			value: 'Attack',
			label: 'Terrorist Attack',
			pluralLabel: 'Terrorist Attacks',
		},
		{
			value: 'FoiledAttack',
			label: 'Foiled Attack',
			pluralLabel: 'Foiled Attacks',
		},
		{ value: 'Battle', label: 'Battle', pluralLabel: 'Battles' },
		{
			value: 'Ethnic_TribalViolence',
			label: 'Ethnic/Tribal Violence',
			pluralLabel: 'Ethnic/Tribal Violences ',
		},
		{
			value: 'LocationCapture',
			label: 'Location Capture',
			pluralLabel: 'Location Captures',
		},
		{
			value: 'TroopCapture_Surrender',
			label: 'Troop Capture/Surrender',
			pluralLabel: 'Troop Captures/Surrenders',
		},
		{
			value: 'TroopMovement',
			label: 'Troop Movement',
			pluralLabel: 'Troop Movements',
		},
		{ value: 'Armament', label: 'Armament', pluralLabel: 'Armaments' },
		{ value: 'PeaceEvent', label: 'Peace Event', pluralLabel: 'Peace Events' },
		{
			value: 'Protest_Riot',
			label: 'Protest/Riot',
			pluralLabel: 'Protests/Riots',
		},
		{
			value: 'VAC',
			label: 'Violence Against Civilians',
			pluralLabel: 'Violences Against Civilians',
		},
		{
			value: 'NaturalDisaster',
			label: 'Natural Disaster',
			pluralLabel: 'Natural Disasters',
		},
		{ value: 'Pandemic', label: 'Pandemic', pluralLabel: 'Pandemics' },
		{ value: 'Accident', label: 'Accident', pluralLabel: 'Accidents' },
		{ value: 'HateSpeech', label: 'Hate Speech', pluralLabel: 'Hate Speech' },
		{ value: 'Crime', label: 'Crime', pluralLabel: 'Crimes' },
		{
			value: 'MassShooting',
			label: 'Mass Killing',
			pluralLabel: 'Mass Killings',
		},
		{
			value: 'Assassination',
			label: 'Assassination',
			pluralLabel: 'Assassinations',
		},
		{
			value: 'Announcement',
			label: 'Announcement/Statement',
			pluralLabel: 'Announcements/Statements',
		},
		{ value: 'Suicide', label: 'Suicide', pluralLabel: 'Suicides' },
		{
			value: 'AggregateReport',
			label: 'Aggregate Report',
			pluralLabel: 'Aggregate Reports',
		},
		{
			value: 'JudicialAction',
			label: 'Judicial Action',
			pluralLabel: 'Judicial Actions',
		},
		{
			value: 'MilitaryCollaboration_Cooperation',
			label: 'Military Collaboration/Cooperation',
			pluralLabel: 'Military Collaborations/Cooperations',
		},
		{
			value: 'Conversation_MeetingOfInterest',
			label: 'Conversation/Meeting of Interest',
			pluralLabel: 'Conversations/Meetings of Interest',
		},
		{ value: 'Execution', label: 'Execution', pluralLabel: 'Executions' },
		{
			value: 'HumanitarianDisplacement',
			label: 'Humanitarian Displacement',
			pluralLabel: 'Humanitarian Displacements',
		},
	],

	baseUrl: window.location.href.split('/').slice(0, 3).join('/'),
	domainName: window.location.hostname,
	OPENAI_API_KEY: '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',
	kaziMtaaniUrl: 'https://kazimtaani.siasareport.com',
	// kaziMtaaniUrl: 'http://kazimtaanidev.siasareport.com',
	// machineLearningApi: 'https://machinelearningapidev.siasareport.com',
	machineLearningApi: 'https://machinelearningapi.siasareport.com',

	// smsApi: 'http://messagingdev.siasareport.com',
	choroplethPath:
		'https://prd-siasareport-public.s3-eu-west-1.amazonaws.com/maps/countries.json',
	kenyaCountiesDataPath:
		'https://prd-siasareport-public.s3-eu-west-1.amazonaws.com/maps/kenyan-counties.json',

	//kibana dashboards
	generalDashboardIframeOriginalContainer:
		'https://v2dashboards.siasareport.com/app/kibana#/dashboard/db087050-b9c5-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',
	generalDashboardIframeOriginalContainerDev:
		'http://v2dashboardsdev.siasareport.com/app/kibana#/dashboard/db087050-b9c5-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',

	generalDashboardMobileIframeOriginalContainer:
		'https://v2dashboards.siasareport.com/app/kibana#/dashboard/9cc69ef0-ba85-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',
	generalDashboardMobileIframeOriginalContainerDev:
		'http://v2dashboardsdev.siasareport.com/app/kibana#/dashboard/9cc69ef0-ba85-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',

	heatmapDashboardIframeOriginalContainer:
		'https://v2dashboards.siasareport.com/app/kibana#/dashboard/1d8f3180-ba7e-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',
	heatmapDashboardIframeOriginalContainerDev:
		'http://v2dashboardsdev.siasareport.com/app/kibana#/dashboard/1d8f3180-ba7e-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',

	heatmapDashboardMobileIframeOriginalContainer:
		'https://v2dashboards.siasareport.com/app/kibana#/dashboard/3c9cbe00-ba86-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',
	heatmapDashboardMobileIframeOriginalContainerDev:
		'http://v2dashboardsdev.siasareport.com/app/kibana#/dashboard/3c9cbe00-ba86-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',

	trendsDashboardIframeOriginalContainer:
		'https://v2dashboards.siasareport.com/app/kibana#/dashboard/a9f27550-ba75-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',
	trendsDashboardIframeOriginalContainerDev:
		'http://v2dashboardsdev.siasareport.com/app/kibana#/dashboard/a9f27550-ba75-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',

	trendsDashboardMobileIframeOriginalContainer:
		'https://v2dashboards.siasareport.com/app/kibana#/dashboard/4daa2ba0-ba87-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',
	trendsDashboardMobileIframeOriginalContainerDev:
		'http://v2dashboardsdev.siasareport.com/app/kibana#/dashboard/4daa2ba0-ba87-11e9-a94a-e791e9292ea8?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-30d%2Cmode%3Aquick%2Cto%3Anow))',
};

export default stringLookup;
