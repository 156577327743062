import { postSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const postEmbeddingWebsiteType = 'POST_EMBEDDING_WEBSITE_TYPE';
const postEmbeddingWebsiteSuccessType = 'POST_EMBEDDING_WEBSITE_SUCCESS_TYPE';
const postEmbeddingWebsiteErrorType = 'POST_EMBEDDING_WEBSITE_ERROR_TYPE';

const initialState = {
	isPostingEmbeddingWebsite: false,
	postingEmbeddingWebsiteError: false,
};

export const embeddingWebsitesActionCreators = {
	postEmbeddingWebsiteAsync: () => async (dispatch, getState) => {
		if (getState().embeddingWebsites.isPostingEmbeddingWebsite) {
			return;
		}

		dispatch({ type: postEmbeddingWebsiteType });

		try {
			const url = new URL('/api/v1/embeddingwebsites', stringLookup.baseUrl);

			const res = await postSecured(url);

			if (res.ok) {
				dispatch({ type: postEmbeddingWebsiteSuccessType });
			} else {
				dispatch({ type: postEmbeddingWebsiteErrorType });
			}
		} catch (error) {
			dispatch({ type: postEmbeddingWebsiteErrorType });
		}
	},
};

const embeddingWebsitesReducer = (state = initialState, action) => {
	if (action.type === postEmbeddingWebsiteType) {
		return {
			...state,
			isPostingEmbeddingWebsite: true,
			postingEmbeddingWebsiteError: false,
		};
	}

	if (action.type === postEmbeddingWebsiteSuccessType) {
		return {
			...state,
			isPostingEmbeddingWebsite: false,
			postingEmbeddingWebsiteError: false,
		};
	}

	if (action.type === postEmbeddingWebsiteErrorType) {
		return {
			...state,
			isPostingEmbeddingWebsite: false,
			postingEmbeddingWebsiteError: true,
		};
	}

	return state;
};

export default embeddingWebsitesReducer;
