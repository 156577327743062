import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import SendIcon from '@material-ui/icons/Send';
import AddGroupIcon from '@material-ui/icons/GroupAdd';
import classNames from 'classnames';
import React from 'react';
import { Redirect } from 'react-router-dom';
import TablePaginationActions from '../../../util/PaginationActions';
import AddOrEditGroupDialog from './_AddOrEditGroupDialog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../store/Messaging.Campaigns';
import LinearLoading from './../../../util/LinearLoading';
import { GetGroupContact } from '../MessagingApiWrapper';
import PaginationActions from '../../../util/PaginationActions';

const styles = (theme) => ({
	newGroupButton: {
		margin: 10,
		right: 0,
		position: 'absolute',
	},
	cellBlockContent: {
		display: 'table-cell',
		paddingRight: theme.spacing.unit,
	},
	button: {
		fontSize: 8,
	},
	nameDiv: {
		height: '100%',
	},
	actionButtonCells: {
		width: theme.spacing.unit * 5,
		paddingRight: 0,
	},
	actionButtonIcons: {
		fontSize: 16,
	},
	table: {
		maxWidth: '100%',
		height: '71vh',
	},

	tableRow: {
		height: 50,
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: '#EEEEEE',
			'& $tableCell': {},
		},
	},
	tableCell: {
		maxWidth: theme.spacing.unit * 20,
		wordWrap: 'break-word',
		boxSizing: 'none',
	},
	lightTooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		border: '1px solid #e0e0e0',
		fontSize: 11,
	},
});

class Groups extends React.Component {
	state = {
		page: 1,
		addGroupOpen: false,
		editGroupOpen: false,
		groupEditId: null,
		groupEditName: null,
		redirectToGroupContacts: false,
		redirectToGroupContactGroupId: null,
		//redirectToGroupMessages: false,
		redirectToGroupMessageGroupId: null,
		sendMessageOpen: false,
		sendSmsGroupId: null,
		groupCampaignId: null,
		contacts: [],
	};

	// handlePageChange = async (event, logicalPage) => {
	// 	const page = logicalPage + 1;
	// 	// await this.props.getFilteredGroups(page);
	// 	this.setState({ page });
	// };

	handleAddGroupClick = () => {
		this.setState({ addGroupOpen: true });
	};

	handleAddGroupClose = () => {
		this.setState({ addGroupOpen: false });
	};

	handleEditGroupInvoked = (id, name, campaignId) => {
		this.setState({
			groupEditId: id,
			groupEditName: name,
			editGroupOpen: true,
			groupCampaignId: campaignId,
		});
	};

	handleEditGroupClose = () => {
		this.setState({ editGroupOpen: false });
	};

	handleAddGroup = (name, campaignId, selectedContacts, closeDialog) => {
		this.props.addGroup(name, campaignId, selectedContacts);

		if (closeDialog) {
			this.handleAddGroupClose();
		}
	};

	handleEditGroup = (id, name, campaignId) => {
		this.props.editGroup(id, name, campaignId);
		this.handleEditGroupClose();
	};

	handeDeleteGroup = (id) => {
		this.props.deleteGroup(id);
		this.handleEditGroupClose();
	};

	causesDuplicate = (id, name) => {
		return this.props.groups
			.filter((x) => (id == null ? true : x.id !== id))
			.some((x) => x.name?.toLowerCase().trim() === name?.toLowerCase().trim());
	};

	handleRedirectToGroupContacts = (groupId) => {
		this.setState({
			redirectToGroupContacts: true,
			redirectToGroupContactGroupId: groupId,
		});
	};

	//handleRedirectToGroupMessages = (groupId) =>{
	//    this.setState({redirectToGroupMessages: true, redirectToGroupMessageGroupId: groupId });

	//}

	handleSendSmsRequest = (id) => {
		this.setState({
			sendMessageOpen: true,
			sendSmsGroupId: id,
		});
	};

	handleSendSmsClose = () => {
		this.setState({
			sendMessageOpen: false,
			sendSmsGroupId: null,
		});
	};

	getContacts = async (searchString) => {
		let res = null;
		res = await GetGroupContact(1, 1, 500, searchString);
		this.setState({ contacts: res == null ? [] : res.items });
	};

	componentDidMount = async () => {
		await this.getContacts();
	};

	render() {
		const {
			groups,
			groupsCount,
			classes,
			theme,
			campaigns,
			loading,
			contactsUrl,
			groupsPage,
			handleGroupsPageChange,
		} = this.props;

		const {
			// page,
			addGroupOpen,
			editGroupOpen,
			groupEditId,
			groupEditName,
			redirectToGroupContacts,
			contacts,
			redirectToGroupContactGroupId,
			redirectToGroupMessages,
			redirectToGroupMessageGroupId,
			groupCampaignId,
			sendSmsGroupId,
		} = this.state;
		const {
			// handlePageChange,
			handleAddGroupClose,
			handleAddGroup,
			causesDuplicate,
			handleAddGroupClick,
			handleEditGroupInvoked,
			handleEditGroupClose,
			handleEditGroup,
			handeDeleteGroup,
			handleRedirectToGroupContacts,
			handleRedirectToGroupMessages,
			handleSendSmsRequest,
			handleSendSmsClose,
			getContacts,
		} = this;

		const rowsPerPage = 10;
		const zeroBasedPage = groupsPage === 0 ? 0 : groupsPage - 1;
		const addOrEditModal = addGroupOpen ? (
			<AddOrEditGroupDialog
				mode={'ADD'}
				open={true}
				title={'Add Group'}
				handleClose={handleAddGroupClose}
				handleAdd={handleAddGroup}
				causesDuplicate={causesDuplicate}
				campaigns={campaigns}
				addGroupOpen={addGroupOpen}
				contacts={contacts}
				getContacts={getContacts}
			/>
		) : editGroupOpen ? (
			<AddOrEditGroupDialog
				mode={'EDIT'}
				open={true}
				title={'Edit Group'}
				handleClose={handleEditGroupClose}
				handleEdit={handleEditGroup}
				handleDelete={handeDeleteGroup}
				causesDuplicate={causesDuplicate}
				name={groupEditName}
				id={groupEditId}
				campaigns={campaigns}
				editGroupOpen={editGroupOpen}
				groupCampaignId={groupCampaignId}
				contacts={contacts}
			/>
		) : (
			<React.Fragment />
		);

		if (redirectToGroupContacts) {
			return (
				<Redirect
					to={{
						pathname: contactsUrl,
						state: { groupId: redirectToGroupContactGroupId },
					}}
				/>
			);
		}

		//if(redirectToGroupMessages){
		//    return   <Redirect to={{
		//        pathname: '/messaging/sms/messages',
		//        state: { groupId: redirectToGroupMessageGroupId }
		//    }}/>
		//}
		return (
			<Paper variant='outlined' style={{ position: 'relative', height: '77vh' }}>
				<LinearLoading active={loading} />

				{addOrEditModal}
				<Button
					variant='contained'
					color='primary'
					className={classes.newGroupButton}
					onClick={handleAddGroupClick}
				>
					<AddGroupIcon style={{ marginRight: 8, fontSize: 18 }} />
					Add group
				</Button>

				{/*                         
                <Button variant="contained" color="secondary" className={classes.button}>
                    Delete
                    <DeleteIcon className={classes.rightIcon} />
                </Button>
                <Button variant="contained" color="primary" className={classes.button}>
                    Send
                    <Icon className={classes.rightIcon}>send</Icon>
                </Button>
       */}
				<div className={classes.table}>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell style={{ height: 60 }}>Name</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{groups.map((grp) => {
								return (
									<TableRow
										key={grp.id}
										className={classes.tableRow}
										onClick={() =>
											handleEditGroupInvoked(
												grp.id,
												grp.name,
												grp.campaignId
											)
										}
									>
										{/* <TableCell scope="row" className={classNames(classes.tableCell, classes.actionButtonCells)}>
                                                    <div>
                                                        <div className={classNames(classes.cellBlockContent)}> 
                                                                <Tooltip   title="Messages sent" placement="top">
                                                                    <IconButton  style={{width:theme.spacing.unit * 3, height:theme.spacing.unit * 3}} onClick = {() => {handleRedirectToGroupMessages(grp.id)}} color="primary" className={classes.button} aria-label="List group messages">
                                                                        <Icon className={classes.actionButtonIcons}>forum</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                        </div>

                                                        <div className={classNames(classes.cellBlockContent)}> 
                                                                <Tooltip   title="Send message" placement="top">
                                                                    <IconButton style={{width:theme.spacing.unit * 3, height:theme.spacing.unit * 3}} onClick={() => handleSendSmsRequest (grp.id)} color="primary" className={classes.button} aria-label="Send sms">
                                                                        <SendIcon className={classes.actionButtonIcons} fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                    </div>
                                                    </div>
                                                </TableCell> */}
										<TableCell
											scope='row'
											className={classes.tableCell}
										>
											{grp.name}
										</TableCell>
										<TableCell
											scope='row'
											className={classNames(
												classes.tableCell,
												classes.actionButtonCells
											)}
										>
											<div
												className={classNames(
													classes.cellBlockContent
												)}
											>
												<Tooltip
													title='Contact list'
													classes={{
														tooltip: classes.lightTooltip,
													}}
													placement='left'
												>
													<IconButton
														onClick={() => {
															handleRedirectToGroupContacts(
																grp.id
															);
														}}
														color='primary'
														className={classes.button}
														aria-label='List contacts'
													>
														<Icon
															className={
																classes.actionButtonIcons
															}
														>
															contacts
														</Icon>
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
				<Table>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={3}
								count={groupsCount}
								rowsPerPage={rowsPerPage}
								page={zeroBasedPage}
								onPageChange={handleGroupsPageChange}
								labelDisplayedRows={({ from, to, count }) =>
									groupsCount == null ? '' : `${from}-${to} of ${count}`
								}
								ActionsComponent={(props) => (
									<PaginationActions {...props} disabled={loading} />
								)}
								rowsPerPageOptions={[]}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Paper>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	connect(
		(state) => state.messagingCampaigns,
		(dispatch) => bindActionCreators(actionCreators, dispatch)
	)(Groups)
);
