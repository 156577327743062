import stringLookup from './../stringslookup';
import { fetchSecured } from '../fetchUtils';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';

const REQUEST_SUBTOPIC_TIMELINE_REPORT_TYPE =
	'REQUEST_SUBTOPIC_TIMELINE_REPORT';
const RECEIVE_SUBTOPIC_TIMELINE_REPORT_TYPE =
	'RECEIVE_SUBTOPIC_TIMELINE_REPORT';
const RECEIVE_SUBTOPIC_TIMELINE_REPORT_ERROR_TYPE =
	'RECEIVE_SUBTOPIC_TIMELINE_REPORT_ERROR';

const REQUEST_SUBTOPIC_TIMELINE_COUNT_TYPE = 'REQUEST_SUBTOPIC_TIMELINE_COUNT';
const RECEIVE_SUBTOPIC_TIMELINE_COUNT_TYPE = 'RECEIVE_SUBTOPIC_TIMELINE_COUNT';
const RECEIVE_SUBTOPIC_TIMELINE_COUNT_ERROR_TYPE =
	'RECEIVE_SUBTOPIC_TIMELINE_COUNT_ERROR';

const REQUEST_SUBTOPIC_SUMMARY_REPORT_TYPE = 'REQUEST_SUBTOPIC_SUMMARY_REPORT';
const RECEIVE_SUBTOPIC_SUMMARY_REPORT_TYPE = 'RECEIVE_SUBTOPIC_SUMMARY_REPORT';
const RECEIVE_SUBTOPIC_SUMMARY_REPORT_ERROR_TYPE =
	'RECEIVE_SUBTOPIC_SUMMARY_REPORT_ERROR';

const REQUEST_TOPIC_TIMELINE_REPORT_TYPE = 'REQUEST_TOPIC_TIMELINE_REPORT';
const RECEIVE_TOPIC_TIMELINE_REPORT_TYPE = 'RECEIVE_TOPIC_TIMELINE_REPORT';
const RECEIVE_TOPIC_TIMELINE_REPORT_ERROR_TYPE =
	'RECEIVE_TOPIC_TIMELINE_REPORT_ERROR';

const REQUEST_TOPIC_TIMELINE_COUNT_TYPE = 'REQUEST_TOPIC_TIMELINE_COUNT';
const RECEIVE_TOPIC_TIMELINE_COUNT_TYPE = 'RECEIVE_TOPIC_TIMELINE_COUNT';
const RECEIVE_TOPIC_TIMELINE_COUNT_ERROR_TYPE =
	'RECEIVE_TOPIC_TIMELINE_COUNT_ERROR';

const REQUEST_TOPIC_SUMMARY_REPORT_TYPE = 'REQUEST_TOPIC_SUMMARY_REPORT';
const RECEIVE_TOPIC_SUMMARY_REPORT_TYPE = 'RECEIVE_TOPIC_SUMMARY_REPORT';
const RECEIVE_TOPIC_SUMMARY_REPORT_ERROR_TYPE =
	'RECEIVE_TOPIC_SUMMARY_REPORT_ERROR';

const REQUEST_CATEGORY_TIMELINE_REPORT_TYPE =
	'REQUEST_CATEGORY_TIMELINE_REPORT';
const RECEIVE_CATEGORY_TIMELINE_REPORT_TYPE =
	'RECEIVE_CATEGORY_TIMELINE_REPORT';
const RECEIVE_CATEGORY_TIMELINE_REPORT_ERROR_TYPE =
	'RECEIVE_CATEGORY_TIMELINE_REPORT_ERROR';

const REQUEST_CATEGORY_TIMELINE_COUNT_TYPE = 'REQUEST_CATEGORY_TIMELINE_COUNT';
const RECEIVE_CATEGORY_TIMELINE_COUNT_TYPE = 'RECEIVE_CATEGORY_TIMELINE_COUNT';
const RECEIVE_CATEGORY_TIMELINE_COUNT_ERROR_TYPE =
	'RECEIVE_CATEGORY_TIMELINE_COUNT_ERROR';

const REQUEST_CATEGORY_SUMMARY_REPORT_TYPE = 'REQUEST_CATEGORY_SUMMARY_REPORT';
const RECEIVE_CATEGORY_SUMMARY_REPORT_TYPE = 'RECEIVE_CATEGORY_SUMMARY_REPORT';
const RECEIVE_CATEGORY_SUMMARY_REPORT_ERROR_TYPE =
	'RECEIVE_CATEGORY_SUMMARY_REPORT_ERROR';

const initialState = {
	reportFetching: false,
	documentCountFetching: false,
	report: {},
	documentCount: {},
	topicTimelineCount: {},
	summaryFetching: false,
	summary: null,
	topicTimelineReport: {},
	topicSummary: null,
	categoryTimelineReport: {},
	categorySummary: null,
	categoryTimelineCount: {},
};

export const subtopicTimelineActionCreators = {
	loadSubtopicTimelines:
		(
			scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_SUBTOPIC_TIMELINE_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/subtopicstimeline', stringLookup.baseUrl);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subTopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			url.searchParams.append('scoreType', scoreType);
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('subtopicTimelineItemsLimit', 10);

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const reportResponse = await fetchSecured(url, 'subtopic_timelines');
				if (reportResponse.ok) {
					const data = await reportResponse.json();

					var report = {};
					data.forEach((x) => {
						report[x.subtopicName] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_SUBTOPIC_TIMELINE_REPORT_TYPE,
						subtopicTimelineReport: report,
					});
				} else {
					dispatch({
						type: RECEIVE_SUBTOPIC_TIMELINE_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SUBTOPIC_TIMELINE_REPORT_ERROR_TYPE });
			}
			unsetGlobalLoad(dispatch);
		},

	loadSubtopicTimelinesCount:
		(
			scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_SUBTOPIC_TIMELINE_COUNT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/subtopicstimeline/count',
				stringLookup.baseUrl
			);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subTopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			if (scoreType !== 'DocumentCount') {
				url.searchParams.append('scoreType', scoreType);
			}
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('subtopicTimelineItemsLimit', 10);

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const documentCountResponse = await fetchSecured(
					url,
					'subtopic_timelines'
				);
				if (documentCountResponse.ok) {
					const data = await documentCountResponse.json();

					var documentCount = {};

					data.forEach((x) => {
						documentCount[x.subtopicName] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_SUBTOPIC_TIMELINE_COUNT_TYPE,
						subtopicTimelineCount: documentCount,
					});
				} else {
					dispatch({
						type: RECEIVE_SUBTOPIC_TIMELINE_COUNT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SUBTOPIC_TIMELINE_COUNT_ERROR_TYPE });
			}
			unsetGlobalLoad(dispatch);
		},

	loadSubtopicSummary:
		(
			scoreType,
			searchString,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			dateFilter,
			page,
			pageSize,
			orderBy,
			sortDirection,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter
		) =>
		async (dispatch, getState) => {
			//if (getState().subtopicTimeline.summaryFetching) {
			//    return;
			//}

			dispatch({ type: REQUEST_SUBTOPIC_SUMMARY_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/subtopicstimeline/summary',
				stringLookup.baseUrl
			);

			if (scoreType !== 'DocumentCount') {
				url.searchParams.append('scoreType', scoreType);
			}

			if (categoryFilters?.length > 0) {
				categoryFilters.forEach((c) => {
					url.searchParams.append('categories', c);
				});
			}

			if (topicsFilters?.length > 0) {
				topicsFilters.forEach((t) => {
					url.searchParams.append('topics', t);
				});
			}

			if (subtopicFilters?.length > 0) {
				subtopicFilters.forEach((s) => {
					url.searchParams.append('subtopics', s);
				});
			}

			if (keywordFilters?.length > 0) {
				keywordFilters.forEach((k) => {
					url.searchParams.append('keywords', k);
				});
			}

			url.searchParams.append('startDate', startDate?.toISOString());
			url.searchParams.append('endDate', endDate?.toISOString());
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('mediaType', mediaTypeFilter);

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			try {
				const reportResponse = await fetchSecured(url, 'subtopic_summary');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_SUBTOPIC_SUMMARY_REPORT_TYPE,
						summary: data,
					});
				} else {
					dispatch({
						type: RECEIVE_SUBTOPIC_SUMMARY_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SUBTOPIC_SUMMARY_REPORT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadTopicTimelines:
		(
			scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_TOPIC_TIMELINE_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/topicstimeline', stringLookup.baseUrl);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subTopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			url.searchParams.append('scoreType', scoreType);
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('TopicTimelineItemsLimit', 10);

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const reportResponse = await fetchSecured(url, 'topic_timelines');
				if (reportResponse.ok) {
					const data = await reportResponse.json();

					var report = {};
					data.forEach((x) => {
						report[x.topicName] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_TOPIC_TIMELINE_REPORT_TYPE,
						topicTimelineReport: report,
					});
				} else {
					dispatch({
						type: RECEIVE_TOPIC_TIMELINE_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_TOPIC_TIMELINE_REPORT_ERROR_TYPE });
			}
			unsetGlobalLoad(dispatch);
		},

	loadTopicTimelinesCount:
		(
			scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({
				type: REQUEST_TOPIC_TIMELINE_COUNT_TYPE,
			});
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/topicstimeline/count',
				stringLookup.baseUrl
			);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subTopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			if (scoreType !== 'DocumentCount') {
				url.searchParams.append('scoreType', scoreType);
			}
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('TopicTimelineItemsLimit', 10);

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const reportResponse = await fetchSecured(url, 'topic_timelines');
				if (reportResponse.ok) {
					const data = await reportResponse.json();

					var topicCount = {};
					data.forEach((x) => {
						topicCount[x.topicName] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_TOPIC_TIMELINE_COUNT_TYPE,
						topicTimelineCount: topicCount,
					});
				} else {
					dispatch({
						type: RECEIVE_TOPIC_TIMELINE_COUNT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_TOPIC_TIMELINE_COUNT_ERROR_TYPE });
			}
			unsetGlobalLoad(dispatch);
		},

	loadTopicSummary:
		(
			scoreType,
			searchString,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			dateFilter,
			page,
			pageSize,
			orderBy,
			sortDirection,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_TOPIC_SUMMARY_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/topicstimeline/summary',
				stringLookup.baseUrl
			);
			if (scoreType !== 'DocumentCount') {
				url.searchParams.append('scoreType', scoreType);
			}

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subtopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('mediaType', mediaTypeFilter);

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			try {
				const reportResponse = await fetchSecured(url, 'topic_summary');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_TOPIC_SUMMARY_REPORT_TYPE,
						topicSummary: data,
					});
				} else {
					dispatch({ type: RECEIVE_TOPIC_SUMMARY_REPORT_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_TOPIC_SUMMARY_REPORT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadCategoryTimelines:
		(
			scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_CATEGORY_TIMELINE_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/categoriestimeline',
				stringLookup.baseUrl
			);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subTopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			url.searchParams.append('scoreType', scoreType);
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('CategoryTimelineItemsLimit', 10);

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const reportResponse = await fetchSecured(url, 'topic_timelines');
				if (reportResponse.ok) {
					const data = await reportResponse.json();

					var report = {};
					data.forEach((x) => {
						report[x.categoryName] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_CATEGORY_TIMELINE_REPORT_TYPE,
						categoryTimelineReport: report,
					});
				} else {
					dispatch({
						type: RECEIVE_CATEGORY_TIMELINE_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_CATEGORY_TIMELINE_REPORT_ERROR_TYPE });
			}
			unsetGlobalLoad(dispatch);
		},

	loadCategoryTimelinesCount:
		(
			scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_CATEGORY_TIMELINE_COUNT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/categoriestimeline/count',
				stringLookup.baseUrl
			);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subTopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			if (scoreType !== 'DocumentCount') {
				url.searchParams.append('scoreType', scoreType);
			}
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('CategoryTimelineItemsLimit', 10);

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const reportResponse = await fetchSecured(
					url,
					'category_timelines_count'
				);
				if (reportResponse.ok) {
					const data = await reportResponse.json();

					var categoryCount = {};
					data.forEach((x) => {
						categoryCount[x.categoryName] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_CATEGORY_TIMELINE_COUNT_TYPE,
						categoryTimelineCount: categoryCount,
					});
				} else {
					dispatch({
						type: RECEIVE_CATEGORY_TIMELINE_COUNT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_CATEGORY_TIMELINE_COUNT_ERROR_TYPE });
			}
			unsetGlobalLoad(dispatch);
		},

	loadCategorySummary:
		(
			scoreType,
			searchString,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			dateFilter,
			page,
			pageSize,
			orderBy,
			sortDirection,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_CATEGORY_SUMMARY_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/categoriestimeline/summary',
				stringLookup.baseUrl
			);

			if (scoreType !== 'DocumentCount') {
				url.searchParams.append('scoreType', scoreType);
			}

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subtopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('mediaType', mediaTypeFilter);

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}
			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}
			try {
				const reportResponse = await fetchSecured(url, 'topic_summary');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_CATEGORY_SUMMARY_REPORT_TYPE,
						categorySummary: data,
					});
				} else {
					dispatch({
						type: RECEIVE_CATEGORY_SUMMARY_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_CATEGORY_SUMMARY_REPORT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === REQUEST_SUBTOPIC_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: true,
			report: {},
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: false,
			report: action.subtopicTimelineReport,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_TIMELINE_REPORT_ERROR_TYPE) {
		return {
			...state,
			reportFetching: false,
		};
	}

	if (action.type === REQUEST_SUBTOPIC_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: true,
			documentCount: {},
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: false,
			documentCount: action.subtopicTimelineCount,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_TIMELINE_COUNT_ERROR_TYPE) {
		return {
			...state,
			documentCountFetching: false,
		};
	}

	if (action.type === REQUEST_SUBTOPIC_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			summaryFetching: true,
			summary: null,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			summary: action.summary,
			summaryFetching: false,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_SUMMARY_REPORT_ERROR_TYPE) {
		return {
			...state,
			summaryFetching: false,
		};
	}

	if (action.type === REQUEST_CATEGORY_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: true,
			categoryTimelineReport: {},
		};
	}
	if (action.type === RECEIVE_CATEGORY_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: false,
			categoryTimelineReport: action.categoryTimelineReport,
		};
	}
	if (action.type === RECEIVE_CATEGORY_TIMELINE_REPORT_ERROR_TYPE) {
		return {
			...state,
			reportFetching: false,
		};
	}

	if (action.type === REQUEST_CATEGORY_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			summaryFetching: true,
			categorySummary: null,
		};
	}
	if (action.type === RECEIVE_CATEGORY_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			categorySummary: action.categorySummary,
			summaryFetching: false,
		};
	}
	if (action.type === RECEIVE_CATEGORY_SUMMARY_REPORT_ERROR_TYPE) {
		return {
			...state,
			summaryFetching: false,
		};
	}

	if (action.type === REQUEST_CATEGORY_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: true,
			categoryTimelineCount: {},
		};
	}
	if (action.type === RECEIVE_CATEGORY_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: false,
			categoryTimelineCount: action.categoryTimelineCount,
		};
	}
	if (action.type === RECEIVE_CATEGORY_TIMELINE_COUNT_ERROR_TYPE) {
		return {
			...state,
			documentCountFetching: false,
		};
	}

	if (action.type === REQUEST_TOPIC_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: true,
			topicTimelineReport: {},
		};
	}
	if (action.type === RECEIVE_TOPIC_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: false,
			topicTimelineReport: action.topicTimelineReport,
		};
	}
	if (action.type === RECEIVE_TOPIC_TIMELINE_REPORT_ERROR_TYPE) {
		return {
			...state,
			reportFetching: false,
		};
	}

	if (action.type === REQUEST_TOPIC_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: true,
			topicTimelineCount: {},
		};
	}
	if (action.type === RECEIVE_TOPIC_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: false,
			topicTimelineCount: action.topicTimelineCount,
		};
	}
	if (action.type === RECEIVE_TOPIC_TIMELINE_COUNT_ERROR_TYPE) {
		return {
			...state,
			documentCountFetching: false,
		};
	}

	if (action.type === REQUEST_TOPIC_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			summaryFetching: true,
			topicSummary: null,
		};
	}
	if (action.type === RECEIVE_TOPIC_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			topicSummary: action.topicSummary,
			summaryFetching: false,
		};
	}
	if (action.type === RECEIVE_TOPIC_SUMMARY_REPORT_ERROR_TYPE) {
		return {
			...state,
			summaryFetching: false,
		};
	}

	return state;
};
