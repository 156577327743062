import { deleteSecured, fetchDefault } from '../fetchUtils';
import { fetchSecured, patchSecured, postSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

// Action types
const SET_DATASET = 'SET_DATASET';

const GET_ORGANISATION_DATASETS_TYPE = 'GET_ORGANISATION_DATASETS_TYPE';
const GET_ORGANISATION_DATASETS_SUCCESS_TYPE =
	'GET_ORGANISATION_DATASETS_SUCCESS_TYPE';
const GET_ORGANISATION_DATASETS_ERROR_TYPE =
	'GET_ORGANISATION_DATASETS_ERROR_TYPE';

const GET_PAGED_DATASETS_TYPE = 'GET_PAGED_DATASETS_TYPE';
const GET_PAGED_DATASETS_SUCCESS_TYPE = 'GET_PAGED_DATASETS_SUCCESS_TYPE';
const GET_PAGED_DATASETS_ERROR_TYPE = 'GET_PAGED_DATASETS_ERROR_TYPE';

const GET_ALL_DATASETS_TYPE = 'GET_ALL_DATASETS_TYPE';
const GET_ALL_DATASETS_SUCCESS_TYPE = 'GET_ALL_DATASETS_SUCCESS_TYPE';
const GET_ALL_DATASETS_ERROR_TYPE = 'GET_ALL_DATASETS_ERROR_TYPE';

const VALIDATE_DATASET_DOCS_TYPE = 'VALIDATE_DATASET_DOCS_TYPE';
const VALIDATE_DATASET_DOCS_SUCCESS_TYPE = 'VALIDATE_DATASET_DOCS_SUCCESS_TYPE';
const VALIDATE_DATASET_DOCS_ERROR_TYPE = 'VALIDATE_DATASET_DOCS_ERROR_TYPE';

const CREATE_DATASET_TYPE = 'CREATE_DATASET_TYPE';
const CREATE_DATASET_SUCCESS_TYPE = 'CREATE_DATASET_SUCCESS_TYPE';
const CREATE_DATASET_ERROR_TYPE = 'CREATE_DATASET_ERROR_TYPE';

const UPDATE_DATASET_TYPE = 'UPDATE_DATASET_TYPE';
const UPDATE_DATASET_SUCCESS_TYPE = 'UPDATE_DATASET_SUCCESS_TYPE';
const UPDATE_DATASET_ERROR_TYPE = 'UPDATE_DATASET_ERROR_TYPE';

const DELETE_DATASET_TYPE = 'DELETE_DATASET_TYPE';
const DELETE_DATASET_SUCCESS_TYPE = 'DELETE_DATASET_SUCCESS_TYPE';
const DELETE_DATASET_ERROR_TYPE = 'DELETE_DATASET_ERROR_TYPE';

const initialState = {
	datasets: [],
	allPagedDatasetsLoading: false,
	allPagedDatasets: null,
	isPagedDatasetsLoadingError: false,
	creatingDataset: false,
	isDatasetCreationError: false,
	updatingDataset: false,
	isDatasetUpdateError: false,
	deletingDataset: false,
	isDatasetDeletionError: false,
	datasetCreationErrorMessage: '',

	allDatasets: [],
	allDatasetsLoading: false,
	allDatasetsLoadingError: false,

	organisationDatasets: [],
	areOrganisationDatasetsLoading: false,
	orgDatasetsLoadingHasErrors: false,

	validatingDatasetDocs: false,
	validationResponse: null,
};

export const datasetsActionCreators = {
	setDataset: (datasetDetails) => (dispatch, getState) => {
		dispatch({ type: SET_DATASET, datasetDetails });
	},

	getAllPagedDatasets: (page, pageSize) => async (dispatch, getState) => {
		if (getState().datasets.allPagedDatasetsLoading) {
			return;
		}

		dispatch({ type: GET_PAGED_DATASETS_TYPE });

		try {
			const url = new URL('/api/v1/datasets/paged', stringLookup.baseUrl);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			const res = await fetchSecured(url);

			if (res.ok) {
				const datasets = await res.json();

				dispatch({ type: GET_PAGED_DATASETS_SUCCESS_TYPE, datasets });
			} else {
				dispatch({ type: GET_PAGED_DATASETS_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: GET_PAGED_DATASETS_ERROR_TYPE });
		}
	},
	getAllDatasets: () => async (dispatch, getState) => {
		if (getState().datasets.allDatasetsLoading) {
			return;
		}

		dispatch({ type: GET_ALL_DATASETS_TYPE });

		try {
			const url = new URL('/api/v1/datasets', stringLookup.baseUrl);

			const res = await fetchDefault(url);

			if (res.ok) {
				const allDatasets = await res.json();

				dispatch({
					type: GET_ALL_DATASETS_SUCCESS_TYPE,
					allDatasets,
				});
			} else {
				dispatch({ type: GET_ALL_DATASETS_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: GET_ALL_DATASETS_ERROR_TYPE });
		}
	},
	getOrganisationDatasets: (organisationId) => async (dispatch, getState) => {
		if (getState().datasets.areOrganisationDatasetsLoading) {
			return;
		}

		dispatch({ type: GET_ORGANISATION_DATASETS_TYPE });

		try {
			const url = new URL(
				`/api/v1/datasets/${organisationId}`,
				stringLookup.baseUrl
			);

			const res = await fetchSecured(url);

			if (res.ok) {
				const datasets = await res.json();

				dispatch({ type: GET_ORGANISATION_DATASETS_SUCCESS_TYPE, datasets });
			} else {
				dispatch({ type: GET_ORGANISATION_DATASETS_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: GET_ORGANISATION_DATASETS_ERROR_TYPE });
		}
	},

	validateDatasetDocuments:
		(datasetId, docsPerIncidentType) => async (dispatch, getState) => {
			if (getState().datasets.validatingDatasetDocs) {
				return;
			}

			dispatch({ type: VALIDATE_DATASET_DOCS_TYPE });

			try {
				const url = new URL(
					`/api/v1/datasets/${datasetId}/validate-training`,
					stringLookup.baseUrl
				);

				if (docsPerIncidentType) {
					url.searchParams.append('count', docsPerIncidentType);
				}

				const res = await fetchSecured(url);

				if (res.ok) {
					const validationResponse = await res.json();

					dispatch({
						type: VALIDATE_DATASET_DOCS_SUCCESS_TYPE,
						validationResponse,
					});
				} else {
					dispatch({ type: VALIDATE_DATASET_DOCS_ERROR_TYPE });
				}
			} catch (error) {
				dispatch({ type: VALIDATE_DATASET_DOCS_ERROR_TYPE });
			}
		},

	createDataset: (dataset) => async (dispatch, getState) => {
		if (getState().datasets.creatingDataset) {
			return;
		}

		dispatch({ type: CREATE_DATASET_TYPE });

		try {
			const url = new URL('/api/v1/datasets', stringLookup.baseUrl);

			const res = await postSecured(url, dataset);

			if (res.ok) {
				dispatch({ type: CREATE_DATASET_SUCCESS_TYPE });
			} else {
				const error = await res.json();

				dispatch({ type: CREATE_DATASET_ERROR_TYPE, error });
			}
		} catch ({ message }) {
			const error = JSON.stringify(message);

			dispatch({
				type: CREATE_DATASET_ERROR_TYPE,
				error,
			});
		}
	},
	updateDataset: (datasetId, dataset) => async (dispatch, getState) => {
		if (getState().datasets.updatingDataset) {
			return;
		}

		dispatch({ type: UPDATE_DATASET_TYPE });

		try {
			const url = new URL(
				`/api/v1/datasets/${datasetId}`,
				stringLookup.baseUrl
			);

			const res = await patchSecured(url, dataset);

			if (res.ok) {
				dispatch({ type: UPDATE_DATASET_SUCCESS_TYPE });
			} else {
				dispatch({ type: UPDATE_DATASET_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: UPDATE_DATASET_ERROR_TYPE });
		}
	},
	deleteDataset: (datasetId) => async (dispatch, getState) => {
		if (getState().datasets.deletingDataset) {
			return;
		}

		dispatch({ type: DELETE_DATASET_TYPE });

		try {
			const url = new URL(
				`/api/v1/datasets/${datasetId}`,
				stringLookup.baseUrl
			);

			const res = await deleteSecured(url);

			if (res.ok) {
				dispatch({ type: DELETE_DATASET_SUCCESS_TYPE });
			} else {
				dispatch({ type: DELETE_DATASET_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: DELETE_DATASET_ERROR_TYPE });
		}
	},
};

const datasetsReducer = (state = initialState, action) => {
	if (action.type === SET_DATASET) {
		return {
			...state,
			datasets: [...state.datasets, action.datasetDetails],
		};
	}

	if (action.type === GET_PAGED_DATASETS_TYPE) {
		return {
			...state,
			allPagedDatasetsLoading: true,
		};
	}

	if (action.type === GET_PAGED_DATASETS_SUCCESS_TYPE) {
		return {
			...state,
			allPagedDatasetsLoading: false,
			allPagedDatasets: action.datasets,
			isDatasetsLoadingError: false,
		};
	}

	if (action.type === GET_PAGED_DATASETS_ERROR_TYPE) {
		return {
			...state,
			allPagedDatasetsLoading: false,
			isDatasetsLoadingError: true,
		};
	}

	if (action.type === GET_ALL_DATASETS_TYPE) {
		return {
			...state,
			allDatasetsLoading: true,
		};
	}

	if (action.type === GET_ALL_DATASETS_SUCCESS_TYPE) {
		return {
			...state,
			allDatasetsLoading: false,
			allDatasets: action.allDatasets,
			allDatasetsLoadingError: false,
		};
	}

	if (action.type === GET_ALL_DATASETS_ERROR_TYPE) {
		return {
			...state,
			allDatasetsLoading: false,
			allDatasetsLoadingError: true,
		};
	}

	if (action.type === GET_ORGANISATION_DATASETS_TYPE) {
		return {
			...state,
			areOrganisationDatasetsLoading: true,
		};
	}

	if (action.type === GET_ORGANISATION_DATASETS_SUCCESS_TYPE) {
		return {
			...state,
			organisationDatasets: action.datasets,
			areOrganisationDatasetsLoading: false,
		};
	}

	if (action.type === GET_ORGANISATION_DATASETS_ERROR_TYPE) {
		return {
			...state,
			areOrganisationDatasetsLoading: false,
			orgDatasetsLoadingHasErrors: true,
		};
	}

	if (action.type === VALIDATE_DATASET_DOCS_TYPE) {
		return {
			...state,
			validatingDatasetDocs: true,
		};
	}

	if (action.type === VALIDATE_DATASET_DOCS_SUCCESS_TYPE) {
		return {
			...state,
			validationResponse: action.validationResponse,
			validatingDatasetDocs: false,
		};
	}

	if (action.type === VALIDATE_DATASET_DOCS_ERROR_TYPE) {
		return {
			...state,
			validatingDatasetDocs: false,
		};
	}

	if (action.type === CREATE_DATASET_TYPE) {
		return {
			...state,
			creatingDataset: true,
			datasetCreationErrorMessage: '',
			isDatasetCreationError: false,
		};
	}

	if (action.type === CREATE_DATASET_SUCCESS_TYPE) {
		return {
			...state,
			creatingDataset: false,
			isDatasetCreationError: false,
		};
	}

	if (action.type === CREATE_DATASET_ERROR_TYPE) {
		return {
			...state,
			creatingDataset: false,
			isDatasetCreationError: true,
			datasetCreationErrorMessage: action.error,
		};
	}

	if (action.type === UPDATE_DATASET_TYPE) {
		return {
			...state,
			updatingDataset: true,
		};
	}

	if (action.type === UPDATE_DATASET_SUCCESS_TYPE) {
		return {
			...state,
			updatingDataset: false,
			isDatasetUpdateError: false,
		};
	}

	if (action.type === UPDATE_DATASET_ERROR_TYPE) {
		return {
			...state,
			updatingDataset: false,
			isDatasetUpdateError: true,
		};
	}

	if (action.type === DELETE_DATASET_TYPE) {
		return {
			...state,
			deletingDataset: true,
		};
	}

	if (action.type === DELETE_DATASET_SUCCESS_TYPE) {
		return {
			...state,
			deletingDataset: false,
			isDatasetDeletionError: false,
		};
	}

	if (action.type === DELETE_DATASET_ERROR_TYPE) {
		return {
			...state,
			deletingDataset: false,
			isDatasetDeletionError: true,
		};
	}

	return state;
};

export default datasetsReducer;
