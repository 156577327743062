import { Auth } from '@aws-amplify/auth';

export async function getLoggedInuser() {
	try {
		var user = await Auth.currentAuthenticatedUser();
	} catch (err) {
		//console.log(err);
		user = null;
	}

	if (!user) return null;

	return {
		username: user.username,
		roles: user.signInUserSession.accessToken.payload['cognito:groups'],
	};
}

export async function getRequestToken() {
	const currentSession = await Auth.currentSession()
		.then((data) => data)
		.catch((err) => console.log(err));
	if (currentSession) return currentSession.accessToken.jwtToken;

	return null;
}
