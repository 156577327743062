import {
	deleteSecured,
	fetchSecured,
	postSecured,
	putSecured,
} from '../fetchUtils';
import stringLookup from '../stringslookup';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';

const requestAllClassifiersType = 'REQUEST_ALL_CLASSIFIERS';
const receiveAllClassifiersType = 'RECEIVE_ALL_CLASSIFIERS';
const receiveAllClassifiersErrorType = 'RECEIVE_ALL_CLASSIFIERS_ERROR';

const requestClassfiersType = 'REQUEST_CLASSIFIERS';
const receiveClassfiersType = 'RECEIVE_CLASSIFIERS';
const receiveClassfiersErrorType = 'RECEIVE_CLASSIFIERS_ERROR';

const requestClassfierByIdType = 'REQUEST_CLASSIFIERS_BY_ID';
const receiveClassfierByIdType = 'RECEIVE_CLASSIFIERS_BY_ID';
const receiveClassfierByIdErrorType = 'RECEIVE_CLASSIFIERS_BY_ID_ERROR';

const SET_SELECTED_CLASSIFIER_TYPE = 'SET_SELECTED_CLASSIFIER';

const addModelType = 'ADD_MODEL';
const addModelCompleteType = 'ADD_MODEL_COMPLETE';
const addModelErrorType = 'ADD_MODEL_ERROR';

const editModelType = 'EDIT_MODEL';
const editModelCompleteType = 'EDIT_MODEL_COMPLETE';
const editModelErrorType = 'EDIT_MODEL_ERROR';

const deleteModelType = 'DELETE_MODEL';
const deleteModelCompleteType = 'DELETE_MODEL_COMPLETE';
const deleteModelErrorType = 'DELETE_MODEL_ERROR';

const requestFieldNamesType = 'REQUEST_FIELD_NAMES';
const receiveFieldNamesType = 'RECEIVE_FIELD_NAMES';
const receiveFieldNamesErrorType = 'RECEIVE_FIELD_NAMES_ERROR';

const trainingDatasetRequestType = 'TRAINING_DATASET_REQUEST';
const trainingDatasetRequestCompleteType = 'TRAINING_DATASET_COMPLETE';
const trainingDatasetRequestErrorType = 'TRAINING_DATASET_ERROR';

const requestTrainingDatasetType = 'REQUEST_TRAINING_DATASET';
const receiveTrainingDatasetType = 'RECEIVE_TRAINING_DATASET';
const receiveTrainingDatasetErrorType = 'RECEIVE_TRAINING_DATASET_ERROR';

const labelDataType = 'LABEL_DATA';
const labelDataCompleteType = 'LABEL_DATA_COMPLETE';
const labelDataErrorType = 'LABEL_DATA_ERROR';

const requestModelSimulationType = 'REQUEST_MODEL_SIMULATION';
const receiveModelSimulationType = 'RECEIVE_MODEL_SIMULATION';
const receiveModelSimulationErrorType = 'RECEIVE_MODEL_SIMULATION_ERROR';

const requestLatestClassifierMetricsType = 'REQUEST_LATEST_CLASSIFIER_METRICS';
const receiveLatestClassifierMetricsType = 'RECEIVE_LATEST_CLASSIFIER_METRICS';
const receiveLatestClassifierMetricsErrorType =
	'RECEIVE_LATEST_CLASSIFIER_METRICS_ERROR';

const requestEngagementModelSimulationType =
	'REQUEST_ENGAGEMENT_MODEL_SIMULATION';
const receiveEngagementModelSimulationType =
	'RECEIVE_ENGAGEMENT_MODEL_SIMULATION';
const receiveEngagementModelSimulationErrorType =
	'RECEIVE_ENGAGEMENT_MODEL_SIMULATION_ERROR';

const REQUEST_ARTICLES_PREDICTION_RESULTS_TYPE =
	'REQUEST_ARTICLES_PREDICTION_RESULTS_TYPE';
const REQUEST_ARTICLES_PREDICTION_RESULTS_SUCCESS_TYPE =
	'REQUEST_ARTICLES_PREDICTION_RESULTS_SUCCESS_TYPE';
const REQUEST_ARTICLES_PREDICTION_RESULTS_ERROR_TYPE =
	'REQUEST_ARTICLES_PREDICTION_RESULTS_ERROR_TYPE';

const REQUEST_DOCUMENT_PREDICTION_RESULTS_TYPE =
	'REQUEST_DOCUMENT_PREDICTION_RESULTS_TYPE';
const REQUEST_DOCUMENT_PREDICTION_RESULTS_SUCCESS_TYPE =
	'REQUEST_DOCUMENT_PREDICTION_RESULTS_SUCCESS_TYPE';
const REQUEST_DOCUMENT_PREDICTION_RESULTS_ERROR_TYPE =
	'REQUEST_DOCUMENT_PREDICTION_RESULTS_ERROR_TYPE';

const REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_TYPE =
	'REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_TYPE';
const REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_SUCCESS_TYPE =
	'REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_SUCCESS_TYPE';
const REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_ERROR_TYPE =
	'REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_ERROR_TYPE';

const REQUEST_DOCUMENTS_STATUS_TYPE = 'REQUEST_DOCUMENTS_STATUS_TYPE';
const REQUEST_DOCUMENTS_STATUS_SUCCESS_TYPE =
	'REQUEST_DOCUMENTS_STATUS_SUCCESS_TYPE';
const REQUEST_DOCUMENTS_STATUS_ERROR_TYPE =
	'REQUEST_DOCUMENTS_STATUS_ERROR_TYPE';

const initialState = {
	classifersLoading: false,
	classifiersError: false,
	classifiersList: [],
	classifiersTotal: 0,
	allClassifersLoading: false,
	allClassifiersError: false,
	allClassifiersList: [],
	addingModel: false,
	addModelError: false,
	addModelErrorMessage: '',
	editingModel: false,
	editModelError: false,
	editModelErrorMessage: '',
	deletingModel: false,
	deleteModelError: false,
	deleteModelErrorMessage: '',
	fieldNamesLoading: false,
	fieldNames: [],
	fieldNamesError: false,
	requestingTrainingDataset: false,
	trainingDatasetRequestError: false,
	trainingDatasetLoading: false,
	trainingDataset: [],
	trainingDatasetError: false,
	trainingDatasetTotal: 0,
	labelingData: false,
	labelDataError: false,
	labelDataErrorMessage: '',
	selectedClassifier: null,
	selectedClassifierLoading: false,
	selectedClassifierError: false,
	modelSimulationLoading: false,
	modelSimulationData: null,
	modelSimulationError: false,
	latestClassifierMetrics: {},
	latestClassifierMetricsErrorMsg: '',
	latestClassifierMetricsLoading: false,
	engagementModelSimulationData: null,
	engagementModelSimulationError: false,
	articlesPredictionResults: null,
	articlesPredictionResultsLoading: false,
	articlesPredictionResultsError: false,
	documentPredictionLoading: false,
	documentPredictionResults: null,
	documentPredictionResultsError: false,

	submittingTrainingSetEntries: false,
	trainingSetSubmissionError: false,

	loadingDocumentsStatus: false,
	documentsStatusData: [],
	documentsStatusError: false,
};

const machineLearningApi =
	stringLookup.baseUrl.includes('duma.siasareport.com') ||
	stringLookup.baseUrl.includes('beta.siasareport.com')
		? 'https://machinelearningapi.siasareport.com'
		: 'https://machinelearningapidev.siasareport.com';

// const machineLearningApi = 'http://localhost:5067';

export const MLActionCreators = {
	getClassifiers:
		(
			page = 1,
			pageSize = 10,
			classifierType,
			sortBy = 'dateCreated',
			sortOrder = 'desc',
			searchInput = ''
		) =>
		async (dispatch, getState) => {
			if (getState().machineLearning.classifersLoading) {
				return;
			}

			dispatch({ type: requestClassfiersType });
			// setGlobalLoad(dispatch);

			const url = new URL('/api/v1/text-classifier', machineLearningApi);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (classifierType !== 'all') {
				url.searchParams.append('classifierType', classifierType);
			}

			if (searchInput) {
				url.searchParams.append('searchString', searchInput);
			}

			url.searchParams.append('sortBy', sortBy);
			url.searchParams.append('sortOrder', sortOrder);

			try {
				const response = await fetchSecured(url, 'getClassifiers');

				if (response.ok) {
					const resPayload = await response.json();
					dispatch({
						type: receiveClassfiersType,
						classifiersList: resPayload.data,
						classifiersTotal: resPayload.total,
					});
				} else {
					dispatch({ type: receiveClassfiersErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveClassfiersErrorType });
			}

			// unsetGlobalLoad(dispatch);
		},

	getAllClassifiers: () => async (dispatch, getState) => {
		if (getState().machineLearning.allClassifiersLoading) {
			return;
		}

		dispatch({ type: requestAllClassifiersType });

		const url = new URL('/api/v1/text-classifier/all', machineLearningApi);
		// url.searchParams.append('classifierType', classifierType);

		try {
			const Response = await fetchSecured(url, 'getAllClassifiers');

			if (Response.ok) {
				const resPayload = await Response.json();
				dispatch({
					type: receiveAllClassifiersType,
					allClassifiersList: resPayload,
				});
			} else {
				dispatch({ type: receiveAllClassifiersErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveAllClassifiersErrorType });
		}

		// unsetGlobalLoad(dispatch);
	},

	getClassifierById: (id) => async (dispatch, getState) => {
		if (getState().machineLearning.selectedClassifierLoading) {
			return;
		}

		dispatch({ type: requestClassfierByIdType });

		const url = new URL(`/api/v1/text-classifier/${id}`, machineLearningApi);

		try {
			const Response = await fetchSecured(url, 'getClassifiers');

			if (Response.ok) {
				const resPayload = await Response.json();
				dispatch({
					type: receiveClassfierByIdType,
					selectedClassifier: resPayload,
				});
			} else {
				dispatch({ type: receiveClassfierByIdErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveClassfierByIdErrorType });
		}
	},

	setSelectedClassifier: (classifier) => async (dispatch, getState) => {
		dispatch({
			type: SET_SELECTED_CLASSIFIER_TYPE,
			selectedClassifier: classifier,
		});
	},

	addModel: (model) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().machineLearning.addingModel) {
			return metadata;
		}

		dispatch({ type: addModelType });
		const url = new URL('/api/v1/text-classifier', machineLearningApi);

		try {
			const res = await postSecured(url, model);

			if (res.ok) {
				dispatch({ type: addModelCompleteType });
			} else if (res.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await res.json();
				dispatch({
					type: addModelErrorType,
					addModelErrorMessage: _400ResMessage.title,
				});
			} else {
				dispatch({ type: addModelCompleteType });
			}
		} catch (err) {
			dispatch({
				type: addModelErrorType,
				addModelErrorMessage: err.message,
			});
		}

		return metadata;
	},

	editModel: (model) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().machineLearning.editingModel) {
			return metadata;
		}

		dispatch({ type: editModelType });
		const url = new URL('/api/v1/text-classifier', machineLearningApi);

		try {
			const res = await putSecured(url, model);

			if (res.ok) {
				dispatch({ type: editModelCompleteType });
			} else if (res.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await res.json();
				dispatch({
					type: editModelErrorType,
					editModelErrorMessage: _400ResMessage.title,
				});
			} else {
				dispatch({ type: editModelCompleteType });
			}
		} catch (err) {
			dispatch({
				type: editModelErrorType,
				editModelErrorMessage: err.message,
			});
		}

		return metadata;
	},

	deleteModel: (id) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().machineLearning.deletingModel) {
			return metadata;
		}

		dispatch({ type: deleteModelType });
		const url = new URL(`/api/v1/text-classifier/${id}`, machineLearningApi);

		try {
			const res = await deleteSecured(url);

			if (res.ok) {
				dispatch({ type: deleteModelCompleteType });
			} else if (res.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await res.json();
				dispatch({
					type: deleteModelErrorType,
					deleteModelErrorMessage: _400ResMessage.title,
				});
			} else {
				dispatch({ type: deleteModelCompleteType });
			}
		} catch (err) {
			dispatch({
				type: deleteModelErrorType,
				deleteModelErrorMessage: err.message,
			});
		}

		return metadata;
	},

	requestFieldNames: () => async (dispatch, getState) => {
		if (getState().machineLearning.fieldNamesLoading) {
			return;
		}

		dispatch({ type: requestFieldNamesType });

		const url = new URL('/api/v1/text-classifier/fields', machineLearningApi);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({ type: receiveFieldNamesType, fieldNames: data });
			} else {
				dispatch({ type: receiveFieldNamesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveFieldNamesErrorType });
		}
	},

	requestTrainingDataset:
		(classifierId, subtopics, count, mediaType, incidentTypes) =>
		async (dispatch, getState) => {
			const metadata = {};
			if (getState().machineLearning.requestingTrainingDataset) {
				return metadata;
			}

			dispatch({ type: trainingDatasetRequestType });
			const url = new URL('/api/v1/classifier-data', machineLearningApi);

			try {
				const res = await postSecured(url, {
					classifierId: classifierId,
					subtopics: subtopics,
					count: count,
					mediaType: mediaType,
					incidentTypes,
				});

				if (res.ok) {
					dispatch({ type: trainingDatasetRequestCompleteType });
				} else if (res.status === 400) {
					metadata._400Error = true;
					const _400ResMessage = await res.json();
					dispatch({
						type: trainingDatasetRequestErrorType,
					});
				} else {
					dispatch({ type: trainingDatasetRequestErrorType });
				}
			} catch (err) {
				dispatch({
					type: trainingDatasetRequestErrorType,
				});
			}

			return metadata;
		},

	getTrainingData:
		(classifierId, page = 1, pageSize = 10) =>
		async (dispatch, getState) => {
			if (getState().machineLearning.trainingDatasetLoading) {
				return;
			}

			dispatch({ type: requestTrainingDatasetType });
			// setGlobalLoad(dispatch);

			const url = new URL('/api/v1/classifier-data', machineLearningApi);
			url.searchParams.append('classifierId', classifierId);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const Response = await fetchSecured(url, 'getTrainingDataset');

				if (Response.ok) {
					const resPayload = await Response.json();
					dispatch({
						type: receiveTrainingDatasetType,
						trainingDataset: resPayload.data,
						trainingDatasetTotal: resPayload.total,
					});
				} else {
					dispatch({ type: receiveTrainingDatasetErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveTrainingDatasetErrorType });
			}

			// unsetGlobalLoad(dispatch);
		},

	labelData: (classifierId, labels) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().machineLearning.labelingData) {
			return metadata;
		}

		dispatch({ type: labelDataType });
		const url = new URL('/api/v1/classifier-data/label', machineLearningApi);

		try {
			const res = await postSecured(url, { classifierId, labels });

			if (res.ok) {
				dispatch({ type: labelDataCompleteType });
			} else if (res.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await res.json();
				dispatch({
					type: labelDataErrorType,
					labelDataErrorMessage: _400ResMessage.title,
				});
			} else {
				dispatch({ type: labelDataCompleteType });
			}
		} catch (err) {
			dispatch({
				type: labelDataErrorType,
				labelDataErrorMessage: err.message,
			});
		}

		return metadata;
	},

	getModelSimulation:
		(classifierId, subtopicId, startDate, endDate) =>
		async (dispatch, getState) => {
			if (getState().machineLearning.modelSimulationLoading) {
				return;
			}

			dispatch({ type: requestModelSimulationType });

			const url = new URL(
				'/api/v1/text-classifier/simulate',
				machineLearningApi
			);

			url.searchParams.append('classifierId', classifierId);
			url.searchParams.append('subtopicId', subtopicId);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: receiveModelSimulationType,
						modelSimulationData: data,
					});
				} else {
					dispatch({ type: receiveModelSimulationErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveModelSimulationErrorType });
			}
		},

	getLatestClassifierMetrics: (classifierId) => async (dispatch, getState) => {
		if (getState().machineLearning.latestClassifierMetricsLoading) {
			return;
		}

		dispatch({ type: requestLatestClassifierMetricsType });

		const url = new URL(
			`/api/v1/classifier-metrics/${classifierId}/latest`,
			machineLearningApi
		);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: receiveLatestClassifierMetricsType,
					latestClassifierMetrics: data,
				});
			} else {
				dispatch({ type: receiveLatestClassifierMetricsErrorType });
			}
		} catch (err) {
			dispatch({
				type: receiveLatestClassifierMetricsErrorType,
				latestClassifierMetricsErrorMsg: err.message,
			});
		}
	},

	getEngagementModelSimulation:
		(classifierId, OrganisationId, label = true) =>
		async (dispatch, getState) => {
			if (getState().machineLearning.modelSimulationLoading) {
				return;
			}

			dispatch({ type: requestEngagementModelSimulationType });

			const url = new URL('/api/v1/engagement/simulate', stringLookup.baseUrl);

			url.searchParams.append('classifierId', classifierId);
			url.searchParams.append('OrganisationId', OrganisationId);
			url.searchParams.append('label', label);

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: receiveEngagementModelSimulationType,
						engagementModelSimulationData: data,
					});
				} else {
					dispatch({
						type: receiveEngagementModelSimulationErrorType,
					});
				}
			} catch (err) {
				dispatch({ type: receiveEngagementModelSimulationErrorType });
			}
		},

	getArticlesPredictionResults: (reqPayload) => async (dispatch, getState) => {
		if (getState().predictionResultsLoading) {
			return;
		}

		dispatch({ type: REQUEST_ARTICLES_PREDICTION_RESULTS_TYPE });

		try {
			const url = new URL(
				'/api/v1/text-classifier/predict',
				machineLearningApi
			);

			const res = await postSecured(url, reqPayload);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: REQUEST_ARTICLES_PREDICTION_RESULTS_SUCCESS_TYPE,
					articlesPredictionResults: data,
				});
			} else {
				dispatch({
					type: REQUEST_ARTICLES_PREDICTION_RESULTS_ERROR_TYPE,
				});
			}
		} catch (error) {
			dispatch({
				type: REQUEST_ARTICLES_PREDICTION_RESULTS_ERROR_TYPE,
			});
		}
	},

	getDocumentPredictionResults:
		(classifierId, webpageId) => async (dispatch, getState) => {
			if (getState().documentPredictionLoading) {
				return;
			}

			dispatch({ type: REQUEST_DOCUMENT_PREDICTION_RESULTS_TYPE });

			try {
				const url = new URL(
					'/api/v1/text-classifier/predict',
					machineLearningApi
				);
				url.searchParams.append('classifierId', classifierId);
				url.searchParams.append('webpageId', webpageId);

				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: REQUEST_DOCUMENT_PREDICTION_RESULTS_SUCCESS_TYPE,
						documentPredictionResults: data,
					});
				} else {
					dispatch({
						type: REQUEST_DOCUMENT_PREDICTION_RESULTS_ERROR_TYPE,
					});
				}
			} catch (error) {
				dispatch({
					type: REQUEST_DOCUMENT_PREDICTION_RESULTS_ERROR_TYPE,
				});
			}
		},

	submitTrainingSetEntries: (payload) => async (dispatch, getState) => {
		if (getState().submittingTrainingSetEntries) {
			return;
		}

		dispatch({ type: REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_TYPE });

		try {
			const url = new URL(
				'/api/v1/classifier-data/training-set',
				machineLearningApi
			);

			const res = await postSecured(url, payload);

			if (res.ok) {
				dispatch({
					type: REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_SUCCESS_TYPE,
				});
			} else {
				dispatch({
					type: REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_ERROR_TYPE,
				});
			}
		} catch (error) {
			dispatch({
				type: REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_ERROR_TYPE,
			});
		}
	},
	getDocumentsStatus:
		(documentIds, classifierId) => async (dispatch, getState) => {
			if (getState().loadingDocumentsStatus) {
				return;
			}

			dispatch({ type: REQUEST_DOCUMENTS_STATUS_TYPE });

			try {
				const url = new URL(
					'/api/v1/classifier-data/document-status',
					machineLearningApi
				);

				url.searchParams.append('webpageIds', documentIds.join(','));
				url.searchParams.append('classifierId', classifierId);

				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: REQUEST_DOCUMENTS_STATUS_SUCCESS_TYPE,
						documentsStatusData: data,
					});
				} else {
					dispatch({
						type: REQUEST_DOCUMENTS_STATUS_ERROR_TYPE,
					});
				}
			} catch (error) {
				dispatch({
					type: REQUEST_DOCUMENTS_STATUS_ERROR_TYPE,
				});
			}
		},
};

export const reducer = (state, action) => {
	state = state || initialState;
	if (action.type === requestClassfiersType) {
		return {
			...state,
			classifersLoading: true,
			classifiersError: false,
		};
	}
	if (action.type === receiveClassfiersType) {
		return {
			...state,
			classifersLoading: false,
			classifiersList: action.classifiersList,
			classifiersTotal: action.classifiersTotal,
		};
	}
	if (action.type === receiveClassfiersErrorType) {
		return {
			...state,
			classifersLoading: false,
			classifiersError: true,
		};
	}

	if (action.type === requestAllClassifiersType) {
		return {
			...state,
			allClassifersLoading: true,
			allClassifiersError: false,
		};
	}
	if (action.type === receiveAllClassifiersType) {
		return {
			...state,
			allClassifersLoading: false,
			allClassifiersList: action.allClassifiersList,
		};
	}
	if (action.type === receiveAllClassifiersErrorType) {
		return {
			...state,
			allClassifersLoading: false,
			allClassifiersError: true,
		};
	}

	if (action.type === requestClassfierByIdType) {
		return {
			...state,
			selectedClassifierLoading: true,
			selectedClassifierError: false,
		};
	}
	if (action.type === receiveClassfierByIdType) {
		return {
			...state,
			selectedClassifierLoading: false,
			selectedClassifier: action.selectedClassifier,
		};
	}
	if (action.type === receiveClassfierByIdErrorType) {
		return {
			...state,
			selectedClassifierLoading: false,
			selectedClassifierError: true,
		};
	}

	if (action.type === SET_SELECTED_CLASSIFIER_TYPE) {
		return {
			...state,
			selectedClassifier: action.selectedClassifier,
		};
	}

	if (action.type === addModelType) {
		return {
			...state,
			addingModel: true,
			addModelError: false,
			addModelErrorMessage: '',
		};
	}
	if (action.type === addModelCompleteType) {
		return {
			...state,
			addingModel: false,
		};
	}
	if (action.type === addModelErrorType) {
		return {
			...state,
			addingModel: false,
			addModelError: true,
			addModelErrorMessage: action.addModelErrorMessage,
		};
	}

	if (action.type === editModelType) {
		return {
			...state,
			editingModel: true,
			editModelError: false,
			editModelErrorMessage: '',
		};
	}
	if (action.type === editModelCompleteType) {
		return {
			...state,
			editingModel: false,
		};
	}
	if (action.type === editModelErrorType) {
		return {
			...state,
			editingModel: false,
			editModelError: true,
			editModelErrorMessage: action.editModelErrorMessage,
		};
	}

	if (action.type === deleteModelType) {
		return {
			...state,
			deletingModel: true,
			deleteModelError: false,
			deleteModelErrorMessage: '',
		};
	}
	if (action.type === deleteModelCompleteType) {
		return {
			...state,
			deletingModel: false,
		};
	}
	if (action.type === deleteModelErrorType) {
		return {
			...state,
			deletingModel: false,
			deleteModelError: true,
			deleteModelErrorMessage: action.deleteModelErrorMessage,
		};
	}

	if (action.type === requestFieldNamesType) {
		return {
			...state,
			fieldNamesLoading: true,
			fieldNamesError: false,
		};
	}
	if (action.type === receiveFieldNamesType) {
		return {
			...state,
			fieldNamesLoading: false,
			fieldNames: action.fieldNames,
		};
	}
	if (action.type === receiveFieldNamesErrorType) {
		return {
			...state,
			fieldNamesLoading: false,
			fieldNamesError: true,
		};
	}

	if (action.type === trainingDatasetRequestType) {
		return {
			...state,
			requestingTrainingDataset: true,
			trainingDatasetRequestError: false,
		};
	}
	if (action.type === trainingDatasetRequestCompleteType) {
		return {
			...state,
			requestingTrainingDataset: false,
		};
	}
	if (action.type === trainingDatasetRequestErrorType) {
		return {
			...state,
			requestingTrainingDataset: false,
			trainingDatasetRequestError: true,
		};
	}

	if (action.type === requestTrainingDatasetType) {
		return {
			...state,
			trainingDatasetLoading: true,
			trainingDatasetError: false,
		};
	}
	if (action.type === receiveTrainingDatasetType) {
		return {
			...state,
			trainingDatasetLoading: false,
			trainingDataset: action.trainingDataset,
			trainingDatasetTotal: action.trainingDatasetTotal,
		};
	}
	if (action.type === receiveTrainingDatasetErrorType) {
		return {
			...state,
			trainingDatasetLoading: false,
			trainingDatasetError: true,
		};
	}

	if (action.type === labelDataType) {
		return {
			...state,
			labelingData: true,
			labelDataError: false,
			labelDataErrorMessage: '',
		};
	}
	if (action.type === labelDataCompleteType) {
		return {
			...state,
			labelingData: false,
		};
	}
	if (action.type === labelDataErrorType) {
		return {
			...state,
			labelingData: false,
			labelDataError: true,
			labelDataErrorMessage: action.addModelErrorMessage,
		};
	}

	if (action.type === requestModelSimulationType) {
		return {
			...state,
			modelSimulationLoading: true,
			modelSimulationError: false,
		};
	}
	if (action.type === receiveModelSimulationType) {
		return {
			...state,
			modelSimulationData: action.modelSimulationData,
			modelSimulationLoading: false,
		};
	}
	if (action.type === receiveModelSimulationErrorType) {
		return {
			...state,
			modelSimulationLoading: false,
			modelSimulationError: true,
		};
	}

	if (action.type === requestLatestClassifierMetricsType) {
		return {
			...state,
			latestClassifierMetricsLoading: true,
		};
	}
	if (action.type === receiveLatestClassifierMetricsType) {
		return {
			...state,
			latestClassifierMetricsLoading: false,
			latestClassifierMetrics: action.latestClassifierMetrics,
		};
	}
	if (action.type === receiveLatestClassifierMetricsErrorType) {
		return {
			...state,
			latestClassifierMetricsErrorMsg: action.latestClassifierMetricsErrorMsg,
			latestClassifierMetricsLoading: false,
		};
	}

	if (action.type === requestEngagementModelSimulationType) {
		return {
			...state,
			modelSimulationLoading: true,
			engagementModelSimulationError: false,
			engagementModelSimulationData: null,
		};
	}
	if (action.type === receiveEngagementModelSimulationType) {
		return {
			...state,
			engagementModelSimulationData: action.engagementModelSimulationData,
			modelSimulationLoading: false,
		};
	}
	if (action.type === receiveEngagementModelSimulationErrorType) {
		return {
			...state,
			modelSimulationLoading: false,
			engagementModelSimulationError: true,
		};
	}

	if (action.type === REQUEST_ARTICLES_PREDICTION_RESULTS_TYPE) {
		return {
			...state,
			articlesPredictionResultsLoading: true,
			articlesPredictionResultsError: false,
		};
	}
	if (action.type === REQUEST_ARTICLES_PREDICTION_RESULTS_SUCCESS_TYPE) {
		return {
			...state,
			articlesPredictionResultsLoading: false,
			articlesPredictionResultsError: false,
			articlesPredictionResults: action.articlesPredictionResults,
		};
	}
	if (action.type === REQUEST_ARTICLES_PREDICTION_RESULTS_ERROR_TYPE) {
		return {
			...state,
			articlesPredictionResultsLoading: false,
			articlesPredictionResultsError: true,
		};
	}

	if (action.type === REQUEST_DOCUMENT_PREDICTION_RESULTS_TYPE) {
		return {
			...state,
			documentPredictionLoading: true,
			documentPredictionResultsError: false,
		};
	}
	if (action.type === REQUEST_DOCUMENT_PREDICTION_RESULTS_SUCCESS_TYPE) {
		return {
			...state,
			documentPredictionLoading: false,
			documentPredictionResults: action.documentPredictionResults,
		};
	}
	if (action.type === REQUEST_DOCUMENT_PREDICTION_RESULTS_ERROR_TYPE) {
		return {
			...state,
			documentPredictionLoading: false,
			documentPredictionResultsError: true,
		};
	}

	if (action.type === REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_TYPE) {
		return {
			...state,
			submittingTrainingSetEntries: true,
			trainingSetSubmissionError: false,
		};
	}

	if (action.type === REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_SUCCESS_TYPE) {
		return {
			...state,
			submittingTrainingSetEntries: false,
			trainingSetSubmissionError: false,
		};
	}

	if (action.type === REQUEST_TRAINING_SET_ENTRIES_SUBMISSION_ERROR_TYPE) {
		return {
			...state,
			submittingTrainingSetEntries: false,
			trainingSetSubmissionError: true,
		};
	}

	if (action.type === REQUEST_DOCUMENTS_STATUS_TYPE) {
		return {
			...state,
			loadingDocumentsStatus: true,
			documentsStatusError: false,
		};
	}

	if (action.type === REQUEST_DOCUMENTS_STATUS_SUCCESS_TYPE) {
		return {
			...state,
			loadingDocumentsStatus: false,
			documentsStatusError: false,
			documentsStatusData: action.documentsStatusData,
		};
	}

	if (action.type === REQUEST_DOCUMENTS_STATUS_ERROR_TYPE) {
		return {
			...state,
			loadingDocumentsStatus: false,
			documentsStatusError: true,
		};
	}

	return state;
};
