import {
	deleteSecured,
	fetchSecured,
	postSecured,
	putSecured,
} from '../fetchUtils';
import stringLookup from '../stringslookup';

const requestInAppNotificationsType = 'REQUEST_INAPP_NOTIFICATIONS';
const receiveInAppNotificationsSuccessType =
	'RECEIVE_INAPP_NOTIFICATIONS_SUCCESS';
const receiveInAppNotificationsErrorType = 'RECEIVE_INAPP_NOTIFICATIONS_ERROR';

const requestEngagementNotificationsType = 'REQUEST_ENGAGEMENT_NOTIFICATIONS';
const receiveEngagementNotificationsType = 'RECEIVE_ENGAGEMENT_NOTIFICATIONS';
const receiveEngagementNotificationsErrorType =
	'RECEIVE_ENGAGEMENT_NOTIFICATIONS_ERROR';

const requestEngagementReadingListType = 'REQUEST_ENGAGEMENT_READING_LIST';
const receiveEngagementReadingListType = 'RECEIVE_ENGAGEMENT_READING_LIST';
const receiveEngagementReadingListErrorType =
	'RECEIVE_ENGAGEMENT_READING_LIST_ERROR';

const addEngagementNotificationType = 'ADD_MODEL';
const addEngagementNotificationCompleteType = 'ADD_MODEL_COMPLETE';
const addEngagementNotificationErrorType = 'ADD_MODEL_ERROR';

const editEngagementNotificationType = 'EDIT_MODEL';
const editEngagementNotificationCompleteType = 'EDIT_MODEL_COMPLETE';
const editEngagementNotificationErrorType = 'EDIT_MODEL_ERROR';

const deleteEngagementNotificationType = 'DELETE_MODEL';
const deleteEngagementNotificationCompleteType = 'DELETE_MODEL_COMPLETE';
const deleteEngagementNotificationErrorType = 'DELETE_MODEL_ERROR';

const setAddEngagementErrorType = 'SET_ADD_ENGAGEMENT_ERROR';
const setAddEngagementSuccessType = 'SET_ADD_ENGAGEMENT_SUCCESS';

const setEditEngagementErrorType = 'SET_EDIT_ENGAGEMENT_ERROR';
const setEditEngagementSuccessType = 'SET_EDIT_ENGAGEMENT_SUCCESS';

const setDeleteEngagementErrorType = 'SET_DELETE_ENGAGEMENT_ERROR';
const setDeleteEngagementSuccessType = 'SET_DELETE_ENGAGEMENT_SUCCESS';

const deleteReadingListItemType = 'DELETE_READING_LIST_ITEM';
const deleteReadingListItemCompleteType = 'DELETEREADING_LIST_ITEM_COMPLETE';
const deleteReadingListItemErrorType = 'DELETE_READING_LIST_ITEM_ERROR';

const requestEngagementHistoryType = 'REQUEST_ENGAGEMENT_HISTORY';
const receiveEngagementHistoryType = 'RECEIVE_ENGAGEMENT_HISTORY';
const receiveEngagementHistoryErrorType = 'RECEIVE_ENGAGEMENT_HISTORY_ERROR';

const requestEventTypesType = 'REQUEST_EVENT_TYPES';
const receiveEventTypesType = 'RECEIVE_EVENT_TYPES';
const receiveEventTypesErrorType = 'RECEIVE_EVENT_TYPES_ERROR';

const requestBulkEngagementItemsDeletionType =
	'requestBulkEngagementItemsDeletionType';
const receiveBulkEngagementItemsDeletionSuccessType =
	'receiveBulkEngagementItemsDeletionSuccessType';
const receiveBulkEngagementItemsDeletionErrorType =
	'receiveBulkEngagementItemsDeletionErrorType';

const requestUserEngagementNotificationType =
	'REQUEST_USER_ENGAGEMENT_NOTIFICATION_TYPE';
const receiveUserEngagementNotificationSuccessType =
	'RECEIVE_USER_ENGAGEMENT_NOTIFICATION_SUCCESS_TYPE';
const receiveUserEngagementNotificationErrorType =
	'RECEIVE_USER_ENGAGEMENT_NOTIFICATION_ERROR_TYPE';

const SET_RECURRENCE_MENU_STATE_TYPE = 'SET_RECURRENCE_MENU_STATE';

const initialState = {
	inAppNotifications: [],
	inAppNotificationsTotal: 0,
	inAppNotificationsLoading: false,
	inAppNotificationsError: '',
	addingEngagementNotification: false,
	addEngagementSuccess: false,
	addEngagementNotificationError: false,
	addEngagementNotificationErrorMessage: '',
	editingEngagementNotification: false,
	editEngagementSuccess: false,
	editEngagementNotificationError: false,
	editEngagementNotificationErrorMessage: '',
	deletingEngagementNotification: false,
	deleteEngagementSuccess: false,
	deleteEngagementNotificationError: false,
	deleteEngagementNotificationErrorMessage: '',
	engagementNotifications: [],
	engagementNotificationsLoading: false,
	engagementNotificationsTotal: 0,
	engagementNotificationsError: false,
	engagementNotificationsErrorMessage: '',
	readingList: [],
	readingListLoading: false,
	readingListTotal: 0,
	readingListError: false,
	readingListErrorMessage: '',
	editedNotification: null,

	deletingReadingListItem: false,
	deleteReadingListItemError: false,
	deleteReadingListItemErrorMessage: '',
	notificationHistoryLoading: false,
	notificationHistoryError: false,
	notificationHistoryErrorMessage: '',
	notificationHistory: [],
	notificationHistoryTotal: null,
	eventTypesLoading: false,
	eventTypesList: [],
	eventTypesError: false,
	bulkEngagementItemsDeletionLoading: false,
	bulkEngagementItemsDeletionError: false,

	userEngagementNotificationLoading: true,
	userEngagementNotificationError: false,
	userEngagementNotification: null,

	recurrenceMenuState: null,
};

export const NotificationsActionCreators = {
	setRecurrenceMenuState: (recurrenceMenuState) => (dispatch, getState) => {
		dispatch({
			type: SET_RECURRENCE_MENU_STATE_TYPE,
			recurrenceMenuState: recurrenceMenuState,
		});
	},
	getNotificationEventTypes: () => async (dispatch, getState) => {
		if (getState().notifications.eventTypesLoading) {
			return;
		}

		dispatch({ type: requestEventTypesType });

		const url = new URL('/api/notifications/event-types', stringLookup.baseUrl);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const resJson = await res.json();

				dispatch({ type: receiveEventTypesType, payload: resJson });
			} else {
				dispatch({
					type: receiveEventTypesErrorType,
					entityTypesError: true,
				});
			}
		} catch (e) {
			dispatch({
				type: receiveEventTypesErrorType,
				entityTypesError: true,
			});
		}
	},

	getInAppNotifications:
		(
			page = 1,
			pageSize = 10,
			searchString,
			event,
			status,
			startDate,
			endDate,
			sortBy,
			sortOrder = 'desc' //either 'desc' or 'asc'
		) =>
		async (dispatch, getState) => {
			if (getState().notifications.inAppNotificationsLoading) {
				return;
			}

			dispatch({ type: requestInAppNotificationsType });

			const url = new URL('/api/notifications', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (startDate) {
				url.searchParams.append('startDate', startDate.toISOString());
			}

			if (endDate) {
				url.searchParams.append('endDate', endDate.toISOString());
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (event) {
				url.searchParams.append('event', event);
			}

			if (status) {
				url.searchParams.append('status', status);
			}

			if (sortBy) {
				url.searchParams.append('sortBy', sortBy);
			}

			if (sortOrder) {
				url.searchParams.append('sortOrder', sortOrder);
			}

			try {
				const res = await fetchSecured(url, 'getInAppNotifications');

				if (res.ok) {
					const resPayload = await res.json();

					dispatch({
						type: receiveInAppNotificationsSuccessType,
						payload: resPayload.data,
						inAppNotificationsTotal: resPayload.total,
					});
				} else {
					dispatch({
						type: receiveInAppNotificationsErrorType,
						payload: 'Something went wrong!',
					});
				}
			} catch (e) {
				dispatch({
					type: receiveInAppNotificationsErrorType,
					payload: e.message,
				});
			}
		},

	getUserEngagementNotifications:
		(page = 1, pageSize = 10) =>
		async (dispatch, getState) => {
			if (getState().notifications.engagementNotificationsLoading) {
				return;
			}

			dispatch({ type: requestEngagementNotificationsType });

			const url = new URL('/api/v1/engagement', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const res = await fetchSecured(url, 'getEngagementNotifications');

				if (res.ok) {
					const resPayload = await res.json();

					dispatch({
						type: receiveEngagementNotificationsType,
						payload: resPayload.data,
						engagementNotificationsTotal: resPayload.total,
					});
				} else {
					dispatch({
						type: receiveEngagementNotificationsErrorType,
						payload: 'Something went wrong!',
					});
				}
			} catch (e) {
				dispatch({
					type: receiveEngagementNotificationsErrorType,
					payload: e.message,
				});
			}
		},

	getUserEngagementNotificationById:
		(engagementId) => async (dispatch, getState) => {
			if (getState.userEngagementNotificationLoading) {
				return;
			}

			dispatch({ type: requestUserEngagementNotificationType });

			try {
				const url = new URL(
					'/api/v1/engagement/engagement-item',
					stringLookup.baseUrl
				);
				url.searchParams.append('id', engagementId);

				const res = await fetchSecured(url);

				if (res.ok) {
					const resPayload = await res.json();

					dispatch({
						type: receiveUserEngagementNotificationSuccessType,
						userEngagementNotification: resPayload,
					});
				} else {
					dispatch({
						type: receiveUserEngagementNotificationErrorType,
					});
				}
			} catch (error) {
				dispatch({
					type: receiveUserEngagementNotificationErrorType,
				});
			}
		},

	addEngagementNotification:
		(engagementNotification) => async (dispatch, getState) => {
			const metadata = {};
			if (getState().machineLearning.addingEngagementNotification) {
				return metadata;
			}

			dispatch({ type: addEngagementNotificationType });
			const url = new URL('/api/v1/engagement', stringLookup.baseUrl);

			try {
				const res = await postSecured(url, engagementNotification);

				if (res.ok) {
					dispatch({ type: addEngagementNotificationCompleteType });
				} else if (res.status === 400) {
					metadata._400Error = true;
					const _400ResMessage = await res.json();
					dispatch({
						type: addEngagementNotificationErrorType,
						addEngagementNotificationErrorMessage: _400ResMessage.description,
					});
				} else {
					dispatch({ type: addEngagementNotificationCompleteType });
				}
			} catch (err) {
				dispatch({
					type: addEngagementNotificationErrorType,
					addEngagementNotificationErrorMessage: err.message,
				});
			}

			return metadata;
		},

	editEngagementNotification:
		(id, engagementNotification) => async (dispatch, getState) => {
			const metadata = {};
			if (getState().machineLearning.editingEngagementNotification) {
				return metadata;
			}

			dispatch({ type: editEngagementNotificationType });
			const url = new URL(`/api/v1/engagement/${id}`, stringLookup.baseUrl);

			try {
				const res = await putSecured(url, engagementNotification);

				if (res.ok) {
					const resPayload = await res.json();
					dispatch({
						type: editEngagementNotificationCompleteType,
						editedNotification: resPayload,
					});
				} else if (res.status === 400) {
					metadata._400Error = true;
					const _400ResMessage = await res.json();
					dispatch({
						type: editEngagementNotificationErrorType,
						editEngagementNotificationErrorMessage: _400ResMessage.description,
					});
				} else {
					dispatch({
						type: editEngagementNotificationCompleteType,
						editedNotification: null,
					});
				}
			} catch (err) {
				dispatch({
					type: editEngagementNotificationErrorType,
					editEngagementNotificationErrorMessage: err.message,
				});
			}

			return metadata;
		},

	deleteEngagementNotification: (id) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().machineLearning.deletingEngagementNotification) {
			return metadata;
		}

		dispatch({ type: deleteEngagementNotificationType });
		const url = new URL(`/api/v1/engagement/${id}`, stringLookup.baseUrl);

		try {
			const res = await deleteSecured(url);

			if (res.ok) {
				dispatch({ type: deleteEngagementNotificationCompleteType });
			} else if (res.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await res.json();
				dispatch({
					type: deleteEngagementNotificationErrorType,
					deleteEngagementNotificationErrorMessage: _400ResMessage.description,
				});
			} else {
				dispatch({ type: deleteEngagementNotificationCompleteType });
			}
		} catch (err) {
			dispatch({
				type: deleteEngagementNotificationErrorType,
				deleteEngagementNotificationErrorMessage: err.message,
			});
		}

		return metadata;
	},

	getEngagementReadingList:
		(id, page = 1, pageSize = 10) =>
		async (dispatch, getState) => {
			if (getState().notifications.readingListLoading) {
				return;
			}

			dispatch({ type: requestEngagementReadingListType });

			const url = new URL(
				`/api/v1/engagement/${id}/latest`,
				stringLookup.baseUrl
			);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const res = await fetchSecured(url, 'getEngagementReadingList');

				if (res.ok) {
					const resPayload = await res.json();

					dispatch({
						type: receiveEngagementReadingListType,
						payload: resPayload.engagementItems,
						readingListTotal: resPayload.engagementItems.length,
					});
				} else {
					dispatch({
						type: receiveEngagementReadingListErrorType,
						payload: 'Something went wrong!',
					});
				}
			} catch (e) {
				dispatch({
					type: receiveEngagementReadingListErrorType,
					payload: e.message,
				});
			}
		},

	deleteReadingListItem: (id) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().machineLearning.deletingReadingListItem) {
			return metadata;
		}

		dispatch({ type: deleteReadingListItemType });
		const url = new URL(
			`/api/v1/engagement/engagement-item/${id}`,
			stringLookup.baseUrl
		);

		try {
			const res = await deleteSecured(url);

			if (res.ok) {
				dispatch({ type: deleteReadingListItemCompleteType });
			} else if (res.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await res.json();
				dispatch({
					type: deleteReadingListItemErrorType,
					deleteReadingListItemErrorMessage: _400ResMessage.description,
				});
			} else {
				dispatch({ type: deleteReadingListItemCompleteType });
			}
		} catch (err) {
			dispatch({
				type: deleteReadingListItemErrorType,
				deleteReadingListItemErrorMessage: err.message,
			});
		}

		return metadata;
	},
	deleteBulkEngagementItems:
		(engagementItemIds) => async (dispatch, getState) => {
			if (getState().notifications.bulkEngagementItemsDeletionLoading) {
				return;
			}

			dispatch({ type: requestBulkEngagementItemsDeletionType });

			const url = new URL(
				'api/v1/engagement/engagement-items',
				stringLookup.baseUrl
			);

			try {
				const res = await deleteSecured(url, engagementItemIds);

				if (res.ok) {
					dispatch({
						type: receiveBulkEngagementItemsDeletionSuccessType,
					});
				} else {
					dispatch({
						type: receiveBulkEngagementItemsDeletionErrorType,
					});
				}
			} catch (error) {
				dispatch({ type: receiveBulkEngagementItemsDeletionErrorType });
			}
		},

	getEngagementHistory:
		(id, page = 1, pageSize = 20) =>
		async (dispatch, getState) => {
			if (getState().notifications.engagementHistoryLoading) {
				return;
			}

			dispatch({ type: requestEngagementHistoryType });

			const url = new URL(`/api/v1/engagement/${id}`, stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const res = await fetchSecured(url, 'getEngagementHistory');

				if (res.ok) {
					const resPayload = await res.json();

					dispatch({
						type: receiveEngagementHistoryType,
						payload: resPayload.data,
						notificationHistoryTotal: resPayload.total,
					});
				} else {
					dispatch({
						type: receiveEngagementHistoryErrorType,
						payload: 'Something went wrong!',
					});
				}
			} catch (e) {
				dispatch({
					type: receiveEngagementHistoryErrorType,
					payload: e.message,
				});
			}
		},

	setAddEngagementError: (bool) => async (dispatch) => {
		dispatch({
			type: setAddEngagementErrorType,
			addEngagementNotificationError: bool,
		});
	},

	setAddEngagementSuccess: (bool) => async (dispatch) => {
		dispatch({
			type: setAddEngagementSuccessType,
			addEngagementSuccess: bool,
		});
	},

	setEditEngagementError: (bool) => async (dispatch) => {
		dispatch({
			type: setEditEngagementErrorType,
			editEngagementNotificationError: bool,
		});
	},

	setEditEngagementSuccess: (bool) => async (dispatch) => {
		dispatch({
			type: setEditEngagementSuccessType,
			editEngagementSuccess: bool,
		});
	},

	setDeleteEngagementError: (bool) => async (dispatch) => {
		dispatch({
			type: setDeleteEngagementErrorType,
			deleteEngagementNotificationError: bool,
		});
	},

	setDeleteEngagementSuccess: (bool) => async (dispatch) => {
		dispatch({
			type: setDeleteEngagementSuccessType,
			deleteEngagementSuccess: bool,
		});
	},
};

export const reducer = (state = initialState, action) => {
	if (action.type === SET_RECURRENCE_MENU_STATE_TYPE) {
		return {
			...state,
			recurrenceMenuState: action.recurrenceMenuState,
		};
	}

	if (action.type === requestInAppNotificationsType) {
		return {
			...state,
			inAppNotificationsLoading: true,
			inAppNotificationsError: '',
		};
	}
	if (action.type === receiveInAppNotificationsSuccessType) {
		return {
			...state,
			inAppNotifications: action.payload,
			inAppNotificationsTotal: action.inAppNotificationsTotal,
			inAppNotificationsLoading: false,
			inAppNotificationsError: '',
		};
	}
	if (action.type === receiveInAppNotificationsErrorType) {
		return {
			...state,
			inAppNotificationsLoading: false,
			inAppNotificationsError: action.payload,
		};
	}

	if (action.type === requestEngagementNotificationsType) {
		return {
			...state,
			engagementNotificationsLoading: true,
			engagementNotificationsError: false,
			engagementNotificationsErrorMessage: '',
			engagementNotifications: [],
		};
	}
	if (action.type === receiveEngagementNotificationsType) {
		return {
			...state,
			engagementNotifications: action.payload,
			engagementNotificationsTotal: action.engagementNotificationsTotal,
			engagementNotificationsLoading: false,
		};
	}
	if (action.type === receiveEngagementNotificationsErrorType) {
		return {
			...state,
			engagementNotificationsLoading: false,
			engagementNotificationsError: true,
			engagementNotificationsErrorMessage: action.payload,
		};
	}

	if (action.type === requestEngagementReadingListType) {
		return {
			...state,
			readingListLoading: true,
			readingListError: false,
			readingListErrorMessage: '',
			readingList: [],
		};
	}
	if (action.type === receiveEngagementReadingListType) {
		return {
			...state,
			readingList: action.payload,
			readingListTotal: action.readingListTotal,
			readingListLoading: false,
		};
	}
	if (action.type === receiveEngagementReadingListErrorType) {
		return {
			...state,
			readingListLoading: false,
			readingListError: true,
			readingListErrorMessage: action.payload,
		};
	}

	if (action.type === addEngagementNotificationType) {
		return {
			...state,
			addingEngagementNotification: true,
			addEngagementNotificationError: false,
			addEngagementNotificationErrorMessage: '',
		};
	}
	if (action.type === addEngagementNotificationCompleteType) {
		return {
			...state,
			addingEngagementNotification: false,
			addEngagementSuccessful: true,
		};
	}
	if (action.type === addEngagementNotificationErrorType) {
		return {
			...state,
			addingEngagementNotification: false,
			addEngagementNotificationError: true,
			addEngagementNotificationErrorMessage:
				action.addEngagementNotificationErrorMessage,
		};
	}

	if (action.type === editEngagementNotificationType) {
		return {
			...state,
			editingEngagementNotification: true,
			editEngagementNotificationError: false,
			editEngagementNotificationErrorMessage: '',
		};
	}
	if (action.type === editEngagementNotificationCompleteType) {
		return {
			...state,
			editingEngagementNotification: false,
			editEngagementSuccess: true,
			editedNotification: action.editedNotification,
		};
	}
	if (action.type === editEngagementNotificationErrorType) {
		return {
			...state,
			editingEngagementNotification: false,
			editEngagementNotificationError: true,
			editEngagementNotificationErrorMessage:
				action.editEngagementNotificationErrorMessage,
		};
	}

	if (action.type === deleteEngagementNotificationType) {
		return {
			...state,
			deletingEngagementNotification: true,
			deleteEngagementNotificationError: false,
			deleteEngagementNotificationErrorMessage: '',
		};
	}
	if (action.type === deleteEngagementNotificationCompleteType) {
		return {
			...state,
			deletingEngagementNotification: false,
			deleteEngagementSuccess: true,
		};
	}
	if (action.type === deleteEngagementNotificationErrorType) {
		return {
			...state,
			deletingEngagementNotification: false,
			deleteEngagementNotificationError: true,
			deleteEngagementNotificationErrorMessage:
				action.deleteEngagementNotificationErrorMessage,
		};
	}

	if (action.type === setAddEngagementErrorType) {
		return {
			...state,
			addEngagementNotificationError: action.addEngagementNotificationError,
		};
	}
	if (action.type === setAddEngagementSuccessType) {
		return {
			...state,
			addEngagementSuccess: action.addEngagementSuccess,
		};
	}

	if (action.type === setEditEngagementErrorType) {
		return {
			...state,
			editEngagementNotificationError: action.editEngagementNotificationError,
		};
	}
	if (action.type === setEditEngagementSuccessType) {
		return {
			...state,
			editEngagementSuccess: action.editEngagementSuccess,
		};
	}

	if (action.type === setDeleteEngagementErrorType) {
		return {
			...state,
			deleteEngagementNotificationError:
				action.deleteEngagementNotificationError,
		};
	}
	if (action.type === setDeleteEngagementSuccessType) {
		return {
			...state,
			deleteEngagementSuccess: action.deleteEngagementSuccess,
		};
	}

	if (action.type === deleteReadingListItemType) {
		return {
			...state,
			deletingReadingListItem: true,
			deleteReadingListItemError: false,
			deleteReadingListItemErrorMessage: '',
		};
	}
	if (action.type === deleteReadingListItemCompleteType) {
		return {
			...state,
			deletingReadingListItem: false,
		};
	}
	if (action.type === deleteReadingListItemErrorType) {
		return {
			...state,
			deletingReadingListItem: false,
			deleteReadingListItemError: true,
			deleteReadingListItemErrorMessage:
				action.deleteReadingListItemErrorMessage,
		};
	}

	if (action.type === requestBulkEngagementItemsDeletionType) {
		return {
			...state,
			bulkEngagementItemsDeletionLoading: true,
			bulkEngagementItemsDeletionError: false,
		};
	}

	if (action.type === receiveBulkEngagementItemsDeletionSuccessType) {
		return {
			...state,
			bulkEngagementItemsDeletionLoading: false,
			bulkEngagementItemsDeletionError: false,
		};
	}

	if (action.type === receiveBulkEngagementItemsDeletionErrorType) {
		return {
			...state,
			bulkEngagementItemsDeletionLoading: false,
			bulkEngagementItemsDeletionError: true,
		};
	}

	if (action.type === requestEngagementHistoryType) {
		return {
			...state,
			notificationHistoryLoading: true,
			notificationHistoryError: false,
			notificationHistoryErrorMessage: '',
			// notificationHistory: [],
		};
	}
	if (action.type === receiveEngagementHistoryType) {
		return {
			...state,
			notificationHistory: action.payload,
			notificationHistoryTotal: action.notificationHistoryTotal,
			notificationHistoryLoading: false,
		};
	}
	if (action.type === receiveEngagementHistoryErrorType) {
		return {
			...state,
			notificationHistoryLoading: false,
			notificationHistoryError: true,
			notificationHistoryErrorMessage: action.payload,
		};
	}

	if (action.type === requestEventTypesType) {
		return {
			...state,
			eventTypesLoading: false,
			eventTypesError: false,
		};
	}
	if (action.type === receiveEventTypesType) {
		return {
			...state,
			eventTypesLoading: false,
			eventTypesError: false,
			eventTypesList: action.payload,
		};
	}
	if (action.type === receiveEventTypesErrorType) {
		return {
			...state,
			eventTypesLoading: false,
			eventTypesError: true,
		};
	}

	if (action.type === requestUserEngagementNotificationType) {
		return {
			...state,
			userEngagementNotificationLoading: true,
			userEngagementNotificationError: false,
			userEngagementNotification: null,
		};
	}

	if (action.type === receiveUserEngagementNotificationSuccessType) {
		return {
			...state,
			userEngagementNotification: action.userEngagementNotification,
			userEngagementNotificationLoading: false,
			userEngagementNotificationError: false,
		};
	}

	if (action.type === receiveUserEngagementNotificationErrorType) {
		return {
			...state,
			userEngagementNotification: null,
			userEngagementNotificationLoading: false,
			userEngagementNotificationError: true,
		};
	}

	return state;
};
