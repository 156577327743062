import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/styles/withStyles';

const styles = (theme) => ({
	progress: {
		padding: 0,
		margin: 0,
		width: '100%',
		height: 3,
	},
	dialogContent: {
		[theme.breakpoints.up('md')]: {
			margin: 'auto',
			minWidth: 400,
		},
	},
});

class DeleteConfirmation extends React.Component {
	render() {
		const { classes, open, handleClose, handleDeleteConfirm, loading } = this.props;
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<Fade
					in={loading}
					style={{ transitionDelay: loading ? '300ms' : '0ms' }}
					unmountOnExit
				>
					<LinearProgress className={classes.progress} color='primary' />
				</Fade>
				<DialogTitle id='alert-dialog-title'>Confirm delete</DialogTitle>
				<DialogContent
					classes={{
						root: classes.dialogContent,
					}}
				>
					<DialogContentText id='alert-dialog-description'>
						Are you sure you want to Delete this item?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='default' autoFocus>
						No, Cancel
					</Button>
					<Button
						variant='contained'
						onClick={handleDeleteConfirm}
						color='primary'
						style={{ margin: 8 }}
					>
						Yes, Delete
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles)(DeleteConfirmation);
