import React, { Component } from "react";
import ReactGA from "react-ga";
import stringLookup from "./stringslookup";

// const prodTrackingId = 'UA-188521371-1';
const TrackingId = stringLookup.baseUrl.includes("duma.siasareport.com")
	? "UA-188521371-1"
	: "UA-188521371-2";

ReactGA.initialize(TrackingId);

export const reactGAEvent = (category, action, transport) => {
	ReactGA.event({
		category,
		action,
		transport,
	});
};

const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = (page) => {
		ReactGA.set({
			page,
			...options,
		});
		ReactGA.pageview(page);
	};

	// eslint-disable-next-line
	const HOC = class extends Component {
		componentDidMount() {
			// eslint-disable-next-line
			const page =
				this.props.location.pathname + this.props.location.search;
			trackPage(page);
		}

		componentDidUpdate(prevProps) {
			const currentPage =
				prevProps.location.pathname + prevProps.location.search;
			const nextPage =
				this.props.location.pathname + this.props.location.search;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

export default withTracker;
