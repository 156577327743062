import stringLookup from './../stringslookup';
import { fetchSecured, postSecured, deleteSecured } from './../fetchUtils';

const requestContactsChatsListType = 'REQUEST_CONTACT_CHATS_LIST';
const receiveContactsChatsListType = 'RECEIVE_CONTACT_CHATS_LIST';
const receiveContactsChatsListErrorType = 'RECEIVE_CONTACT_CHATS_LIST_ERROR';
const requestGroupsChatsListType = 'REQUEST_GROUP_CHATS_LIST';
const receiveGroupsChatsListType = 'RECEIVE_GROUP_CHATS_LIST';
const receiveGroupChatsListErrorType = 'RECEIVE_GROUP_CHATS_LIST_ERROR';
const requestContactMessagesType = 'REQUEST_CONTACT_MESSAGES';
const receiveContactMessagesType = 'RECEIVE_CONTACT_MESSAGES';
const receiveContactMessagesErrorType = 'RECEIVE_CONTACT_MESSAGES_ERROR';
const requestGroupMessagesType = 'REQUEST_GROUP_MESSAGES';
const receiveGroupMessagesType = 'RECEIVE_GROUP_MESSAGES';
const receiveGroupMessagesErrorType = 'RECEIVE_GROUP_MESSAGES_ERROR';
const SET_INITIAL_STATE_TYPE = 'SET_INITIAL_STATE_MESSAGING';
const SET_SELECTED_CONTACT_TYPE = 'SET_SELECTED_CONTACT';

const initialMessagesState = {
	contactChatsList: [],
	contactsListsLoading: false,
	groupChatsList: [],
	groupChatsListLoading: false,
	totalGroupChats: 0,
	contactMessages: [],
	contactMessagesLoading: false,
	groupMessages: [],
	groupMessagesLoading: false,
	totalContactChats: 0,
	totalUnreadChats: 0,
	activeChatContact: null,
};

export const messagingActionCreators = {
	getContactChatsList:
		(campaignId, page = 1, pageSize = 10, searchString, unreadBool) =>
		async (dispatch, getState) => {
			if (getState().messagingMessages.contactsListsLoading) {
				return;
			}

			dispatch({ type: requestContactsChatsListType });

			const url = new URL('api/messaging/chat/contactlist', stringLookup.baseUrl);
			url.searchParams.append('campaignId', campaignId);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (unreadBool) {
				url.searchParams.append('unread', unreadBool);
			}

			try {
				const contactListResponse = await fetchSecured(url);

				if (contactListResponse.ok) {
					const contactListRes = await contactListResponse.json();
					dispatch({
						type: receiveContactsChatsListType,
						contactChatsList: contactListRes.items,
						totalContactChats: contactListRes.totalCount,
						totalUnreadChats: contactListRes.unreadCount,
					});
				} else {
					dispatch({ type: receiveContactsChatsListErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveContactsChatsListErrorType });
			}
		},

	getGroupChatsList:
		(campaignId, page = 1, pageSize = 10, searchString) =>
		async (dispatch, getState) => {
			if (getState().messagingMessages.groupChatsListLoading) {
				return;
			}

			dispatch({ type: requestGroupsChatsListType });

			const url = new URL(
				'api/messaging/sms/groupsAggregate',
				stringLookup.baseUrl
			);
			url.searchParams.append('campaignId', campaignId);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const groupChatsListResponse = await fetchSecured(url);

				if (groupChatsListResponse.ok) {
					const groupChatsListRes = await groupChatsListResponse.json();
					dispatch({
						type: receiveGroupsChatsListType,
						groupChatsList: groupChatsListRes.items,
						totalGroupChats: groupChatsListRes.totalCount,
					});
				} else {
					dispatch({ type: receiveGroupChatsListErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveGroupChatsListErrorType });
			}
		},

	getContactMessages:
		(campaignId, contactId, page = 1, pageSize = 100) =>
		async (dispatch, getState) => {
			if (getState().messagingMessages.contactMessagesLoading) {
				return;
			}

			dispatch({ type: requestContactMessagesType });

			const url = new URL('api/messaging/chat', stringLookup.baseUrl);
			url.searchParams.append('campaignId', campaignId);
			url.searchParams.append('contactId', contactId);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const contactMessagesResponse = await fetchSecured(url);

				if (contactMessagesResponse.ok) {
					const contactMessagesRes = await contactMessagesResponse.json();
					dispatch({
						type: receiveContactMessagesType,
						contactMessages: contactMessagesRes,
					});
				} else {
					dispatch({ type: receiveContactMessagesErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveContactMessagesErrorType });
			}
		},

	getGroupMessages:
		(groupId, page = 1, pageSize = 100) =>
		async (dispatch, getState) => {
			if (getState().messagingMessages.groupMessagesLoading) {
				return;
			}

			dispatch({ type: requestGroupMessagesType });

			const url = new URL('api/messaging/sms', stringLookup.baseUrl);
			url.searchParams.append('groupId', groupId.toString());
			url.searchParams.append('page', page.toString());
			url.searchParams.append('pageSize', pageSize.toString());

			try {
				const groupMessagesResponse = await fetchSecured(url);

				if (groupMessagesResponse.ok) {
					const groupMessagesRes = await groupMessagesResponse.json();
					dispatch({
						type: receiveGroupMessagesType,
						groupMessages: groupMessagesRes.items,
					});
				} else {
					dispatch({ type: receiveGroupMessagesErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveGroupMessagesErrorType });
			}
		},

	setInitialStateMessaging: () => async (dispatch, _) => {
		dispatch({ type: SET_INITIAL_STATE_TYPE });
	},

	setActiveChatContact: (contact) => async (dispatch, _) => {
		dispatch({ type: SET_SELECTED_CONTACT_TYPE, activeChatContact: contact });
	},
};

export const reducer = (state, action) => {
	state = state || initialMessagesState;

	if (action.type === requestContactsChatsListType) {
		return {
			...state,
			contactsListsLoading: true,
		};
	}

	if (action.type === receiveContactsChatsListType) {
		return {
			...state,
			contactChatsList: action.contactChatsList,
			totalContactChats: action.totalContactChats,
			totalUnreadChats: action.totalUnreadChats,
			contactsListsLoading: false,
		};
	}

	if (action.type === receiveContactsChatsListErrorType) {
		return {
			...state,
			contactsListsLoading: false,
		};
	}

	if (action.type === requestGroupsChatsListType) {
		return {
			...state,
			groupChatsListLoading: true,
		};
	}

	if (action.type === receiveGroupsChatsListType) {
		return {
			...state,
			groupChatsList: action.groupChatsList,
			totalGroupChats: action.totalGroupChats,
			groupChatsListLoading: false,
		};
	}

	if (action.type === receiveGroupChatsListErrorType) {
		return {
			...state,
			groupChatsListLoading: false,
		};
	}

	if (action.type === requestContactMessagesType) {
		return {
			...state,
			contactMessagesLoading: true,
		};
	}

	if (action.type === receiveContactMessagesType) {
		return {
			...state,
			contactMessages: action.contactMessages,
			contactMessagesLoading: false,
		};
	}

	if (action.type === receiveContactMessagesErrorType) {
		return {
			...state,
			contactMessagesLoading: false,
		};
	}

	if (action.type === requestGroupMessagesType) {
		return {
			...state,
			groupMessagesLoading: true,
		};
	}

	if (action.type === receiveGroupMessagesType) {
		return {
			...state,
			groupMessages: action.groupMessages,
			groupMessagesLoading: false,
		};
	}

	if (action.type === receiveGroupMessagesErrorType) {
		return {
			...state,
			groupMessagesLoading: false,
		};
	}

	if (action.type === SET_SELECTED_CONTACT_TYPE) {
		return {
			...state,
			activeChatContact: action.activeChatContact,
		};
	}

	if (action.type === SET_INITIAL_STATE_TYPE) {
		return {
			...initialMessagesState,
		};
	}

	return state;
};
