import { fetchDefault, fetchSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const RESET_WIKIPEDIA_PROFILE = 'RESET_WIKIPEDIA_PROFILE';

const REQUEST_WIKIPEDIA_PROFILE = 'REQUEST_WIKIPEDIA_PROFILE';
const RECEIVE_WIKIPEDIA_PROFILE_SUCCESS = 'RECEIVE_WIKIPEDIA_PROFILE_SUCCESS';
const RECEIVE_WIKIPEDIA_PROFILE_ERROR = 'RECEIVE_WIKIPEDIA_PROFILE_ERROR';

const initialState = {
	wikipediaProfile: null,
	wikipediaProfileLoading: false,
	wikipediaProfileError: false,
	wikipediaProfileErrorMessage: '',
};

export const wikipediaActionCreators = {
	resetWikipediaProfile: () => (dispatch) => {
		dispatch({ type: RESET_WIKIPEDIA_PROFILE });
	},
	getWikipediaProfile:
		(subtopicName, entityType) => async (dispatch, getState) => {
			if (getState().wikipedia.wikipediaProfileLoading) {
				return;
			}

			dispatch({ type: REQUEST_WIKIPEDIA_PROFILE });

			try {
				const url = new URL(
					`/api/v1/wikipedia/${subtopicName}`,
					stringLookup.baseUrl
				);

				url.searchParams.append('entityType', entityType);

				const res = await fetchDefault(url);
				const jsonRes = await res.json();

				if (res.ok) {
					dispatch({
						type: RECEIVE_WIKIPEDIA_PROFILE_SUCCESS,
						payload: jsonRes,
					});
				} else {
					dispatch({
						type: RECEIVE_WIKIPEDIA_PROFILE_ERROR,
						payload: jsonRes.description,
					});
				}
			} catch (error) {
				dispatch({
					type: RECEIVE_WIKIPEDIA_PROFILE_ERROR,
					payload: 'Something went wrong!',
				});
			}
		},
};

const wikipediaReducer = (state = initialState, action) => {
	if (action.type === RESET_WIKIPEDIA_PROFILE) {
		return {
			...state,
			wikipediaProfile: null,
			wikipediaProfileLoading: false,
			wikipediaProfileError: false,
			wikipediaProfileErrorMessage: '',
		};
	}

	if (action.type === REQUEST_WIKIPEDIA_PROFILE) {
		return {
			...state,
			wikipediaProfileLoading: true,
			wikipediaProfileErrorMessage: '',
			wikipediaProfileError: false,
			wikipediaProfile: null,
		};
	}

	if (action.type === RECEIVE_WIKIPEDIA_PROFILE_SUCCESS) {
		return {
			...state,
			wikipediaProfileLoading: false,
			wikipediaProfile: action.payload,
			wikipediaProfileErrorMessage: '',
			wikipediaProfileError: false,
		};
	}

	if (action.type === RECEIVE_WIKIPEDIA_PROFILE_ERROR) {
		return {
			...state,
			wikipediaProfileLoading: false,
			wikipediaProfileError: true,
			wikipediaProfileErrorMessage: action.payload,
			wikipediaProfile: null,
		};
	}

	return state;
};

export default wikipediaReducer;
