import stringLookup from './../stringslookup';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';
import { fetchDefault, fetchSecured } from '../fetchUtils';

const REQUEST_CATEGORY_TREND_REPORT_TYPE = 'REQUEST_CATEGORY_TREND_REPORT';
const RECEIVE_CATEGORY_TREND_REPORT_TYPE = 'RECEIVE_CATEGORY_TREND_REPORT';
const RECEIVE_CATEGORY_TREND_REPORT_ERROR_TYPE = 'RECEIVE_CATEGORY_TREND_REPORT_ERROR';

const REQUEST_TOPIC_TREND_REPORT_TYPE = 'REQUEST_TOPIC_TREND_REPORT';
const RECEIVE_TOPIC_TREND_REPORT_TYPE = 'RECEIVE_TOPIC_TREND_REPORT';
const RECEIVE_TOPIC_TREND_REPORT_ERROR_TYPE = 'RECEIVE_TOPIC_TREND_REPORT_ERROR';

const REQUEST_SUBTOPIC_TREND_REPORT_TYPE = 'REQUEST_SUBTOPIC_TREND_REPORT';
const RECEIVE_SUBTOPIC_TREND_REPORT_TYPE = 'RECEIVE_SUBTOPIC_TREND_REPORT';
const RECEIVE_SUBTOPIC_TREND_REPORT_ERROR_TYPE = 'RECEIVE_SUBTOPIC_TREND_REPORT_ERROR';

const REQUEST_WORDCLOUD_DATA_TYPE = 'REQUEST_WORDCLOUD_DATA';
const RECEIVE_WORDCLOUD_DATA_TYPE = 'RECEIVE_WORDCLOUD_DATA';
const RECEIVE_WORDCLOUD_DATA_ERROR_TYPE = 'RECEIVE_WORDCLOUD_DATA_ERROR';

const REQUEST_DETAILED_TREND_REPORT_TYPE = 'REQUEST_DETAILED_TREND_REPORT';
const RECEIVE_DETAILED_TREND_REPORT_TYPE = 'RECEIVE_DETAILED_TREND_REPORT';
const RECEIVE_DETAILED_TREND_REPORT_ERROR_TYPE = 'RECEIVE_DETAILED_TREND_REPORT_ERROR';

const REQUEST_TREND_REPORT_SUMMARY_TYPE = 'REQUEST_MAIN_TREND_REPORT_SUMMARY';
const RECEIVE_TREND_REPORT_SUMMARY_TYPE = 'RECEIVE_MAIN_TREND_REPORT_SUMMARY';
const RECEIVE_TREND_REPORT_SUMMARY_ERROR_TYPE = 'RECEIVE_TREND_REPORT_SUMMARY_ERROR';

const REQUEST_VIDEOS_TREND_REPORT_SUMMARY_TYPE = 'REQUEST_VIDEOS_TREND_REPORT_SUMMARY';
const RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_TYPE = 'RECEIVE_VIDEOS_TREND_REPORT_SUMMARY';
const RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_ERROR_TYPE =
	'RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_ERROR';

const REQUEST_TWITTER_TREND_REPORT_SUMMARY_TYPE = 'REQUEST_TWITTER_TREND_REPORT_SUMMARY';
const RECEIVE_TWITTER_TREND_REPORT_SUMMARY_TYPE = 'RECEIVE_TWITTER_TREND_REPORT_SUMMARY';
const RECEIVE_TWITTER_TREND_REPORT_SUMMARY_ERROR_TYPE =
	'RECEIVE_TWITTER_TREND_REPORT_SUMMARY_ERROR';

const REQUEST_DOCUMENT_TREND_SUMMARY_TYPE = 'REQUEST_DOCUMENT_TREND_SUMMARY';
const RECEIVE_DOCUMENT_TREND_SUMMARY_TYPE = 'RECEIVE_DOCUMENT_TREND_SUMMARY';
const RECEIVE_DOCUMENT_TREND_SUMMARY_ERROR_TYPE = 'RECEIVE_DOCUMENT_TREND_SUMMARY_ERROR';

const REQUEST_TREND_REPORT_DOWNLOAD_TYPE = 'REQUEST_TREND_REPORT_DOWNLOAD';
const RECEIVE_TREND_REPORT_DOWNLOAD_TYPE = 'RECEIVE_MAIN_TREND_REPORT_DOWNLOAD';
const RECEIVE_TREND_REPORT_DOWNLOAD_ERROR_TYPE = 'RECEIVE_TREND_REPORT_DOWNLOAD_ERROR';

const REQUEST_WEBPAGE_DETAILS_TYPE = 'REQUEST_WEBPAGE_DETAILS';
const RECEIVE_WEBPAGE_DETAILS_TYPE = 'RECEIVE_WEBPAGE_DETAILS';
const RECEIVE_WEBPAGE_DETAILS_ERROR_TYPE = 'RECEIVE_WEBPAGE_DETAILS_ERROR';

const REQUEST_MATCHED_WORDS_TYPE = 'REQUEST_MATCHED_WORDS';
const RECEIVE_MATCHED_WORDS_TYPE = 'RECEIVE_MATCHED_WORDS';
const RECEIVE_MATCHED_WORDS_ERROR_TYPE = 'RECEIVE_MATCHED_WORDS_ERROR';

const REQUEST_TREND_IMAGES_TYPE = 'REQUEST_TREND_IMAGES';
const RECEIVE_TREND_IMAGES_TYPE = 'RECEIVE_IMAGES_SUMMARY';
const RECEIVE_TREND_IMAGES_ERROR_TYPE = 'RECEIVE_TREND_IMAGES_ERROR';

const REQUEST_SUBTOPIC_DETAILS_TYPE = 'REQUEST_SUBTOPIC_DETAILS';
const RECEIVE_SUBTOPIC_DETAILS_TYPE = 'RECEIVE_SUBTOPIC_DETAILS';
const RECEIVE_SUBTOPIC_DETAILS_ERROR_TYPE = 'RECEIVE_SUBTOPIC_DETAILS_ERROR';

const REQUEST_IMAGES_TYPE = 'REQUEST_IMAGES';
const RECEIVE_IMAGES_TYPE = 'RECEIVE_IMAGES';
const RECEIVE_IMAGES_ERROR_TYPE = 'RECEIVE_IMAGES_ERROR';

const REQUEST_DOCUMENT_COUNT_REPORT_TYPE = 'REQUEST_DOCUMENT_COUNT_REPORT';
const RECEIVE_DOCUMENT_COUNT_REPORT_TYPE = 'RECEIVE_DOCUMENT_COUNT_REPORT';
const RECEIVE_DOCUMENT_COUNT_REPORT_ERROR_TYPE = 'RECEIVE_DOCUMENT_COUNT_REPORT_ERROR';

const REQUEST_DOMAIN_COUNT_REPORT_TYPE = 'REQUEST_DOMAIN_COUNT_REPORT';
const RECEIVE_DOMAIN_COUNT_REPORT_TYPE = 'RECEIVE_DOMAIN_COUNT_REPORT';
const RECEIVE_DOMAIN_COUNT_REPORT_ERROR_TYPE = 'RECEIVE_DOMAIN_COUNT_REPORT_ERROR';

const REQUEST_SENTIMENT_SCORE_REPORT_TYPE = 'REQUEST_SENTIMENT_SCORE_REPORT';
const RECEIVE_SENTIMENT_SCORE_REPORT_TYPE = 'RECEIVE_SENTIMENT_SCORE_REPORT';
const RECEIVE_SENTIMENT_SCORE_REPORT_ERROR_TYPE = 'RECEIVE_SENTIMENT_SCORE_REPORT_ERROR';

const REQUEST_EMOTIONAL_SENTIMENT_REPORT_TYPE = 'REQUEST_EMOTIONAL_SENTIMENT_REPORT';
const RECEIVE_EMOTIONAL_SENTIMENT_REPORT_TYPE = 'RECEIVE_EMOTIONAL_SENTIMENT_REPORT';
const RECEIVE_EMOTIONAL_SENTIMENT_REPORT_ERROR_TYPE =
	'RECEIVE_EMOTIONAL_SENTIMENT_REPORT_ERROR';

const REQUEST_LANGUAGE_USE_REPORT_TYPE = 'REQUEST_LANGUAGE_USE_REPORT';
const RECEIVE_LANGUAGE_USE_REPORT_TYPE = 'RECEIVE_LANGUAGE_USE_REPORT';
const RECEIVE_LANGUAGE_USE_REPORT_ERROR_TYPE = 'RECEIVE_LANGUAGE_USE_REPORT_ERROR';

const REQUEST_NARRATIVE_REPORT_TYPE = 'REQUEST_NARRATIVE_REPORT';
const RECEIVE_NARRATIVE_REPORT_TYPE = 'RECEIVE_NARRATIVE_REPORT';
const RECEIVE_NARRATIVE_REPORT_ERROR_TYPE = 'RECEIVE_NARRATIVE_REPORT_ERROR';

const REQUEST_IMPRESSIONS_REPORT_TYPE = 'REQUEST_IMPRESSIONS_REPORT';
const RECEIVE_IMPRESSIONS_REPORT_TYPE = 'RECEIVE_IMPRESSIONS_REPORT';
const RECEIVE_IMPRESSIONS_REPORT_ERROR_TYPE = 'RECEIVE_IMPRESSIONS_REPORT_ERROR';

const REQUEST_MEDIA_COUNT_SUMMARY_TYPE = 'REQUEST_MEDIA_COUNT_SUMMARY';
const RECEIVE_MEDIA_COUNT_SUMMARY_TYPE = 'RECEIVE_MEDIA_COUNT_SUMMARY';
const RECEIVE_MEDIA_COUNT_SUMMARY_ERROR_TYPE = 'RECEIVE_MEDIA_COUNT_SUMMARY_ERROR';

const REQUEST_ENGAGEMENT_COUNT_SUMMARY_TYPE = 'REQUEST_ENGAGEMENT_COUNT_SUMMARY';
const RECEIVE_ENGAGEMENT_COUNT_SUMMARY_TYPE = 'RECEIVE_ENGAGEMENT_COUNT_SUMMARY';
const RECEIVE_ENGAGEMENT_COUNT_SUMMARY_ERROR_TYPE =
	'RECEIVE_ENGAGEMENT_COUNT_SUMMARY_ERROR';

const REQUEST_ENTITY_COUNT_SUMMARY_TYPE = 'REQUEST_ENTITY_COUNT_SUMMARY';
const RECEIVE_ENTITY_COUNT_SUMMARY_TYPE = 'RECEIVE_ENTITY_COUNT_SUMMARY';
const RECEIVE_ENTITY_COUNT_SUMMARY_ERROR_TYPE = 'RECEIVE_ENTITY_COUNT_SUMMARY_ERROR';

const REQUEST_NLP_COUNT_SUMMARY_TYPE = 'REQUEST_NLP_COUNT_SUMMARY';
const RECEIVE_NLP_COUNT_SUMMARY_TYPE = 'RECEIVE_NLP_COUNT_SUMMARY';
const RECEIVE_NLP_COUNT_SUMMARY_ERROR_TYPE = 'RECEIVE_NLP_COUNT_SUMMARY_ERROR';

const SET_CUSTOM_DOMAIN_COLORS_TYPE = 'SET_CUSTOM_DOMAIN_COLORS';
const SET_CUSTOM_SUBTOPIC_COLORS_TYPE = 'SET_CUSTOM_SUBTOPIC_COLORS';
const SET_CUSTOM_TOPIC_COLORS_TYPE = 'SET_CUSTOM_TOPIC_COLORS';
const SET_CUSTOM_CATEGORY_COLORS_TYPE = 'SET_CUSTOM_CATEGORY_COLORS';
const SET_CUSTOM_SCORE_TYPE_COLORS_TYPE = 'SET_CUSTOM_SCORE_TYPE_COLORS';
const SET_CUSTOM_IMPRESSION_COLORS_TYPE = 'SET_CUSTOM_IMPRESSION_COLORS';

const SET_INITIAL_STATE_TYPE = 'SET_INITIAL_STATE';

const initialState = {
	mainTrendReport: null,
	mainTrendReportLoading: false,
	wordCloudData: null,
	wordCloudDataLoading: false,
	detailedTrendReport: null,
	detailedTrendReportLoading: false,
	summary: null,
	summaryTotal: 0,
	summaryData: [],
	summaryLoading: false,
	summaryError: false,
	videosSummary: null,
	videosSummaryData: [],
	videosSummaryLoading: false,
	videosSummaryError: false,
	twitterSummary: null,
	twitterSummaryData: [],
	twitterSummaryLoading: false,
	twitterSummaryError: false,
	trendsDataBlob: null,
	trendsDownloadError: false,
	webpageDetails: null,
	matchedWords: [],
	matchedWordsLoading: false,
	matchedWordsError: false,
	topicTrendReport: null,
	topicTrendReportLoading: false,
	categoryTrendReport: null,
	categoryTrendReportLoading: false,
	imagesData: [],
	imagesTotal: 0,
	imagesLoading: false,
	imagesError: false,
	subtopicDetailsLoading: false,
	subtopicDetails: null,
	subtopicDetailsError: false,
	documentSummary: null,
	documentSummaryLoading: false,
	documentSummaryError: false,
	documentCountReport: null,
	documentCountReportLoading: false,
	documentCountReportError: false,
	domainCountReport: null,
	domainCountReportLoading: false,
	domainCountReportError: false,
	sentimentScoreReport: null,
	sentimentScoreReportLoading: false,
	sentimentScoreReportError: false,
	emotionalSentimentReport: null,
	emotionalSentimentReportLoading: false,
	emotionalSentimentReportError: false,
	languageUseReport: null,
	languageUseReportLoading: false,
	languageUseReportError: false,
	narrativeReport: null,
	narrativeReportLoading: false,
	narrativeReportError: false,
	impressionsReport: null,
	impressionsReportLoading: false,
	impressionsReportError: false,
	customDomainColors: {},
	customSubtopicColors: {},
	customTopicColors: {},
	customCategoryColors: {},
	customImpressionColors: {},
	webpageDetailsLoading: false,
	customScoreTypeColors: {},
	mediaCountSummary: null,
	mediaSummaryLoading: false,
	mediaSummaryError: false,
	engagementCountSummary: null,
	engagementSummaryLoading: false,
	engagementSummaryError: false,
	entityCountSummary: null,
	entitySummaryLoading: false,
	entitySummaryError: false,
	NLPCountSummary: null,
	NLPSummaryLoading: false,
	NLPSummaryError: false,
};

const appendReportFilters = (
	url,
	mediaSourceType,
	categoryFilters,
	topicFilters,
	subtopicFilters,
	keywordFilters,
	startDate,
	endDate,
	domainFilter,
	searchString,
	NLPFilter,
	relatedSubtopicFilter
) => {
	if (mediaSourceType == 'images') {
		url.searchParams.append('mediaType', 'all');
	} else {
		url.searchParams.append('mediaType', mediaSourceType);
	}

	categoryFilters.forEach((c) => {
		url.searchParams.append('categories', c);
	});

	topicFilters.forEach((t) => {
		url.searchParams.append('topics', t);
	});

	subtopicFilters.forEach((s) => {
		url.searchParams.append('subtopics', s);
	});

	keywordFilters.forEach((k) => {
		url.searchParams.append('keywords', k);
	});

	if (startDate) {
		url.searchParams.append('startDate', startDate.toISOString());
	}
	if (endDate) {
		url.searchParams.append('endDate', new Date(endDate).toISOString());
	}

	if (domainFilter) {
		url.searchParams.append('websites', domainFilter);
	}

	if (NLPFilter) {
		url.searchParams.append('nlptexts', NLPFilter);
	}

	if (relatedSubtopicFilter) {
		url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
	}

	if (searchString && searchString.length > 0) {
		url.searchParams.append('searchString', searchString);
	}
};

export const trendReportActionCreators = {
	loadCategoryTrendReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			domainFilter,
			searchString,
			subtopicOccurencesLimit = 20
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_CATEGORY_TREND_REPORT_TYPE });
			// setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/trends/category', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString
			);

			url.searchParams.append('subtopicOccurencesLimit', subtopicOccurencesLimit);

			try {
				const reportResponse = await fetchSecured(url, 'category-trends-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_CATEGORY_TREND_REPORT_TYPE,
						categoryTrendReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_CATEGORY_TREND_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_CATEGORY_TREND_REPORT_ERROR_TYPE });
			}

			// unsetGlobalLoad(dispatch);
		},

	loadTopicTrendReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			domainFilter,
			searchString,
			subtopicOccurencesLimit = 20
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_TOPIC_TREND_REPORT_TYPE });
			// setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/trends/topic', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString
			);

			url.searchParams.append('subtopicOccurencesLimit', subtopicOccurencesLimit);

			try {
				const reportResponse = await fetchSecured(url, 'topic-trends-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_TOPIC_TREND_REPORT_TYPE,
						topicTrendReport: data,
					});
				} else {
					dispatch({ type: RECEIVE_TOPIC_TREND_REPORT_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_TOPIC_TREND_REPORT_ERROR_TYPE });
			}

			// unsetGlobalLoad(dispatch);
		},

	loadMainTrendReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString,
			subtopicOccurencesLimit = 20
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_SUBTOPIC_TREND_REPORT_TYPE });
			// setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/trends/subtopic', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			url.searchParams.append('subtopicOccurencesLimit', subtopicOccurencesLimit);

			try {
				const reportResponse = await fetchSecured(url, 'subtopic-trends-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_SUBTOPIC_TREND_REPORT_TYPE,
						mainTrendReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_SUBTOPIC_TREND_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SUBTOPIC_TREND_REPORT_ERROR_TYPE });
			}

			// unsetGlobalLoad(dispatch);
		},

	loadWordCloudData:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			entityTypeFilters = [],
			searchString,
			useDates = true,
			domainFilter,
			useCache
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_WORDCLOUD_DATA_TYPE });

			const url = new URL('/api/analytics/trends/wordcloud', stringLookup.baseUrl);

			entityTypeFilters.forEach((e) => {
				url.searchParams.append('entityTypes', e);
			});

			if (searchString && searchString.length > 0) {
				url.searchParams.append('searchString', searchString);
			}

			url.searchParams.append('useDates', useDates);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				null,
				NLPFilter,
				relatedSubtopicFilter
			);

			if (domainFilter) {
				url.searchParams.append('websites', domainFilter);
			}

			if (useCache) {
				url.searchParams.append('useCache', useCache);
			}

			try {
				const reportResponse = await fetchDefault(url, 'wordcloud-trends-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_WORDCLOUD_DATA_TYPE,
						wordCloudData: data,
					});
				} else {
					dispatch({
						type: RECEIVE_WORDCLOUD_DATA_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_WORDCLOUD_DATA_ERROR_TYPE });
			}
		},

	loadDetailedTrendReport:
		(
			mediaSourceType,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_DETAILED_TREND_REPORT_TYPE });
			// setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/trends/detailed', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicsFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(url, 'detailed-trends-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_DETAILED_TREND_REPORT_TYPE,
						detailedTrendReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_DETAILED_TREND_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_DETAILED_TREND_REPORT_ERROR_TYPE });
			}

			// unsetGlobalLoad(dispatch);
		},

	loadTrendSummary:
		(
			mediaSourceType,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			page,
			pageSize,
			searchString,
			orderBy,
			sortDirection,
			domainFilter,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			useDates = false,
			preferTitle
		) =>
		async (dispatch, getState) => {
			if (getState().trendsReport.summaryLoading) {
				return;
			}

			dispatch({ type: REQUEST_TREND_REPORT_SUMMARY_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL('/api/analytics/trends/summary', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicsFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter
			);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			url.searchParams.append('useDates', useDates);

			if (preferTitle) {
				url.searchParams.append('preferTitle', preferTitle);
			}

			try {
				const summaryResponse = await fetchDefault(url, 'default-trend-summary');
				if (summaryResponse.ok) {
					const summary = await summaryResponse.json();

					dispatch({
						type: RECEIVE_TREND_REPORT_SUMMARY_TYPE,
						summary: summary,
						summaryTotal: summary.total,
						summaryData: summary.data,
					});
				} else {
					dispatch({ type: RECEIVE_TREND_REPORT_SUMMARY_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_TREND_REPORT_SUMMARY_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadVideosTrendSummary:
		(
			mediaSourceType,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			page,
			pageSize,
			searchString,
			orderBy,
			sortDirection,
			domainFilter,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			useDates = false,
			preferTitle,
			useCache = false
		) =>
		async (dispatch, getState) => {
			if (getState().trendsReport.videosSummaryLoading) {
				return;
			}

			dispatch({ type: REQUEST_VIDEOS_TREND_REPORT_SUMMARY_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL('/api/analytics/trends/summary', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicsFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter
			);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			url.searchParams.append('useDates', useDates);

			if (preferTitle) {
				url.searchParams.append('preferTitle', preferTitle);
			}

			if (useCache) {
				url.searchParams.append('useCache', useCache);
			}

			try {
				const summaryResponse = await fetchDefault(url, 'videos-trend-summary');
				if (summaryResponse.ok) {
					const summary = await summaryResponse.json();
					dispatch({
						type: RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_TYPE,
						videosSummary: summary,
						videosSummaryData: summary.data,
					});
				} else {
					dispatch({
						type: RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({
					type: RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_ERROR_TYPE,
				});
			}

			unsetGlobalLoad(dispatch);
		},
	loadTwitterTrendSummary:
		(
			mediaSourceType,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			page,
			pageSize,
			searchString,
			orderBy,
			sortDirection,
			domainFilter,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			useDates = false,
			preferTitle,
			useCache = false
		) =>
		async (dispatch, getState) => {
			if (getState().trendsReport.twitterSummaryLoading) {
				return;
			}

			dispatch({ type: REQUEST_TWITTER_TREND_REPORT_SUMMARY_TYPE });
			setGlobalLoad(dispatch);
			const url = new URL('/api/analytics/trends/summary', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicsFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter
			);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			url.searchParams.append('useDates', useDates);

			if (preferTitle) {
				url.searchParams.append('preferTitle', preferTitle);
			}

			if (useCache) {
				url.searchParams.append('useCache', useCache);
			}

			try {
				const summaryResponse = await fetchDefault(url, 'twitter-trend-summary');
				if (summaryResponse.ok) {
					const summary = await summaryResponse.json();
					dispatch({
						type: RECEIVE_TWITTER_TREND_REPORT_SUMMARY_TYPE,
						twitterSummary: summary,
						twitterSummaryData: summary.data,
					});
				} else {
					dispatch({
						type: RECEIVE_TWITTER_TREND_REPORT_SUMMARY_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({
					type: RECEIVE_TWITTER_TREND_REPORT_SUMMARY_ERROR_TYPE,
				});
			}

			unsetGlobalLoad(dispatch);
		},

	loadDocumentTrendSummary: (webpageId) => async (dispatch, getState) => {
		if (getState().trendsReport.documentSummaryLoading) {
			return;
		}

		dispatch({ type: REQUEST_DOCUMENT_TREND_SUMMARY_TYPE });
		// setGlobalLoad(dispatch);
		const url = new URL('/api/analytics/trends/elasticsearch', stringLookup.baseUrl);
		url.searchParams.append('id', webpageId);

		try {
			const documentSummaryResponse = await fetchDefault(
				url,
				'document-trend-summary'
			);
			if (documentSummaryResponse.ok) {
				const documentSummary = await documentSummaryResponse.json();

				dispatch({
					type: RECEIVE_DOCUMENT_TREND_SUMMARY_TYPE,
					documentSummary: documentSummary,
				});
			} else {
				dispatch({ type: RECEIVE_DOCUMENT_TREND_SUMMARY_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_DOCUMENT_TREND_SUMMARY_ERROR_TYPE });
		}

		// unsetGlobalLoad(dispatch);
	},

	downloadTrendSummary:
		(
			mediaSourceType,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			searchString,
			orderBy,
			sortDirection,
			domainFilter,
			categoryFilters
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_TREND_REPORT_DOWNLOAD_TYPE });
			// setGlobalLoad(dispatch);
			const url = new URL('/api/analytics/trends/dump', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicsFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter
			);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			try {
				const downloadResponse = await fetchSecured(url, 'downloadTrendSummary');
				if (downloadResponse.ok) {
					const downloadBlob = await downloadResponse.blob();
					dispatch({
						type: RECEIVE_TREND_REPORT_DOWNLOAD_TYPE,
						trendsDataBlob: downloadBlob,
					});
				} else {
					dispatch({
						type: RECEIVE_TREND_REPORT_DOWNLOAD_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_TREND_REPORT_DOWNLOAD_ERROR_TYPE });
			}

			// unsetGlobalLoad(dispatch);
		},

	setInitialState: () => async (dispatch, _) => {
		dispatch({ type: SET_INITIAL_STATE_TYPE });
	},

	getWebpageDetails: (pageId) => async (dispatch, getState) => {
		dispatch({ type: REQUEST_WEBPAGE_DETAILS_TYPE });
		// setGlobalLoad(dispatch);

		const url = new URL('/api/wordlist/webpagedetails', stringLookup.baseUrl);
		url.searchParams.append('webPageId', pageId);

		try {
			const webpageResponse = await fetchSecured(url, 'getWebpageDetails');
			if (webpageResponse.ok) {
				const webpageDetailsPayload = await webpageResponse.json();
				dispatch({
					type: RECEIVE_WEBPAGE_DETAILS_TYPE,
					webpageDetails: webpageDetailsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_WEBPAGE_DETAILS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_WEBPAGE_DETAILS_ERROR_TYPE });
		}

		// unsetGlobalLoad(dispatch);
	},

	getMatchedWords: (pageId) => async (dispatch, getState) => {
		dispatch({ type: REQUEST_MATCHED_WORDS_TYPE });
		// setGlobalLoad(dispatch);

		const url = new URL('/api/wordlist/matchedwords', stringLookup.baseUrl);
		url.searchParams.append('webPageId', pageId);

		try {
			const matchedWordsResponse = await fetchSecured(url, 'getMatchedWords');
			if (matchedWordsResponse.ok) {
				const matchedWordsPayload = await matchedWordsResponse.json();
				dispatch({
					type: RECEIVE_MATCHED_WORDS_TYPE,
					matchedWords: matchedWordsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_MATCHED_WORDS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_MATCHED_WORDS_ERROR_TYPE });
		}

		// unsetGlobalLoad(dispatch);
	},

	loadTrendImages:
		(
			mediaSourceType,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			page,
			pageSize,
			searchString,
			orderBy,
			sortDirection,
			domainFilter,
			categoryFilters
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_TREND_IMAGES_TYPE });
			// setGlobalLoad(dispatch);
			const url = new URL(
				'/api/analytics/trends/subtopic-trends',
				stringLookup.baseUrl
			);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicsFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter
			);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			try {
				const imagesResponse = await fetchDefault(url, 'trend-images');
				if (imagesResponse.ok) {
					const images = await imagesResponse.json();
					dispatch({
						type: RECEIVE_TREND_IMAGES_TYPE,
						imagesData: images.data,
						imagesTotal: images.total,
					});
				} else {
					dispatch({ type: RECEIVE_TREND_IMAGES_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_TREND_IMAGES_ERROR_TYPE });
			}

			// unsetGlobalLoad(dispatch);
		},

	getSubtopicDetails: (subtopic) => async (dispatch, getState) => {
		dispatch({ type: REQUEST_SUBTOPIC_DETAILS_TYPE });
		// setGlobalLoad(dispatch);

		const url = new URL(
			'/api/analytics/trends/subtopic-activity',
			stringLookup.baseUrl
		);

		url.searchParams.append('subtopics', subtopic);

		try {
			// loosened the restriction to access subtopic details even when the user is logged out
			// const subtopicDetailsResponse = await fetchSecured(url);

			const subtopicDetailsResponse = await fetchDefault(url, 'getSubtopicDetails');
			if (subtopicDetailsResponse.ok) {
				const subtopicDetailsPayload = await subtopicDetailsResponse.json();
				dispatch({
					type: RECEIVE_SUBTOPIC_DETAILS_TYPE,
					subtopicDetails: subtopicDetailsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_SUBTOPIC_DETAILS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_SUBTOPIC_DETAILS_ERROR_TYPE });
		}

		// unsetGlobalLoad(dispatch);
	},

	loadImages:
		(
			mediaSourceType,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			page,
			pageSize,
			searchString,
			orderBy,
			sortDirection,
			domainFilter,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			useDates = false,
			preferTitle,
			isGeotagged
		) =>
		async (dispatch, getState) => {
			if (getState().trendsReport.imagesLoading) {
				return;
			}

			dispatch({ type: REQUEST_IMAGES_TYPE });
			const url = new URL('/api/analytics/trends/images', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicsFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter
			);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (useDates) {
				url.searchParams.append('useDates', useDates);
			}

			if (preferTitle) {
				url.searchParams.append('preferTitle', preferTitle);
			}

			if (isGeotagged) {
				url.searchParams.append('IsGeoTagged', isGeotagged);
			}

			try {
				const imagesResponse = await fetchDefault(url, 'loadImages');
				if (imagesResponse.ok) {
					const images = await imagesResponse.json();

					dispatch({
						type: RECEIVE_IMAGES_TYPE,
						imagesData: images.data,
						imagesTotal: images.total,
					});
				} else {
					dispatch({ type: RECEIVE_IMAGES_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_IMAGES_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadDocumentCountReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString,
			occurencesLimit = 20,
			reportKind
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_DOCUMENT_COUNT_REPORT_TYPE });

			const url = new URL('/api/analytics/document-count', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			url.searchParams.append('occurencesLimit', occurencesLimit);
			url.searchParams.append('reportKind', reportKind);

			try {
				const reportResponse = await fetchSecured(url, 'document-count-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_DOCUMENT_COUNT_REPORT_TYPE,
						documentCountReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_DOCUMENT_COUNT_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_DOCUMENT_COUNT_REPORT_ERROR_TYPE });
			}
		},

	loadDomainCountReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString,
			occurencesLimit = 20,
			reportKind
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_DOMAIN_COUNT_REPORT_TYPE });

			const url = new URL('/api/analytics/domain-count', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			url.searchParams.append('occurencesLimit', occurencesLimit);
			url.searchParams.append('reportKind', reportKind);

			try {
				const reportResponse = await fetchSecured(url, 'domain-count-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_DOMAIN_COUNT_REPORT_TYPE,
						domainCountReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_DOMAIN_COUNT_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_DOMAIN_COUNT_REPORT_ERROR_TYPE });
			}
		},

	loadSentimentScoreReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString,
			occurencesLimit = 20
			// reportKind
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_SENTIMENT_SCORE_REPORT_TYPE });

			const url = new URL('/api/analytics/sentiment', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			url.searchParams.append('occurencesLimit', occurencesLimit);
			// url.searchParams.append('reportKind', reportKind);

			try {
				const reportResponse = await fetchSecured(url, 'sentiment-score-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_SENTIMENT_SCORE_REPORT_TYPE,
						sentimentScoreReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_SENTIMENT_SCORE_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SENTIMENT_SCORE_REPORT_ERROR_TYPE });
			}
		},

	loadEmotionalSentimentReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_EMOTIONAL_SENTIMENT_REPORT_TYPE });

			const url = new URL(
				'/api/analytics/emotional-sentiment',
				stringLookup.baseUrl
			);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(
					url,
					'emotional-sentiment-report'
				);
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_EMOTIONAL_SENTIMENT_REPORT_TYPE,
						emotionalSentimentReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_EMOTIONAL_SENTIMENT_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_EMOTIONAL_SENTIMENT_REPORT_ERROR_TYPE });
			}
		},

	loadLanguageUseReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_LANGUAGE_USE_REPORT_TYPE });

			const url = new URL('/api/analytics/language-use', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(url, 'language-use-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_LANGUAGE_USE_REPORT_TYPE,
						languageUseReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_LANGUAGE_USE_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_LANGUAGE_USE_REPORT_ERROR_TYPE });
			}
		},

	loadNarrativeReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_NARRATIVE_REPORT_TYPE });

			const url = new URL(
				'/api/analytics/narrative-distance',
				stringLookup.baseUrl
			);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(url, 'narrative-report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_NARRATIVE_REPORT_TYPE,
						narrativeReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_NARRATIVE_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_NARRATIVE_REPORT_ERROR_TYPE });
			}
		},

	loadImpressionsReport:
		(
			mediaSourceType,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			NLPFilter,
			relatedSubtopicFilter,
			domainFilter,
			searchString,
			occurencesLimit = 20,
			reportKind
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_IMPRESSIONS_REPORT_TYPE });

			const url = new URL('/api/analytics/impression-counts', stringLookup.baseUrl);

			appendReportFilters(
				url,
				mediaSourceType,
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			url.searchParams.append('occurencesLimit', occurencesLimit);
			url.searchParams.append('reportKind', reportKind);

			try {
				const reportResponse = await fetchSecured(
					url,
					'impression-counts-report'
				);
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_IMPRESSIONS_REPORT_TYPE,
						impressionsReport: data,
					});
				} else {
					dispatch({
						type: RECEIVE_IMPRESSIONS_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_IMPRESSIONS_REPORT_ERROR_TYPE });
			}
		},

	setCustomDomainColors: (customDomainColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_DOMAIN_COLORS_TYPE,
			customDomainColors,
		});
	},

	setCustomSubtopicColors: (customSubtopicColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_SUBTOPIC_COLORS_TYPE,
			customSubtopicColors,
		});
	},

	setCustomTopicColors: (customTopicColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_TOPIC_COLORS_TYPE,
			customTopicColors,
		});
	},

	setCustomCategoryColors: (customCategoryColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_CATEGORY_COLORS_TYPE,
			customCategoryColors,
		});
	},

	setCustomScoreTypeColors: (customScoreTypeColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_SCORE_TYPE_COLORS_TYPE,
			customScoreTypeColors,
		});
	},

	setCustomImpressionColors: (customImpressionColors) => async (dispatch) => {
		dispatch({
			type: SET_CUSTOM_IMPRESSION_COLORS_TYPE,
			customImpressionColors,
		});
	},

	getMediaCountSummary:
		(
			categoryFilters,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			domainFilter,
			searchString,
			NLPFilter,
			relatedSubtopicFilter
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_MEDIA_COUNT_SUMMARY_TYPE });

			const url = new URL('/api/analytics/media-summary', stringLookup.baseUrl);

			appendReportFilters(
				url,
				'all',
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(url, 'media-count-summary');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_MEDIA_COUNT_SUMMARY_TYPE,
						mediaCountSummary: data,
					});
				} else {
					dispatch({
						type: RECEIVE_MEDIA_COUNT_SUMMARY_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_MEDIA_COUNT_SUMMARY_ERROR_TYPE });
			}
		},

	getEngagementCountSummary:
		(
			categoryFilters,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			domainFilter,
			searchString,
			NLPFilter,
			relatedSubtopicFilter
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_ENGAGEMENT_COUNT_SUMMARY_TYPE });

			const url = new URL(
				'/api/analytics/impression-summary',
				stringLookup.baseUrl
			);

			appendReportFilters(
				url,
				'all',
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(
					url,
					'engagement-count-summary'
				);
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_ENGAGEMENT_COUNT_SUMMARY_TYPE,
						engagementCountSummary: data,
					});
				} else {
					dispatch({
						type: RECEIVE_ENGAGEMENT_COUNT_SUMMARY_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_ENGAGEMENT_COUNT_SUMMARY_ERROR_TYPE });
			}
		},

	getEntityCountSummary:
		(
			categoryFilters,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			domainFilter,
			searchString,
			NLPFilter,
			relatedSubtopicFilter
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_ENTITY_COUNT_SUMMARY_TYPE });

			const url = new URL('/api/analytics/entity-summary', stringLookup.baseUrl);

			appendReportFilters(
				url,
				'all',
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(url, 'entity-count-summary');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_ENTITY_COUNT_SUMMARY_TYPE,
						entityCountSummary: data,
					});
				} else {
					dispatch({
						type: RECEIVE_ENTITY_COUNT_SUMMARY_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_ENTITY_COUNT_SUMMARY_ERROR_TYPE });
			}
		},

	getNLPCountSummary:
		(
			categoryFilters,
			topicFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			domainFilter,
			searchString,
			NLPFilter,
			relatedSubtopicFilter
		) =>
		async (dispatch, getState) => {
			dispatch({ type: REQUEST_NLP_COUNT_SUMMARY_TYPE });

			const url = new URL('/api/analytics/nlp-summary', stringLookup.baseUrl);

			appendReportFilters(
				url,
				'all',
				categoryFilters,
				topicFilters,
				subtopicFilters,
				keywordFilters,
				startDate,
				endDate,
				domainFilter,
				searchString,
				NLPFilter,
				relatedSubtopicFilter
			);

			try {
				const reportResponse = await fetchSecured(url, 'nlp-count-summary');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_NLP_COUNT_SUMMARY_TYPE,
						NLPCountSummary: data,
					});
				} else {
					dispatch({
						type: RECEIVE_NLP_COUNT_SUMMARY_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_NLP_COUNT_SUMMARY_ERROR_TYPE });
			}
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === REQUEST_CATEGORY_TREND_REPORT_TYPE) {
		return {
			...state,
			categoryTrendReportLoading: true,
		};
	}

	if (action.type === RECEIVE_CATEGORY_TREND_REPORT_TYPE) {
		return {
			...state,
			categoryTrendReport: action.categoryTrendReport,
			categoryTrendReportLoading: false,
		};
	}

	if (action.type === RECEIVE_CATEGORY_TREND_REPORT_ERROR_TYPE) {
		return {
			...state,
			categoryTrendReportLoading: false,
		};
	}

	if (action.type === REQUEST_TOPIC_TREND_REPORT_TYPE) {
		return {
			...state,
			topicTrendReportLoading: true,
		};
	}

	if (action.type === RECEIVE_TOPIC_TREND_REPORT_TYPE) {
		return {
			...state,
			topicTrendReport: action.topicTrendReport,
			topicTrendReportLoading: false,
		};
	}

	if (action.type === RECEIVE_TOPIC_TREND_REPORT_ERROR_TYPE) {
		return {
			...state,
			topicTrendReportLoading: false,
		};
	}

	if (action.type === REQUEST_SUBTOPIC_TREND_REPORT_TYPE) {
		return {
			...state,
			mainTrendReportLoading: true,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_TREND_REPORT_TYPE) {
		return {
			...state,
			mainTrendReport: action.mainTrendReport,
			mainTrendReportLoading: false,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_TREND_REPORT_ERROR_TYPE) {
		return {
			...state,
			mainTrendReportLoading: false,
		};
	}

	if (action.type === REQUEST_WORDCLOUD_DATA_TYPE) {
		return {
			...state,
			wordCloudDataLoading: true,
		};
	}

	if (action.type === RECEIVE_WORDCLOUD_DATA_TYPE) {
		return {
			...state,
			wordCloudData: action.wordCloudData,
			wordCloudDataLoading: false,
		};
	}

	if (action.type === RECEIVE_WORDCLOUD_DATA_ERROR_TYPE) {
		return {
			...state,
			wordCloudDataLoading: false,
		};
	}

	if (action.type === REQUEST_DETAILED_TREND_REPORT_TYPE) {
		return {
			...state,
			detailedTrendReportLoading: !state.detailedTrendReportLoading,
		};
	}

	if (action.type === RECEIVE_DETAILED_TREND_REPORT_TYPE) {
		return {
			...state,
			detailedTrendReportLoading: !state.detailedTrendReportLoading,
			detailedTrendReport: action.detailedTrendReport,
		};
	}

	if (action.type === RECEIVE_DETAILED_TREND_REPORT_ERROR_TYPE) {
		return {
			...state,
			detailedTrendReportLoading: !state.detailedTrendReportLoading,
		};
	}

	if (action.type === REQUEST_TREND_REPORT_SUMMARY_TYPE) {
		return {
			...state,
			summaryLoading: true,
		};
	}
	if (action.type === RECEIVE_TREND_REPORT_SUMMARY_TYPE) {
		return {
			...state,
			summary: action.summary,
			summaryData: action.summaryData,
			summaryTotal: action.summaryTotal,
			summaryLoading: false,
			summaryError: false,
		};
	}
	if (action.type === RECEIVE_TREND_REPORT_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			summaryLoading: false,
			summaryError: true,
		};
	}

	if (action.type === REQUEST_IMAGES_TYPE) {
		return {
			...state,
			imagesLoading: true,
		};
	}
	if (action.type === RECEIVE_IMAGES_TYPE) {
		return {
			...state,
			imagesData: action.imagesData,
			imagesTotal: action.imagesTotal,
			imagesLoading: false,
			imagesError: false,
		};
	}
	if (action.type === RECEIVE_IMAGES_ERROR_TYPE) {
		return {
			...state,
			imagesLoading: false,
			imagesError: true,
		};
	}

	if (action.type === REQUEST_VIDEOS_TREND_REPORT_SUMMARY_TYPE) {
		return {
			...state,
			videosSummaryLoading: true,
		};
	}
	if (action.type === RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_TYPE) {
		return {
			...state,
			videosSummary: action.videosSummary,
			videosSummaryData: action.videosSummaryData,
			videosSummaryLoading: false,
			videosSummaryError: false,
		};
	}
	if (action.type === RECEIVE_VIDEOS_TREND_REPORT_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			videosSummaryLoading: false,
			videosSummaryError: true,
		};
	}

	if (action.type === REQUEST_TWITTER_TREND_REPORT_SUMMARY_TYPE) {
		return {
			...state,
			twitterSummaryLoading: true,
		};
	}
	if (action.type === RECEIVE_TWITTER_TREND_REPORT_SUMMARY_TYPE) {
		return {
			...state,
			twitterSummary: action.twitterSummary,
			twitterSummaryData: action.twitterSummaryData,
			twitterSummaryLoading: false,
			twitterSummaryError: false,
		};
	}
	if (action.type === RECEIVE_TWITTER_TREND_REPORT_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			twitterSummaryLoading: false,
			twitterSummaryError: true,
		};
	}

	if (action.type === REQUEST_DOCUMENT_TREND_SUMMARY_TYPE) {
		return {
			...state,
			documentSummary: null,
			documentSummaryLoading: true,
			documentSummaryError: false,
		};
	}
	if (action.type === RECEIVE_DOCUMENT_TREND_SUMMARY_TYPE) {
		return {
			...state,
			documentSummary: action.documentSummary,
			documentSummaryLoading: false,
		};
	}
	if (action.type === RECEIVE_DOCUMENT_TREND_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			documentSummaryLoading: false,
			documentSummaryError: true,
		};
	}

	if (action.type === RECEIVE_TREND_REPORT_DOWNLOAD_TYPE) {
		return {
			...state,
			trendsDataBlob: action.trendsDataBlob,
		};
	}

	if (action.type === RECEIVE_TREND_REPORT_DOWNLOAD_ERROR_TYPE) {
		return {
			...state,
			trendsDownloadError: true,
		};
	}

	if (action.type === SET_INITIAL_STATE_TYPE) {
		return state;
	}

	if (action.type === REQUEST_WEBPAGE_DETAILS_TYPE) {
		return {
			...state,
			matchedWordsLoading: true,
			webpageDetailsLoading: true,
		};
	}

	if (action.type === RECEIVE_WEBPAGE_DETAILS_TYPE) {
		return {
			...state,
			matchedWordsLoading: false,
			webpageDetails: action.webpageDetails,
			webpageDetailsLoading: false,
		};
	}

	if (action.type === REQUEST_MATCHED_WORDS_TYPE) {
		return {
			...state,
			matchedWordsLoading: true,
		};
	}

	if (action.type === RECEIVE_MATCHED_WORDS_TYPE) {
		return {
			...state,
			matchedWordsLoading: false,
			matchedWords: action.matchedWords,
		};
	}

	if (action.type === RECEIVE_MATCHED_WORDS_ERROR_TYPE) {
		return {
			...state,
			matchedWordsError: true,
			matchedWordsLoading: false,
		};
	}

	if (action.type === REQUEST_TREND_IMAGES_TYPE) {
		return {
			...state,
			imagesLoading: true,
		};
	}

	if (action.type === RECEIVE_TREND_IMAGES_TYPE) {
		return {
			...state,
			imagesData: action.imagesData,
			imagesTotal: action.imagesTotal,
			imagesLoading: false,
			imagesError: false,
		};
	}

	if (action.type === RECEIVE_TREND_IMAGES_ERROR_TYPE) {
		return {
			...state,
			imagesLoading: false,
			imagesError: true,
		};
	}

	if (action.type === REQUEST_SUBTOPIC_DETAILS_TYPE) {
		return {
			...state,
			subtopicDetailsLoading: true,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_DETAILS_TYPE) {
		return {
			...state,
			subtopicDetails: action.subtopicDetails,
			subtopicDetailsLoading: false,
			subtopicDetailsError: false,
		};
	}
	if (action.type === RECEIVE_SUBTOPIC_DETAILS_ERROR_TYPE) {
		return {
			...state,
			subtopicDetailsLoading: false,
			subtopicDetailsError: true,
		};
	}

	if (action.type === REQUEST_DOCUMENT_COUNT_REPORT_TYPE) {
		return {
			...state,
			documentCountReportLoading: !state.documentCountReportLoading,
			documentCountReportError: false,
		};
	}
	if (action.type === RECEIVE_DOCUMENT_COUNT_REPORT_TYPE) {
		return {
			...state,
			documentCountReport: action.documentCountReport,
			documentCountReportLoading: !state.documentCountReportLoading,
		};
	}
	if (action.type === RECEIVE_DOCUMENT_COUNT_REPORT_ERROR_TYPE) {
		return {
			...state,
			documentCountReportLoading: !state.documentCountReportLoading,
			documentCountReportError: true,
		};
	}

	if (action.type === REQUEST_DOMAIN_COUNT_REPORT_TYPE) {
		return {
			...state,
			domainCountReportLoading: !state.domainCountReportLoading,
			domainCountReportError: false,
		};
	}
	if (action.type === RECEIVE_DOMAIN_COUNT_REPORT_TYPE) {
		return {
			...state,
			domainCountReport: action.domainCountReport,
			domainCountReportLoading: !state.domainCountReportLoading,
		};
	}
	if (action.type === RECEIVE_DOMAIN_COUNT_REPORT_ERROR_TYPE) {
		return {
			...state,
			domainCountReportLoading: !state.domainCountReportLoading,
			domainCountReportError: true,
		};
	}

	if (action.type === REQUEST_SENTIMENT_SCORE_REPORT_TYPE) {
		return {
			...state,
			sentimentScoreReportLoading: !state.sentimentScoreReportLoading,
			sentimentScoreReportError: false,
		};
	}
	if (action.type === RECEIVE_SENTIMENT_SCORE_REPORT_TYPE) {
		return {
			...state,
			sentimentScoreReport: action.sentimentScoreReport,
			sentimentScoreReportLoading: !state.sentimentScoreReportLoading,
		};
	}
	if (action.type === RECEIVE_SENTIMENT_SCORE_REPORT_ERROR_TYPE) {
		return {
			...state,
			sentimentScoreReportLoading: !state.sentimentScoreReportLoading,
			sentimentScoreReportError: true,
		};
	}

	if (action.type === REQUEST_EMOTIONAL_SENTIMENT_REPORT_TYPE) {
		return {
			...state,
			emotionalSentimentReportLoading: !state.emotionalSentimentReportLoading,
			emotionalSentimentReportError: false,
		};
	}
	if (action.type === RECEIVE_EMOTIONAL_SENTIMENT_REPORT_TYPE) {
		return {
			...state,
			emotionalSentimentReport: action.emotionalSentimentReport,
			emotionalSentimentReportLoading: !state.emotionalSentimentReportLoading,
		};
	}
	if (action.type === RECEIVE_EMOTIONAL_SENTIMENT_REPORT_ERROR_TYPE) {
		return {
			...state,
			emotionalSentimentReportLoading: !state.emotionalSentimentReportLoading,
			emotionalSentimentReportError: true,
		};
	}

	if (action.type === REQUEST_LANGUAGE_USE_REPORT_TYPE) {
		return {
			...state,
			languageUseReportLoading: !state.languageUseReportLoading,
			languageUseReportError: false,
		};
	}
	if (action.type === RECEIVE_LANGUAGE_USE_REPORT_TYPE) {
		return {
			...state,
			languageUseReport: action.languageUseReport,
			languageUseReportLoading: !state.languageUseReportLoading,
		};
	}
	if (action.type === RECEIVE_LANGUAGE_USE_REPORT_ERROR_TYPE) {
		return {
			...state,
			languageUseReportLoading: !state.languageUseReportLoading,
			languageUseReportError: true,
		};
	}

	if (action.type === REQUEST_NARRATIVE_REPORT_TYPE) {
		return {
			...state,
			narrativeReportLoading: !state.narrativeReportLoading,
			narrativeReportError: false,
		};
	}
	if (action.type === RECEIVE_NARRATIVE_REPORT_TYPE) {
		return {
			...state,
			narrativeReport: action.narrativeReport,
			narrativeReportLoading: !state.narrativeReportLoading,
		};
	}
	if (action.type === RECEIVE_NARRATIVE_REPORT_ERROR_TYPE) {
		return {
			...state,
			narrativeReportLoading: !state.narrativeReportLoading,
			narrativeReportError: true,
		};
	}

	if (action.type === REQUEST_IMPRESSIONS_REPORT_TYPE) {
		return {
			...state,
			impressionsReportLoading: !state.impressionsReportLoading,
			impressionsReportError: false,
		};
	}
	if (action.type === RECEIVE_IMPRESSIONS_REPORT_TYPE) {
		return {
			...state,
			impressionsReport: action.impressionsReport,
			impressionsReportLoading: !state.impressionsReportLoading,
		};
	}
	if (action.type === RECEIVE_IMPRESSIONS_REPORT_ERROR_TYPE) {
		return {
			...state,
			impressionsReportLoading: !state.impressionsReportLoading,
			impressionsReportError: true,
		};
	}

	if (action.type === SET_CUSTOM_DOMAIN_COLORS_TYPE) {
		return {
			...state,
			customDomainColors: action.customDomainColors,
		};
	}

	if (action.type === SET_CUSTOM_SUBTOPIC_COLORS_TYPE) {
		return {
			...state,
			customSubtopicColors: action.customSubtopicColors,
		};
	}

	if (action.type === SET_CUSTOM_TOPIC_COLORS_TYPE) {
		return {
			...state,
			customTopicColors: action.customTopicColors,
		};
	}

	if (action.type === SET_CUSTOM_CATEGORY_COLORS_TYPE) {
		return {
			...state,
			customCategoryColors: action.customCategoryColors,
		};
	}

	if (action.type === SET_CUSTOM_SCORE_TYPE_COLORS_TYPE) {
		return {
			...state,
			customScoreTypeColors: action.customScoreTypeColors,
		};
	}

	if (action.type === SET_CUSTOM_IMPRESSION_COLORS_TYPE) {
		return {
			...state,
			customImpressionColors: action.customImpressionColors,
		};
	}

	if (action.type === REQUEST_MEDIA_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			mediaSummaryLoading: !state.mediaSummaryLoading,
			mediaSummaryError: false,
		};
	}
	if (action.type === RECEIVE_MEDIA_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			mediaCountSummary: action.mediaCountSummary,
			mediaSummaryLoading: !state.mediaSummaryLoading,
		};
	}
	if (action.type === RECEIVE_MEDIA_COUNT_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			mediaSummaryLoading: !state.mediaSummaryLoading,
			mediaSummaryError: true,
		};
	}

	if (action.type === REQUEST_ENGAGEMENT_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			engagementSummaryLoading: !state.engagementSummaryLoading,
			engagementSummaryError: false,
		};
	}
	if (action.type === RECEIVE_ENGAGEMENT_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			engagementCountSummary: action.engagementCountSummary,
			engagementSummaryLoading: !state.engagementSummaryLoading,
		};
	}
	if (action.type === RECEIVE_ENGAGEMENT_COUNT_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			engagementSummaryLoading: !state.engagementSummaryLoading,
			engagementSummaryError: true,
		};
	}

	if (action.type === REQUEST_ENTITY_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			entitySummaryLoading: !state.entitySummaryLoading,
			entitySummaryError: false,
		};
	}
	if (action.type === RECEIVE_ENTITY_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			entityCountSummary: action.entityCountSummary,
			entitySummaryLoading: !state.entitySummaryLoading,
		};
	}
	if (action.type === RECEIVE_ENTITY_COUNT_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			entitySummaryLoading: !state.entitySummaryLoading,
			entitySummaryError: true,
		};
	}

	if (action.type === REQUEST_NLP_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			NLPSummaryLoading: !state.NLPSummaryLoading,
			NLPSummaryError: false,
		};
	}
	if (action.type === RECEIVE_NLP_COUNT_SUMMARY_TYPE) {
		return {
			...state,
			NLPCountSummary: action.NLPCountSummary,
			NLPSummaryLoading: !state.NLPSummaryLoading,
		};
	}
	if (action.type === RECEIVE_NLP_COUNT_SUMMARY_ERROR_TYPE) {
		return {
			...state,
			NLPSummaryLoading: !state.NLPSummaryLoading,
			NLPSummaryError: true,
		};
	}

	return state;
};
