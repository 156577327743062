import moment from 'moment-timezone';
import 'moment-timezone/moment-timezone';
// import moment from 'moment';

moment.tz.load({
	version: '2021a',
	zones: ['Etc/UTC|UTC|0|0|'],
	links: [
		'Etc/UCT|UCT',
		'Etc/UTC|Etc/Universal',
		'Etc/UTC|Etc/Zulu',
		'Etc/UTC|UTC',
		'Etc/UTC|Universal',
		'Etc/UTC|Zulu',
	],
});

moment.tz.setDefault('UTC');

export function addMinutes(date, minutes) {
	const dateInSecondsEpoch = Math.floor(date / 1000);
	const minutesAsSeconds = minutes * 60;

	return (dateInSecondsEpoch + minutesAsSeconds) * 1000; //milliseconds
}

export function addHours(date, hours) {
	const dateInSecondsEpoch = Math.floor(date / 1000);
	const hoursAsSeconds = hours * 3600;

	return (dateInSecondsEpoch + hoursAsSeconds) * 1000; //milliseconds
}

export function addSeconds(date, seconds) {
	const dateInSecondsEpoch = Math.floor(date / 1000);
	return (dateInSecondsEpoch + seconds) * 1000; //milliseconds
}

export const timeToFriendlyTimestamp = (
	timeString,
	format = 'YYYY-MM-DD hh:mm A'
) => {
	try {
		// var date = Date.parse(timeString);

		// if(isNaN(date)){
		//     date = Date.parse(timeString.replace(/-/g, '/'))
		// }

		//const now = Date.now();

		// // YYYY-MM-DDTHH:mm
		// if( addMinutes(date, 30) > now ){
		//     return "Just now"; //30 minutes or less
		// }
		// else if ( addMinutes(date, 60) > now)
		// {
		//     return "A few minutes ago" // 1 hour or less
		// }
		// else{
		//     var dateObj =  new Date(date);

		//     return dateObj.toLocaleString('en-GB', { timeZone: 'UTC' });
		// }
		// var dateObj =  new Date(date);

		// var isoTimeString = dateObj.toISOString();

		var isoTimeString = timeString;
		var utcMoment = moment.tz(isoTimeString, 'Etc/UTC');

		const machineTimeZoneIana = moment.tz.guess();
		//const machineTimeZoneIana = Intl.DateTimeFormat().resolvedOptions().timeZone;
		//var dateString = dateObj.toLocaleString('en-GB', { timeZone: timeZoneIana });

		return utcMoment.tz(machineTimeZoneIana).format(format);
	} catch (err) {
		console.log(err);
	}
	return '';
};

export const timeToHumanTimestamp = (
	timeString,
	format = 'MMM D YYYY, HH:mm'
) => {
	try {
		var date = Date.parse(timeString);

		if (isNaN(date)) {
			date = Date.parse(timeString.replace(/-/g, '/'));
		}

		const now = Date.now();
		var midnight = new Date();
		midnight.setHours(24, 0, 0, 0);

		if (addSeconds(date, 60) > now) {
			return 'Just now'; //60 seconds or less
		} else if (addDays(date, 1) > midnight) {
			var isoTimeString = timeString;
			var utcMoment = moment.tz(isoTimeString, 'Etc/UTC');

			const machineTimeZoneIana = moment.tz.guess();

			return utcMoment.tz(machineTimeZoneIana).format('HH:mm'); // 1 day or less
		} else if (addDays(date, 7) > now) {
			var isoTimeString = timeString;
			var utcMoment = moment.tz(isoTimeString, 'Etc/UTC');

			const machineTimeZoneIana = moment.tz.guess();

			return utcMoment.tz(machineTimeZoneIana).format('ddd, HH:mm'); // 1 week or less
		} else if (addDays(date, 365) > now) {
			var isoTimeString = timeString;
			var utcMoment = moment.tz(isoTimeString, 'Etc/UTC');

			const machineTimeZoneIana = moment.tz.guess();

			return utcMoment.tz(machineTimeZoneIana).format('MMM D, HH:mm'); // 1 year or less
		} else {
			var isoTimeString = timeString;
			var utcMoment = moment.tz(isoTimeString, 'Etc/UTC');

			const machineTimeZoneIana = moment.tz.guess();

			return utcMoment.tz(machineTimeZoneIana).format(format);
		}
	} catch (err) {
		console.log(err);
	}
	return '';
};

export const timeToDate = (timestring) => {
	const options = { month: 'short', day: 'numeric' };
	try {
		return new Date(Date.parse(timestring)).toLocaleDateString(
			'en-US',
			options
		);
	} catch (err) {
		return '';
	}
};

export const timeToDateFull = (timestring) => {
	const options = { month: 'short', day: 'numeric', year: 'numeric' };
	try {
		return new Date(Date.parse(timestring)).toLocaleDateString(
			'en-US',
			options
		);
	} catch (err) {
		return '';
	}
};

export const timeToDateTimeFull = (timestring) => {
	const options = {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		// hourCycle: 'h23',
	};
	try {
		return new Date(Date.parse(timestring)).toLocaleString('en-US', options);
	} catch (err) {
		return '';
	}
};

export const timeToMonth = (timestring) => {
	const options = { month: 'short', year: 'numeric' };
	try {
		return new Date(Date.parse(timestring)).toLocaleDateString(
			'en-US',
			options
		);
	} catch (err) {
		return '';
	}
};

export const timeToWeekDayDateFull = (timestring) => {
	const options = {
		dateStyle: 'full',
		timeStyle: 'short',
		hour12: true,
	};
	try {
		return new Intl.DateTimeFormat('en-US', options).format(
			Date.parse(timestring)
		);
	} catch (err) {
		return '';
	}
};

export const timeToStringFull = (timestring) => {
	const options = {
		month: 'short',
		day: 'numeric',
		// year: "numeric",
		hour: '2-digit',
		minute: '2-digit',
	};
	try {
		return new Date(Date.parse(timestring)).toLocaleString('en-US', options);
	} catch (err) {
		return '';
	}
};

export const timeToWeekDayDate = (timestring) => {
	const options = { month: 'short', day: 'numeric', weekday: 'short' };
	try {
		return new Date(Date.parse(timestring)).toLocaleDateString(
			'en-US',
			options
		);
	} catch (err) {
		return '';
	}
};

export const timeToWeekDay = (timestring) => {
	const options = { weekday: 'long' };
	try {
		return new Date(Date.parse(timestring)).toLocaleDateString(
			'en-US',
			options
		);
	} catch (err) {
		return '';
	}
};

export const timeOfDay = (timestring) => {
	const options = { hour: '2-digit', minute: '2-digit' };
	try {
		return new Date(Date.parse(timestring)).toLocaleTimeString(
			'en-US',
			options
		);
	} catch (err) {
		return '';
	}
};

export const time24hour = (timestring) => {
	const options = { hour: '2-digit', minute: '2-digit' };
	try {
		return new Date(Date.parse(timestring)).toLocaleTimeString(
			'en-GB',
			options
		);
	} catch (err) {
		return '';
	}
};

export const timeToNumber = (timeString) => {
	try {
		var date = Date.parse(timeString);

		if (isNaN(date)) {
			date = Date.parse(timeString.replace(/-/g, '/'));
		}
		return date;
	} catch (err) {
		return 0;
	}
};

export const dateSortingFunction = (a, b) =>
	timeToNumber(b.DatePublished) - timeToNumber(a.DatePublished);

export function addDays(date, days) {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

export function startOfDay(date) {
	var result = new Date(date);
	result.setHours(0, 0, 0, 0);
	return result;
}

export function endOfDay(date) {
	var result = new Date(date);
	result.setHours(23, 59, 59, 999);
	return result;
}

export const dateNowMoment = moment().toISOString();

export const subtractMonthsMoment = (dateString, months) => {
	return moment(dateString).subtract(months, 'months').toISOString();
};

export const differenceBetweenDates = (startDate, endDate) => {
	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	const firstDate = new Date(startDate);
	const secondDate = new Date(endDate);

	const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
	return diffDays;
};

export const convertToDaySinceEpoch = (date) => {
	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	const day = Math.round(new Date(date).getTime() / oneDay);

	return day;
};

export const isPastTime = (timeString) => {
	const currentTime = new Date();

	if (currentTime > new Date(timeString)) {
		return true;
	} else {
		return false;
	}
};
