/*global process*/

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as ConfigurationsTopics from './Configurations.Topics';
import * as ConfigurationsRootUrls from './Configurations.RootUrls';
import * as ConfigurationsIgnoredHosts from './Configurations.IgnoredHosts';
import * as ConfigurationsSubtopics from './Configurations.Subtopics';
import * as ConfigurationsApiKeys from './Configurations.ApiKeys';
import * as News from './News';
import * as Search from './Search';
import * as Auth from './Auth';
import * as NewsJunkTextML from './MachineLearning.NewsJunkText';
import * as DatePublishedML from './PublishedDateMachineLearning';
import * as AnalyticsTrends from './Analytics.Trends';
import * as SubtopicTimeline from './Analytics.SubtopicsTimeline';
import * as KeywordTimeline from './Analytics.KeywordTimeline';
import * as IncidenceTimeline from './Analytics.IncidenceTimeline';
import * as AnalyticsGeneral from './Analytics.General';
import * as Global from './GlobalStore';
import * as UserManagement from './UserManagement';
import * as MessagingShortCodes from './Messaging.ShortCodes';
import * as MessagingCampaigns from './Messaging.Campaigns';
import * as MessagingMessages from './Messaging.Messages';
import * as ConfigurationNarrative from './Configurations.Narratives';
import * as WordLists from './WordList';
import * as OrganisationConfigurations from './OrganisationConfigurations';
import * as ConfigurationCategories from './Configurations.Categories';
import * as KaziMtaaniManagement from './KaziMtaaniManagement';
import * as FileManagement from './FileManagement';
import * as MachineLearning from './MachineLearning';
import * as Incidents from './Incidents';
import * as Twitter from './Twitter';
import * as Notifications from './Notifications';
import * as UserAgreements from './UserAgreements';
import * as Ranking from './Ranking';
import * as Chatbot from './Messaging.Chatbot';
import wikipediaReducer from './Wikipedia';
import keysReducer from './Keys';
import embeddingWebsitesReducer from './EmbeddingWebsites';
import datasetsReducer from './Datasets';
import youtubeReducer from './Youtube';
import rssFeedReducer from './RSS';
import webpagesReducer from './Webpages';
import textReaderReducer from './TextReader';

export default function configureStore(history, initialState) {
	const reducers = {
		global: Global.reducer,
		configurationCategories: ConfigurationCategories.reducer,
		configurationTopics: ConfigurationsTopics.reducer,
		configurationRootUrls: ConfigurationsRootUrls.reducer,
		configurationIgnoredHosts: ConfigurationsIgnoredHosts.reducer,
		configurationSubtopics: ConfigurationsSubtopics.reducer,
		configurationsApiKeys: ConfigurationsApiKeys.reducer,
		news: News.reducer,
		search: Search.reducer,
		auth: Auth.reducer,
		newsJunkTextML: NewsJunkTextML.reducer,
		datePublishedML: DatePublishedML.reducer,
		trendsReport: AnalyticsTrends.reducer,
		analytics: AnalyticsGeneral.reducer,
		subtopicTimeline: SubtopicTimeline.reducer,
		keywordTimeline: KeywordTimeline.reducer,
		incidenceTimeline: IncidenceTimeline.reducer,
		userManagement: UserManagement.reducer,
		messagingShortCodes: MessagingShortCodes.reducer,
		messagingCampaigns: MessagingCampaigns.reducer,
		messagingMessages: MessagingMessages.reducer,
		configurationNarratives: ConfigurationNarrative.reducer,
		wordLists: WordLists.reducer,
		organisationConfigurations: OrganisationConfigurations.reducer,
		kaziMtaaniManagement: KaziMtaaniManagement.reducer,
		fileManagement: FileManagement.reducer,
		machineLearning: MachineLearning.reducer,
		incidents: Incidents.reducer,
		twitter: Twitter.reducer,
		notifications: Notifications.reducer,
		userAgreements: UserAgreements.reducer,
		ranking: Ranking.reducer,
		chatbot: Chatbot.reducer,
		wikipedia: wikipediaReducer,
		keys: keysReducer,
		embeddingWebsites: embeddingWebsitesReducer,
		datasets: datasetsReducer,
		youtube: youtubeReducer,
		rssFeed: rssFeedReducer,
		webpages: webpagesReducer,
		textReader: textReaderReducer,
	};

	const middleware = [thunk, routerMiddleware(history)];

	// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (
		isDevelopment &&
		typeof window !== 'undefined' &&
		window.devToolsExtension
	) {
		enhancers.push(window.devToolsExtension());
	}

	const rootReducer = combineReducers({
		...reducers,
		routing: routerReducer,
	});

	return createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);
}
