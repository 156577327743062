import React, { Fragment, useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {
	Chip,
	CircularProgress,
	Grid,
	List,
	ListItem,
	Snackbar,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Alert, Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginTop: 32,
		marginBottom: theme.spacing(1),
		display: 'flex',
		justifyContent: 'flex-end',
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	typography: {
		marginBottom: 16,
	},
	confirmationStep: {
		padding: 24,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '86%',
	},
	finalStep: {
		padding: 24,
	},
	listPadding: { padding: 0 },
	phoneInput: {
		'& input[type=number]': {
			'-moz-appearance': 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		marginBottom: 16,
	},
	input: {
		marginBottom: 16,
	},
	stepContentRoot: {
		width: '100%',
	},
	stepperRoot: {
		paddingRight: 0,
		paddingLeft: 0,
	},
	stepContainer: { paddingLeft: 24, paddingRight: 24, marginTop: 8 },
	textSeperator: {
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(1),
		verticalAlign: 'middle',
		fontSize: 40,
		lineHeight: 0.7,
	},
}));

const MIN_NAME_CHARACTERS = 3;
const MAX_NAME_CHARACTERS = 30;

function getSteps() {
	const mainSteps = ['Personal Information', 'Organisation Details'];

	return mainSteps;

	// return [
	// 	'Full Name',
	// 	'Email Address',
	// 	'Phone Number',
	// 	'Organisation Name (Optional)',
	// 	'Web Address',
	// 	'Datasets',
	// 	// 'Confirmation',
	// ];
}

function getStepContent(params) {
	const {
		classes,
		step,
		fullName,
		webAddress,
		emailAddress,
		handleInputChange,
		phoneNumber,
		isEmailError,
		isPhoneError,
		isWebAddressError,
		isNameError,
		organizationName,
		handleDatasetsSelectionChange,
		allDatasets,
		selectedDatasets,
	} = params;

	switch (step) {
		case 0:
			return (
				<React.Fragment>
					<Typography variant='body2' className={classes.typography}>
						Kindly enter your first and last name below
					</Typography>
					<TextField
						required
						fullWidth
						variant='outlined'
						error={isNameError}
						onChange={(e) => handleInputChange(e)}
						name='fullName'
						label='Full Name'
						value={fullName}
						helperText={
							isNameError
								? `${MIN_NAME_CHARACTERS} - ${MAX_NAME_CHARACTERS} characters required`
								: ''
						}
					/>
				</React.Fragment>
			);
		case 1:
			return (
				<React.Fragment>
					<Typography variant='body2' className={classes.typography}>
						Kindly enter your email address below
					</Typography>
					<TextField
						required
						fullWidth
						variant='outlined'
						error={isEmailError}
						onChange={(e) => handleInputChange(e)}
						name='emailAddress'
						label='Email address'
						value={emailAddress}
						helperText={isEmailError ? 'A valid email address required' : ''}
					/>
				</React.Fragment>
			);
		case 2:
			return (
				<React.Fragment>
					<Typography variant='body2' className={classes.typography}>
						Kindly enter your phone number below
					</Typography>
					<TextField
						fullWidth
						variant='outlined'
						onChange={(e) => handleInputChange(e)}
						error={isPhoneError}
						name='phoneNumber'
						label='Phone number'
						type='number'
						value={phoneNumber}
						helperText={
							isPhoneError
								? 'A valid phone number should have 10 characters'
								: ''
						}
						classes={{ root: classes.phoneInput }}
						className={classes.phoneInput}
					/>
				</React.Fragment>
			);
		case 3:
			return (
				<React.Fragment>
					<Typography variant='body2' className={classes.typography}>
						Kindly enter your organization name below
					</Typography>
					<TextField
						fullWidth
						variant='outlined'
						onChange={(e) => handleInputChange(e)}
						label='Organization Name'
						name='organizationName'
						value={organizationName}
					/>
				</React.Fragment>
			);
		case 4:
			return (
				<React.Fragment>
					<Typography variant='body2' className={classes.typography}>
						Kindly enter your web address below
					</Typography>
					<TextField
						required
						fullWidth
						variant='outlined'
						error={isWebAddressError}
						onChange={(e) => handleInputChange(e)}
						label='Web Address'
						name='webAddress'
						value={webAddress}
						helperText={
							isWebAddressError
								? 'A valid web address required eg. https://www.example.com or https://example.com '
								: ''
						}
						placeholder='eg. https://example.com'
					/>
				</React.Fragment>
			);
		case 5:
			return (
				<div className={classes.input}>
					<Autocomplete
						multiple
						id='organisation-datasets'
						options={allDatasets.map((x) => ({
							label: x.name,
							value: x.id,
						}))}
						fullWidth
						getOptionLabel={(option) => option.label}
						getOptionSelected={(option, value) => option.value === value.value}
						defaultValue={selectedDatasets}
						value={selectedDatasets}
						onChange={(e, value) => handleDatasetsSelectionChange(value)}
						renderInput={(params) => {
							return (
								<TextField
									{...params}
									variant='outlined'
									fullWidth
									label='Datasets'
								/>
							);
						}}
						renderTags={(tagValue, getTagProps) =>
							tagValue.map((option, index) => (
								<Chip
									size='small'
									variant='outlined'
									color='primary'
									label={option.label}
									{...getTagProps({ index })}
								/>
							))
						}
						filterSelectedOptions={true}
						renderOption={(option, state) => {
							return (
								<li
									key={option.value}
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography
										variant='subtitle1'
										style={{
											fontSize: 14,
											color: '#000',
										}}
									>
										{option.label}
									</Typography>
								</li>
							);
						}}
					/>
				</div>
			);

		case 6:
			return (
				<React.Fragment>
					<Typography variant='body2' className={classes.typography}>
						You are about to create an account with the following details:
					</Typography>

					<List>
						<ListItem>
							Full Name: <b>{fullName}</b>
						</ListItem>
						<ListItem>
							Email Address: <b>{emailAddress}</b>
						</ListItem>
						<ListItem>
							Phone: <b>{phoneNumber}</b>
						</ListItem>
						<ListItem>
							Organization Name: <b>{organizationName}</b>
						</ListItem>
						<ListItem>
							Web Address: <b>{webAddress}</b>
						</ListItem>
					</List>
				</React.Fragment>
			);
		default:
			return 'Unknown step';
	}
}

export default function OnboardingSteps(props) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const [isNameError, setNameError] = useState(false);
	const [isEmailError, setEmailError] = useState(false);
	const [isPhoneError, setPhoneError] = useState(false);
	const [isWebAddressError, setWebAddressError] = useState(false);
	const [activeView, setActiveView] = useState(0);
	const [isDatasetsError, selectedDatasetsError] = useState(false);

	const steps = getSteps();

	const {
		fullName,
		emailAddress,
		phoneNumber,
		webAddress,
		organizationName,
		handleChange,
		handleSubmit,
		signingUpForIncidents,
		isSuccessSnackbarOpen,
		handleSuccessSnackbarState,
		handleErrorSnackbarState,
		isSignupError,
		signingUpForIncidentsError,
		allDatasets,
		selectedDatasets,
		handleDatasetsSelectionChange,
	} = props;

	const handleNext = async () => {
		const isValid = validateInputs(activeStep);

		if (isValid) {
			if (activeView == 1) {
				let isSuccess = await handleSubmit();

				if (isSuccess) {
					setActiveView((prev) => prev + 1);
				}
				return;
			}

			if (activeStep === steps.length - 1) {
				setActiveView((prev) => prev + 1);
				return;
			}

			resetErrorStates();

			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const resetErrorStates = () => {
		setNameError(false);
		setEmailError(false);
		setWebAddressError(false);
		setPhoneError(false);
		selectedDatasetsError(false);
	};

	const handleBack = () => {
		if (activeView === 1) {
			setActiveView((prev) => prev - 1);
			return;
		}

		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi;
		return re.test(email);
	};

	const validateWebAddress = (webAddress) => {
		const validUrl = /^(https?):\/\/([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/gi;

		return validUrl.test(webAddress);
	};

	const validatePhone = (phone) => {
		let valid = true;

		if (phone.length > 0) {
			if (isNaN(phone)) {
				valid = false;
			}

			if (phone.toString().length != 10) {
				valid = false;
			}
		}

		return valid;
	};

	const validateDatasets = () => {
		const isValid = selectedDatasets.length > 0;
		return isValid;
	};

	const validateInputs = (step) => {
		let isValid = false;

		if (step === 0) {
			isValid = true;

			if (
				fullName.trim().length < MIN_NAME_CHARACTERS ||
				fullName.trim().length > MAX_NAME_CHARACTERS
			) {
				setNameError(true);
				isValid = false;
			}

			if (!validateEmail(emailAddress)) {
				setEmailError(true);
				isValid = false;
			}

			if (!validatePhone(phoneNumber)) {
				setPhoneError(true);
				isValid = false;
			}

			return isValid;
		}

		if (step === 1) {
			isValid = true;

			if (!validateWebAddress(webAddress)) {
				setWebAddressError(true);
				isValid = false;
			}

			if (!validateDatasets()) {
				selectedDatasetsError(true);
				isValid = false;
			}

			return isValid;
		}

		// if (
		// 	(fullName.trim().length < MIN_NAME_CHARACTERS ||
		// 		fullName.trim().length > MAX_NAME_CHARACTERS) &&
		// 	step === 0
		// ) {
		// 	setNameError(true);
		// 	return isValid;
		// }

		// if (!validateEmail(emailAddress) && step === 0) {
		// 	setEmailError(true);
		// 	return isValid;
		// }

		// if (!validatePhone(phoneNumber) && step == 2) {
		// 	setPhoneError(true);
		// 	return isValid;
		// }

		// if (!validateWebAddress(webAddress) && step === 1) {
		// 	setWebAddressError(true);
		// 	return isValid;
		// }

		// if (!validateDatasets && step == 1) {
		// 	selectedDatasetsError(true);
		// 	return isValid;
		// }

		return true;
	};

	const handleInputChange = (e) => {
		handleChange(e);
		resetErrorStates();
	};

	const handleDatasetsChange = (datasets) => {
		handleDatasetsSelectionChange(datasets);
		resetErrorStates();
	};

	const accountDetails = [
		{ label: 'Full Name', value: fullName, canDisplay: true },
		{ label: 'Email Address', value: emailAddress, canDisplay: true },
		{
			label: 'Phone Number',
			value: phoneNumber,
			canDisplay: phoneNumber.length > 0,
		},
		{
			label: 'Organisation Name',
			value: organizationName,
			canDisplay: organizationName.length > 0,
		},
		{ label: 'Web Address', value: webAddress, canDisplay: true },
		{
			label: 'Datasets',
			value: selectedDatasets?.map((x) => x.label),
			canDisplay: true,
		},
	];

	return (
		<div className={classes.root}>
			{isSignupError && (
				<Snackbar
					open={true}
					autoHideDuration={4000}
					onClose={() => handleErrorSnackbarState(false)}
				>
					<Alert
						onClose={() => handleErrorSnackbarState(false)}
						variant='filled'
						severity='error'
						elevation={6}
					>
						Another user with the same email already exists.
					</Alert>
				</Snackbar>
			)}

			<SwipeableViews axis={'x'} index={activeView} disableLazyLoading disabled>
				<div>
					{/* <Stepper activeStep={activeStep} orientation='vertical'>
						{steps.map((label, index) => {
							const params = {
								step: index,
								classes,
								fullName,
								emailAddress,
								phoneNumber,
								webAddress,
								handleInputChange,
								isEmailError,
								isNameError,
								isWebAddressError,
								organizationName,
								isPhoneError,
								selectedDatasets,
								handleDatasetsSelectionChange,
								allDatasets,
							};

							return (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
									<StepContent>
										<Typography>{getStepContent(params)}</Typography>
										<div className={classes.actionsContainer}>
											<div>
												<Button
													disabled={activeStep === 0}
													onClick={handleBack}
													className={classes.button}
												>
													Back
												</Button>
												<Button
													variant='contained'
													color='primary'
													onClick={handleNext}
													className={classes.button}
													startIcon={
														activeStep === steps.length - 1 &&
														signingUpForIncidents && (
															<CircularProgress size={16} />
														)
													}
												>
													Next
												</Button>
											</div>
										</div>
									</StepContent>
								</Step>
							);
						})}
					</Stepper> */}
					<Stepper
						activeStep={activeStep}
						alternativeLabel
						classes={{ root: classes.stepperRoot }}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<SwipeableViews index={activeStep} disableLazyLoading disabled>
						<div className={classes.stepContainer}>
							<TextField
								required
								fullWidth
								variant='outlined'
								error={isNameError}
								onChange={(e) => handleInputChange(e)}
								name='fullName'
								label='Full Name'
								value={fullName}
								helperText={
									isNameError
										? `${MIN_NAME_CHARACTERS} - ${MAX_NAME_CHARACTERS} characters required`
										: ''
								}
								className={classes.input}
							/>
							<TextField
								required
								fullWidth
								variant='outlined'
								error={isEmailError}
								onChange={(e) => handleInputChange(e)}
								name='emailAddress'
								label='Email address'
								value={emailAddress}
								helperText={
									isEmailError ? 'A valid email address required' : ''
								}
								className={classes.input}
							/>
							<TextField
								fullWidth
								variant='outlined'
								onChange={(e) => handleInputChange(e)}
								error={isPhoneError}
								name='phoneNumber'
								label='Phone number'
								type='number'
								value={phoneNumber}
								helperText={
									isPhoneError
										? 'A valid phone number should have 10 characters'
										: ''
								}
								classes={{ root: classes.phoneInput }}
								className={classes.phoneInput}
							/>
						</div>
						<div className={classes.stepContainer}>
							<TextField
								fullWidth
								variant='outlined'
								onChange={(e) => handleInputChange(e)}
								label='Organization Name'
								name='organizationName'
								value={organizationName}
								className={classes.input}
							/>
							<TextField
								required
								fullWidth
								variant='outlined'
								error={isWebAddressError}
								onChange={(e) => handleInputChange(e)}
								label='Web Address'
								name='webAddress'
								value={webAddress}
								helperText={
									isWebAddressError
										? 'A valid web address required eg. https://www.example.com or https://example.com '
										: ''
								}
								placeholder='eg. https://example.com'
								className={classes.input}
							/>
							<Autocomplete
								multiple
								id='organisation-datasets'
								options={allDatasets.map((x) => ({
									label: x.name,
									value: x.id,
								}))}
								fullWidth
								getOptionLabel={(option) => option.label}
								getOptionSelected={(option, value) =>
									option.value === value.value
								}
								defaultValue={selectedDatasets}
								value={selectedDatasets}
								onChange={(e, value) => handleDatasetsChange(value)}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											variant='outlined'
											fullWidth
											label='Datasets'
											required
											error={isDatasetsError}
											helperText={
												isDatasetsError && 'The datasets are required'
											}
										/>
									);
								}}
								className={classes.input}
								renderTags={(tagValue, getTagProps) =>
									tagValue.map((option, index) => (
										<Chip
											size='small'
											variant='outlined'
											color='primary'
											label={option.label}
											{...getTagProps({ index })}
										/>
									))
								}
								filterSelectedOptions={true}
								renderOption={(option, state) => {
									return (
										<li
											key={option.value}
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Typography
												variant='subtitle1'
												style={{
													fontSize: 14,
													color: '#000',
												}}
											>
												{option.label}
											</Typography>
										</li>
									);
								}}
							/>
						</div>
					</SwipeableViews>
					<div
						style={{
							paddingLeft: 24,
							paddingRight: 24,
							paddingBottom: 16,
							paddingTop: 24,
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<div>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								// className={classes.button}
							>
								Back
							</Button>
							<Button
								variant='contained'
								color='primary'
								onClick={handleNext}
								// className={classes.button}
								startIcon={
									activeStep === steps.length - 1 &&
									signingUpForIncidents && <CircularProgress size={16} />
								}
							>
								Next
							</Button>
						</div>
					</div>
					<Typography variant='body1' style={{ marginLeft: 24 }}>
						Already have an account? <a href='/login'>Sign In</a>
					</Typography>
				</div>
				<div className={classes.confirmationStep}>
					<div>
						<Typography
							variant='body1'
							style={{ fontWeight: 500, marginBottom: 16 }}
						>
							Confirmation
						</Typography>
						<Typography variant='body1' style={{ marginBottom: 8 }}>
							Please confirm the following account details:
						</Typography>
						<List classes={{ padding: classes.listPadding }}>
							{accountDetails.map((details) => {
								return (
									<>
										{details.canDisplay && (
											<ListItem>
												<Grid container>
													<Grid item sm={4}>
														<Typography color='textSecondary'>
															{details.label}
														</Typography>
													</Grid>
													<Grid
														item
														sm={8}
														style={{
															display: 'flex',
															alignItems: 'center',
															flexWrap: 'wrap',
															width: '100%',
														}}
													>
														{Array.isArray(details.value) ? (
															<Fragment>
																{details.value?.map((x) => (
																	<Chip
																		label={x}
																		key={x}
																		size='small'
																		variant='outlined'
																		color='primary'
																		style={{
																			marginRight: 4,
																			marginBottom: 4,
																		}}
																	/>
																))}
															</Fragment>
														) : (
															<Typography>{details.value}</Typography>
														)}
													</Grid>
												</Grid>
											</ListItem>
										)}
									</>
								);
							})}
						</List>
					</div>
					<div className={classes.actionsContainer}>
						<div>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.button}
							>
								Back
							</Button>
							<Button
								variant='contained'
								color='primary'
								onClick={handleNext}
								className={classes.button}
								disabled={
									activeStep === steps.length - 1 && signingUpForIncidents
								}
								startIcon={
									activeStep === steps.length - 1 &&
									signingUpForIncidents && (
										<CircularProgress size={16} style={{ color: 'white' }} />
									)
								}
							>
								{activeStep === steps.length - 1 ? 'Submit' : 'Next'}
							</Button>
						</div>
					</div>
				</div>
				<div className={classes.finalStep}>
					<Alert severity='success'>
						A notification has been sent to the email address provided.
					</Alert>

					<Typography variant='body1' style={{ marginTop: '16px' }}>
						Just one more step in the sign up process, you're almost done!
					</Typography>
					<Typography variant='body1' style={{ margin: '16px 0' }}>
						To provide you with your username and password, please click on the{' '}
						<b>Complete Sign Up</b> link in the email notification sent to you
						to complete the process.
					</Typography>
					<Typography
						variant='body1'
						color='textSecondary'
						className={classes.text}
					>
						Sincerely,
					</Typography>
					<Typography variant='body1' color='primary'>
						DUMA Support Team
					</Typography>
				</div>
			</SwipeableViews>
		</div>
	);
}
