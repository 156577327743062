import stringLookup from '../../../stringslookup';
import {
	fetchSecured,
	postSecured,
	patchSecured,
	deleteSecured,
} from '../../../fetchUtils';

export async function getGroups(campaignId, page, pageSize) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/groups`);

	//remove after implement get all
	url.searchParams.append('page', `${page}`);
	url.searchParams.append('pagesize', `${pageSize}`);
	url.searchParams.append('campaignId', campaignId);
	try {
		var res = await fetchSecured(url);
		if (res.ok) {
			var data = await res.json();
			return data;
		}
	} catch (e) {
		console.log(e);
		return null;
	}
}

export async function AddGroup(name, campaignId, contactIds) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/groups`);
	try {
		await postSecured(url, {
			name: name,
			campaignId: campaignId,
			contactIds: contactIds,
		});
	} catch (e) {
		console.log(e);
	}
}
export async function EditGroup(groupId, groupName, campaignId) {
	const url = new URL(
		`${stringLookup.baseUrl}/api/messaging/groups/${groupId.toString()}`
	);
	try {
		await patchSecured(url, { name: groupName, campaignId: campaignId });
	} catch (e) {
		console.log(e);
	}
}
export async function GetAllContacts(page, size) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/smscontacts`);
	url.searchParams.append('page', page.toString());
	url.searchParams.append('pagesize', size.toString());
	try {
		var res = await fetchSecured(url);
		if (res.ok) {
			var data = await res.json();
			return data;
		}
	} catch (e) {
		console.log(e);
	}
}

export async function GetGroupContact(groupId, page, size, searchString) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/smscontacts`);

	// url.searchParams.append("groupid", groupId.toString());
	url.searchParams.append('page', page.toString());
	url.searchParams.append('pagesize', size.toString());

	if (groupId) {
		url.searchParams.append('groupid', groupId.toString());
	}

	if (searchString) {
		url.searchParams.append('searchString', searchString);
	}

	try {
		var res = await fetchSecured(url);
		if (res.ok) {
			var data = await res.json();
			return data;
		}
	} catch (e) {
		console.log(e);
		return null;
	}
}

export async function AddContact(selectedGroups, name, phoneNumber, campaignId) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/smscontacts`);
	try {
		await postSecured(url, {
			groupIds: selectedGroups,
			name: name,
			phoneNumber: phoneNumber,
			campaignId: campaignId,
		});
	} catch (e) {
		console.log(e);
	}
}

export async function EditContact(contactId, name, phoneNumber, selectedGroups) {
	const url = new URL(
		`${stringLookup.baseUrl}/api/messaging/smscontacts/${contactId.toString()}`
	);

	try {
		await patchSecured(url, {
			name: name,
			phoneNumber: phoneNumber,
			groupIds: selectedGroups,
		});
	} catch (e) {
		console.log(e);
	}
}

export async function DeleteContact(contactId) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/smscontacts/${contactId}`);

	try {
		await deleteSecured(url, { contactId: contactId });
	} catch (e) {
		console.log(e);
	}
}

export async function GetAllGroupMessages(page, size) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/sms`);
	url.searchParams.append('page', page.toString());
	url.searchParams.append('pagesize', size.toString());
	try {
		var res = await fetchSecured(url);
		if (res.ok) {
			var data = await res.json();
			return data;
		}
	} catch (e) {
		console.log(e);
		return null;
	}
}

export async function GetGroupMessages(groupId, page, size) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/sms`);

	url.searchParams.append('groupid', groupId.toString());
	url.searchParams.append('page', page.toString());
	url.searchParams.append('pagesize', size.toString());
	try {
		var res = await fetchSecured(url);
		if (res.ok) {
			var data = await res.json();
			return data;
		}
	} catch (e) {
		console.log(e);
		return null;
	}
}

export async function SendMessageToGroup(groupId, text, campaignId) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/sms/sendtogroup`);
	try {
		await postSecured(url, {
			groupId: groupId.toString(),
			message: text,
			campaignId: campaignId,
		});
	} catch (e) {
		console.log(e);
	}
}

export async function SendDirectMessage(phoneNumber, text, campaignId) {
	const url = new URL(`${stringLookup.baseUrl}/api/messaging/sms/senddirect`);
	try {
		await postSecured(url, {
			phoneNumber: phoneNumber,
			message: text,
			campaignId: campaignId,
		});
	} catch (e) {
		console.log(e);
	}
}

// export async function DeleteContact(contactId) {
//     const url = new URL(`${stringLookup.smsApi}/smscontacts/${contactId}/deactivate`);
//     try {
//         return await postSecured(url, {});
//     } catch (e) {
//         console.log(e);
//     }
// }

export async function DeleteGroup(groupId) {
	const url = new URL(
		`${stringLookup.baseUrl}/api/messaging/groups/${groupId}/deactivate`
	);
	try {
		await postSecured(url, {});
	} catch (e) {
		console.log(e);
	}
}
