import stringLookup from './../stringslookup';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';
import { fetchSecured, fetchDefault } from '../fetchUtils';

const REQUEST_NEWS_TYPE = 'REQUEST_NEWS';
const RECEIVE_NEWS_TYPE = 'RECEIVE_NEWS';
const RECEIVE_NEWS_ERROR_TYPE = 'SET_ERROR';

const REQUEST_HEADLINES_TYPE = 'REQUEST_HEADLINE_ARTICLES';
const RECEIVE_HEADLINES_TYPE = 'RECEIVE_ARTICLES';

const REQUEST_RELATED_TYPE = 'REQUEST_RELATED_NEWS';
const RECEIVE_RELATED_TYPE = 'RECEIVE_RELATED_NEWS';
const RECEIVE_RELATED_ERROR_TYPE = 'SET_RELATED_ERROR';

const REQUEST_TEXT_TYPE = 'REQUEST_NEWS_TEXT';
const RECEIVE_TEXT_TYPE = 'RECEIVE_NEWS_TEXT';
const RECEIVE_TEXT_ERROR_TYPE = 'SET_TEXT_ERROR';

const REQUEST_TRANSLATED_TEXT_TYPE = 'REQUEST_TRANSLATED_NEWS_TEXT';
const RECEIVE_TRANSLATED_TEXT_TYPE = 'RECEIVE_TRANSLATED_NEWS_TEXT';
const RECEIVE_TRANSLATION_ERROR_TYPE = 'RECEIVE_TRANSLATION_ERROR';

const RESET_TRANSLATED_TEXT_TYPE = 'RESET_TRANSLATED_TEXT';

const REQUEST_COVID19_NEWS_TYPE = 'REQUEST_COVID19_NEWS';
const RECEIVE_COVID19_NEWS_TYPE = 'RECEIVE_COVID19_NEWS';
const RECEIVE_COVID19_NEWS_ERROR_TYPE = 'RECEIVE_COVID19_NEWS_ERROR';

const RECEIVE_LANDING_TOPICS_TYPE = 'RECEIVE_LANDING_TOPICS';
const RECEIVE_LANDING_TOPICS_ERROR_TYPE = 'RECEIVE_LANDING_TOPICS_ERROR';

const REQUEST_FILTERED_ARTICLES_TYPE = 'REQUEST_FILTERED_ARTICLES';
const RECEIVE_FILTERED_ARTICLES_TYPE = 'RECEIVE_FILTERED_ARTICLES';

const initialState = {
	id: null,
	topics: [],
	articles: [],
	newsRequestDispatched: false,
	relatedNewsFetchError: false,
	newsFetchError: false,
	newsLoading: false,
	relatedArticles: [],
	relatedNewsLoading: false,
	status: null,
	relatedResultsNumber: 0,
	originalText: null,
	selectedArticleLanguages: [],
	textFetchError: false,
	translatedText: null,
	translationLoading: false,
	translationError: false,
	totalArticles: 0,
	covid19News: [],
	covid19NewsLoading: false,
	covid19NewsFetchError: false,
	landingTopics: [],
	landingTopicsError: false,
	filteredNewsLoading: false,
};

export const actionCreators = {
	getNewsAsync: (_) => async (dispatch, getState) => {
		if (getState().news.newsLoading) {
			return;
		}

		dispatch({ type: REQUEST_NEWS_TYPE });
		setGlobalLoad(dispatch);

		const newsUrl = new URL('/api/crawler/news', stringLookup.baseUrl);

		try {
			const newsResponse = await fetchSecured(newsUrl);
			if (newsResponse.ok) {
				const news = await newsResponse.json();
				dispatch({ type: RECEIVE_NEWS_TYPE, news: news });
			} else {
				dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
		}

		unsetGlobalLoad(dispatch);
	},

	getLandingPageTopics: () => async (dispatch, getState) => {
		const url = new URL('/api/LandingPage', stringLookup.baseUrl);

		try {
			const topicsResponse = await fetchDefault(url);

			if (topicsResponse.ok) {
				const topicsPayload = await topicsResponse.json();
				dispatch({
					type: RECEIVE_LANDING_TOPICS_TYPE,
					landingTopics: topicsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_LANDING_TOPICS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_LANDING_TOPICS_ERROR_TYPE });
		}
	},

	// getHeadlineArticlesAsync:
	// 	(
	// 		topicFilters,
	// 		subtopicFilters,
	// 		keywordFilters,
	// 		page = 1,
	// 		pageSize = 50,
	// 		categoryFilters
	// 	) =>
	// 	async (dispatch, getState) => {
	// 		if (getState().news.newsLoading) {
	// 			return;
	// 		}

	// 		const organisationId = getState().auth.organisationId;

	// 		dispatch({ type: REQUEST_HEADLINES_TYPE });
	// 		setGlobalLoad(dispatch);

	// 		const url = new URL(
	// 			'/api/crawler/news/headlines',
	// 			stringLookup.baseUrl
	// 		);
	// 		url.searchParams.append('page', page);
	// 		url.searchParams.append('pageSize', pageSize);

	// 		categoryFilters.forEach((c) => {
	// 			url.searchParams.append('categories', c);
	// 		});

	// 		topicFilters.forEach((t) => {
	// 			url.searchParams.append('topics', t);
	// 		});

	// 		subtopicFilters.forEach((s) => {
	// 			url.searchParams.append('subtopics', s);
	// 		});

	// 		keywordFilters.forEach((k) => {
	// 			url.searchParams.append('keywords', k);
	// 		});

	// 		try {
	// 			const newsResponse =
	// 				organisationId === null
	// 					? await fetchDefault(url)
	// 					: await fetchSecured(url);

	// 			if (newsResponse.ok) {
	// 				const newsPayload = await newsResponse.json();
	// 				dispatch({
	// 					type: RECEIVE_HEADLINES_TYPE,
	// 					articles: newsPayload,
	// 				});
	// 			} else {
	// 				dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
	// 			}
	// 		} catch (err) {
	// 			dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
	// 		}

	// 		unsetGlobalLoad(dispatch);
	// 	},

	getHeadlineArticlesAsync:
		(
			topicFilters,
			subtopicFilters,
			keywordFilters,
			page = 1,
			pageSize = 50,
			categoryFilters,
			useCache = false
		) =>
		async (dispatch, getState) => {
			if (getState().news.filteredNewsLoading) {
				return;
			}

			const organisationId = getState().auth.organisationId;

			dispatch({ type: REQUEST_FILTERED_ARTICLES_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL('/api/crawler/news/search', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (useCache) {
				url.searchParams.append('useCache', useCache);
			}

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subtopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			try {
				const newsResponse =
					organisationId === null
						? await fetchDefault(url)
						: await fetchSecured(url);

				if (newsResponse.ok) {
					const newsPayload = await newsResponse.json();
					dispatch({
						type: RECEIVE_FILTERED_ARTICLES_TYPE,
						articles: newsPayload,
					});
				} else {
					dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	getSearchFilteredArticlesAsync:
		(
			topicFilters,
			subtopicFilters,
			keywordFilters,
			page = 1,
			pageSize = 50,
			categoryFilters
		) =>
		async (dispatch, getState) => {
			if (getState().news.filteredNewsLoading) {
				return;
			}

			const organisationId = getState().auth.organisationId;

			dispatch({ type: REQUEST_FILTERED_ARTICLES_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL('/api/crawler/news/search', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subtopics', s);
			});

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			try {
				const newsResponse =
					organisationId === null
						? await fetchDefault(url)
						: await fetchSecured(url);

				if (newsResponse.ok) {
					const newsPayload = await newsResponse.json();
					dispatch({
						type: RECEIVE_FILTERED_ARTICLES_TYPE,
						articles: newsPayload,
					});
				} else {
					dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_NEWS_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	getRelatedArticles:
		(pageId, topicId, pageNumber, pageSize) => async (dispatch, getState) => {
			if (getState().news.relatedNewsLoading) {
				return;
			}

			dispatch({ type: REQUEST_RELATED_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				`/api/crawler/news/related?articleId=${pageId}`,
				stringLookup.baseUrl
			);
			url.searchParams.append('topicId', topicId);
			url.searchParams.append('page', pageNumber);
			url.searchParams.append('pageSize', pageSize);

			try {
				const newsByTopicResponse = await fetchDefault(url);

				if (newsByTopicResponse.ok) {
					const newsPayload = await newsByTopicResponse.json();
					dispatch({
						type: RECEIVE_RELATED_TYPE,
						relatedArticles: newsPayload.articles,
						relatedResultsNumber: newsPayload.total,
					});
				} else {
					dispatch({ type: RECEIVE_RELATED_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_RELATED_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	getWebpageText: (pageId) => async (dispatch, getState) => {
		if (getState().news.newsLoading) {
			return;
		}

		dispatch({ type: REQUEST_TEXT_TYPE });
		// setGlobalLoad(dispatch);

		const url = new URL('/api/crawler/webpages', stringLookup.baseUrl);
		url.searchParams.append('id', pageId);
		try {
			const newsTextResponse = await fetchDefault(url);

			if (newsTextResponse.ok) {
				const textPayload = await newsTextResponse.json();
				dispatch({
					type: RECEIVE_TEXT_TYPE,
					originalText: textPayload,
					selectedArticleLanguages: textPayload.translatedToLanguages,
				});
			} else {
				dispatch({ type: RECEIVE_TEXT_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_TEXT_ERROR_TYPE });
		}

		// unsetGlobalLoad(dispatch);
	},

	getWebpageTranslation: (pageId) => async (dispatch, getState) => {
		if (getState().news.translationLoading) {
			return;
		}

		dispatch({ type: REQUEST_TRANSLATED_TEXT_TYPE });

		const url = new URL(
			'/api/crawler/webpages/translation',
			stringLookup.baseUrl
		);
		url.searchParams.append('id', pageId);

		try {
			const newsTextResponse = await fetchDefault(url);

			if (newsTextResponse.ok) {
				const textPayload = await newsTextResponse.json();
				dispatch({
					type: RECEIVE_TRANSLATED_TEXT_TYPE,
					translatedText: textPayload,
				});
			} else {
				dispatch({ type: RECEIVE_TRANSLATION_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_TRANSLATION_ERROR_TYPE });
		}
	},

	resetTranslatedText: () => async (dispatch, getState) => {
		dispatch({ type: RESET_TRANSLATED_TEXT_TYPE });
	},

	getCovid19News:
		(page = 1, pageSize = 50, county) =>
		async (dispatch, getState) => {
			if (getState().news.covid19NewsLoading) {
				return;
			}

			dispatch({ type: REQUEST_COVID19_NEWS_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL('/api/covid/news', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (county) {
				url.searchParams.append('county', county);
			}

			try {
				const newsResponse = await fetchDefault(url);
				if (newsResponse.ok) {
					const news = await newsResponse.json();
					dispatch({
						type: RECEIVE_COVID19_NEWS_TYPE,
						covid19News: news.data,
					});
				} else {
					dispatch({ type: RECEIVE_COVID19_NEWS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_COVID19_NEWS_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === REQUEST_HEADLINES_TYPE) {
		return {
			...state,
			newsLoading: true,
			newsRequestDispatched: true,
		};
	}

	if (action.type === REQUEST_FILTERED_ARTICLES_TYPE) {
		return {
			...state,
			filteredNewsLoading: true,
			newsRequestDispatched: true,
		};
	}

	if (action.type === REQUEST_RELATED_TYPE) {
		return {
			...state,
			relatedNewsLoading: true,
		};
	}

	if (action.type === REQUEST_TEXT_TYPE) {
		return {
			...state,
			relatedNewsLoading: true,
			translationLoading: true,
		};
	}

	if (action.type === REQUEST_TRANSLATED_TEXT_TYPE) {
		return {
			...state,
			translationLoading: true,
		};
	}

	if (action.type === RECEIVE_RELATED_TYPE) {
		return {
			...state,
			relatedNewsLoading: false,
			relatedArticles: action.relatedArticles,
			relatedResultsNumber: action.relatedResultsNumber,
		};
	}

	if (action.type === RECEIVE_LANDING_TOPICS_TYPE) {
		return {
			...state,
			landingTopics: action.landingTopics,
		};
	}

	if (action.type === RECEIVE_LANDING_TOPICS_ERROR_TYPE) {
		return {
			...state,
			landingTopicsError: true,
		};
	}

	if (action.type === RECEIVE_HEADLINES_TYPE) {
		return {
			...state,
			newsLoading: false,
			articles: action.articles,
			totalArticles: action.totalArticles,
		};
	}

	if (action.type === RECEIVE_FILTERED_ARTICLES_TYPE) {
		return {
			...state,
			filteredNewsLoading: false,
			articles: action.articles,
			totalArticles: action.totalArticles,
		};
	}

	if (action.type === RECEIVE_NEWS_TYPE) {
		return {
			...state,
			newsLoading: false,
			id: action.news.newsId,
			topics: action.news.topics,
			status: true,
		};
	}

	if (action.type === RECEIVE_TEXT_TYPE) {
		return {
			...state,
			relatedNewsLoading: false,
			translationLoading: false,
			originalText: action.originalText,
		};
	}

	if (action.type === RECEIVE_TRANSLATED_TEXT_TYPE) {
		return {
			...state,
			translationLoading: false,
			translationError: false,
			translatedText: action.translatedText,
		};
	}

	if (action.type === RECEIVE_NEWS_ERROR_TYPE) {
		return {
			...state,
			newsFetchError: true,
			newsLoading: false,
			filteredNewsLoading: false,
		};
	}

	if (action.type === RECEIVE_RELATED_ERROR_TYPE) {
		return {
			...state,
			relatedNewsFetchError: true,
			relatedNewsLoading: false,
		};
	}

	if (action.type === RECEIVE_TEXT_ERROR_TYPE) {
		return {
			...state,
			textFetchError: true,
			relatedNewsLoading: false,
		};
	}

	if (action.type === RECEIVE_TRANSLATION_ERROR_TYPE) {
		return {
			...state,
			translationError: true,
			translationLoading: false,
		};
	}

	if (action.type === REQUEST_COVID19_NEWS_TYPE) {
		return {
			...state,
			covid19NewsLoading: true,
		};
	}
	if (action.type === RECEIVE_COVID19_NEWS_TYPE) {
		return {
			...state,
			covid19NewsLoading: false,
			covid19News: action.covid19News,
		};
	}
	if (action.type === RECEIVE_COVID19_NEWS_ERROR_TYPE) {
		return {
			...state,
			covid19NewsLoading: false,
			covid19NewsFetchError: true,
		};
	}

	if (action.type === RESET_TRANSLATED_TEXT_TYPE) {
		return {
			...state,
			translatedText: action.translatedText,
		};
	}

	return state;
};
