import stringLookup from './../stringslookup';
import { fetchSecured, postSecured, deleteSecured, patchSecured } from './../fetchUtils';

const requestApiKeyType = 'REQUEST_CONFIG_API_KEYS';
const receiveApiKeyType = 'RECEIVE_CONFIG_API_KEYS';
const receiveApiKeyErrorType = 'RECEIVE_CONFIG_API_KEYS_FETCH_ERROR';

const addApiKeyType = 'ADD_CONFIG_API_KEYS';
const addApiKeyCompleteType = 'ADD_CONFIG_API_KEYS_COMPLETE';

const editApiKeyType = 'EDIT_CONFIG_API_KEYS';
const editApiKeyCompleteType = 'EDIT_CONFIG_API_KEYS_COMPLETE';

const deleteApiKeyType = 'DELETE_CONFIG_API_KEYS';
const deleteApiKeyCompleteType = 'DELETE_CONFIG_API_KEYS_COMPLETE';

const modifyApiKey400ErrorType = 'MODIFY_API_KEYS_400_ERROR_COMPLETE';
const modifyApiKey400ErrorReset = 'MODIFY_API_KEYS_400_ERROR_RESET';

const initialApiKeysState = {
	apiKeys: [],
	totalKeys: 0,
	apiKeysLoading: false,
	addingApiKey: false,
	editingApiKey: false,
	deletingApiKey: false,
	validationError: null,
};

export const apiKeysActionCreators = {
	requestApiKeys: (page, pageSize) => async (dispatch, getState) => {
		if (getState().configurationsApiKeys.apiKeysLoading) {
			return;
		}

		dispatch({ type: requestApiKeyType });

		const url = new URL('/api/external/apikeys/list', stringLookup.baseUrl);
		url.searchParams.append('page', page);
		url.searchParams.append('pageSize', pageSize);

		try {
			const apiKeysResponse = await fetchSecured(url);

			if (apiKeysResponse.ok) {
				const apiKeysRes = await apiKeysResponse.json();
				dispatch({
					type: receiveApiKeyType,
					apiKeys: apiKeysRes.data,
					totalKeys: apiKeysRes.total,
				});
			} else {
				dispatch({ type: receiveApiKeyErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveApiKeyErrorType });
		}
	},

	editApiKey:
		(apiKey, mode = 'ADD') =>
		async (dispatch, getState) => {
			const metadata = {};
			if (getState().configurationsApiKeys.addingApiKey) {
				return metadata;
			}

			dispatch({ type: addApiKeyType });
			const url = new URL('/api/external/apikeys', stringLookup.baseUrl);

			try {
				const apiKeysResponse =
					mode === 'ADD'
						? await postSecured(url, apiKey)
						: await patchSecured(url, apiKey);

				if (apiKeysResponse.ok) {
					dispatch({ type: addApiKeyCompleteType });
				} else if (apiKeysResponse.status === 400) {
					metadata._400Error = true;

					const _400ResMessage = await apiKeysResponse.json();
					dispatch({
						type: modifyApiKey400ErrorType,
						validationError: _400ResMessage.message,
					});
				} else {
					dispatch({ type: addApiKeyCompleteType });
				}
			} catch (err) {
				dispatch({ type: addApiKeyCompleteType });
			}

			return metadata;
		},

	// deleteApiKey: (id) => async (dispatch, getState) => {

	// 	if (getState().configurationsApiKeys.deletingApiKey) {
	// 		return;
	// 	}

	// 	dispatch({ type: deleteApiKeyType });

	// 	const url = new URL(`/api/crawler/Topic/${id}`, stringLookup.baseUrl);

	// 	try {
	// 		const deleteResponse= await deleteSecured(url);

	// 		if (deleteResponse.ok) {
	// 			dispatch({ type: deleteApiKeyCompleteType});
	// 		}
	// 		else {
	// 			dispatch({ type: deleteApiKeyCompleteType });
	// 		}
	// 	} catch (err) {
	// 		dispatch({ type: deleteApiKeyCompleteType });
	// 	}
	// },
	resetValidationMessage: () => async (dispatch, getState) => {
		dispatch({ type: modifyApiKey400ErrorReset });
	},
};

export const reducer = (state, action) => {
	state = state || initialApiKeysState;

	if (action.type === requestApiKeyType) {
		return {
			...state,
			apiKeysLoading: true,
		};
	}

	if (action.type === receiveApiKeyType) {
		return {
			...state,
			apiKeys: action.apiKeys,
			totalKeys: action.totalKeys,
			apiKeysLoading: false,
			validationError: null,
		};
	}
	if (action.type === receiveApiKeyErrorType) {
		return {
			...state,
			apiKeysLoading: false,
		};
	}

	if (action.type === addApiKeyType) {
		return {
			...state,
			addingApiKey: true,
		};
	}

	if (action.type === addApiKeyCompleteType) {
		return {
			...state,
			addingApiKey: false,
		};
	}

	// if (action.type === deleteApiKeyType) {
	// 	return {
	// 		...state,
	// 		deletingApiKey: true
	// 	};
	// }

	// if (action.type === deleteApiKeyCompleteType) {
	// 	return {
	// 		...state,
	// 		deletingApiKey: false
	// 	};
	// }

	if (action.type === modifyApiKey400ErrorType) {
		return {
			...state,
			validationError: action.validationError,
			addingApiKey: false,
		};
	}

	if (action.type === modifyApiKey400ErrorReset) {
		return {
			...state,
			validationError: null,
		};
	}

	return state;
};
