import stringLookup from '../stringslookup';
import {
	fetchSecured,
	patchSecured,
	patchSecuredForm,
	postSecured,
	postSecuredForm,
} from '../fetchUtils';

const requestIncidentsType = 'REQUEST_INCIDENTS';
const receiveIncidentsType = 'RECEIVE_INCIDENTS';
const receiveIncidentsErrorType = 'RECEIVE_INCIDENTS_ERROR';

const requestActorsType = 'REQUEST_ACTORS';
const receiveActorsType = 'RECEIVE_ACTORS';
const receiveActorsErrorType = 'RECEIVE_ACTORS_ERROR';

const requestTargetsType = 'REQUEST_TARGETS';
const receiveTargetsType = 'RECEIVE_TARGETS';
const receiveTargetsErrorType = 'RECEIVE_TARGETS_ERROR';

const requestIncidentArticlesType = 'REQUEST_INCIDENT_ARTICLES';
const receiveIncidentArticlesType = 'RECEIVE_INCIDENT_ARTICLES';
const receiveIncidentArticlesErrorType = 'RECEIVE_INCIDENT_ARTICLES_ERROR';

const requestAddIncidentType = 'REQUEST_ADD_INCIDENT';
const addIncidentCompleteType = 'ADD_INCIDENT_COMPLETE';
const addIncidentErrorType = 'ADD_INCIDENT_ERROR';
const setAddIncidentErrorType = 'SET_ADD_INCIDENT_ERROR';
const setAddIncidentSuccessType = 'SET_ADD_INCIDENT_SUCCESS';

const REQUEST_INCIDENT_TYPES_TYPE = 'REQUEST_INCIDENT_TYPES_TYPE';
const RECEIVE_INCIDENT_TYPES_SUCCESS_TYPE =
	'RECEIVE_INCIDENT_TYPES_SUCCESS_TYPE';
const RECEIVE_INCIDENT_TYPES_ERROR_TYPE = 'RECEIVE_INCIDENT_TYPES_ERROR_TYPE';

const requestIncidentRecompilationType = 'REQUEST_INCIDENT_RECOMPILATION_TYPE';
const receiveIncidentRecompilationType = 'RECEIVE_INCIDENT_RECOMPILATION_TYPE';
const receiveIncidentRecompilationErrorType =
	'RECEIVE_INCIDENT_RECOMPILATION_ERROR_TYPE';

const requestIncidentRecompilationDataType =
	'REQUEST_INCIDENT_RECOMPILATION_DATA_TYPE';
const receiveIncidentRecompilationDataType =
	'RECEIVE_INCIDENT_RECOMPILATION_DATA_TYPE';
const receiveIncidentRecompilationDataErrorType =
	'RECEIVE_INCIDENT_RECOMPILATION_DATA_ERROR_TYPE';

const requestClusterDetailsType = 'REQUEST_CLUSTER_DETAILS_TYPE';
const receiveClusterDetailsType = 'RECEIVE_CLUSTER_DETAILS_TYPE';
const receiveClusterDetailsErrorType = 'RECEIVE_CLUSTER_DETAILS_ERROR_TYPE';

const requestClusterRecompilationType = 'REQUEST_CLUSTER_RECOMPILATION_TYPE';
const receiveClusterRecompilationType = 'RECEIVE_CLUSTER_RECOMPILATION_TYPE';
const receiveClusterRecompilationErrorType =
	'RECEIVE_CLUSTER_RECOMPILATION_ERROR_TYPE';

const requestClusterRecompilationDataType =
	'REQUEST_CLUSTER_RECOMPILATION_DATA_TYPE';
const receiveClusterRecompilationDataType =
	'RECEIVE_CLUSTER_RECOMPILATION_DATA_TYPE';
const receiveClusterRecompilationDataErrorType =
	'RECEIVE_CLUSTER_RECOMPILATION_DATA_ERROR_TYPE';

const requestFieldTypeClassifiersType = 'REQUEST_FIELD_TYPE_CLASSIFIERS_TYPE';
const receiveFieldTypeClassifiersType = 'RECEIVE_FIELD_TYPE_CLASSIFIERS_TYPE';
const receiveFieldTypeClassifiersErrorType =
	'RECEIVE_FIELD_TYPE_CLASSIFIERS_ERROR_TYPE';

const requestProcessingIncidentsType = 'REQUEST_PROCESSING_INCIDENTS_TYPE';
const receiveProcessingIncidentsType = 'RECEIVE_PROCESSING_INCIDENTS_TYPE';
const receiveProcessingIncidentsErrorType =
	'RECEIVE_PROCESSING_INCIDENTS_ERROR_TYPE';

const requestIncidenceSearchArticlesType =
	'REQUEST_INCIDENCE_SEARCH_ARTICLES_TYPE';
const receiveIncidenceSearchArticlesType =
	'RECEIVE_INCIDENCE_SEARCH_ARTICLES_TYPE';
const receiveIncidenceSearchArticlesErrorType =
	'RECEIVE_INCIDENCE_SEARCH_ARTICLES_ERROR_TYPE';

const requestFloatArticleType = 'REQUEST_FLOAT_ARTICLE_TYPE';
const receiveFloatArticleType = 'RECEIVE_FLOAT_ARTICLE_TYPE';
const receiveFloatArticleErrorType = 'RECEIVE_FLOAT_ARTICLE_ERROR_TYPE';

const requestSetArticleToIncidenceType =
	'REQUEST_SET_ARTICLE_TO_INCIDENCE_TYPE';
const receiveSetArticleToIncidenceType =
	'RECEIVE_SET_ARTICLE_TO_INCIDENCE_TYPE';
const receiveSetArticleToIncidenceErrorType =
	'RECEIVE_SET_ARTICLE_TO_INCIDENCE_ERROR_TYPE';

const requestSimilarIncidentsType = 'REQUEST_SIMILAR_INCIDENTS_TYPE';
const receiveSimilarIncidentsType = 'RECEIVE_SIMILAR_INCIDENTS_TYPE';
const receiveSimilarIncidentsErrorType = 'RECEIVE_SIMILAR_INCIDENTS_ERROR_TYPE';

const requestBulkClusteringType = 'REQUEST_BULK_CLUSTERING_TYPE';
const receiveBulkClusteringType = 'RECEIVE_BULK_CLUSTERING_TYPE';
const receiveBulkClusteringErrorType = 'RECEIVE_BULK_CLUSTERING_ERROR_TYPE';

const requestIncidentUpdateType = 'REQUEST_INCIDENT_UPDATE_TYPE';
const receiveIncidentUpdateSuccessType = 'RECEIVE_INCIDENT_UPDATE_SUCCESS_TYPE';
const receiveIncidentUpdateErrorType = 'RECEIVE_INCIDENT_UPDATE_ERROR_TYPE';

const requestIncidentDataByClusterIdType =
	'REQUEST_INCIDENT_DATA_BY_CLUSTER_ID_TYPE';
const receiveIncidentDataByClusterIdSuccessType =
	'RECEIVE_INCIDENT_DATA_BY_CLUSTER_ID_SUCCESS_TYPE';
const receiveIncidentDataByClusterIdErrorType =
	'RECEIVE_INCIDENT_DATA_BY_CLUSTER_ID_ERROR_TYPE';

const requestAllWeaponsType = 'REQUEST_ALL_WEAPONS_TYPE';
const receiveAllWeaponsSuccessType = 'RECEIVE_ALL_WEAPONS_SUCCESS_TYPE';
const receiveAllWeaponsErrorType = 'RECEIVE_ALL_WEAPONS_ERROR_TYPE';

const requestWeaponTypesType = 'REQUEST_WEAPON_TYPES_TYPE';
const receiveWeaponTypesSuccessType = 'RECEIVE_WEAPON_TYPES_SUCCESS_TYPE';
const receiveWeaponTypesErrorType = 'RECEIVE_WEAPON_TYPES_ERROR_TYPE';

const requestIncidentTypesType = 'REQUEST_ALL_INCIDENT_TYPES_TYPE';
const receiveIncidentTypesSuccessType =
	'REQUEST_ALL_INCIDENT_TYPES_SUCCESS_TYPE';
const receiveIncidentTypesErrorType = 'REQUEST_INCIDENT_TYPES_ERROR_TYPE';

const requestIncidentTypeCreateType = 'REQUEST_INCIDENT_TYPE_CREATE_TYPE';
const receiveIncidentTypeCreateSuccessType =
	'RECEIVE_INCIDENT_TYPE_CREATE_SUCCESS_TYPE';
const receiveIncidentTypeCreateErrorType =
	'RECEIVE_INCIDENT_TYPE_CREATE_ERROR_TYPE';

const requestIncidentTypeEditType = 'REQUEST_INCIDENT_TYPE_EDIT_TYPE';
const receiveIncidentTypeEditSuccessType =
	'RECEIVE_INCIDENT_TYPE_EDIT_SUCCESS_TYPE';
const receiveIncidentTypeEditErrorType =
	'RECEIVE_INCIDENT_TYPE_EDIT_ERROR_TYPE';

const requestIncidentDataByWebpageIdType =
	'REQUEST_INCIDENT_DATA_BY_WEBPAGE_ID_TYPE';
const receiveIncidentDataByWebpageIdSuccessType =
	'RECEIVE_INCIDENT_DATA_BY_WEBPAGE_ID_SUCCESS_TYPE';
const receiveIncidentDataByWebpageIdErrorType =
	'RECEIVE_INCIDENT_DATA_BY_WEBPAGE_ID_ERROR_TYPE';

const setIncidentsFilterDrawerOpenType = 'SET_INCIDENTS_FILTER_DRAWER_OPEN';

const setSelectedDaysType = 'SET_SELECTED_DAYS';

const mergeClustersType = 'MERGE_CLUSTER_TYPE';
const mergeClustersSuccessType = 'MERGE_CLUSTER_SUCCESS_TYPE';
const mergeClustersErrorType = 'MERGE_CLUSTER_ERROR_TYPE';

const incidentToClusterConversionType = 'INCIDENT_TO_CLUSTER_CONVERSION_TYPE';
const incidentToClusterConversionSuccessType =
	'INCIDENT_TO_CLUSTER_CONVERSION_SUCCESS_TYPE';
const incidentToClusterConversionErrorType =
	'INCIDENT_TO_CLUSTER_CONVERSION_ERROR_TYPE';

const getIncidentsStatusType = 'GET_INCIDENTS_STATUS_TYPE';
const getIncidentsStatusSuccessType = 'GET_INCIDENTS_STATUS_SUCCESS_TYPE';
const getIncidentsStatusErrorType = 'GET_INCIDENTS_STATUS_ERROR_TYPE';

const initialState = {
	incidentsList: [],
	incidentsTotal: 0,
	incidentsLoading: false,
	incidentsListError: false,
	actorsList: [],
	actorsLoading: false,
	actorsListError: false,
	targetsList: [],
	targetsLoading: false,
	targetsListError: false,
	addingIncident: false,
	addIncidentSuccess: false,
	addIncidentError: false,
	addIncidentErrorMessage: null,
	incidentArticles: [],
	incidentArticlesLoading: false,
	incidentArticlesError: false,
	incidentTypesLoading: false,
	incidentTypes: [],
	incidentTypesError: false,
	incidentsDrawerOpen: true,

	initializingIncidentRecompilation: false,
	incidentRecompilationData: [],
	incidentRecompilationError: false,

	incidentRecompilationDataLoading: false,

	clusterLoading: false,
	clusterData: [],
	clusterError: false,

	clusterRecompilationDataLoading: false,
	clusterRecompilationError: false,
	clusterRecompilationData: null,
	clusterRecompilationLoading: false,

	fieldTypeClassifiers: [],
	fieldTypeClassifiersError: false,
	fieldTypeClassifiersLoading: false,

	processingIncidentsLoading: false,
	processingIncidentsData: [],
	processingIncidentsDataTotal: 0,
	processingIncidentsError: false,

	incidenceArticlesSearchLoading: false,
	incidenceSearchArticles: [],
	incidenceArticlesSearchError: false,

	floatingArticle: false,
	floatingArticleError: false,
	newIncidentClusterId: null,

	setArticleToIncidenceLoading: false,
	setArticleToIncidenceError: false,
	articleToIncidenceData: null,

	similarIncidents: [],
	similarIncidentsLoading: false,
	similarIncidentsError: false,

	bulkClusterReqLoading: false,
	bulkClusterReqError: false,

	updatingIncident: false,
	incidentUpdateError: false,

	incidentDataLoading: false,
	incidentData: null,
	incidentDataError: false,

	allWeapons: [],
	allWeaponsError: false,
	allWeaponsLoading: false,

	weaponTypes: [],
	weaponTypesLoading: false,
	weaponsTypesError: false,

	allIncidentTypes: [],
	incidentTypesLoading: false,
	incidentTypesError: false,

	editingIncidentType: false,
	creatingIncidentType: false,
	incidentTypeEditingError: false,
	incidentTypeCreationError: false,

	webpageIncidentData: null,
	webpageIncidentDataLoading: false,
	webpageIncidentDataError: false,
	selectedDays: 7,

	mergingClusters: false,
	mergingClustersError: false,

	convertingIncidentToCluster: false,
	incidentToClusterConversionError: false,

	incidentsDataStatus: [],
	fetchingIncidentsStatus: false,
	incidentsStatusDataError: false,
};

export const incidentsActionCreators = {
	setIncidentsFilterDrawerOpen:
		(incidentsDrawerOpen) => async (dispatch, _) => {
			dispatch({
				type: setIncidentsFilterDrawerOpenType,
				incidentsDrawerOpen,
			});
		},

	setSelectedDays: (selectedDays) => async (dispatch, _) => {
		dispatch({
			type: setSelectedDaysType,
			selectedDays,
		});
	},

	getIncidencesPaged:
		(page, pageSize, filter) => async (dispatch, getState) => {
			if (getState().incidents.incidentsLoading) {
				return;
			}

			dispatch({ type: requestIncidentsType });

			const url = new URL(`${stringLookup.baseUrl}/api/crawler/incidence`);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			if (filter) {
				url.searchParams.append('summaryTextSearch', filter);
			}

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const payload = await res.json();
					dispatch({
						type: receiveIncidentsType,
						incidentsList: payload.data,
						incidentsTotal: payload.total,
					});
				} else {
					dispatch({ type: receiveIncidentsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveIncidentsErrorType });
			}
		},

	getActors: () => async (dispatch, getState) => {
		if (getState().incidents.actorsLoading) {
			return;
		}

		dispatch({ type: requestActorsType });

		const url = new URL(`${stringLookup.baseUrl}/api/crawler/incidence/actors`);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const payload = await res.json();
				dispatch({
					type: receiveActorsType,
					actorsList: payload,
				});
			} else {
				dispatch({ type: receiveActorsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveActorsErrorType });
		}
	},

	getTargets: (filter) => async (dispatch, getState) => {
		if (getState().incidents.targetsLoading) {
			return;
		}

		dispatch({ type: requestTargetsType });

		const url = new URL(`${stringLookup.baseUrl}/api/crawler/categorizedword`);
		url.searchParams.append('page', 1);
		url.searchParams.append('pageSize', 100);
		if (filter) {
			url.searchParams.append('filter', filter);
		}
		url.searchParams.append('getAll', 'true');
		url.searchParams.append('category', 'target');

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const payload = await res.json();
				dispatch({
					type: receiveTargetsType,
					targetsList: payload,
				});
			} else {
				dispatch({ type: receiveTargetsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveTargetsErrorType });
		}
	},

	getAllWeapons: () => async (dispatch, getState) => {
		if (getState().incidents.allWeaponsLoading) {
			return;
		}

		dispatch({ type: requestAllWeaponsType });

		try {
			const url = new URL('/api/crawler/categorizedword', stringLookup.baseUrl);
			url.searchParams.append('page', '99');
			url.searchParams.append('pageSize', '99');

			url.searchParams.append('getAll', 'true');
			url.searchParams.append('category', 'weapon');

			const res = await fetchSecured(url);

			if (res.ok) {
				const resPayload = await res.json();

				dispatch({
					type: receiveAllWeaponsSuccessType,
					allWeapons: resPayload,
				});
			} else {
				dispatch({ type: receiveAllWeaponsErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveAllWeaponsErrorType });
		}
	},

	getWeaponTypes: () => async (dispatch, getState) => {
		if (getState().incidents.weaponTypesLoading) {
			return;
		}

		dispatch({ type: requestWeaponTypesType });

		try {
			const url = new URL(
				'/api/crawler/categorizedwordsgroup/weapon',
				stringLookup.baseUrl
			);

			const res = await fetchSecured(url);

			if (res.ok) {
				const resPayload = await res.json();

				dispatch({
					type: receiveWeaponTypesSuccessType,
					weaponTypes: resPayload,
				});
			} else {
				dispatch({ type: receiveWeaponTypesErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveWeaponTypesErrorType });
		}
	},

	getIncidentArticles: (incidentId, pageSize) => async (dispatch, getState) => {
		if (getState().incidents.incidentArticlesLoading) {
			return;
		}

		dispatch({ type: requestIncidentArticlesType });

		const url = new URL(
			`/api/crawler/incidence/${incidentId}/articles`,
			stringLookup.baseUrl
		);
		url.searchParams.append('page', 1);

		if (pageSize) {
			url.searchParams.append('pageSize', pageSize);
		} else {
			url.searchParams.append('pageSize', 100);
		}

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const payload = await res.json();
				var incidentArticles = payload.data;
				incidentArticles.forEach((x) => {
					if (x.actors == null) {
						x.actors = [];
					}
					if (x.people == null) {
						x.people = [];
					}
					if (x.targets == null) {
						x.targets = [];
					}
				});
				dispatch({
					type: receiveIncidentArticlesType,
					incidentArticles: incidentArticles,
				});
			} else {
				dispatch({ type: receiveIncidentArticlesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveIncidentArticlesErrorType });
		}
	},

	getIncidentByClusterId: (clusterId) => async (dispatch, getState) => {
		if (getState().incidents.incidentDataLoading) {
			return;
		}

		dispatch({ type: requestIncidentDataByClusterIdType });

		try {
			const url = new URL(
				`/api/crawler/incidence/${clusterId}`,
				stringLookup.baseUrl
			);

			const res = await fetchSecured(url);

			if (res.ok) {
				const resPayload = await res.json();

				dispatch({
					type: receiveIncidentDataByClusterIdSuccessType,
					incidentData: resPayload,
				});
			} else {
				dispatch({ type: receiveIncidentDataByClusterIdErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveIncidentDataByClusterIdErrorType });
		}
	},

	addIncident: (incident) => async (dispatch, getState) => {
		if (getState().incidents.addingIncident) {
			return;
		}

		dispatch({ type: requestAddIncidentType });

		const url = new URL('/api/crawler/incidence', stringLookup.baseUrl);

		try {
			const res = await postSecured(url, incident);

			if (res.ok) {
				dispatch({ type: addIncidentCompleteType });
			} else if (res.status === 400) {
				const errorMessage = await res.text();
				dispatch({
					type: addIncidentErrorType,
					addIncidentErrorMessage: errorMessage,
				});
			} else {
				dispatch({
					type: addIncidentErrorType,
					addIncidentErrorMessage:
						'Error! The incident adding process has failed. Please try again.',
				});
			}
		} catch (err) {
			dispatch({
				type: addIncidentErrorType,
				addIncidentErrorMessage: err.message,
			});
		}
	},

	getIncidentTypes: () => async (dispatch, getState) => {
		if (getState().incidents.incidentTypesLoading) {
			return;
		}

		dispatch({ type: REQUEST_INCIDENT_TYPES_TYPE });

		try {
			const url = new URL('/api/crawler/incidence/types', stringLookup.baseUrl);
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: RECEIVE_INCIDENT_TYPES_SUCCESS_TYPE,
					resPayload: data,
				});
			} else {
				dispatch({ type: RECEIVE_INCIDENT_TYPES_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: RECEIVE_INCIDENT_TYPES_ERROR_TYPE });
		}
	},

	inititateIncidentRecompilation:
		(incidentRecompilationPayload) => async (dispatch, getState) => {
			if (getState().incidents.initializingIncidentRecompilation) {
				return;
			}
			dispatch({ type: requestIncidentRecompilationType });

			const url = new URL(
				'/api/crawler/subtopic/recompile',
				stringLookup.baseUrl
			);

			try {
				const res = await postSecured(url, incidentRecompilationPayload);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: receiveIncidentRecompilationType,
						incidentRecompilationData: data,
					});
				} else {
					dispatch({ type: receiveIncidentRecompilationErrorType });
				}
			} catch (err) {
				dispatch({
					type: receiveIncidentRecompilationErrorType,
				});
			}
		},

	requestIncidentRecompilationData: () => async (dispatch, getState) => {
		if (getState().incidents.incidentRecompilationDataLoading) {
			return;
		}

		dispatch({ type: requestIncidentRecompilationDataType });

		try {
			const url = new URL(
				'/api/crawler/subtopic/recompile',
				stringLookup.baseUrl
			);

			url.searchParams.append('recompileIncidents', true);

			const res = await fetchSecured(url);

			if (res.status === 200) {
				const incidentRecompilationData = await res.json();

				dispatch({
					type: receiveIncidentRecompilationDataType,
					incidentRecompilationData,
				});
			} else {
				dispatch({ type: receiveIncidentRecompilationDataErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveIncidentRecompilationDataErrorType });
		}
	},

	getClusterDetailsById: (clusterId) => async (dispatch, getState) => {
		if (getState().incidents.clusterLoading) {
			return;
		}

		dispatch({ type: requestClusterDetailsType });

		try {
			const url = new URL(
				'api/analytics/incidents/cluster',
				stringLookup.baseUrl
			);

			url.searchParams.append('clusterId', clusterId);

			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: receiveClusterDetailsType,
					clusterData: data,
				});
			} else {
				dispatch({ type: receiveClusterDetailsErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveClusterDetailsErrorType });
		}
	},

	initiateClusterRecompilation:
		(recompilationPayload) => async (dispatch, getState) => {
			if (getState().incidents.clusterRecompilationLoading) {
				return;
			}

			dispatch({ type: requestClusterRecompilationType });

			try {
				const url = new URL(
					'/api/crawler/subtopic/recompile',
					stringLookup.baseUrl
				);

				const res = await postSecured(url, recompilationPayload);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveClusterRecompilationType,
						clusterRecompilationData: data,
					});
				} else {
					dispatch({
						type: receiveClusterRecompilationErrorType,
					});
				}
			} catch (error) {
				dispatch({
					type: receiveClusterRecompilationErrorType,
				});
			}
		},

	requestClusterRecompilationData:
		(clusterId) => async (dispatch, getState) => {
			if (getState().incidents.clusterRecompilationDataLoading) {
				return;
			}

			dispatch({ type: requestClusterRecompilationDataType });
			try {
				const url = new URL(
					'/api/crawler/subtopic/recompile',
					stringLookup.baseUrl
				);

				url.searchParams.append('clusterId', clusterId);

				const res = await fetchSecured(url);

				if (res.status === 200) {
					const data = await res.json();

					dispatch({
						type: receiveClusterRecompilationDataType,
						clusterRecompilationData: data,
					});
				} else {
					dispatch({
						type: receiveClusterRecompilationDataErrorType,
					});
				}
			} catch (error) {
				dispatch({
					type: receiveClusterRecompilationDataErrorType,
				});
			}
		},

	getClassifiersByIncidentFieldType:
		(payload) => async (dispatch, getState) => {
			if (getState().incidents.fieldTypeClassifiersLoading) {
				return;
			}

			dispatch({ type: requestFieldTypeClassifiersType });

			try {
				const url = new URL(
					'/api/crawler/incidence/classifiers',
					stringLookup.baseUrl
				);
				url.searchParams.append('mlAction', payload);

				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveFieldTypeClassifiersType,
						fieldTypeClassifiers: data,
					});
				} else {
					dispatch({ type: receiveFieldTypeClassifiersErrorType });
				}
			} catch (error) {
				dispatch({ type: receiveFieldTypeClassifiersErrorType });
			}
		},

	setAddIncidentError: (bool) => async (dispatch) => {
		dispatch({
			type: setAddIncidentErrorType,
			addIncidentError: bool,
		});
	},

	setAddIncidentSuccess: (bool) => async (dispatch) => {
		dispatch({
			type: setAddIncidentSuccessType,
			addIncidentSuccess: bool,
		});
	},

	getProcessingIncidents:
		(page, pageSize, filter) => async (dispatch, getState) => {
			if (getState().incidents.processingIncidentsLoading) {
				return;
			}

			dispatch({ type: requestProcessingIncidentsType });

			try {
				const url = new URL(
					'/api/crawler/incidence/processing',
					stringLookup.baseUrl
				);

				url.searchParams.append('page', page);
				url.searchParams.append('pageSize', pageSize);
				if (filter) {
					url.searchParams.append('summaryTextSearch', filter);
				}

				const res = await fetchSecured(url);

				if (res.ok) {
					const payload = await res.json();

					dispatch({
						type: receiveProcessingIncidentsType,
						processingIncidentsData: payload.data,
						processingIncidentsDataTotal: payload.total,
					});
				} else {
					dispatch({ type: receiveProcessingIncidentsErrorType });
				}
			} catch (error) {
				dispatch({
					type: receiveProcessingIncidentsErrorType,
				});
			}
		},

	searchIncidenceArticles:
		(page, pageSize, searchString) => async (dispatch, getState) => {
			if (getState().incidents.incidenceArticlesSearchLoading) {
				return;
			}

			dispatch({ type: requestIncidenceSearchArticlesType });

			try {
				const url = new URL(
					'/api/crawler/incidence/articles',
					stringLookup.baseUrl
				);

				url.searchParams.append('page', page);
				url.searchParams.append('pageSize', pageSize);

				if (searchString) {
					url.searchParams.append('titleFilter', searchString);
				}

				const res = await fetchSecured(url);

				if (res.ok) {
					const payload = await res.json();

					dispatch({
						type: receiveIncidenceSearchArticlesType,
						incidenceSearchArticles: payload.data,
					});
				} else {
					dispatch({ type: receiveIncidenceSearchArticlesErrorType });
				}
			} catch (error) {
				dispatch({ type: receiveIncidenceSearchArticlesErrorType });
			}
		},

	handleArticleFloating:
		(articleId, incidentId, createIncidentFromArticle) =>
		async (dispatch, getState) => {
			if (getState().incidents.floatingArticle) {
				return;
			}

			dispatch({ type: requestFloatArticleType });

			try {
				let url = new URL(
					`/api/crawler/incidence/${incidentId}/remove/${articleId}`,
					stringLookup.baseUrl
				);

				if (createIncidentFromArticle) {
					url = new URL(
						`/api/crawler/incidence/${incidentId}/float/${articleId}`,
						stringLookup.baseUrl
					);
				}

				const res = await postSecured(url, {});

				if (res.ok) {
					dispatch({
						type: receiveFloatArticleType,
					});
				} else {
					dispatch({ type: receiveFloatArticleErrorType });
				}
			} catch (error) {
				dispatch({
					type: receiveFloatArticleErrorType,
				});
			}
		},

	setArticleToIncidence: (id, from, to) => async (dispatch, getState) => {
		if (getState().incidents.setArticleToIncidenceLoading) {
			return;
		}

		dispatch({ type: requestSetArticleToIncidenceType });

		try {
			const url = new URL(
				`/api/crawler/incidence/${from}/articles/${id}/move/${to}`,
				stringLookup.baseUrl
			);

			const res = await postSecured(url);

			if (res.ok) {
				const payload = await res.json();
				dispatch({
					type: receiveSetArticleToIncidenceType,
					articleToIncidenceData: payload.data,
				});
			} else {
				dispatch({
					type: receiveSetArticleToIncidenceErrorType,
				});
			}
		} catch (error) {
			dispatch({ type: receiveSetArticleToIncidenceErrorType });
		}
	},

	getSimilarIncidents: (req) => async (dispatch, getState) => {
		if (getState().incidents.similarIncidentsLoading) {
			return;
		}

		dispatch({ type: requestSimilarIncidentsType });

		try {
			const url = new URL(
				'/api/crawler/incidence/similar',
				stringLookup.baseUrl
			);

			url.searchParams.append('incidenceId', req.incidenceId);

			if (req.threshold) {
				url.searchParams.append('threshold', req.threshold);
			}

			if (req.searchString) {
				url.searchParams.append('searchString', req.searchString);
			}

			if (req.to && req.from) {
				url.searchParams.append('from', req.from.toISOString());
				url.searchParams.append('to', req.to.toISOString());
			}

			const res = await fetchSecured(url);

			if (res.ok) {
				const resPayload = await res.json();

				dispatch({
					type: receiveSimilarIncidentsType,
					similarIncidents: resPayload.data,
				});
			} else {
				dispatch({ type: receiveSimilarIncidentsErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveSimilarIncidentsErrorType });
		}
	},

	moveBulkIncidentsToCluster: (payload) => async (dispatch, getState) => {
		if (getState().incidents.bulkClusterReqLoading) {
			return;
		}

		dispatch({ type: requestBulkClusteringType });

		try {
			const url = new URL(
				'/api/crawler/incidence/move-bulk',
				stringLookup.baseUrl
			);

			const res = await postSecured(url, payload);

			if (res.ok) {
				dispatch({ type: receiveBulkClusteringType });
			} else {
				dispatch({ type: receiveBulkClusteringErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveBulkClusteringErrorType });
		}
	},

	updateIncidentMetadata: (article) => async (dispatch, getState) => {
		if (getState().incidents.updatingIncident) {
			return;
		}

		dispatch({ type: requestIncidentUpdateType });

		try {
			const { actors, targets, weapons, eventNumbers, incidentType } = article;

			const locationData = {
				geoCode: {
					addresses: article?.addresses,
					latitude: article?.latitude,
					longitude: article?.longitude,
				},
				locationName: article?.locationName,
			};

			const articleId = article.mostRelevantArticleId ?? article.id;

			const payload = {
				articleId,
				datePublished: article.dateAddedUtc ?? article.datePublished,
				actors,
				targets,
				location: article.location ?? locationData,
				weapons,
				eventNumbers: article.eventNumbers ?? {},
				incidentType,
			};

			const url = new URL(
				`/api/crawler/incidence/articles/${articleId}`,
				stringLookup.baseUrl
			);

			const res = await patchSecured(url, payload);

			if (res.ok) {
				dispatch({ type: receiveIncidentUpdateSuccessType });
			} else {
				dispatch({ type: receiveIncidentUpdateErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveIncidentUpdateErrorType });
		}
	},

	getAllIncidentTypes:
		(datasetFilter = null, all = false) =>
		async (dispatch, getState) => {
			if (getState().incidents.incidentTypesLoading) {
				return;
			}

			dispatch({ type: requestIncidentTypesType });

			try {
				const url = new URL(
					'/api/crawler/incidence/incident-types',
					stringLookup.baseUrl
				);

				if (datasetFilter) {
					url.searchParams.append('datasetsFilter', datasetFilter);
				}

				if (all) {
					url.searchParams.append('all', all);
				}

				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: receiveIncidentTypesSuccessType,
						allIncidentTypes: data,
					});
				} else {
					dispatch({ type: receiveIncidentTypesErrorType });
				}
			} catch (error) {
				dispatch({ type: receiveIncidentTypesErrorType });
			}
		},

	getIncidentTypesIcons: (url) => async (dispatch, getState) => {
		const res = await fetch(url);

		const svg = res.text();
	},

	createIncidentType: (incidentTypeDetails) => async (dispatch, getState) => {
		if (getState().incidents.creatingIncidentType) {
			return;
		}

		dispatch({ type: requestIncidentTypeCreateType });

		try {
			const url = new URL(
				'/api/crawler/incidence/incident-types',
				stringLookup.baseUrl
			);

			if (incidentTypeDetails == null) {
				return;
			}

			const res = await postSecuredForm(url, incidentTypeDetails);

			if (res.ok) {
				dispatch({
					type: receiveIncidentTypeCreateSuccessType,
				});
			} else {
				dispatch({ type: receiveIncidentTypeCreateErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveIncidentTypeCreateErrorType });
		}
	},

	editIncidentType:
		(incidentTypeDetails, incidentTypeName, editSvgFile) =>
		async (dispatch, getState) => {
			if (getState().incidents.editingIncidentType) {
				return;
			}

			dispatch({ type: requestIncidentTypeEditType });

			try {
				const url = new URL(
					'/api/crawler/incidence/incident-types',
					stringLookup.baseUrl
				);

				url.searchParams.append('editSvgFile', editSvgFile);
				url.searchParams.append('incidentTypeName', incidentTypeName);

				if (incidentTypeDetails == null) {
					return;
				}

				const res = await patchSecuredForm(url, incidentTypeDetails);

				if (res.ok) {
					dispatch({
						type: receiveIncidentTypeEditSuccessType,
					});
				} else {
					dispatch({ type: receiveIncidentTypeEditErrorType });
				}
			} catch (error) {
				dispatch({ type: receiveIncidentTypeEditErrorType });
			}
		},

	getIncidentByWebpageId: (webpageId) => async (dispatch, getState) => {
		if (getState().incidents.webpageIncidentDataLoading) {
			return;
		}

		dispatch({ type: requestIncidentDataByWebpageIdType });

		try {
			const url = new URL(
				'/api/crawler/incidence/elasticsearch',
				stringLookup.baseUrl
			);
			url.searchParams.append('elasticsearchId', webpageId);

			const res = await fetchSecured(url);

			if (res.ok) {
				const resPayload = await res.json();

				dispatch({
					type: receiveIncidentDataByWebpageIdSuccessType,
					webpageIncidentData: resPayload,
				});
			} else {
				dispatch({ type: receiveIncidentDataByWebpageIdErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveIncidentDataByWebpageIdErrorType });
		}
	},

	mergeClusters: (payload) => async (dispatch, getState) => {
		if (getState().incidents.mergingClusters) return;

		const url = new URL(
			'/api/crawler/incidence/merge-clusters',
			stringLookup.baseUrl
		);

		if (!payload || !payload?.from || !payload?.to) return;

		dispatch({ type: mergeClustersType });

		try {
			const res = await postSecured(url, payload);

			if (res.ok) {
				const data = await res.json();

				dispatch({ type: mergeClustersSuccessType });
			} else {
				dispatch({ type: mergeClustersErrorType });
			}
		} catch (error) {
			dispatch({ type: mergeClustersErrorType });
		}
	},
	createIncidentFromArticle:
		(clusterId, articleId) => async (dispatch, getState) => {
			if (getState().incidents.convertingIncidentToCluster) return;

			dispatch({ type: incidentToClusterConversionType });

			try {
				const url = new URL(
					`/api/crawler/incidence/${clusterId}/float/${articleId}`,
					stringLookup.baseUrl
				);

				const res = await postSecured(url, {});

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: incidentToClusterConversionSuccessType,
						newIncidentClusterId: data.clusterId,
					});
				} else {
					dispatch({ type: incidentToClusterConversionErrorType });
				}
			} catch (error) {
				dispatch({ type: incidentToClusterConversionErrorType });
			}
		},

	getIncidentsStatus: () => async (dispatch, getState) => {
		if (getState().incidents.fetchingIncidentsStatus) return;

		const url = new URL('/api/crawler/incidence/status', stringLookup.baseUrl);

		dispatch({ type: getIncidentsStatusType });

		try {
			const res = await fetchSecured(url, 'incidents-status');

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: getIncidentsStatusSuccessType,
					incidentsDataStatus: data,
				});
			} else {
				dispatch({ type: getIncidentsStatusErrorType });
			}
		} catch (error) {
			dispatch({ type: getIncidentsStatusErrorType });
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === setIncidentsFilterDrawerOpenType) {
		return {
			...state,
			incidentsDrawerOpen: action.incidentsDrawerOpen,
		};
	}

	if (action.type === setSelectedDaysType) {
		return {
			...state,
			selectedDays: action.selectedDays,
		};
	}

	if (action.type === requestIncidentsType) {
		return {
			...state,
			incidentsLoading: true,
			incidentsListError: false,
		};
	}
	if (action.type === receiveIncidentsType) {
		return {
			...state,
			incidentsList: action.incidentsList,
			incidentsTotal: action.incidentsTotal,
		};
	}
	if (action.type === receiveIncidentsErrorType) {
		return {
			...state,
			incidentsLoading: false,
			incidentsListError: true,
		};
	}

	if (action.type === requestActorsType) {
		return {
			...state,
			actorsLoading: true,
			actorsListError: false,
		};
	}
	if (action.type === receiveActorsType) {
		return {
			...state,
			actorsLoading: false,
			actorsList: action.actorsList,
		};
	}
	if (action.type === receiveActorsErrorType) {
		return {
			...state,
			actorsLoading: false,
			actorsListError: true,
		};
	}

	if (action.type === requestTargetsType) {
		return {
			...state,
			targetsLoading: true,
			targetsListError: false,
		};
	}
	if (action.type === receiveTargetsType) {
		return {
			...state,
			targetsLoading: false,
			targetsList: action.targetsList,
		};
	}
	if (action.type === receiveTargetsErrorType) {
		return {
			...state,
			targetsLoading: false,
			targetsListError: true,
		};
	}

	if (action.type === requestIncidentArticlesType) {
		return {
			...state,
			incidentArticlesLoading: true,
			incidentArticlesError: false,
		};
	}
	if (action.type === receiveIncidentArticlesType) {
		return {
			...state,
			incidentArticlesLoading: false,
			incidentArticles: action.incidentArticles,
			incidentArticlesError: false,
		};
	}
	if (action.type === receiveIncidentArticlesErrorType) {
		return {
			...state,
			incidentArticlesLoading: false,
			incidentArticlesError: true,
		};
	}

	if (action.type === requestAddIncidentType) {
		return {
			...state,
			addingIncident: true,
			addIncidentSuccess: false,
			addIncidentError: false,
			addIncidentErrorMessage: null,
		};
	}
	if (action.type === addIncidentCompleteType) {
		return {
			...state,
			addingIncident: false,
			addIncidentSuccess: true,
		};
	}
	if (action.type === addIncidentErrorType) {
		return {
			...state,
			addingIncident: false,
			addIncidentError: true,
			addIncidentErrorMessage: action.addIncidentErrorMessage,
		};
	}
	if (action.type === setAddIncidentErrorType) {
		return {
			...state,
			addIncidentError: action.addIncidentError,
		};
	}

	if (action.type === setAddIncidentSuccessType) {
		return {
			...state,
			addIncidentSuccess: action.addIncidentSuccess,
		};
	}

	if (action.type === REQUEST_INCIDENT_TYPES_TYPE) {
		return {
			...state,
			incidentTypesLoading: true,
			incidentTypesError: false,
		};
	}

	if (action.type === RECEIVE_INCIDENT_TYPES_SUCCESS_TYPE) {
		return {
			...state,
			incidentTypesLoading: false,
			incidentArticlesError: false,
			incidentTypes: action.resPayload,
		};
	}

	if (action.type === RECEIVE_INCIDENT_TYPES_ERROR_TYPE) {
		return {
			...state,
			incidentArticlesLoading: false,
			incidentArticlesError: true,
		};
	}

	if (action.type === requestIncidentRecompilationType) {
		return {
			...state,
			initializingIncidentRecompilation: true,
			incidentRecompilationError: false,
		};
	}

	if (action.type === receiveIncidentRecompilationType) {
		return {
			...state,
			initializingIncidentRecompilation: false,
			incidentRecompilationData: action.incidentRecompilationData,
			incidentRecompilationError: false,
		};
	}

	if (action.type === receiveIncidentRecompilationErrorType) {
		return {
			...state,
			initializingIncidentRecompilation: false,
			incidentRecompilationError: true,
		};
	}

	if (action.type === requestIncidentRecompilationDataType) {
		return {
			...state,
			incidentRecompilationDataLoading: true,
			incidentRecompilationDataError: false,
		};
	}

	if (action.type === receiveIncidentRecompilationDataType) {
		return {
			...state,
			incidentRecompilationDataLoading: false,
			incidentRecompilationDataError: false,
			incidentRecompilationData: action.incidentRecompilationData,
		};
	}

	if (action.type === receiveIncidentRecompilationDataErrorType) {
		return {
			...state,
			incidentRecompilationDataLoading: false,
			incidentRecompilationDataError: true,
		};
	}

	if (action.type === requestClusterDetailsType) {
		return {
			...state,
			clusterError: false,
			clusterLoading: true,
		};
	}

	if (action.type === receiveClusterDetailsType) {
		return {
			...state,
			clusterError: false,
			clusterLoading: false,
			clusterData: action.clusterData,
		};
	}

	if (action.type === receiveClusterDetailsErrorType) {
		return {
			...state,
			clusterError: true,
			clusterLoading: false,
		};
	}

	if (action.type === requestClusterRecompilationType) {
		return {
			...state,
			clusterRecompilationLoading: true,
			clusterRecompilationError: false,
		};
	}

	if (action.type === receiveClusterRecompilationType) {
		return {
			...state,
			clusterRecompilationLoading: false,
			clusterRecompilationError: false,
			clusterRecompilationData: action.clusterRecompilationData,
		};
	}

	if (action.type === receiveClusterRecompilationErrorType) {
		return {
			...state,
			clusterRecompilationLoading: false,
			clusterRecompilationError: true,
		};
	}

	if (action.type === requestClusterRecompilationDataType) {
		return {
			...state,
			clusterRecompilationError: false,
			clusterRecompilationDataLoading: true,
		};
	}

	if (action.type === receiveClusterRecompilationDataType) {
		return {
			...state,
			clusterRecompilationError: false,
			clusterRecompilationData: action.clusterRecompilationData,
			clusterRecompilationDataLoading: false,
		};
	}

	if (action.type === receiveClusterRecompilationDataErrorType) {
		return {
			...state,
			clusterRecompilationError: true,
			clusterRecompilationDataLoading: false,
			clusterRecompilationData: null,
		};
	}

	if (action.type === requestFieldTypeClassifiersType) {
		return {
			...state,
			fieldTypeClassifiersError: false,
			fieldTypeClassifiersLoading: true,
		};
	}

	if (action.type === receiveFieldTypeClassifiersType) {
		return {
			...state,
			fieldTypeClassifiersError: false,
			fieldTypeClassifiersLoading: false,
			fieldTypeClassifiers: action.fieldTypeClassifiers,
		};
	}

	if (action.type === receiveFieldTypeClassifiersErrorType) {
		return {
			...state,
			fieldTypeClassifiersError: false,
			fieldTypeClassifiersLoading: false,
		};
	}

	if (action.type === requestProcessingIncidentsType) {
		return {
			...state,
			processingIncidentsLoading: true,
			processingIncidentsError: false,
		};
	}

	if (action.type === receiveProcessingIncidentsType) {
		return {
			...state,
			processingIncidentsLoading: false,
			processingIncidentsError: false,
			processingIncidentsData: action.processingIncidentsData,
			processingIncidentsDataTotal: action.processingIncidentsDataTotal,
		};
	}

	if (action.type === receiveProcessingIncidentsErrorType) {
		return {
			...state,
			processingIncidentsLoading: false,
			processingIncidentsError: true,
		};
	}

	if (action.type === requestIncidenceSearchArticlesType) {
		return {
			...state,
			incidenceArticlesSearchLoading: true,
			incidenceArticlesSearchError: false,
		};
	}

	if (action.type === receiveIncidenceSearchArticlesType) {
		return {
			...state,
			incidenceArticlesSearchLoading: false,
			incidenceArticlesSearchError: false,
			incidenceSearchArticles: action.incidenceSearchArticles,
		};
	}

	if (action.type === receiveIncidenceSearchArticlesErrorType) {
		return {
			...state,
			incidenceArticlesSearchLoading: false,
			incidenceArticlesSearchError: true,
		};
	}

	if (action.type === requestFloatArticleType) {
		return {
			...state,
			floatingArticle: true,
			floatingArticleError: false,
		};
	}

	if (action.type === receiveFloatArticleType) {
		return {
			...state,
			floatingArticle: false,
			floatingArticleError: false,
		};
	}

	if (action.type === receiveFloatArticleErrorType) {
		return {
			...state,
			floatingArticle: false,
			floatingArticleError: true,
		};
	}

	if (action.type === requestSetArticleToIncidenceType) {
		return {
			...state,
			setArticleToIncidenceLoading: true,
			setArticleToIncidenceError: false,
		};
	}

	if (action.type === receiveSetArticleToIncidenceType) {
		return {
			...state,
			setArticleToIncidenceLoading: false,
			setArticleToIncidenceError: false,
		};
	}

	if (action.type === receiveSetArticleToIncidenceErrorType) {
		return {
			...state,
			setArticleToIncidenceLoading: false,
			setArticleToIncidenceError: true,
		};
	}

	if (action.type === requestSimilarIncidentsType) {
		return {
			...state,
			similarIncidentsLoading: true,
			similarIncidentsError: false,
		};
	}

	if (action.type === receiveSimilarIncidentsType) {
		return {
			...state,
			similarIncidentsLoading: false,
			similarIncidents: action.similarIncidents,
			similarIncidentsError: false,
		};
	}

	if (action.type === receiveSimilarIncidentsErrorType) {
		return {
			...state,
			similarIncidentsLoading: false,
			similarIncidentsError: true,
		};
	}

	if (action.type === requestBulkClusteringType) {
		return {
			...state,
			bulkClusterReqLoading: true,
			bulkClusterReqError: false,
		};
	}

	if (action.type === receiveBulkClusteringType) {
		return {
			...state,
			bulkClusterReqLoading: false,
			bulkClusterReqError: false,
		};
	}

	if (action.type === receiveBulkClusteringErrorType) {
		return {
			...state,
			bulkClusterReqLoading: false,
			bulkClusterReqError: true,
		};
	}

	if (action.type === requestIncidentDataByClusterIdType) {
		return {
			...state,
			incidentDataLoading: true,
			incidentDataError: false,
		};
	}

	if (action.type === receiveIncidentDataByClusterIdSuccessType) {
		return {
			...state,
			incidentDataLoading: false,
			incidentDataError: false,
			incidentData: action.incidentData,
		};
	}

	if (action.type === receiveIncidentDataByClusterIdErrorType) {
		return {
			...state,
			incidentDataLoading: false,
			incidentDataError: true,
		};
	}

	if (action.type === requestIncidentUpdateType) {
		return {
			...state,
			updatingIncident: true,
			incidentUpdateError: false,
		};
	}

	if (action.type === receiveIncidentUpdateSuccessType) {
		return {
			...state,
			updatingIncident: false,
			incidentUpdateError: false,
		};
	}

	if (action.type === receiveIncidentUpdateErrorType) {
		return {
			...state,
			updatingIncident: false,
			incidentUpdateError: true,
		};
	}

	if (action.type === requestAllWeaponsType) {
		return {
			...state,
			allWeaponsLoading: true,
			allWeaponsError: false,
		};
	}

	if (action.type === receiveAllWeaponsSuccessType) {
		return {
			...state,
			allWeaponsLoading: false,
			allWeaponsError: false,
			allWeapons: action.allWeapons,
		};
	}

	if (action.type === receiveAllWeaponsErrorType) {
		return {
			...state,
			allWeaponsLoading: false,
			allWeaponsError: true,
		};
	}

	if (action.type === requestWeaponTypesType) {
		return {
			...state,
			weaponTypesLoading: true,
			weaponsTypesError: false,
		};
	}

	if (action.type === receiveWeaponTypesSuccessType) {
		return {
			...state,
			weaponTypesLoading: false,
			weaponsTypesError: false,
			weaponTypes: action.weaponTypes,
		};
	}

	if (action.type === receiveWeaponTypesErrorType) {
		return {
			...state,
			weaponTypesLoading: false,
			weaponsTypesError: true,
		};
	}

	if (action.type === requestIncidentTypesType) {
		return {
			...state,
			incidentTypesError: false,
			incidentTypesLoading: true,
		};
	}

	if (action.type === receiveIncidentTypesSuccessType) {
		return {
			...state,
			incidentTypesError: false,
			incidentTypesLoading: false,
			allIncidentTypes: action.allIncidentTypes,
		};
	}

	if (action.type === receiveIncidentTypesErrorType) {
		return {
			...state,
			incidentTypesError: true,
			incidentTypesLoading: false,
		};
	}

	if (action.type === requestIncidentTypeCreateType) {
		return {
			...state,
			creatingIncidentType: true,
			incidentTypeCreationError: false,
		};
	}

	if (action.type === receiveIncidentTypeCreateSuccessType) {
		return {
			...state,
			creatingIncidentType: false,
			incidentTypeCreationError: false,
		};
	}

	if (action.type === receiveIncidentTypeCreateErrorType) {
		return {
			...state,
			creatingIncidentType: false,
			incidentTypeCreationError: true,
		};
	}

	if (action.type === requestIncidentTypeEditType) {
		return {
			...state,
			editingIncidentType: true,
			incidentTypeEditingError: false,
		};
	}

	if (action.type === receiveIncidentTypeEditSuccessType) {
		return {
			...state,
			editingIncidentType: false,
			incidentTypeEditingError: false,
		};
	}

	if (action.type === receiveIncidentTypeEditErrorType) {
		return {
			...state,
			editingIncidentType: false,
			incidentTypeEditingError: true,
		};
	}

	if (action.type === requestIncidentDataByWebpageIdType) {
		return {
			...state,
			webpageIncidentData: null,
			webpageIncidentDataLoading: true,
			webpageIncidentDataError: false,
		};
	}
	if (action.type === receiveIncidentDataByWebpageIdSuccessType) {
		return {
			...state,
			webpageIncidentDataLoading: false,
			webpageIncidentDataError: false,
			webpageIncidentData: action.webpageIncidentData,
		};
	}
	if (action.type === receiveIncidentDataByWebpageIdErrorType) {
		return {
			...state,
			webpageIncidentDataLoading: false,
			webpageIncidentDataError: true,
		};
	}

	if (action.type === mergeClustersType) {
		return {
			...state,
			mergingClusters: true,
			mergingClustersError: false,
		};
	}

	if (action.type === mergeClustersSuccessType) {
		return {
			...state,
			mergingClusters: false,
			mergingClustersError: false,
		};
	}

	if (action.type === mergeClustersErrorType) {
		return {
			...state,
			mergingClusters: false,
			mergingClustersError: true,
		};
	}

	if (action.type === incidentToClusterConversionType) {
		return {
			...state,
			convertingIncidentToCluster: true,
			incidentToClusterConversionError: false,
			newIncidentClusterId: null,
		};
	}

	if (action.type === incidentToClusterConversionSuccessType) {
		return {
			...state,
			convertingIncidentToCluster: false,
			incidentToClusterConversionError: false,
			newIncidentClusterId: action.newIncidentClusterId,
		};
	}

	if (action.type === incidentToClusterConversionErrorType) {
		return {
			...state,
			convertingIncidentToCluster: false,
			incidentToClusterConversionError: true,
		};
	}

	if (action.type === getIncidentsStatusType) {
		return {
			...state,
			fetchingIncidentsStatus: true,
			incidentsStatusDataError: false,
		};
	}

	if (action.type === getIncidentsStatusSuccessType) {
		return {
			...state,
			fetchingIncidentsStatus: false,
			incidentsStatusDataError: false,
			incidentsDataStatus: action.incidentsDataStatus,
		};
	}

	if (action.type === getIncidentsStatusErrorType) {
		return {
			fetchingIncidentsStatus: false,
			incidentsStatusDataError: true,
		};
	}

	return state;
};
