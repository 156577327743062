import React from 'react';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const loading = keyframes`
  0% {
    margin-top: 0px;
    transform: scale(1);
  }
  10% {
    margin-top: 10px;
    transform: scale(0.85);
  }
  30% {
    margin-top: -35px;
    transform: scale(0.55);
  }
  50% {
    margin-top: 10px;
    transform: scale(0.85);
  }
  70% {
    margin-top: 0px;
    transform: scale(1);
  }
  100% {
    margin-top: 0px;
    transform: scale(1);
  }

`;

export const LoaderContainer = styled.div`
	width: 100%;
	height: 85vh;
	background: #00000000;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Loady = styled.div`
	width: 150px;
	height: 50px;
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const LoaderDot = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	animation: 1.5s ease-in ${loading} infinite;

	&:nth-of-type(1) {
		background: #e57373;
		animation-delay: 0s;
	}

	&:nth-of-type(2) {
		background: #e4960e;
		animation-delay: 0.14s;
	}

	&:nth-of-type(3) {
		background: #5c755e;
		animation-delay: 0.32s;
	}
`;

const FallbackLoader = () => (
	<LoaderContainer>
		<Loady>
			<LoaderDot />
			<LoaderDot />
			<LoaderDot />
		</Loady>
	</LoaderContainer>
);

export default FallbackLoader;
