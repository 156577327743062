import stringLookup from '../stringslookup';
import { fetchSecured, postSecuredForm, deleteSecured } from '../fetchUtils';

const requestDocumentsListType = 'REQUEST_DOCUMENTS_LIST';
const receiveDocumentsListType = 'RECEIVE_DOCUMENTS_LIST';
const receiveDocumentsListErrorType = 'RECEIVE_DOCUMENTS_LIST_ERROR';

const requestFilesUploadType = 'REQUEST_FILES_UPLOAD';
const filesUploadCompleteType = 'FILES_UPLOAD_COMPLETE';
const filesUploadErrorType = 'FILES_UPLOAD_ERROR';

const requestDeleteFileType = 'REQUEST_DELETE_FILE';
const deleteFileCompleteType = 'DELETE_USER_COMPLETE';
const deleteFileErrorType = 'DELETE_USER_ERROR';

const setSelectedFilesType = 'SET_SELECTED_FILES';
const setFilesUploadErrorType = 'SET_FILES_UPLOAD_ERROR';
const setFilesUploadSuccessType = 'SET_FILES_UPLOAD_SUCCESS';

const requestFileUrlType = 'REQUEST_FILE_URL';
const receiveFileUrlType = 'RECEIVE_FILE_URL';
const receiveFileUrlErrorType = 'RECEIVE_FILE_URL_ERROR';

const initialState = {
	documentsList: [],
	documentsTotal: 0,
	documentsListLoading: false,
	documentsListError: false,
	selectedFiles: [],
	filesUploading: false,
	filesUploadError: false,
	filesUploadSuccess: false,
	deletingFile: false,
	deletingFileError: false,
	fileUrl: null,
	fileUrlLoading: false,
	fileUrlError: false,
};

export const fileManagementActionCreators = {
	setSelectedFiles: (selectedFiles) => async (dispatch) => {
		dispatch({
			type: setSelectedFilesType,
			selectedFiles: selectedFiles,
		});
	},

	setFilesUploadError: (bool) => async (dispatch) => {
		dispatch({
			type: setFilesUploadErrorType,
			filesUploadError: bool,
		});
	},

	setFilesUploadSuccess: (bool) => async (dispatch) => {
		dispatch({
			type: setFilesUploadSuccessType,
			filesUploadSuccess: bool,
		});
	},

	requestDocumentList:
		(page = 1, pageSize = 10, searchString) =>
		async (dispatch, getState) => {
			if (getState().fileManagement.documentsListLoading) {
				return;
			}

			dispatch({ type: requestDocumentsListType });

			const url = new URL('/api/documents', stringLookup.baseUrl);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveDocumentsListType,
						documentsList: data.data,
						documentsTotal: data.total,
					});
				} else {
					dispatch({ type: receiveDocumentsListErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveDocumentsListErrorType });
			}
		},

	uploadFiles: (files) => async (dispatch, getState) => {
		if (getState().fileManagement.filesUploading) {
			return;
		}

		dispatch({ type: requestFilesUploadType });

		const url = new URL('/api/documents', stringLookup.baseUrl);

		try {
			const res = await postSecuredForm(url, files);

			if (res.ok) {
				dispatch({ type: filesUploadCompleteType });
			} else {
				dispatch({ type: filesUploadErrorType });
			}
		} catch (err) {
			dispatch({ type: filesUploadErrorType });
		}
	},

	deleteFile: (fileId) => async (dispatch, getState) => {
		if (getState().fileManagement.deletingFile) {
			return;
		}

		dispatch({ type: requestDeleteFileType });

		const url = new URL(`/api/documents/${fileId}`, stringLookup.baseUrl);

		try {
			const res = await deleteSecured(url);

			if (res.ok) {
				dispatch({ type: deleteFileCompleteType });
			} else {
				dispatch({ type: deleteFileErrorType });
			}
		} catch (err) {
			dispatch({ type: deleteFileErrorType });
		}
	},

	requestFileUrl: (uri) => async (dispatch, getState) => {
		if (getState().fileManagement.fileUrlLoading) {
			return;
		}

		dispatch({ type: requestFileUrlType });

		const url = new URL('/api/documents/link', stringLookup.baseUrl);

		url.searchParams.append('uri', uri);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const link = await res.text();
				dispatch({
					type: receiveFileUrlType,
					fileUrl: link,
				});
			} else {
				dispatch({ type: receiveFileUrlErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveFileUrlErrorType });
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === setSelectedFilesType) {
		return {
			...state,
			selectedFiles: action.selectedFiles,
		};
	}

	if (action.type === setFilesUploadErrorType) {
		return {
			...state,
			filesUploadError: action.filesUploadError,
		};
	}

	if (action.type === setFilesUploadSuccessType) {
		return {
			...state,
			filesUploadSuccess: action.filesUploadSuccess,
		};
	}

	if (action.type === requestFilesUploadType) {
		return {
			...state,
			filesUploading: true,
		};
	}
	if (action.type === filesUploadCompleteType) {
		return {
			...state,
			filesUploading: false,
			filesUploadSuccess: true,
		};
	}
	if (action.type === filesUploadErrorType) {
		return {
			...state,
			filesUploading: false,
			filesUploadError: true,
		};
	}

	if (action.type === requestDocumentsListType) {
		return {
			...state,
			documentsListLoading: true,
		};
	}
	if (action.type === receiveDocumentsListType) {
		return {
			...state,
			documentsListLoading: false,
			documentsListError: false,
			documentsList: action.documentsList,
			documentsTotal: action.documentsTotal,
		};
	}
	if (action.type === receiveDocumentsListErrorType) {
		return {
			...state,
			documentsListLoading: false,
			documentsListError: true,
		};
	}

	if (action.type === requestDeleteFileType) {
		return {
			...state,
			deletingFile: true,
		};
	}
	if (action.type === deleteFileCompleteType) {
		return {
			...state,
			deletingFile: false,
			deletingFileError: false,
		};
	}
	if (action.type === deleteFileErrorType) {
		return {
			...state,
			deletingFile: false,
			deletingFileError: true,
		};
	}

	if (action.type === requestFileUrlType) {
		return {
			...state,
			fileUrlLoading: true,
		};
	}
	if (action.type === receiveFileUrlType) {
		return {
			...state,
			fileUrlLoading: false,
			fileUrl: action.fileUrl,
		};
	}
	if (action.type === receiveFileUrlErrorType) {
		return {
			...state,
			fileUrlLoading: false,
			fileUrlError: true,
		};
	}

	return state;
};
