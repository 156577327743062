import stringLookup from './../stringslookup';
import { fetchSecured, postSecured, deleteSecured } from './../fetchUtils';
import { Configuration, OpenAIApi } from 'openai';

const requestSendChatbotPromptType = 'REQUEST_SEND_CHATBOT_PROMPT_LIST';
const receiveSendChatbotPromptType = 'RECEIVE_SEND_CHATBOT_PROMPT_LIST';
const receiveSendChatbotPromptErrorType = 'RECEIVE_SEND_CHATBOT_PROMPT_LIST_ERROR';

const setChatbotMessagesType = 'SET_CHATBOT_MESSAGES';
const setNewChatbotResponseType = 'SET_NEW_CHATBOT_RESPONSE';

const initialMessagesState = {
	chatbotMessages: [],
	sendingPromptMessage: false,
	chatbotResponseError: false,
	newChatbotResponse: null,
};

export const chatbotActionCreators = {
	setChatbotMessages: (chatbotMessages) => async (dispatch, _) => {
		dispatch({ type: setChatbotMessagesType, chatbotMessages });
	},

	setNewChatbotResponse: (newChatbotResponse) => async (dispatch, _) => {
		dispatch({ type: setNewChatbotResponseType, newChatbotResponse });
	},

	sendChatbotPrompt: (chatbotMessages) => async (dispatch, getState) => {
		if (getState().messagingMessages.sendingPromptMessage) {
			return;
		}

		dispatch({ type: requestSendChatbotPromptType });
		const configuration = new Configuration({
			apiKey: stringLookup.OPENAI_API_KEY,
		});

		const openai = new OpenAIApi(configuration);
		const reversedChatbotMessages = [...chatbotMessages].reverse();

		try {
			const chatbotResponse = await openai.createChatCompletion({
				model: 'gpt-3.5-turbo',
				messages: reversedChatbotMessages,
			});

			if (chatbotResponse.status == 200) {
				const chatbotAnswer = chatbotResponse.data.choices[0].message;
				const messagesList = [chatbotAnswer, ...chatbotMessages];

				dispatch({
					type: receiveSendChatbotPromptType,
					chatbotMessages: messagesList,
					newChatbotResponse: chatbotAnswer,
				});
			} else {
				dispatch({ type: receiveSendChatbotPromptErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveSendChatbotPromptErrorType });
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialMessagesState;

	if (action.type === requestSendChatbotPromptType) {
		return {
			...state,
			sendingPromptMessage: true,
			chatbotResponseError: false,
		};
	}
	if (action.type === receiveSendChatbotPromptType) {
		return {
			...state,
			sendingPromptMessage: false,
			chatbotMessages: action.chatbotMessages,
			newChatbotResponse: action.newChatbotResponse,
		};
	}
	if (action.type === receiveSendChatbotPromptErrorType) {
		return {
			...state,
			sendingPromptMessage: false,
			chatbotResponseError: true,
		};
	}

	if (action.type === setChatbotMessagesType) {
		return {
			...state,
			chatbotMessages: action.chatbotMessages,
		};
	}

	if (action.type === setNewChatbotResponseType) {
		return {
			...state,
			newChatbotResponse: action.newChatbotResponse,
		};
	}

	return state;
};
