import { fetchSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const REQUEST_RSS_FEED_TYPE = 'REQUEST_RSS_FEED_TYPE';
const RECEIVE_RSS_FEED_SUCCESS_TYPE = 'RECEIVE_RSS_FEED_SUCCESS_TYPE';
const RECEIVE_RSS_FEED_ERROR_TYPE = 'RECEIVE_RSS_FEED_ERROR_TYPE';

const initialState = {
	rssFeedLoading: false,
	rssFeedData: null,
	rssFeedError: false,
};

export const rssFeedActionCreators = {
	getRssFeed: (rssFeedUrl) => async (dispatch, getState) => {
		if (getState().rssFeed.rssFeedLoading) {
			return;
		}

		dispatch({ type: REQUEST_RSS_FEED_TYPE });

		try {
			const url = new URL('/api/rss', stringLookup.baseUrl);

			if (rssFeedUrl.length === 0) {
				dispatch({ type: RECEIVE_RSS_FEED_ERROR_TYPE });
			}
			url.searchParams.append('url', rssFeedUrl);

			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({ type: RECEIVE_RSS_FEED_SUCCESS_TYPE, rssFeedData: data });
			} else {
				dispatch({ type: RECEIVE_RSS_FEED_ERROR_TYPE });
			}
		} catch (error) {
			dispatch({ type: RECEIVE_RSS_FEED_ERROR_TYPE });
		}
	},
};

const rssFeedReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_RSS_FEED_TYPE:
			return {
				...state,
				rssFeedLoading: true,
				rssFeedError: false,
			};
		case RECEIVE_RSS_FEED_SUCCESS_TYPE:
			return {
				...state,
				rssFeedLoading: false,
				rssFeedError: false,
				rssFeedData: action.rssFeedData,
			};
		case RECEIVE_RSS_FEED_ERROR_TYPE:
			return {
				...state,
				rssFeedLoading: false,
				rssFeedError: true,
				rssFeedData: null,
			};
		default:
			return state;
	}
};

export default rssFeedReducer;
