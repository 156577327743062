import stringLookup from './../stringslookup';
import {
	fetchSecured,
	postSecured,
	deleteSecured,
	putSecured,
	fetchDefault,
	post,
} from './../fetchUtils';
import Groups from '../components/messaging/sms/Groups';

const requestUsersType = 'REQUEST_USER_LIST';
const receiveUsersType = 'RECEIVE_USER_LIST';
const receiveUsersErrorType = 'RECEIVE_USER_LIST_ERROR';

const requestGroupsType = 'REQUEST_GROUPS_LIST';
const receiveGroupsType = 'RECEIVE_GROUPS_LIST';
const receiveGroupsErrorType = 'RECEIVE_GROUPS_LIST_ERROR';

const requestAddUserType = 'REQUEST_ADD_USER';
const addUserCompleteType = 'ADD_USER_COMPLETE';
const addUserErrorType = 'ADD_USER_ERROR';

const requestDeleteUserType = 'REQUEST_DELETE_USER';
const deleteUserCompleteType = 'DELETE_USER_COMPLETE';
const deleteUserErrorType = 'DELETE_USER_ERROR';

const requestUpdateUserType = 'REQUEST_UPDATE_USER';
const updateUserCompleteType = 'UPDATE_USER_COMPLETE';
const updateUserErrorType = 'UPDATE_USER_ERROR';

const requestInviteUserType = 'REQUEST_INVITE_USER';
const inviteUserCompleteType = 'INVITE_USER_COMPLETE';
const inviteUserErrorType = 'INVITE_USER_ERROR';

const requestInvitedUserType = 'REQUEST_INVITED_USER';
const receiveInvitedUserType = 'RECEIVE_INVITED_USER';
const receiveInvitedUserErrorType = 'RECEIVE_INVITED_USER_ERROR';

const requestInviteToOrganisationType = 'REQUEST_INVITE_TO_ORGANISATION';
const inviteToOrganisationCompleteType = 'INVITE_TO_ORGANISATION_COMPLETE';
const inviteToOrganisationErrorType = 'INVITE_TO_ORGANISATION_ERROR';

const requestSignUpUserType = 'REQUEST_SIGN_UP_USER';
const signUpUserCompleteType = 'SIGN_UP_USER_COMPLETE';
const signUpUserErrorType = 'SIGN_UP_USER_ERROR';

const requestSignUpToOrganisationType = 'REQUEST_SIGNUP_TO_ORGANISATION';
const signUpToOrganisationCompleteType = 'SIGNUP_TO_ORGANISATION_COMPLETE';
const signUpToOrganisationErrorType = 'SIGNUP_TO_ORGANISATION_ERROR';

const setSelectedGroupType = 'SET_SELECTED_GROUP';

const requestIncidentsAccessSignUpType = 'REQUEST_INCIDENTS_ACCESS_SIGNUP_TYPE';
const incidentsAccessSignUpSuccessType =
	'REQUEST_INCIDENTS_ACCESS_SIGNUP_SUCCESS_TYPE';
const incidentsAccessSignUpErrorType =
	'REQUEST_INCIDENTS_ACCESS_SIGNUP_ERROR_TYPE';

const initialState = {
	users: [],
	usersTotal: 0,
	userListLoading: false,
	groupsLoading: false,
	groups: [],
	selectedGroup: '',
	addingUser: false,
	deletingUser: false,
	updatingUser: false,
	invitingUser: false,
	invitingUserError: false,
	invitingUserErrorMessage: '',
	invitedUser: null,
	invitedUserLoading: false,
	invitedUserError: false,
	invitedUserErrorMessage: '',

	invitingToOrganisation: false,
	invitingToOrganisationError: false,
	invitingToOrganisationErrorMessage: '',

	signingUpUser: false,
	signUpUserError: false,
	signUpUserErrorMessage: '',

	signingUpUserToOrganisation: false,
	signUpUserToOrganisationError: false,
	signUpUserToOrganisationErrorMessage: '',

	signingUpForIncidents: false,
	signingUpForIncidentsError: null,
};

export const userManagementActionCreators = {
	requestUserList:
		(page, pageSize, organisationId, searchString = '', all = '') =>
		async (dispatch, getState) => {
			if (getState().userManagement.userListLoading) {
				return;
			}

			dispatch({ type: requestUsersType });

			const url = new URL(
				`/api/security/usermanagement/${organisationId}/users`,
				stringLookup.baseUrl
			);

			if (all.length !== 0 && all === 'all') {
				url.searchParams.append('all', true);
			}

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (searchString.length !== 0) {
				url.searchParams.append('searchString', searchString);
			}
			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();
					dispatch({
						type: receiveUsersType,
						users: data.data,
						usersTotal: data.total,
					});
				} else {
					dispatch({ type: receiveUsersErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveUsersErrorType });
			}
		},

	requestGroupsList: () => async (dispatch, getState) => {
		if (getState().userManagement.groupsLoading) {
			return;
		}

		dispatch({ type: requestGroupsType });

		const url = new URL(
			'/api/security/usermanagement/usergroups',
			stringLookup.baseUrl
		);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({ type: receiveGroupsType, groups: data });
			} else {
				dispatch({ type: receiveGroupsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveGroupsErrorType });
		}
	},

	addUser:
		(username, password, usergroups, userOrganisations) =>
		async (dispatch, getState) => {
			if (getState().userManagement.addingUser) {
				return;
			}

			dispatch({ type: requestAddUserType });

			const url = new URL(
				'/api/security/usermanagement/adduser',
				stringLookup.baseUrl
			);

			try {
				const res = await postSecured(url, {
					username: username,
					password: password,
					groups: usergroups,
					organisations: userOrganisations,
				});

				if (res.ok) {
					dispatch({ type: addUserCompleteType });
				} else {
					dispatch({ type: addUserErrorType });
				}
			} catch (err) {
				dispatch({ type: addUserErrorType });
			}
		},

	inviteUser:
		(email, name, inviter, environment) => async (dispatch, getState) => {
			if (getState().userManagement.invitingUser) {
				return;
			}

			dispatch({ type: requestInviteUserType });

			const url = new URL('/api/marketing/invitations', stringLookup.baseUrl);

			try {
				const res = await postSecured(url, {
					email: email,
					name: name,
					inviter: inviter,
					environment: environment,
				});

				if (res.ok) {
					dispatch({ type: inviteUserCompleteType });
				} else {
					const data = await res.json();
					dispatch({
						type: inviteUserErrorType,
						invitingUserErrorMessage: data.description,
					});
				}
			} catch (err) {
				dispatch({
					type: inviteUserErrorType,
					invitingUserErrorMessage: err.message,
				});
			}
		},

	inviteToOrganisation:
		(email, name, inviter, environment, userGroups, userOrganisations) =>
		async (dispatch, getState) => {
			if (getState().userManagement.invitingToOrganisation) {
				return;
			}

			dispatch({ type: requestInviteToOrganisationType });

			const url = new URL('/api/marketing/invitations', stringLookup.baseUrl);

			try {
				const res = await postSecured(url, {
					email: email,
					name: name,
					inviter: inviter,
					environment: environment,
					organizations: userOrganisations,
					permissions: userGroups,
				});

				if (res.ok) {
					dispatch({ type: inviteToOrganisationCompleteType });
				} else {
					const data = await res.json();

					dispatch({
						type: inviteToOrganisationErrorType,
						invitingToOrganisationErrorMessage: data.description,
					});
				}
			} catch (err) {
				dispatch({
					type: inviteToOrganisationErrorType,
					invitingToOrganisationErrorMessage: err.message,
				});
			}
		},

	signUpForIncidents: (payload) => async (dispatch, getState) => {
		if (getState().userManagement.signingUpForIncidents) {
			return;
		}

		dispatch({ type: requestIncidentsAccessSignUpType });

		const url = new URL('/api/marketing/invitations', stringLookup.baseUrl);

		try {
			const res = await post(url, payload);

			if (res.ok) {
				dispatch({ type: incidentsAccessSignUpSuccessType });
			} else {
				const data = await res.json();

				dispatch({
					type: incidentsAccessSignUpErrorType,
					signingUpForIncidentsError: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: incidentsAccessSignUpErrorType,
				signingUpForIncidentsError: err.message,
			});
		}
	},

	deleteUser: (username, organisationId) => async (dispatch, getState) => {
		if (getState().userManagement.deletingUser) {
			return;
		}

		dispatch({ type: requestDeleteUserType });

		const url = new URL(
			`/api/security/usermanagement/${organisationId}/removeuser`,
			stringLookup.baseUrl
		);

		try {
			const res = await deleteSecured(url, { username: username });

			if (res.ok) {
				dispatch({ type: deleteUserCompleteType });
			} else {
				dispatch({ type: deleteUserErrorType });
			}
		} catch (err) {
			dispatch({ type: deleteUserErrorType });
		}
	},

	updateUser:
		(username, groups, userOrganisations) => async (dispatch, getState) => {
			if (getState().userManagement.updatingUser) {
				return;
			}

			dispatch({ type: requestUpdateUserType });

			const url = new URL(
				'/api/security/usermanagement/updateuser',
				stringLookup.baseUrl
			);

			try {
				const res = await putSecured(url, {
					username: username,
					groups: groups,
					organisations: userOrganisations,
				});

				if (res.ok) {
					dispatch({ type: updateUserCompleteType });
				} else {
					dispatch({ type: updateUserErrorType });
				}
			} catch (err) {
				dispatch({ type: updateUserErrorType });
			}
		},

	requestInvitedUser: (userId) => async (dispatch, getState) => {
		if (getState().userManagement.invitedUserLoading) {
			return;
		}

		dispatch({ type: requestInvitedUserType });

		const url = new URL(
			`/api/marketing/invitations/${userId}`,
			stringLookup.baseUrl
		);

		try {
			const res = await fetch(url);

			if (res.ok) {
				const data = await res.json();
				dispatch({ type: receiveInvitedUserType, invitedUser: data });
			} else {
				dispatch({ type: receiveInvitedUserErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveInvitedUserErrorType });
		}
	},

	signUpUser: (userId, payload) => async (dispatch, getState) => {
		if (getState().userManagement.signingUpUser) {
			return;
		}

		dispatch({ type: requestSignUpUserType });

		const url = new URL(`/api/signup/${userId}`, stringLookup.baseUrl);

		try {
			const res = await post(url, payload);

			if (res.ok) {
				dispatch({ type: signUpUserCompleteType });
			} else {
				const data = await res.json();
				dispatch({
					type: signUpUserErrorType,
					signUpUserErrorMessage: data.description,
				});
			}
		} catch (err) {
			dispatch({
				type: signUpUserErrorType,
				signUpUserErrorMessage: err.message,
			});
		}
	},

	signUpUserToOrganization:
		(name, password, userId) => async (dispatch, getState) => {
			if (getState().userManagement.signingUpUserToOrganisation) {
				return;
			}
			dispatch({ type: requestSignUpToOrganisationType });

			const url = new URL(`/api/signup/${userId}`, stringLookup.baseUrl);

			try {
				const res = await post(url, {
					name: name,
					password: password,
				});

				if (res.ok) {
					dispatch({ type: signUpToOrganisationCompleteType });
				} else {
					const data = await res.json();
					dispatch({
						type: signUpToOrganisationErrorType,
						signUpUserToOrganisationErrorMessage: data.description,
					});
				}
			} catch (err) {
				dispatch({
					type: signUpToOrganisationErrorType,
					signUpUserToOrganisationErrorMessage: err.message,
				});
			}
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === requestUsersType) {
		return {
			...state,
			userListLoading: true,
		};
	}
	if (action.type === receiveUsersType) {
		return {
			...state,
			users: action.users,
			usersTotal: action.usersTotal,
			userListLoading: false,
		};
	}
	if (action.type === receiveUsersErrorType) {
		return {
			...state,
			userListLoading: false,
		};
	}

	if (action.type === requestGroupsType) {
		return {
			...state,
			groupsLoading: true,
		};
	}
	if (action.type === receiveGroupsType) {
		return {
			...state,
			groups: action.groups,
			groupsLoading: false,
		};
	}
	if (action.type === receiveGroupsErrorType) {
		return {
			...state,
			groupsLoading: false,
		};
	}

	if (action.type === requestAddUserType) {
		return {
			...state,
			addingUser: true,
		};
	}
	if (action.type === addUserCompleteType) {
		return {
			...state,
			addingUser: false,
		};
	}
	if (action.type === addUserErrorType) {
		return {
			...state,
			addingUser: false,
		};
	}

	if (action.type === requestDeleteUserType) {
		return {
			...state,
			deletingUser: true,
		};
	}
	if (action.type === deleteUserCompleteType) {
		return {
			...state,
			deletingUser: false,
		};
	}
	if (action.type === deleteUserErrorType) {
		return {
			...state,
			deletingUser: false,
		};
	}

	if (action.type === requestUpdateUserType) {
		return {
			...state,
			updatingUser: true,
		};
	}
	if (action.type === updateUserCompleteType) {
		return {
			...state,
			updatingUser: false,
		};
	}
	if (action.type === updateUserErrorType) {
		return {
			...state,
			updatingUser: false,
		};
	}

	if (action.type === setSelectedGroupType) {
		return {
			...state,
			selectedGroup: action.group,
		};
	}

	if (action.type === requestInviteUserType) {
		return {
			...state,
			invitingUser: true,
			invitingUserError: false,
		};
	}
	if (action.type === inviteUserCompleteType) {
		return {
			...state,
			invitingUser: false,
			invitingUserError: false,
		};
	}
	if (action.type === inviteUserErrorType) {
		return {
			...state,
			invitingUser: false,
			invitingUserError: true,
			invitingUserErrorMessage: action.invitingUserErrorMessage,
		};
	}

	if (action.type === requestInviteToOrganisationType) {
		return {
			...state,
			invitingToOrganisation: true,
			invitingToOrganisationError: false,
		};
	}

	if (action.type === inviteToOrganisationCompleteType) {
		return {
			...state,
			invitingToOrganisation: false,
			invitingToOrganisationError: false,
		};
	}

	if (action.type === inviteToOrganisationErrorType) {
		return {
			...state,
			invitingToOrganisation: false,
			invitingToOrganisationError: true,
			invitingToOrganisationErrorMessage:
				action.invitingToOrganisationErrorMessage,
		};
	}
	if (action.type === requestInvitedUserType) {
		return {
			...state,
			invitedUserLoading: true,
			invitedUserError: false,
		};
	}
	if (action.type === receiveInvitedUserType) {
		return {
			...state,
			invitedUserLoading: false,
			invitedUser: action.invitedUser,
			invitedUserError: false,
		};
	}
	if (action.type === receiveInvitedUserErrorType) {
		return {
			...state,
			invitedUserLoading: false,
			invitedUserError: true,
			invitedUserErrorMessage: action.invitedUserErrorMessage,
		};
	}

	if (action.type === requestSignUpUserType) {
		return {
			...state,
			signingUpUser: true,
			signUpUserError: false,
		};
	}
	if (action.type === signUpUserCompleteType) {
		return {
			...state,
			signingUpUser: false,
			signUpUserError: false,
		};
	}
	if (action.type === signUpUserErrorType) {
		return {
			...state,
			signingUpUser: false,
			signUpUserError: true,
			signUpUserErrorMessage: action.signUpUserErrorMessage,
		};
	}

	if (action.type === requestSignUpToOrganisationType) {
		return {
			...state,
			signingUpUserToOrganisation: true,
			signUpUserToOrganisationError: false,
		};
	}

	if (action.type === signUpToOrganisationCompleteType) {
		return {
			...state,
			signingUpUserToOrganisation: false,
			signUpUserToOrganisationError: false,
		};
	}

	if (action.type === signUpToOrganisationErrorType) {
		return {
			...state,
			signingUpUserToOrganisation: false,
			signUpUserToOrganisationError: true,
			signUpUserToOrganisationErrorMessage:
				action.signUpUserToOrganisationErrorMessage,
		};
	}

	if (action.type === requestIncidentsAccessSignUpType) {
		return {
			...state,
			signingUpForIncidents: true,
			signingUpForIncidentsError: null,
		};
	}

	if (action.type === incidentsAccessSignUpSuccessType) {
		return {
			...state,
			signingUpForIncidents: false,
			signingUpForIncidentsError: null,
		};
	}

	if (action.type === incidentsAccessSignUpErrorType) {
		return {
			...state,
			signingUpForIncidents: false,
			signingUpForIncidentsError: action.signingUpForIncidentsError,
		};
	}

	return state;
};
