import stringLookup from '../stringslookup';
import {
	fetchSecured,
	postSecured,
	deleteSecured,
	patchSecured,
} from '../fetchUtils';
import { addDays } from '../_lib/timeUtil';

const REQUEST_SUMMARY_WEBPAGES_TYPE = 'REQUEST_SUMMARY_WEBPAGES';
const RECEIVE_SUMMARY_WEBPAGES = 'RECEIVE_SUMMARY_WEBPAGES';
const RECEIVE_SUMMARY_WEBPAGES_ERROR_TYPE = 'RECEIVE_SUMMARY_WEBPAGES_ERROR';

const REQUEST_WORDLIST_TYPE = 'REQUEST_WORDLIST';
const RECEIVE_WORDLIST_TYPE = 'RECEIVE_WORDLIST';
const RECEIVE_WORDLIST_ERROR_TYPE = 'RECEIVE_WORDLIST_ERROR';

const REQUEST_SUMMARY_DETAILS_TYPE = 'REQUEST_SUMMARY_DETAILS';
const RECEIVE_SUMMARY_DETAILS_TYPE = 'RECEIVE_SUMMARY_DETAILS';
const RECEIVE_SUMMARY_DETAILS_ERROR_TYPE = 'RECEIVE_SUMMARY_DETAILS_ERROR';
const REQUEST_AVAILABLE_SCORES_TYPE = 'REQUEST_AVAILABLE_SCORES';
const RECEIVE_AVAILABLE_SCORES_TYPE = 'RECEIVE_AVAILABLE_SCORES';
const RECEIVE_AVAILABLE_SCORES_ERROR_TYPE = 'RECEIVE_AVAILABLE_SCORES_ERROR';
const REQUEST_MATCHED_WORDS_TYPE = 'REQUEST_MATCHED_WORDS';
const RECEIVE_MATCHED_WORDS_TYPE = 'RECEIVE_MATCHED_WORDS';
const RECEIVE_MATCHED_WORDS_ERROR_TYPE = 'RECEIVE_MATCHED_WORDS_ERROR';
const ADD_WORD_TYPE = 'ADD_WORD';
const ADD_WORD_COMPLETE_TYPE = 'ADD_WORD_COMPLETE';

const ADD_SINGLE_WORD_TYPE = 'ADD_SINGLE_WORD';
const ADD_SINGLE_WORD_COMPLETE_TYPE = 'ADD_SINGLE_WORD_COMPLETE';

const EDIT_WORD_TYPE = 'EDIT_WORD';
const EDIT_WORD_COMPLETE_TYPE = 'EDIT_WORD_COMPLETE';

const EDIT_SINGLE_WORD_TYPE = 'EDIT_SINGLE_WORD';
const EDIT_SINGLE_WORD_COMPLETE_TYPE = 'EDIT_SINGLE_WORD_COMPLETE';

const REMOVE_WORD_TYPE = 'REMOVE_WORD';
const REMOVE_WORD_COMPLETE_TYPE = 'REMOVE_WORD_COMPLETE';

const REMOVE_SINGLE_WORD_TYPE = 'REMOVE_SINGLE_WORD';
const REMOVE_SINGLE_WORD_COMPLETE_TYPE = 'REMOVE_SINGLE_WORD_COMPLETE';

const SET_SEARCH_STRING_TYPE = 'SET_SEARCH_STRING';
const SET_PAGE_NUMBER_TYPE = 'SET_PAGE_NUMBER';

const SET_WORDLIST_DAYS_TO_CONSIDER_TYPE = 'SET_WORDLIST_DAYS_TO_CONSIDER';
const SET_WORDLIST_START_DATE_TYPE = 'SET_WORDLIST_START_DATE';
const SET_WORDLIST_END_DATE_TYPE = 'SET_WORDLIST_END_DATE';

const initialState = {
	wordList: [],
	wordListTotal: 0,
	wordlistWebpages: [],
	wordListWebpagesLoading: false,
	wordListLoading: false,
	wordListError: false,
	wordListFetchError: false,
	webpageDetails: null,
	webpageFetchError: false,
	availableScores: [],
	availableScoresError: false,
	matchedWords: [],
	matchedWordsError: false,
	searchString: '',
	page: 0,

	startDate: null,
	endDate: null,
	disableCustomDateRange: false,
	daysToConsider: 'none',
};

export const wordListsActionCreators = {
	getWordList:
		(
			page,
			pageSize,
			searchString,
			language = '',
			scoretypes = '',
			startDate,
			endDate
		) =>
		async (dispatch, getState) => {
			if (getState().wordLists.wordListLoading) {
				return;
			}

			dispatch({ type: REQUEST_WORDLIST_TYPE });

			const wordListUrl = new URL('/api/wordlist', stringLookup.baseUrl);
			wordListUrl.searchParams.append('page', page);
			wordListUrl.searchParams.append('pageSize', pageSize);

			if (searchString) {
				wordListUrl.searchParams.append('searchString', searchString);
			}

			if (language.length > 0 && language !== 'All') {
				wordListUrl.searchParams.append('language', language);
			}

			if (scoretypes.length > 0 && scoretypes !== 'All') {
				wordListUrl.searchParams.append('scoretypes', scoretypes);
			}
			if (startDate !== null && endDate !== null) {
				wordListUrl.searchParams.append('from', startDate.toISOString());
				wordListUrl.searchParams.append('to', endDate.toISOString());
			}

			try {
				const wordListResponse = await fetchSecured(wordListUrl);
				if (wordListResponse.ok) {
					const data = await wordListResponse.json();
					dispatch({
						type: RECEIVE_WORDLIST_TYPE,
						wordList: data.data,
						wordListTotal: data.total,
					});
				} else {
					dispatch({ type: RECEIVE_WORDLIST_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_WORDLIST_ERROR_TYPE });
			}
		},

	getWordListWebpages:
		(page, pageSize, searchString) => async (dispatch, getState) => {
			if (getState().wordLists.wordListWebpagesLoading) {
				return;
			}

			dispatch({ type: REQUEST_SUMMARY_WEBPAGES_TYPE });

			const webpagesUrl = new URL(
				'/api/wordlist/webpages',
				stringLookup.baseUrl
			);
			webpagesUrl.searchParams.append('page', page);
			webpagesUrl.searchParams.append('pageSize', pageSize);
			webpagesUrl.searchParams.append('orderBy', 'datePublished');
			webpagesUrl.searchParams.append('order', 'desc');

			if (searchString) {
				webpagesUrl.searchParams.append('searchString', searchString);
			}

			try {
				const webpagesResponse = await fetchSecured(webpagesUrl);
				if (webpagesResponse.ok) {
					const wordlistWebpages = await webpagesResponse.json();

					dispatch({
						type: RECEIVE_SUMMARY_WEBPAGES,
						wordlistWebpages: wordlistWebpages,
					});
				} else {
					dispatch({ type: RECEIVE_SUMMARY_WEBPAGES_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SUMMARY_WEBPAGES_ERROR_TYPE });
			}
		},

	getWebpageDetails: (pageId) => async (dispatch, getState) => {
		if (getState().wordLists.wordListWebpagesLoading) {
			return;
		}

		dispatch({ type: REQUEST_SUMMARY_DETAILS_TYPE });

		const url = new URL('/api/wordlist/webpagedetails', stringLookup.baseUrl);
		url.searchParams.append('webPageId', pageId);

		try {
			const webpageResponse = await fetchSecured(url);
			if (webpageResponse.ok) {
				const webpageDetailsPayload = await webpageResponse.json();
				dispatch({
					type: RECEIVE_SUMMARY_DETAILS_TYPE,
					webpageDetails: webpageDetailsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_SUMMARY_DETAILS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_SUMMARY_DETAILS_ERROR_TYPE });
		}
	},

	getAvailableScores: () => async (dispatch, getState) => {
		if (getState().wordLists.wordListWebpagesLoading) {
			return;
		}

		dispatch({ type: REQUEST_AVAILABLE_SCORES_TYPE });

		const url = new URL('/api/wordlist/availablescores', stringLookup.baseUrl);

		try {
			const availableScoresResponse = await fetchSecured(url);
			if (availableScoresResponse.ok) {
				const availableScoresPayload = await availableScoresResponse.json();
				dispatch({
					type: RECEIVE_AVAILABLE_SCORES_TYPE,
					availableScores: availableScoresPayload.sort(),
				});
			} else {
				dispatch({ type: RECEIVE_AVAILABLE_SCORES_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_AVAILABLE_SCORES_ERROR_TYPE });
		}
	},

	getMatchedWords: (pageId) => async (dispatch, getState) => {
		if (getState().wordLists.wordListWebpagesLoading) {
			return;
		}

		dispatch({ type: REQUEST_MATCHED_WORDS_TYPE });

		const url = new URL('/api/wordlist/matchedwords', stringLookup.baseUrl);
		url.searchParams.append('webPageId', pageId);

		try {
			const matchedWordsResponse = await fetchSecured(url);
			if (matchedWordsResponse.ok) {
				const matchedWordsPayload = await matchedWordsResponse.json();
				dispatch({
					type: RECEIVE_MATCHED_WORDS_TYPE,
					matchedWords: matchedWordsPayload,
				});
			} else {
				dispatch({ type: RECEIVE_MATCHED_WORDS_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_MATCHED_WORDS_ERROR_TYPE });
		}
	},

	addWord: (words) => async (dispatch, getState) => {
		if (getState().wordLists.wordListWebpagesLoading) {
			return;
		}

		dispatch({ type: ADD_WORD_TYPE });

		const url = new URL('/api/wordlist/many', stringLookup.baseUrl);

		try {
			const addWordResponse = await postSecured(url, words);
			if (addWordResponse.ok) {
				dispatch({ type: ADD_WORD_COMPLETE_TYPE });
			} else {
				dispatch({ type: ADD_WORD_COMPLETE_TYPE });
			}
		} catch (err) {
			dispatch({ type: ADD_WORD_COMPLETE_TYPE });
		}
	},

	addSingleWord: (word) => async (dispatch, getState) => {
		if (getState().wordLists.wordListLoading) {
			return;
		}

		dispatch({ type: ADD_SINGLE_WORD_TYPE });

		const url = new URL('/api/wordlist', stringLookup.baseUrl);

		try {
			const addWordResponse = await postSecured(url, word);
			if (addWordResponse.ok) {
				dispatch({ type: ADD_SINGLE_WORD_COMPLETE_TYPE });
			} else {
				dispatch({ type: ADD_SINGLE_WORD_COMPLETE_TYPE });
			}
		} catch (err) {
			dispatch({ type: ADD_SINGLE_WORD_COMPLETE_TYPE });
		}
	},

	editWord: (word) => async (dispatch, getState) => {
		if (getState().wordLists.wordListWebpagesLoading) {
			return;
		}

		dispatch({ type: EDIT_WORD_TYPE });

		const url = new URL('/api/wordlist', stringLookup.baseUrl);

		try {
			const editWordResponse = await patchSecured(url, word);
			if (editWordResponse.ok) {
				dispatch({ type: EDIT_WORD_COMPLETE_TYPE });
			} else {
				dispatch({ type: EDIT_WORD_COMPLETE_TYPE });
			}
		} catch (err) {
			dispatch({ type: EDIT_WORD_COMPLETE_TYPE });
		}
	},

	editSingleWord: (word) => async (dispatch, getState) => {
		if (getState().wordLists.wordListWebpagesLoading) {
			return;
		}

		dispatch({ type: EDIT_SINGLE_WORD_TYPE });

		const url = new URL(`/api/wordlist/${word.id}`, stringLookup.baseUrl);

		try {
			const editWordResponse = await patchSecured(url, word);
			if (editWordResponse.ok) {
				dispatch({ type: EDIT_SINGLE_WORD_COMPLETE_TYPE });
			} else {
				dispatch({ type: EDIT_SINGLE_WORD_COMPLETE_TYPE });
			}
		} catch (err) {
			dispatch({ type: EDIT_SINGLE_WORD_COMPLETE_TYPE });
		}
	},

	removeWord: (words) => async (dispatch, getState) => {
		if (getState().wordLists.wordListWebpagesLoading) {
			return;
		}

		dispatch({ type: REMOVE_WORD_TYPE });

		const url = new URL(
			'/api/wordlist/removefromscore/many',
			stringLookup.baseUrl
		);

		try {
			const removeWordResponse = await deleteSecured(url, words);
			if (removeWordResponse.ok) {
				dispatch({ type: REMOVE_WORD_COMPLETE_TYPE });
			} else {
				dispatch({ type: REMOVE_WORD_COMPLETE_TYPE });
			}
		} catch (err) {
			dispatch({ type: REMOVE_WORD_COMPLETE_TYPE });
		}
	},

	removeSingleWord: (word) => async (dispatch, getState) => {
		if (getState().wordLists.wordListLoading) {
			return;
		}

		dispatch({ type: REMOVE_SINGLE_WORD_TYPE });

		const url = new URL('/api/wordlist/removefromscore', stringLookup.baseUrl);

		try {
			const removeWordResponse = await deleteSecured(url, word);
			if (removeWordResponse.ok) {
				dispatch({ type: REMOVE_SINGLE_WORD_COMPLETE_TYPE });
			} else {
				dispatch({ type: REMOVE_SINGLE_WORD_COMPLETE_TYPE });
			}
		} catch (err) {
			dispatch({ type: REMOVE_SINGLE_WORD_COMPLETE_TYPE });
		}
	},

	setSearchString: (searchString) => async (dispatch, _) => {
		dispatch({ type: SET_SEARCH_STRING_TYPE, searchString: searchString });
	},

	setPageNumber: (page) => async (dispatch, _) => {
		dispatch({ type: SET_PAGE_NUMBER_TYPE, page: page });
	},

	setStartDate:
		(startDate, disableCustomDateRange = false) =>
		async (dispatch, getState) => {
			dispatch({
				type: SET_WORDLIST_START_DATE_TYPE,
				startDate: startDate,
				disableCustomDateRange: disableCustomDateRange,
			});
		},
	setEndDate:
		(endDate, disableCustomDateRange = false) =>
		async (dispatch, getState) => {
			dispatch({
				type: SET_WORDLIST_END_DATE_TYPE,
				endDate: endDate,
				disableCustomDateRange: disableCustomDateRange,
			});
		},
	setDaysToConsider:
		(days, disableCustomDateRange = false) =>
		async (dispatch, getState) => {
			dispatch({
				type: SET_WORDLIST_DAYS_TO_CONSIDER_TYPE,
				daysToConsider: days,
				disableCustomDateRange: disableCustomDateRange,
			});
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === REQUEST_WORDLIST_TYPE) {
		return {
			...state,
			wordListLoading: true,
		};
	}

	if (action.type === RECEIVE_WORDLIST_TYPE) {
		return {
			...state,
			wordListLoading: false,
			wordList: action.wordList,
			wordListTotal: action.wordListTotal,
		};
	}

	if (action.type === RECEIVE_WORDLIST_ERROR_TYPE) {
		return {
			...state,
			wordListError: true,
			wordListLoading: false,
		};
	}

	if (action.type === REQUEST_SUMMARY_WEBPAGES_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: true,
		};
	}

	if (action.type === RECEIVE_SUMMARY_WEBPAGES) {
		return {
			...state,
			wordListWebpagesLoading: false,
			wordlistWebpages: action.wordlistWebpages,
		};
	}

	if (action.type === RECEIVE_SUMMARY_WEBPAGES_ERROR_TYPE) {
		return {
			...state,
			wordListFetchError: true,
			wordListWebpagesLoading: false,
		};
	}

	if (action.type === REQUEST_SUMMARY_DETAILS_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: true,
		};
	}

	if (action.type === RECEIVE_SUMMARY_DETAILS_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: false,
			webpageDetails: action.webpageDetails,
		};
	}

	if (action.type === RECEIVE_SUMMARY_WEBPAGES_ERROR_TYPE) {
		return {
			...state,
			availableScoresError: true,
			wordListWebpagesLoading: false,
		};
	}

	if (action.type === REQUEST_AVAILABLE_SCORES_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: true,
		};
	}

	if (action.type === RECEIVE_AVAILABLE_SCORES_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: false,
			availableScores: action.availableScores,
		};
	}

	if (action.type === RECEIVE_AVAILABLE_SCORES_ERROR_TYPE) {
		return {
			...state,
			availableScoresError: true,
			wordListWebpagesLoading: false,
		};
	}

	if (action.type === REQUEST_MATCHED_WORDS_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: true,
		};
	}

	if (action.type === RECEIVE_MATCHED_WORDS_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: false,
			matchedWords: action.matchedWords,
		};
	}

	if (action.type === RECEIVE_MATCHED_WORDS_ERROR_TYPE) {
		return {
			...state,
			matchedWordsError: true,
			wordListWebpagesLoading: false,
		};
	}

	if (action.type === ADD_WORD_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: true,
		};
	}

	if (action.type === ADD_WORD_COMPLETE_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: false,
		};
	}

	if (action.type === ADD_SINGLE_WORD_TYPE) {
		return {
			...state,
			wordListLoading: true,
		};
	}

	if (action.type === ADD_SINGLE_WORD_COMPLETE_TYPE) {
		return {
			...state,
			wordListLoading: false,
		};
	}

	if (action.type === EDIT_WORD_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: true,
		};
	}

	if (action.type === EDIT_WORD_COMPLETE_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: false,
		};
	}

	if (action.type === EDIT_SINGLE_WORD_TYPE) {
		return {
			...state,
			wordListLoading: true,
		};
	}

	if (action.type === EDIT_SINGLE_WORD_COMPLETE_TYPE) {
		return {
			...state,
			wordListLoading: false,
		};
	}

	if (action.type === REMOVE_WORD_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: true,
		};
	}

	if (action.type === REMOVE_WORD_COMPLETE_TYPE) {
		return {
			...state,
			wordListWebpagesLoading: false,
		};
	}

	if (action.type === REMOVE_SINGLE_WORD_TYPE) {
		return {
			...state,
			wordListLoading: true,
		};
	}

	if (action.type === REMOVE_SINGLE_WORD_COMPLETE_TYPE) {
		return {
			...state,
			wordListLoading: false,
		};
	}

	if (action.type === SET_SEARCH_STRING_TYPE) {
		return {
			...state,
			searchString: action.searchString,
		};
	}

	if (action.type === SET_PAGE_NUMBER_TYPE) {
		return {
			...state,
			page: action.page,
		};
	}

	if (action.type === SET_WORDLIST_START_DATE_TYPE) {
		return {
			...state,
			startDate: action.startDate,
			disableCustomDateRange: action.disableCustomDateRange,
		};
	}

	if (action.type === SET_WORDLIST_END_DATE_TYPE) {
		return {
			...state,
			endDate: action.endDate,
			disableCustomDateRange: action.disableCustomDateRange,
		};
	}

	if (action.type === SET_WORDLIST_DAYS_TO_CONSIDER_TYPE) {
		return {
			...state,
			daysToConsider: action.daysToConsider,
			disableCustomDateRange: action.disableCustomDateRange,
		};
	}

	return state;
};
