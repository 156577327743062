import { getLoggedInuser, getRequestToken } from './authUtils';
import { store } from '.';

export async function fetchDefault(url, abortId = null) {
	const req = {
		headers: {
			Accept: '*/*',
		},
	};

	const user = await getLoggedInuser();
	const organisationId = store.getState().auth.organisationId;
	const embedOrganisationId = store.getState().global.embedOrganisationId;

	if ((organisationId && user !== null) || embedOrganisationId) {
		const id = organisationId ?? embedOrganisationId;

		req.headers['duma-organisation'] = id;
	}

	if (abortId) {
		var activeRequestController = abortableRequests[abortId];
		if (activeRequestController) activeRequestController.abort();

		const newController = new AbortController();
		abortableRequests[abortId] = newController;
		req.signal = abortableRequests[abortId].signal;
	}

	const res = await fetch(url, req);

	return res;
}

const abortableRequests = {};
export async function fetchSecured(url, abortId = null) {
	const ancestorOrigin = store.getState().global.ancestorOrigin;
	const embedOrgId = store.getState().global.embedOrganisationId;

	const organisationId = store.getState().auth.organisationId;
	const signedIn = store.getState().auth.signedIn;

	const authToken = await getRequestToken();

	if (signedIn && authToken === null && ancestorOrigin === null) {
		store.dispatch({
			type: 'SET_AUTH_TOKEN_EXPIRED',
			authTokenExpired: true,
		});
	}

	let req = {
		headers: {
			Accept: '*/*',
			Authorization: ancestorOrigin === null ? `Bearer ${authToken}` : null,
		},
	};

	if (organisationId || embedOrgId) {
		req.headers['duma-organisation'] = embedOrgId ?? organisationId;
	}

	if (ancestorOrigin) {
		req.headers['duma-embedding-website'] = ancestorOrigin;
	}

	if (abortId) {
		var activeRequestController = abortableRequests[abortId];
		if (activeRequestController) activeRequestController.abort();

		const newController = new AbortController();
		abortableRequests[abortId] = newController;
		req.signal = abortableRequests[abortId].signal;
	}

	const res = await fetch(url, req);
	return res;
}

export async function fetchAbortable(url, abortId = null) {
	const req = {
		headers: {
			Accept: '*/*',
		},
	};

	const user = await getLoggedInuser();
	const organisationId = store.getState().auth.organisationId;

	if (organisationId && user !== null) {
		req.headers['duma-organisation'] = organisationId;
	}

	if (abortId) {
		var activeRequestController = abortableRequests[abortId];
		if (activeRequestController) activeRequestController.abort();

		const newController = new AbortController();
		abortableRequests[abortId] = newController;
		req.signal = abortableRequests[abortId].signal;
	}

	const res = await fetch(url, req);
	return res;
}

export async function kaziMtaaniFetch(url, abortId = null) {
	const req = {
		headers: {
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
			'api-version': 2.0,
		},
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	if (abortId) {
		var activeRequestController = abortableRequests[abortId];
		if (activeRequestController) activeRequestController.abort();

		const newController = new AbortController();
		abortableRequests[abortId] = newController;
		req.signal = abortableRequests[abortId].signal;
	}

	const res = await fetch(url, req);
	return res;
}

export async function postSecuredForm(url, data) {
	const req = {
		body: data,
		headers: {
			// "Content-Type": "multipart/form-data",
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
		},
		method: 'POST',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}

export async function postSecured(url, data = {}) {
	const req = {
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
		},
		method: 'POST',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}

export async function KaziMtaaniPost(url, data = {}) {
	const req = {
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
			'api-version': 2.0,
		},
		method: 'POST',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}

export async function post(url, data) {
	const res = await fetch(url, {
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
		},
		method: 'POST',
	});

	return res;
}

export async function deleteSecured(url, data = null) {
	const payload = {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
		},
		method: 'DELETE',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		payload.headers['duma-organisation'] = organisationId;
	}

	if (data != null) {
		payload.body = JSON.stringify(data);
	}

	const res = await fetch(url, payload);

	return res;
}

export async function kaziMtaaniDelete(url, data = null) {
	const payload = {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
			'api-version': 2.0,
		},
		method: 'DELETE',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		payload.headers['duma-organisation'] = organisationId;
	}

	if (data != null) {
		payload.body = JSON.stringify(data);
	}

	const res = await fetch(url, payload);

	return res;
}

export async function putSecured(url, data) {
	const req = {
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
		},
		method: 'PUT',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}

export async function KaziMtaaniPut(url, data) {
	const req = {
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
			'api-version': 2.0,
		},
		method: 'PUT',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}

export async function patchSecured(url, data) {
	const req = {
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
		},
		method: 'PATCH',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}

export async function putSecuredForm(url, data) {
	const req = {
		body: data,
		headers: {
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
		},
		method: 'PUT',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}

export async function patchSecuredForm(url, data) {
	const req = {
		body: data,
		headers: {
			Accept: '*/*',
			Authorization: `Bearer ${await getRequestToken()}`,
		},
		method: 'PATCH',
	};

	const organisationId = store.getState().auth.organisationId;
	if (organisationId) {
		req.headers['duma-organisation'] = organisationId;
	}

	const res = await fetch(url, req);

	return res;
}
