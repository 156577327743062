import {
	postSecuredForm,
	postSecured,
	fetchSecured,
	patchSecured,
	putSecuredForm,
} from '../fetchUtils';
import stringLookup from '../stringslookup';

const CREATE_AGREEMENT_TYPE = 'CREATE_AGREEMENT_TYPE';
const CREATE_AGREEMENT_SUCCESS_TYPE = 'CREATE_AGREEMENT_SUCCESS_TYPE';
const CREATE_AGREEMENT_ERROR_TYPE = 'CREATE_AGREEMENT_ERROR_TYPE';

const EDIT_AGREEMENT_TYPE = 'EDIT_AGREEMENT_TYPE';
const EDIT_AGREEMENT_SUCCESS_TYPE = 'EDIT_AGREEMENT_SUCCESS_TYPE';
const EDIT_AGREEMENT_ERROR_TYPE = 'EDIT_AGREEMENT_ERROR_TYPE';

const GET_LATEST_USER_AGREEMENTS_TYPE = 'GET_LATEST_USER_AGREEMENTS_TYPE';
const GET_LATEST_USER_AGREEMENTS_SUCCESS_TYPE =
	'GET_LATEST_USER_AGREEMENTS_SUCCESS_TYPE';
const GET_LATEST_USER_AGREEMENTS_ERROR_TYPE =
	'GET_LATEST_USER_AGREEMENTS_ERROR_TYPE';

const GET_EARLIER_VERSIONS_OF_AGREEMENT_TYPE =
	'GET_EARLIER_VERSIONS_OF_AGREEMENT_TYPE';
const GET_EARLIER_VERSIONS_OF_AGREEMENT_SUCCESS_TYPE =
	'GET_EARLIER_VERSIONS_OF_AGREEMENT_SUCCESS_TYPE';
const GET_EARLIER_VERSIONS_OF_AGREEMENT_ERROR_TYPE =
	'GET_EARLIER_VERSIONS_OF_AGREEMENT_ERROR_TYPE';

const GET_UNSIGNED_AGREEMENTS_TYPE = 'GET_UNSIGNED_AGREEMENTS_TYPE';
const GET_UNSIGNED_AGREEMENTS_SUCCESS_TYPE =
	'GET_UNSIGNED_AGREEMENTS_SUCCESS_TYPE';
const GET_UNSIGNED_AGREEMENTS_ERROR_TYPE = 'GET_UNSIGNED_AGREEMENTS_ERROR_TYPE';

const ACCEPT_AGREEMENT_TYPE = 'ACCEPT_AGREEMENT_TYPE';
const ACCEPT_AGREEMENT_SUCCESS_TYPE = 'ACCEPT_AGREEMENT_SUCCESS_TYPE';
const ACCEPT_AGREEMENT_ERROR_TYPE = 'ACCEPT_AGREEMENT_ERROR_TYPE';

const initialState = {
	isLoading: false,
	agreementUploadingError: '',
	areLatestAgreementsLoading: false,
	latestAgreementsError: '',
	latestAgreementsList: [],
	agreementEditingError: '',
	earlierVersionsList: [],
	earlierVersionsLoadingError: '',
	unsignedAgreement: null,
	unsignedAgreementLoading: false,
	unsignedAgreementLoadingError: '',
	isAcceptingAgreement: false,
	acceptingAgreementError: '',
	totalAgreements: 0,
	earlierVersionsCount: 0,
};

export const userAgreementsActionCreators = {
	uploadUserAgreement: (agreementCreationDetails) => async (dispatch) => {
		dispatch({ type: CREATE_AGREEMENT_TYPE });

		const url = new URL('api/v1/agreements', stringLookup.baseUrl);

		try {
			const res = await postSecuredForm(url, agreementCreationDetails);

			if (res.ok) {
				dispatch({
					type: CREATE_AGREEMENT_SUCCESS_TYPE,
				});
			} else {
				const resJson = await res.json();
				dispatch({
					type: CREATE_AGREEMENT_ERROR_TYPE,
					payload: resJson.description,
				});
			}
		} catch (error) {
			dispatch({
				type: CREATE_AGREEMENT_ERROR_TYPE,
				payload: error.message,
			});
		}
	},

	editUserAgreement: (agreementDetails) => async (dispatch) => {
		dispatch({ type: EDIT_AGREEMENT_TYPE });

		const agreementId = agreementDetails.get('Id');

		const url = new URL(
			`/api/v1/agreements/${agreementId}`,
			stringLookup.baseUrl
		);

		try {
			const res = await putSecuredForm(url, agreementDetails);

			if (res.ok) {
				dispatch({ type: EDIT_AGREEMENT_SUCCESS_TYPE });
			} else {
				dispatch({
					type: EDIT_AGREEMENT_ERROR_TYPE,
					payload: res.statusText,
				});
			}
		} catch (e) {
			dispatch({
				type: EDIT_AGREEMENT_ERROR_TYPE,
				payload: e.message,
			});
		}
	},

	getLatestUserAgreements:
		(page = 1, pageSize = 10) =>
		async (dispatch) => {
			dispatch({ type: GET_LATEST_USER_AGREEMENTS_TYPE });

			const url = new URL('/api/v1/agreements', stringLookup.baseUrl);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const serializedData = await res.json();

					dispatch({
						type: GET_LATEST_USER_AGREEMENTS_SUCCESS_TYPE,
						payload: serializedData.data,
						totalAgreements: serializedData.total,
					});
				} else {
					dispatch({
						type: GET_LATEST_USER_AGREEMENTS_ERROR_TYPE,
						payload: res.statusText,
					});
				}
			} catch (e) {
				dispatch({
					type: GET_LATEST_USER_AGREEMENTS_ERROR_TYPE,
					payload: e.message,
				});
			}
		},

	getEarlierVersionsOfAgreement:
		(page = 1, pageSize = 10, agreementId) =>
		async (dispatch) => {
			dispatch({ type: GET_EARLIER_VERSIONS_OF_AGREEMENT_TYPE });

			const url = new URL(
				`/api/v1/agreements/${agreementId}/history`,
				stringLookup.baseUrl
			);

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const resData = await res.json();

					dispatch({
						type: GET_EARLIER_VERSIONS_OF_AGREEMENT_SUCCESS_TYPE,
						payload: resData.data,
						count: resData.total,
					});
				} else {
					dispatch({
						type: GET_EARLIER_VERSIONS_OF_AGREEMENT_ERROR_TYPE,
						payload: res.statusText,
					});
				}
			} catch (error) {
				dispatch({
					type: GET_EARLIER_VERSIONS_OF_AGREEMENT_ERROR_TYPE,
					payload: error.message,
				});
			}
		},

	checkAgreementValidity: (username, agreementType) => async (dispatch) => {
		dispatch({ type: GET_UNSIGNED_AGREEMENTS_TYPE });

		const url = new URL(
			`/api/v1/useragreements/${username}`,
			stringLookup.baseUrl
		);

		url.searchParams.append('agreementType', agreementType);

		try {
			const res = await fetchSecured(url);
			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: GET_UNSIGNED_AGREEMENTS_SUCCESS_TYPE,
					payload: data,
				});
			} else {
				dispatch({
					type: GET_UNSIGNED_AGREEMENTS_ERROR_TYPE,
					payload: res.status,
				});
			}
		} catch (e) {
			dispatch({
				type: GET_UNSIGNED_AGREEMENTS_ERROR_TYPE,
				payload: e.message,
			});
		}
	},

	acceptAgreement: (username, agreementType) => async (dispatch) => {
		dispatch({ type: ACCEPT_AGREEMENT_TYPE });

		const url = new URL(
			`/api/v1/useragreements/${username}`,
			stringLookup.baseUrl
		);

		try {
			const res = await postSecured(url, agreementType);

			if (res.ok) {
				dispatch({
					type: ACCEPT_AGREEMENT_SUCCESS_TYPE,
				});
			} else {
				dispatch({
					type: ACCEPT_AGREEMENT_ERROR_TYPE,
					payload: res.statusText,
				});
			}
		} catch (e) {
			dispatch({
				TYPE: ACCEPT_AGREEMENT_ERROR_TYPE,
				payload: e.message,
			});
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	// user agreement creation
	if (action.type === CREATE_AGREEMENT_TYPE) {
		return {
			...state,
			isLoading: true,
			agreementUploadingError: '',
		};
	}

	if (action.type === CREATE_AGREEMENT_SUCCESS_TYPE) {
		return {
			...state,
			isLoading: false,
			agreementUploadingError: '',
		};
	}

	if (action.type === CREATE_AGREEMENT_ERROR_TYPE) {
		return {
			...state,
			isLoading: false,
			agreementUploadingError: action.payload,
		};
	}

	// agreement editing
	if (action.type === EDIT_AGREEMENT_TYPE) {
		return {
			...state,
			isLoading: true,
			agreementEditingError: '',
		};
	}

	if (action.type === EDIT_AGREEMENT_SUCCESS_TYPE) {
		return {
			...state,
			isLoading: false,
			agreementEditingError: '',
		};
	}

	if (action.type === EDIT_AGREEMENT_ERROR_TYPE) {
		return {
			...state,
			isLoading: false,
			agreementEditingError: action.payload,
		};
	}

	// get the latest version of user agreements
	if (action.type === GET_LATEST_USER_AGREEMENTS_TYPE) {
		return {
			...state,
			areLatestAgreementsLoading: true,
			latestAgreementsError: '',
		};
	}

	if (action.type === GET_LATEST_USER_AGREEMENTS_SUCCESS_TYPE) {
		return {
			...state,
			areLatestAgreementsLoading: false,
			latestAgreementsError: '',
			latestAgreementsList: action.payload,
			totalAgreements: action.totalAgreements,
		};
	}

	if (action.type === GET_LATEST_USER_AGREEMENTS_ERROR_TYPE) {
		return {
			...state,
			areLatestAgreementsLoading: false,
			latestAgreementsError: action.payload,
		};
	}

	if (action.type === GET_EARLIER_VERSIONS_OF_AGREEMENT_TYPE) {
		return {
			...state,
			isLoading: true,
			earlierVersionsLoadingError: '',
		};
	}

	if (action.type === GET_EARLIER_VERSIONS_OF_AGREEMENT_SUCCESS_TYPE) {
		return {
			...state,
			isLoading: false,
			earlierVersionsList: action.payload,
			earlierVersionsCount: action.count,
			earlierVersionsLoadingError: '',
		};
	}

	if (action.type === GET_EARLIER_VERSIONS_OF_AGREEMENT_ERROR_TYPE) {
		return {
			...state,
			isLoading: false,
			earlierVersionsLoadingError: action.payload,
		};
	}

	if (action.type === GET_UNSIGNED_AGREEMENTS_TYPE) {
		return {
			...state,
			unsignedAgreementLoading: false,
			unsignedAgreementLoadingError: '',
		};
	}

	if (action.type === GET_UNSIGNED_AGREEMENTS_SUCCESS_TYPE) {
		return {
			...state,
			unsignedAgreementLoading: false,
			unsignedAgreementLoadingError: '',
			unsignedAgreement: action.payload,
		};
	}

	if (action.type === GET_UNSIGNED_AGREEMENTS_ERROR_TYPE) {
		return {
			...state,
			unsignedAgreementLoading: false,
			unsignedAgreementLoadingError: action.payload,
		};
	}

	if (action.type === ACCEPT_AGREEMENT_TYPE) {
		return {
			...state,
			isAcceptingAgreement: true,
			acceptingAgreementError: '',
		};
	}

	if (action.type === ACCEPT_AGREEMENT_SUCCESS_TYPE) {
		return {
			...state,
			isAcceptingAgreement: false,
			acceptingAgreementError: '',
		};
	}

	if (action.type === ACCEPT_AGREEMENT_ERROR_TYPE) {
		return {
			...state,
			isAcceptingAgreement: false,
			acceptingAgreementError: action.payload,
		};
	}

	return state;
};
