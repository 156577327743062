import stringLookup from './../stringslookup';
import {
	fetchSecured,
	postSecured,
	deleteSecured,
	putSecured,
	patchSecured,
} from './../fetchUtils';

const requestTopicsType = 'REQUEST_ORG_TOPICS';
const receiveTopicsType = 'RECEIVE_ORG_TOPICS';
const receiveTopicsErrorType = 'RECEIVE_ORG_TOPICS_ERROR';

const requestAvailableTopicsType = 'REQUEST_AVAILABLE_ORG_TOPICS';
const receiveAvailableTopicsType = 'RECEIVE_AVAILABLE_ORG_TOPICS';
const receiveAvailableTopicsErrorType = 'RECEIVE_AVAILABLE_ORG_TOPICS_ERROR';

const requestAvailableFeaturesType = 'REQUEST_AVAILABLE_ORG_FEATURES';
const receiveAvailableFeaturesType = 'RECEIVE_AVAILABLE_ORG_FEATURES';
const receiveAvailableFeaturesErrorType = 'RECEIVE_AVAILABLE_ORG_FEATURES_ERROR';

const setTopicType = 'SET_ORGANISATION_TOPICS';
const setTopicTypeCompleteType = 'SET_ORGANISATION_TOPICS_COMPLETE';

const requestOrgsType = 'REQUEST_ORGS';
const receiveOrgsType = 'RECEIVE_ORGS';
const receiveOrgsErrorType = 'RECEIVE_ORGS_ERROR';

const setFeaturesType = 'SET_ORGANISATION_FEATURES';
const setFeaturesCompleteType = 'SET_ORGANISATION_FEATURES_COMPLETE';

const setColourType = 'SET_ORGANISATION_COLOUR';
const setColourCompleteType = 'SET_ORGANISATION_COLOUR_COMPLETE';

const requestAddOrganisationType = 'REQUEST_ADD_ORG';
const addOrganisationCompleteType = 'ADD_ORG_COMPLETE';
const addOrganisationErrorType = 'ADD_ORG_ERROR';

const requestFeaturesType = 'REQUEST_ORG_FEATURES';
const receiveFeaturesType = 'RECEIVE_ORG_FEATURES';
const receiveFeaturesErrorType = 'RECEIVE_ORG_FEATURES_ERROR';

const requestColorType = 'REQUEST_ORG_COLOR';
const receiveColorType = 'RECEIVE_ORG_COLOR';
const receiveColorErrorType = 'RECEIVE_ORG_COLOR_ERROR';

const requestDeleteOrgType = 'REQUEST_ORG_DELETE';
const deleteOrgCompleteType = 'ORG_DELETE_COMPLETE';
const deleteOrgErrorType = 'ORG_DELETE_ERROR';

const requestEditOrgType = 'REQUEST_EDIT_ORG_TYPE';
const receiveEditOrgSuccessType = 'RECEIVE_EDIT_ORG_SUCCESS_TYPE';
const receiveEditOrgErrorType = 'RECEIVE_EDIT_ORG_SUCCESS_ERROR_TYPE';

const requestOrgCountsType = 'REQUEST_ORG_COUNTS';
const receiveOrgCountsType = 'RECEIVE_ORG_COUNTS';
const receiveOrgCountsErrorType = 'RECEIVE_ORG_COUNTS_ERROR';

const initialState = {
	topics: [],
	topicsLoading: false,
	availableTopics: [],
	availableTopicsLoading: false,
	settingTopics: false,
	organisations: [],
	organisationsLoading: false,
	organisationsError: false,
	addingOrganisation: false,
	features: [],
	featuresLoading: false,
	availableFeatures: [],
	availableFeaturesLoading: false,
	deletingOrg: false,
	orgColor: '#26324e',
	organisationLogo: '',
	organisationName: '',
	schemeLoading: false,
	editingOrganisation: false,
	editOrganisationError: false,
	orgCounts: null,
	orgCountsLoading: false,
	orgCountsError: false,
};

export const organisationConfigurationsActionCreators = {
	getOrganisations:
		(page = 1, pageSize = 50) =>
		async (dispatch, getState) => {
			dispatch({ type: requestOrgsType });

			const url = new URL('/api/multitenancy/management', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			try {
				const orgsResponse = await fetchSecured(url, 'getOrganisations');

				if (orgsResponse.ok) {
					const orgsRes = await orgsResponse.json();
					dispatch({ type: receiveOrgsType, organisations: orgsRes.data });
				} else {
					dispatch({ type: receiveOrgsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveOrgsErrorType });
			}
		},

	getOrgTopics: (organisationId) => async (dispatch, getState) => {
		dispatch({ type: requestTopicsType });

		// const organisationId = getState().auth.organisationId;

		const url = new URL(
			`/api/multitenancy/organisations/${organisationId}/topics`,
			stringLookup.baseUrl
		);

		try {
			const topicsResponse = await fetchSecured(url, 'getOrgTopics');

			if (topicsResponse.ok) {
				const topicsRes = await topicsResponse.json();
				dispatch({ type: receiveTopicsType, topics: topicsRes });
			} else {
				dispatch({ type: receiveTopicsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveTopicsErrorType });
		}
	},

	getAvailableTopics: () => async (dispatch, getState) => {
		dispatch({ type: requestAvailableTopicsType });

		const url = new URL(
			'/api/multitenancy/organisations/availableTopics',
			stringLookup.baseUrl
		);

		try {
			const topicsResponse = await fetchSecured(url, 'getAvailableTopicsOrg');

			if (topicsResponse.ok) {
				const topicsRes = await topicsResponse.json();
				dispatch({
					type: receiveAvailableTopicsType,
					availableTopics: topicsRes,
				});
			} else {
				dispatch({ type: receiveTopicsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveAvailableTopicsErrorType });
		}
	},

	getAvailableFeatures: () => async (dispatch, getState) => {
		dispatch({ type: requestAvailableFeaturesType });

		const url = new URL(
			'/api/multitenancy/management/features',
			stringLookup.baseUrl
		);

		try {
			const featuresResponse = await fetchSecured(url, 'getAvailableFeaturesOrg');

			if (featuresResponse.ok) {
				const featuresRes = await featuresResponse.json();
				dispatch({
					type: receiveAvailableFeaturesType,
					availableFeatures: featuresRes,
				});
			} else {
				dispatch({ type: receiveTopicsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveAvailableFeaturesErrorType });
		}
	},

	setOrgTopics: (topicIds, organisationId) => async (dispatch, getState) => {
		dispatch({ type: setTopicType });

		const url = new URL(
			`/api/multitenancy/management/${organisationId}/settopics`,
			stringLookup.baseUrl
		);

		try {
			await postSecured(url, { topics: topicIds });
		} catch (err) {
			dispatch({ type: setTopicTypeCompleteType });
		}
		dispatch({ type: setTopicTypeCompleteType });
	},

	setOrgFeatures: (features, organisationId) => async (dispatch, getState) => {
		dispatch({ type: setFeaturesType });

		const url = new URL(
			`/api/multitenancy/management/${organisationId}/setfeatures`,
			stringLookup.baseUrl
		);

		try {
			await postSecured(url, { features: features });
		} catch (err) {
			dispatch({ type: setFeaturesCompleteType });
		}
		dispatch({ type: setFeaturesCompleteType });
	},

	setOrgColor: (orgColor, organisationId) => async (dispatch, getState) => {
		dispatch({ type: setColourType });

		const url = new URL(
			`/api/multitenancy/management/${organisationId}/colour`,
			stringLookup.baseUrl
		);

		try {
			await postSecured(url, { colour: orgColor });
		} catch (err) {
			dispatch({ type: setColourCompleteType });
		}
		dispatch({ type: setColourCompleteType });
	},

	addOrganisation: (payload) => async (dispatch, getState) => {
		if (getState().organisationConfigurations.addingOrganisation) {
			return;
		}

		dispatch({ type: requestAddOrganisationType });

		const url = new URL('/api/multitenancy/management', stringLookup.baseUrl);

		try {
			const res = await postSecured(url, payload);

			if (res.ok) {
				dispatch({ type: addOrganisationCompleteType });
			} else {
				dispatch({ type: addOrganisationErrorType });
			}
		} catch (err) {
			dispatch({ type: addOrganisationErrorType });
		}
	},

	editOrganisation: (data) => async (dispatch, getState) => {
		if (getState().organisationConfigurations.editingOrganisation) {
			return;
		}

		dispatch({ type: requestEditOrgType });

		try {
			const url = new URL('/api/multitenancy/management', stringLookup.baseUrl);

			const res = await patchSecured(url, data);

			if (res.ok) {
				dispatch({
					type: receiveEditOrgSuccessType,
				});
			} else {
				dispatch({ type: receiveEditOrgErrorType });
			}
		} catch (error) {
			dispatch({ type: receiveEditOrgErrorType });
		}
	},

	getOrgFeatures: (organisationId) => async (dispatch, getState) => {
		dispatch({ type: requestFeaturesType });

		// const organisationId = getState().auth.organisationId;

		const url = new URL(
			`/api/multitenancy/organisations/${organisationId}/features`,
			stringLookup.baseUrl
		);

		try {
			const featuresResponse = await fetchSecured(url, 'getOrgFeatures');

			if (featuresResponse.ok) {
				const featuresRes = await featuresResponse.json();
				dispatch({ type: receiveFeaturesType, features: featuresRes });
			} else {
				dispatch({ type: receiveFeaturesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveFeaturesErrorType });
		}
	},

	getOrganisationColor: (organisationId) => async (dispatch, getState) => {
		dispatch({ type: requestColorType });

		const url = new URL(
			`/api/multitenancy/organisations/${organisationId}/scheme`,
			stringLookup.baseUrl
		);

		try {
			const colorResponse = await fetchSecured(url, 'getOrgColor');

			if (colorResponse.ok) {
				const colorRes = await colorResponse.json();
				dispatch({ type: receiveColorType, orgColor: colorRes.colour });
			} else {
				dispatch({ type: receiveColorErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveColorErrorType });
		}
	},

	deleteOrganisation: (organisationId) => async (dispatch, getState) => {
		if (getState().organisationConfigurations.deletingOrg) {
			return;
		}

		dispatch({ type: requestDeleteOrgType });

		const url = new URL(
			`/api/multitenancy/management/${organisationId}`,
			stringLookup.baseUrl
		);

		try {
			const res = await deleteSecured(url);

			if (res.ok) {
				dispatch({ type: deleteOrgCompleteType });
			} else {
				dispatch({ type: deleteOrgErrorType });
			}
		} catch (err) {
			dispatch({ type: deleteOrgErrorType });
		}
	},

	getOrgCounts: (organisationId) => async (dispatch) => {
		dispatch({ type: requestOrgCountsType });

		const url = new URL(
			'/api/multitenancy/organisations/statusCount',
			stringLookup.baseUrl
		);
		url.searchParams.append('organisationId', organisationId);

		try {
			const response = await fetchSecured(url, 'getOrgCounts');

			if (response.ok) {
				const countsRes = await response.json();
				dispatch({ type: receiveOrgCountsType, orgCounts: countsRes });
			} else {
				dispatch({ type: receiveOrgCountsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveOrgCountsErrorType });
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === requestOrgsType) {
		return {
			...state,
			organisationsLoading: true,
		};
	}
	if (action.type === receiveOrgsType) {
		return {
			...state,
			organisations: action.organisations,
			organisationsLoading: false,
		};
	}
	if (action.type === receiveOrgsErrorType) {
		return {
			...state,
			organisationsLoading: false,
			organisationsError: true,
		};
	}

	if (action.type === requestTopicsType) {
		return {
			...state,
			topicsLoading: true,
		};
	}
	if (action.type === receiveTopicsType) {
		return {
			...state,
			topics: action.topics,
			topicsLoading: false,
		};
	}
	if (action.type === receiveTopicsErrorType) {
		return {
			...state,
			topicsLoading: false,
		};
	}

	if (action.type === requestAvailableTopicsType) {
		return {
			...state,
			availableTopicsLoading: true,
		};
	}
	if (action.type === receiveAvailableTopicsType) {
		return {
			...state,
			availableTopics: action.availableTopics,
			availableTopicsLoading: false,
		};
	}
	if (action.type === receiveAvailableTopicsErrorType) {
		return {
			...state,
			availableTopicsLoading: false,
		};
	}

	if (action.type === setTopicType) {
		return {
			...state,
			settingTopics: true,
		};
	}
	if (action.type === setTopicTypeCompleteType) {
		return {
			...state,
			settingTopics: false,
		};
	}

	if (action.type === setFeaturesType) {
		return {
			...state,
			settingTopics: true,
		};
	}
	if (action.type === setFeaturesCompleteType) {
		return {
			...state,
			settingTopics: false,
		};
	}

	if (action.type === setColourType) {
		return {
			...state,
			settingTopics: true,
		};
	}
	if (action.type === setColourCompleteType) {
		return {
			...state,
			settingTopics: false,
		};
	}

	if (action.type === requestAddOrganisationType) {
		return {
			...state,
			addingOrganisation: true,
		};
	}
	if (action.type === addOrganisationCompleteType) {
		return {
			...state,
			addingOrganisation: false,
		};
	}
	if (action.type === addOrganisationErrorType) {
		return {
			...state,
			addingOrganisation: false,
		};
	}

	if (action.type === requestEditOrgType) {
		return {
			...state,
			editingOrganisation: true,
			editOrganisationError: false,
		};
	}

	if (action.type === receiveEditOrgSuccessType) {
		return {
			...state,
			editingOrganisation: false,
			editOrganisationError: false,
		};
	}

	if (action.type === receiveEditOrgErrorType) {
		return {
			...state,
			editingOrganisation: false,
			editOrganisationError: true,
		};
	}

	if (action.type === requestFeaturesType) {
		return {
			...state,
			featuresLoading: true,
		};
	}
	if (action.type === receiveFeaturesType) {
		return {
			...state,
			features: action.features,
			featuresLoading: false,
		};
	}
	if (action.type === receiveFeaturesErrorType) {
		return {
			...state,
			featuresLoading: false,
		};
	}

	if (action.type === requestColorType) {
		return {
			...state,
			schemeLoading: true,
		};
	}
	if (action.type === receiveColorType) {
		return {
			...state,
			orgColor: action.orgColor,
			schemeLoading: false,
		};
	}
	if (action.type === receiveTopicsErrorType) {
		return {
			...state,
			schemeLoading: false,
		};
	}

	if (action.type === requestAvailableFeaturesType) {
		return {
			...state,
			availableFeaturesLoading: true,
		};
	}
	if (action.type === receiveAvailableFeaturesType) {
		return {
			...state,
			availableFeatures: action.availableFeatures,
			availableFeaturesLoading: false,
		};
	}
	if (action.type === receiveAvailableFeaturesErrorType) {
		return {
			...state,
			availableFeaturesLoading: false,
		};
	}

	if (action.type === requestDeleteOrgType) {
		return {
			...state,
			deletingOrg: true,
		};
	}
	if (action.type === deleteOrgCompleteType) {
		return {
			...state,
			deletingOrg: false,
		};
	}
	if (action.type === deleteOrgErrorType) {
		return {
			...state,
			deletingOrg: false,
		};
	}

	if (action.type === requestOrgCountsType) {
		return {
			...state,
			orgCountsLoading: true,
			orgCountsError: false,
		};
	}
	if (action.type === receiveOrgCountsType) {
		return {
			...state,
			orgCountsLoading: false,
			orgCounts: action.orgCounts,
		};
	}
	if (action.type === receiveOrgCountsErrorType) {
		return {
			...state,
			orgCountsLoading: false,
			orgCountsError: true,
		};
	}

	return state;
};
