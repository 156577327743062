import {
	deleteSecured,
	fetchAbortable,
	fetchDefault,
	fetchSecured,
	postSecured,
	putSecured,
} from '../fetchUtils';
import stringLookup from './../stringslookup';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';

const requestSearchResultsType = 'REQUEST_SEARCH_RESULTS';
const receiveSearchResultsType = 'RECEIVE_SEARCH_RESULTS';
const receiveSearchResultsErrorType = 'RECEIVE_SEARCH_RESULTS_ERROR';

const requestSubtopicResultsType = 'REQUEST_SUBTOPIC_RESULTS';
const receiveSubtopicResultsType = 'RECEIVE_SUBTOPIC_RESULTS';
const receiveSubtopicResultsErrorType = 'RECEIVE_SUBTOPIC_RESULTS_ERROR';

const requestSubtopicRelatedArticlesType = 'REQUEST_SUBTOPIC_RELATED_ARTICLES';
const receiveSubtopicRelatedArticlesType = 'RECEIVE_SUBTOPIC_RELATED_ARTICLES';
const receiveSubtopicRelatedArticlesErrorType = 'RECEIVE_SUBTOPIC_RELATED_ARTICLES_ERROR';

const REQUEST_TEXT_TYPE = 'REQUEST_NEWS_TEXT';
const RECEIVE_TEXT_TYPE = 'RECEIVE_NEWS_TEXT';

const REQUEST_TRANSLATED_TEXT_TYPE = 'REQUEST_TRANSLATED_NEWS_TEXT';
const RECEIVE_TRANSLATED_TEXT_TYPE = 'RECEIVE_TRANSLATED_NEWS_TEXT';
const RECEIVE_TEXT_ERROR_TYPE = 'SET_TEXT_ERROR';

const REQUEST_SEARCH_SUGGESTIONS_TYPE = 'REQUEST_SEARCH_SUGGESTIONS';
const RECEIVE_SEARCH_SUGGESTIONS_TYPE = 'RECEIVE_SEARCH_SUGGESTIONS';
const RECEIVE_SEARCH_SUGGESTIONS_ERROR_TYPE = 'RECEIVE_SEARCH_SUGGESTIONS_ERROR';

const REQUEST_SEARCH_QUERIES_TYPE = 'REQUEST_SEARCH_QUERIES';
const RECEIVE_SEARCH_QUERIES_TYPE = 'RECEIVE_SEARCH_QUERIES';
const RECEIVE_SEARCH_QUERIES_ERROR_TYPE = 'RECEIVE_SEARCH_QUERIES_ERROR';

const ADD_SEARCH_QUERY_TYPE = 'ADD_SEARCH_QUERY';
const ADD_SEARCH_QUERY_COMPLETE_TYPE = 'ADD_SEARCH_QUERY_COMPLETE';
const ADD_SEARCH_QUERY_ERROR_TYPE = 'ADD_SEARCH_QUERY_ERROR';

const EDIT_SEARCH_QUERY_TYPE = 'EDIT_SEARCH_QUERY';
const EDIT_SEARCH_QUERY_COMPLETE_TYPE = 'EDIT_SEARCH_QUERY_COMPLETE';
const EDIT_SEARCH_QUERY_ERROR_TYPE = 'EDIT_SEARCH_QUERY_ERROR';

const ASSOCIATE_QUERY_WITH_SUBTOPIC_TYPE = 'ASSOCIATE_QUERY_WITH_SUBTOPIC';
const ASSOCIATE_QUERY_WITH_SUBTOPIC_COMPLETE_TYPE =
	'ASSOCIATE_QUERY_WITH_SUBTOPIC_COMPLETE';
const ASSOCIATE_QUERY_WITH_SUBTOPIC_ERROR_TYPE = 'ASSOCIATE_QUERY_WITH_SUBTOPIC_ERROR';

const DELETE_SEARCH_QUERY_TYPE = 'DELETE_SEARCH_QUERY';
const DELETE_SEARCH_QUERY_COMPLETE_TYPE = 'DELETE_SEARCH_QUERY_COMPLETE';
const DELETE_SEARCH_QUERY_ERROR_TYPE = 'DELETE_SEARCH_QUERY_ERROR';

const SET_SEARCH_STRING_TYPE = 'SET_SEARCH_STRING';
const SET_MOBILE_SEARCH_OPEN_TYPE = 'SET_MOBILE_SEARCH_OPEN';

const initialState = {
	searchResults: [],
	subtopicsSearchResults: [],
	searchResultsFetchError: false,
	searchLoading: false,
	searchResultsNumber: 0,
	originalText: null,
	translatedText: null,
	selectedArticleLanguages: [],
	textFetchError: false,
	newsTextLoading: false,
	searchSuggestions: [],
	searchSuggestionsLoading: false,
	searchSuggestionsError: false,
	relatedItems: [],
	relatedSubtopics: [],
	appSearchString: null,
	subtopicRelatedArticles: [],
	relatedArticlesLoading: false,
	relatedArticlesError: false,
	addQueryLoading: false,
	addQueryError: false,
	editQueryLoading: false,
	editQueryError: false,
	associateQueryLoading: true,
	associateQueryError: false,
	searchQueries: [],
	searchQueriesTotal: 0,
	searchQueriesLoading: false,
	searchQueriesError: false,
	mobileSearchOpen: false,
};

export const searchActionCreators = {
	getSearchSuggestions:
		(searchString, suggestionKind, mediaType = 'all') =>
		async (dispatch, getState) => {
			// if (getState().search.searchSuggestionsLoading) {
			// 	return;
			// }

			dispatch({ type: REQUEST_SEARCH_SUGGESTIONS_TYPE });

			const url = new URL(
				'/api/landingpage/search/suggestions',
				stringLookup.baseUrl
			);

			if (searchString) {
				url.searchParams.append('keyword', searchString);
			}

			if (suggestionKind) {
				url.searchParams.append('suggestionKind', suggestionKind);
			}

			if (mediaType == 'images') {
				url.searchParams.append('mediaType', 'newsOutlets');
			} else if (mediaType === 'chatbot') {
				url.searchParams.append('mediaType', 'all');
			} else {
				url.searchParams.append('mediaType', mediaType);
			}

			try {
				const response = await fetchAbortable(url, 'search-suggestion');

				if (response.ok) {
					const payload = await response.json();
					dispatch({
						type: RECEIVE_SEARCH_SUGGESTIONS_TYPE,
						searchSuggestions: payload,
					});
				} else {
					dispatch({ type: RECEIVE_SEARCH_SUGGESTIONS_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SEARCH_SUGGESTIONS_ERROR_TYPE });
			}
		},

	getSearchResults:
		(
			subtopicId,
			searchString,
			pageNumber = 1,
			pageSize = 100,
			relatedItemsCount = 30
		) =>
		async (dispatch, getState) => {
			if (getState().search.searchLoading) {
				return;
			}

			dispatch({ type: requestSearchResultsType });
			setGlobalLoad(dispatch);

			const url = new URL('/api/crawler/search', stringLookup.baseUrl);
			url.searchParams.append('page', pageNumber);
			url.searchParams.append('pageSize', pageSize);

			if (subtopicId && subtopicId !== 'null') {
				url.searchParams.append('subtopicId', subtopicId);
			}

			if (searchString && subtopicId === 'null') {
				url.searchParams.append('text', searchString);
				url.searchParams.append('relatedItemsCount', relatedItemsCount);
			}

			try {
				const searchResponse = await fetch(url);

				if (searchResponse.ok) {
					const searchPayload = await searchResponse.json();
					dispatch({
						type: receiveSearchResultsType,
						searchResults: searchPayload.data,
						searchResultsNumber: searchPayload.total,
						relatedItems: searchPayload.relatedItems,
					});
				} else {
					dispatch({ type: receiveSearchResultsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveSearchResultsErrorType });
			}

			unsetGlobalLoad(dispatch);
		},

	getSubtopicSearchResults:
		(subtopicId, pageNumber = 1, pageSize = 10) =>
		async (dispatch, getState) => {
			if (getState().search.searchLoading) {
				return;
			}

			dispatch({ type: requestSubtopicResultsType });
			setGlobalLoad(dispatch);

			const url = new URL('/api/crawler/search/subtopics', stringLookup.baseUrl);
			url.searchParams.append('page', pageNumber);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('subtopicId', subtopicId);

			try {
				const searchResponse = await fetchDefault(url);

				if (searchResponse.ok) {
					const searchPayload = await searchResponse.json();
					dispatch({
						type: receiveSubtopicResultsType,
						subtopicsSearchResults: searchPayload.data,
						searchResultsNumber: searchPayload.total,
						relatedSubtopics: searchPayload.relatedItems,
					});
				} else {
					dispatch({ type: receiveSubtopicResultsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveSubtopicResultsErrorType });
			}
			unsetGlobalLoad(dispatch);
		},

	getSubtopicRelatedArticles:
		(subtopicId, pageNumber = 1, pageSize = 10) =>
		async (dispatch, getState) => {
			if (getState().search.relatedArticlesLoading) {
				return;
			}

			dispatch({ type: requestSubtopicRelatedArticlesType });
			setGlobalLoad(dispatch);

			const url = new URL('/api/crawler/search/subtopics', stringLookup.baseUrl);
			url.searchParams.append('page', pageNumber);
			url.searchParams.append('pageSize', pageSize);
			url.searchParams.append('subtopicId', subtopicId);
			url.searchParams.append('relatedArticles', true);

			try {
				const searchResponse = await fetchDefault(url);

				if (searchResponse.ok) {
					const searchPayload = await searchResponse.json();
					dispatch({
						type: receiveSubtopicRelatedArticlesType,
						subtopicRelatedArticles: searchPayload.data,
						relatedSubtopics: searchPayload.relatedItems,
						searchResultsNumber: searchPayload.total,
					});
				} else {
					dispatch({ type: receiveSubtopicRelatedArticlesErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveSubtopicRelatedArticlesErrorType });
			}
			unsetGlobalLoad(dispatch);
		},

	getWebpageText: (pageId) => async (dispatch, getState) => {
		if (getState().news.newsTextLoading) {
			return;
		}

		dispatch({ type: REQUEST_TEXT_TYPE });
		// setGlobalLoad(dispatch);

		const url = new URL('/api/crawler/webpages', stringLookup.baseUrl);
		url.searchParams.append('id', pageId);

		try {
			const newsTextResponse = await fetch(url);

			if (newsTextResponse.ok) {
				const textPayload = await newsTextResponse.json();
				dispatch({
					type: RECEIVE_TEXT_TYPE,
					originalText: textPayload,
				});
			} else {
				dispatch({ type: RECEIVE_TEXT_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_TEXT_ERROR_TYPE });
		}

		// unsetGlobalLoad(dispatch);
	},

	getWebpageTranslation: (pageId) => async (dispatch, getState) => {
		if (getState().news.newsLoading) {
			return;
		}

		dispatch({ type: REQUEST_TRANSLATED_TEXT_TYPE });

		const url = new URL('/api/crawler/webpages/translation', stringLookup.baseUrl);
		url.searchParams.append('id', pageId);

		try {
			const newsTextResponse = await fetch(url);

			if (newsTextResponse.ok) {
				const textPayload = await newsTextResponse.json();
				dispatch({
					type: RECEIVE_TRANSLATED_TEXT_TYPE,
					translatedText: textPayload,
				});
			} else {
				dispatch({ type: RECEIVE_TEXT_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: RECEIVE_TEXT_ERROR_TYPE });
		}
	},

	setSearchString: (searchString) => async (dispatch, getState) => {
		const searchStringObj = { name: searchString };
		dispatch({
			type: SET_SEARCH_STRING_TYPE,
			appSearchString: searchStringObj,
		});
	},

	getSearchQueries:
		(organisationId, page = 1, pageSize = 10, searchString) =>
		async (dispatch) => {
			dispatch({ type: REQUEST_SEARCH_QUERIES_TYPE });

			const url = new URL('/api/crawler/lucene', stringLookup.baseUrl);
			if (organisationId) {
				url.searchParams.append('organisationId', organisationId);
			}
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const response = await fetchSecured(url, 'getSearchQueries');
				if (response.ok) {
					const searchQueriesPayload = await response.json();
					dispatch({
						type: RECEIVE_SEARCH_QUERIES_TYPE,
						searchQueries: searchQueriesPayload.data,
						searchQueriesTotal: searchQueriesPayload.total,
					});
				} else {
					dispatch({ type: RECEIVE_SEARCH_QUERIES_ERROR_TYPE });
				}
			} catch (err) {
				dispatch({ type: RECEIVE_SEARCH_QUERIES_ERROR_TYPE });
			}
		},

	addSearchQuery: (searchQuery) => async (dispatch) => {
		dispatch({ type: ADD_SEARCH_QUERY_TYPE });
		const url = new URL('/api/crawler/lucene', stringLookup.baseUrl);

		try {
			const Response = await postSecured(url, searchQuery);

			if (Response.ok) {
				dispatch({ type: ADD_SEARCH_QUERY_COMPLETE_TYPE });
			} else {
				dispatch({
					type: ADD_SEARCH_QUERY_ERROR_TYPE,
				});
			}
		} catch (err) {
			dispatch({ type: ADD_SEARCH_QUERY_ERROR_TYPE });
		}
	},

	editSearchQuery: (searchQuery) => async (dispatch) => {
		dispatch({ type: EDIT_SEARCH_QUERY_TYPE });
		const url = new URL('/api/crawler/lucene', stringLookup.baseUrl);

		try {
			const Response = await putSecured(url, searchQuery);

			if (Response.ok) {
				dispatch({ type: EDIT_SEARCH_QUERY_COMPLETE_TYPE });
			} else {
				dispatch({
					type: EDIT_SEARCH_QUERY_ERROR_TYPE,
				});
			}
		} catch (err) {
			dispatch({ type: EDIT_SEARCH_QUERY_ERROR_TYPE });
		}
	},

	associateQueryWithSubtopic: (searchQuery) => async (dispatch) => {
		dispatch({ type: ASSOCIATE_QUERY_WITH_SUBTOPIC_TYPE });
		const url = new URL('/api/crawler/lucene/associate', stringLookup.baseUrl);

		try {
			const Response = await putSecured(url, searchQuery);

			if (Response.ok) {
				dispatch({ type: ASSOCIATE_QUERY_WITH_SUBTOPIC_COMPLETE_TYPE });
			} else {
				dispatch({
					type: ASSOCIATE_QUERY_WITH_SUBTOPIC_ERROR_TYPE,
				});
			}
		} catch (err) {
			dispatch({ type: ASSOCIATE_QUERY_WITH_SUBTOPIC_ERROR_TYPE });
		}
	},

	deleteSearchQuery: (id) => async (dispatch) => {
		dispatch({ type: DELETE_SEARCH_QUERY_TYPE });

		const url = new URL(`/api/crawler/lucene/${id}`, stringLookup.baseUrl);

		try {
			const deleteResponse = await deleteSecured(url);

			if (deleteResponse.ok) {
				dispatch({ type: DELETE_SEARCH_QUERY_COMPLETE_TYPE });
			} else {
				dispatch({ type: DELETE_SEARCH_QUERY_ERROR_TYPE });
			}
		} catch (err) {
			dispatch({ type: DELETE_SEARCH_QUERY_ERROR_TYPE });
		}
	},

	setMobileSearchOpen: (mobileSearchOpen) => async (dispatch) => {
		dispatch({
			type: SET_MOBILE_SEARCH_OPEN_TYPE,
			mobileSearchOpen,
		});
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === requestSearchResultsType) {
		return {
			...state,
			searchLoading: true,
		};
	}

	if (action.type === REQUEST_TEXT_TYPE) {
		return {
			...state,
			newsTextLoading: true,
		};
	}

	if (action.type === REQUEST_TRANSLATED_TEXT_TYPE) {
		return {
			...state,
			newsTextLoading: true,
		};
	}

	if (action.type === REQUEST_SEARCH_SUGGESTIONS_TYPE) {
		return {
			...state,
			searchSuggestionsLoading: true,
			searchSuggestionsError: false,
		};
	}

	if (action.type === RECEIVE_SEARCH_SUGGESTIONS_TYPE) {
		return {
			...state,
			searchSuggestionsLoading: false,
			searchSuggestions: action.searchSuggestions,
		};
	}

	if (action.type === RECEIVE_SEARCH_SUGGESTIONS_ERROR_TYPE) {
		return {
			...state,
			searchSuggestionsLoading: false,
			searchSuggestionsError: true,
		};
	}

	if (action.type === receiveSearchResultsType) {
		return {
			...state,
			searchLoading: false,
			searchResults: action.searchResults,
			searchResultsNumber: action.searchResultsNumber,
			relatedItems: action.relatedItems,
		};
	}

	if (action.type === RECEIVE_TEXT_TYPE) {
		return {
			...state,
			newsTextLoading: false,
			originalText: action.originalText,
		};
	}

	if (action.type === RECEIVE_TRANSLATED_TEXT_TYPE) {
		return {
			...state,
			newsTextLoading: false,
			translatedText: action.translatedText,
		};
	}

	if (action.type === receiveSearchResultsErrorType) {
		return {
			...state,
			searchLoading: false,
			searchResultsFetchError: true,
		};
	}

	if (action.type === RECEIVE_TEXT_ERROR_TYPE) {
		return {
			...state,
			textFetchError: true,
			newsTextLoading: false,
		};
	}

	if (action.type === SET_SEARCH_STRING_TYPE) {
		return {
			...state,
			appSearchString: action.appSearchString,
		};
	}

	if (action.type === requestSubtopicResultsType) {
		return {
			...state,
			searchLoading: true,
			searchResultsFetchError: false,
			subtopicsSearchResults: [],
		};
	}
	if (action.type === receiveSubtopicResultsType) {
		return {
			...state,
			searchLoading: false,
			subtopicsSearchResults: action.subtopicsSearchResults,
			searchResultsNumber: action.searchResultsNumber,
			relatedSubtopics: action.relatedSubtopics,
		};
	}
	if (action.type === receiveSubtopicResultsErrorType) {
		return {
			...state,
			searchLoading: false,
			searchResultsFetchError: true,
		};
	}

	if (action.type === requestSubtopicRelatedArticlesType) {
		return {
			...state,
			relatedArticlesLoading: true,
			relatedArticlesError: false,
			subtopicRelatedArticles: [],
		};
	}
	if (action.type === receiveSubtopicRelatedArticlesType) {
		return {
			...state,
			relatedArticlesLoading: false,
			subtopicRelatedArticles: action.subtopicRelatedArticles,
			relatedSubtopics: action.relatedSubtopics,
			searchResultsNumber: action.searchResultsNumber,
		};
	}
	if (action.type === receiveSubtopicRelatedArticlesErrorType) {
		return {
			...state,
			relatedArticlesLoading: false,
			relatedArticlesError: true,
		};
	}

	if (action.type === ADD_SEARCH_QUERY_TYPE) {
		return {
			...state,
			addQueryLoading: true,
			addQueryError: false,
		};
	}
	if (action.type === ADD_SEARCH_QUERY_COMPLETE_TYPE) {
		return {
			...state,
			addQueryLoading: false,
			addQueryError: false,
		};
	}
	if (action.type === ADD_SEARCH_QUERY_ERROR_TYPE) {
		return {
			...state,
			addQueryLoading: false,
			addQueryError: true,
		};
	}

	if (action.type === EDIT_SEARCH_QUERY_TYPE) {
		return {
			...state,
			editQueryLoading: true,
			editQueryError: false,
		};
	}
	if (action.type === EDIT_SEARCH_QUERY_COMPLETE_TYPE) {
		return {
			...state,
			editQueryLoading: false,
			editQueryError: false,
		};
	}
	if (action.type === EDIT_SEARCH_QUERY_ERROR_TYPE) {
		return {
			...state,
			editQueryLoading: false,
			editQueryError: true,
		};
	}

	if (action.type === REQUEST_SEARCH_QUERIES_TYPE) {
		return {
			...state,
			searchQueriesLoading: true,
			searchQueriesError: false,
		};
	}
	if (action.type === RECEIVE_SEARCH_QUERIES_TYPE) {
		return {
			...state,
			searchQueriesLoading: false,
			searchQueriesError: false,
			searchQueries: action.searchQueries,
			searchQueriesTotal: action.searchQueriesTotal,
		};
	}
	if (action.type === RECEIVE_SEARCH_QUERIES_ERROR_TYPE) {
		return {
			...state,
			searchQueriesLoading: false,
			searchQueriesError: true,
		};
	}

	if (action.type === DELETE_SEARCH_QUERY_TYPE) {
		return {
			...state,
			deletingSearchQuery: true,
			deletingQueryError: false,
		};
	}
	if (action.type === DELETE_SEARCH_QUERY_COMPLETE_TYPE) {
		return {
			...state,
			deletingSearchQuery: false,
		};
	}
	if (action.type === DELETE_SEARCH_QUERY_TYPE) {
		return {
			...state,
			deletingSearchQuery: false,
			deletingQueryError: true,
		};
	}

	if (action.type === ASSOCIATE_QUERY_WITH_SUBTOPIC_TYPE) {
		return {
			...state,
			associateQueryLoading: true,
			associateQueryError: false,
		};
	}
	if (action.type === ASSOCIATE_QUERY_WITH_SUBTOPIC_COMPLETE_TYPE) {
		return {
			...state,
			associateQueryLoading: false,
			associateQueryError: false,
		};
	}
	if (action.type === ASSOCIATE_QUERY_WITH_SUBTOPIC_ERROR_TYPE) {
		return {
			...state,
			associateQueryLoading: false,
			associateQueryError: true,
		};
	}

	if (action.type === SET_MOBILE_SEARCH_OPEN_TYPE) {
		return {
			...state,
			mobileSearchOpen: action.mobileSearchOpen,
		};
	}

	return state;
};
