import stringLookup from '../stringslookup';
import { fetchSecured, putSecured, patchSecured } from '../fetchUtils';
const requestTrainingMetricsType = 'REQUEST_TRAINING_METRICS';
const receiveTrainingMetricsType = 'RECEIVE_TRAINING_METRICS_TYPE';
const receiveTrainingMetricsErrorType = 'RECEIVE_TRAINING_METRICS_ERROR_TYPE';

const requestUntrainedItemsType = 'REQUEST_UNTRAINED';
const receiveUntrainedItemsType = 'RECEIVE_UNTRAINED';
const receiveUntrainedItemsErrorType = 'RECEIVE_UNTRAINED_ERROR';

const sendTrainingCommandType = 'SEND_TRAINING_COMMAND';
const completeTrainingCommandType = 'COMPLETE_TRAINING_COMMAND';

const requestTrainedItemsType = 'REQUEST_TRAINED';
const receiveTrainedItemsType = 'RECEIVE_TRAINED';
const receiveTrainedItemsErrorType = 'RECEIVE_TRAINED_ERROR';

const requestModelMetricsType = 'REQUEST_MODEL_METRICS';
const receiveModelMetricsType = 'RECEIVE_MODEL_METRICS';
const receiveModelMetricsErrorType = 'RECEIVE_MODEL_METRICS_ERROR';

const IntialDatePublishedMLState = {
	trainingMetrics: null,
	trainingMetricsLoading: false,
	untrainedItems: [],
	untrainedItemsLoading: false,
	trainingInProgress: false,
	trainedItems: [],
	trainedItemsLoading: false,
	modelMetrics: null,
	modelMetricsLoading: false,
};
export const datePublishedMLActionCreators = {
	requestTrainingMetrics: (_) => async (dispatch, getState) => {
		if (getState().datePublishedML.trainingMetricsLoading) {
			return;
		}

		dispatch({ type: requestTrainingMetricsType });

		const url = new URL(
			'/api/datepublishedml/gettraningmetrics',
			stringLookup.machineLearningApi
		);
		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const metrics = await res.json();
				dispatch({ type: receiveTrainingMetricsType, metrics: metrics });
			} else {
				dispatch({ type: receiveTrainingMetricsErrorType });
			}
		} catch {
			dispatch({ type: receiveTrainingMetricsErrorType });
		}
	},
	requestUntrainedItems: (page) => async (dispatch, getState) => {
		if (getState().datePublishedML.untrainedItemsLoading) {
			return;
		}

		dispatch({ type: requestUntrainedItemsType });

		const url = new URL(
			'/api/datepublishedml/getuntrained',
			stringLookup.machineLearningApi
		);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const untrainedItems = await res.json();
				dispatch({
					type: receiveUntrainedItemsType,
					untrainedItems: untrainedItems,
				});
			} else {
				dispatch({ type: receiveUntrainedItemsErrorType });
			}
		} catch {
			dispatch({ type: receiveUntrainedItemsErrorType });
		}
	},
	// trainItem: (itemId, trainingInfo) => async (dispatch, getState) => {
	//     if (getState().datePublishedML.trainingInProgress) {
	//         return;
	//     }

	//     dispatch({ type: sendTrainingCommandType });
	//     const url = new URL(`/api/datepublishedml/${itemId}/train`, stringLookup.localMLUrl);

	//     try {
	//         const res = await putSecured (url, trainingInfo);

	//         if (res.ok) {
	//             dispatch({ type: completeTrainingCommandType });
	//         }
	//         else {
	//             dispatch({ type: completeTrainingCommandType });
	//         }

	//     } catch{
	//         dispatch({ type: completeTrainingCommandType });
	//     }

	// },
	// skipItem: (itemId) => async (dispatch, getState) => {
	//     if (getState().datePublishedML.trainingInProgress) {
	//         return;
	//     }

	//     dispatch({ type: sendTrainingCommandType });
	//     const url = new URL(`/api/datepublishedml/${itemId}/skip`, stringLookup.localMLUrl);

	//     try {
	//         const res = await patchSecured(url);

	//         if (res.ok) {
	//             dispatch({ type: completeTrainingCommandType });
	//         }
	//         else {
	//             dispatch({ type: completeTrainingCommandType });
	//         }

	//     } catch{
	//         dispatch({ type: completeTrainingCommandType });
	//     }

	// },
	requestTrainedItems:
		(page, queryString = '') =>
		async (dispatch, getState) => {
			if (getState().datePublishedML.trainedItemsLoading) {
				return;
			}

			dispatch({ type: requestTrainedItemsType });

			const url = new URL(
				'/api/datepublishedml/gettrained',
				stringLookup.machineLearningApi
			);
			url.searchParams.append('page', page);
			if (queryString.trim()) {
				url.searchParams.append('queryString', queryString.trim());
			}

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const trainedItems = await res.json();
					dispatch({
						type: receiveTrainedItemsType,
						trainedItems: trainedItems,
					});
				} else {
					dispatch({ type: receiveTrainedItemsErrorType });
				}
			} catch {
				dispatch({ type: receiveTrainedItemsErrorType });
			}
		},
	requestModelMetrics: () => async (dispatch, getState) => {
		if (getState().datePublishedML.modelMetricsLoading) {
			return;
		}

		dispatch({ type: requestModelMetricsType });

		const url = new URL(
			'/api/datepublishedml/datemodelmetrics',
			stringLookup.machineLearningApi
		);
		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const metrics = await res.json();
				dispatch({ type: receiveModelMetricsType, metrics: metrics });
			} else {
				dispatch({ type: receiveModelMetricsErrorType });
			}
		} catch {
			dispatch({ type: receiveModelMetricsErrorType });
		}
	},
};

export const reducer = (state, action) => {
	state = state || IntialDatePublishedMLState;

	if (action.type === requestTrainingMetricsType) {
		return {
			...state,
			trainingMetricsLoading: true,
		};
	}

	if (action.type === receiveTrainingMetricsType) {
		return {
			...state,
			trainingMetrics: action.metrics,
			trainingMetricsLoading: false,
		};
	}

	if (action.type === receiveTrainingMetricsErrorType) {
		return {
			...state,
			trainingMetricsLoading: false,
			trainingMetrics: null,
		};
	}

	if (action.type === requestUntrainedItemsType) {
		return {
			...state,
			untrainedItemsLoading: true,
		};
	}

	if (action.type === receiveUntrainedItemsType) {
		return {
			...state,
			untrainedItems: action.untrainedItems,
			untrainedItemsLoading: false,
		};
	}

	if (action.type === receiveUntrainedItemsErrorType) {
		return {
			...state,
			untrainedItems: [],
			untrainedItemsLoading: false,
		};
	}

	if (action.type === sendTrainingCommandType) {
		return {
			...state,
			trainingInProgress: true,
		};
	}

	if (action.type === completeTrainingCommandType) {
		return {
			...state,
			trainingInProgress: false,
		};
	}

	if (action.type === requestTrainedItemsType) {
		return {
			...state,
			trainedItemsLoading: true,
		};
	}

	if (action.type === receiveTrainedItemsType) {
		return {
			...state,
			trainedItemsLoading: false,
			trainedItems: action.trainedItems,
		};
	}

	if (action.type === receiveTrainedItemsErrorType) {
		return {
			...state,
			trainedItemsLoading: false,
			trainedItems: [],
		};
	}

	if (action.type === requestModelMetricsType) {
		return {
			...state,
			modelMetricsLoading: true,
		};
	}

	if (action.type === receiveModelMetricsType) {
		return {
			...state,
			modelMetrics: action.metrics,
			modelMetricsLoading: false,
		};
	}

	if (action.type === receiveModelMetricsErrorType) {
		return {
			...state,
			modelMetricsLoading: false,
		};
	}

	return state;
};
