import stringLookup from './../stringslookup';
import {
	fetchSecured,
	postSecured,
	deleteSecured,
	putSecured,
} from './../fetchUtils';

const requestRootUrlsType = 'REQUEST_CONFIG_ROOTURLS';
const receiveRootUrlsType = 'RECEIVE_CONFIG_ROOTURLS';
const receiveRootUrlsErrorType = 'RECEIVE_CONFIG_ROOTURLS_ERROR';

const addRootUrlType = 'ADD_CONFIG_ROOTURLS';
const addRootUrlCompleteType = 'ADD_CONFIG_ROOTURLS_COMPLETE';

const deleteRootUrlType = 'DELETE_CONFIG_ROOTURLS';
const deleteRootUrlCompleteType = 'DELETE_CONFIG_ROOTURLS_COMPLETE';

const requestTwitterHandleSuggestionType = 'REQUEST_TWITTER_HANDLE';
const receiveTwitterHandleSuggestionType = 'RECEIVE_TWITTER_HANDLE';
const receiveTwitterHandleSuggestionErrorType = 'REQUEST_TWITTER_HANDLE_ERROR';

const requestDynamicHostsType = 'REQUEST_DYNAMIC_HOSTS';
const receiveDynamicHostsType = 'RECEIVE_DYNAMIC_HOSTS';
const receiveDynamicHostsErrorType = 'RECEIVE_DYNAMIC_HOSTS_ERROR';

const addDynamicHostType = 'ADD_DYNAMIC_HOST';
const addDynamicHostCompleteType = 'ADD_DYNAMIC_HOST_COMPLETE';

const editDynamicHostType = 'EDIT_DYNAMIC_HOST';
const editDynamicHostCompleteType = 'EDIT_DYNAMIC_HOST_COMPLETE';

const deleteDynamicHostType = 'DELETE_DYNAMIC_HOST';
const deleteDynamicHostCompleteType = 'DELETE_DYNAMIC_HOST_COMPLETE';

const requestSimulatedLinksType = 'REQUEST_SIMULATED_LINKS';
const receiveSimulatedLinksType = 'RECEIVE_SIMULATED_LINKS';
const receiveSimulatedLinksErrorType = 'RECEIVE_SIMULATED_LINKS_ERROR';

const initialRootUrlsState = {
	rootUrls: [],
	totalRootUrls: 0,
	rootUrlsLoading: false,
	addingRootUrl: false,
	deletingRootUrl: false,
	twitterHandleSuggestions: [],
	twitterHandleSuggestionsLoading: false,
	twitterHandleSuggestionsError: false,
	dynamicHosts: [],
	totalDynamicHosts: 0,
	dynamicHostsLoading: false,
	addingDynamicHost: false,
	editingDynamicHost: false,
	deletingDynamicHost: false,
	simulatedLinks: [],
	totalSimulatedLinks: 0,
	simulatedLinksLoading: false,
};

export const actionCreators = {
	requestRootUrls:
		(page, pageSize, filter = '') =>
		async (dispatch, getState) => {
			if (getState().configurationRootUrls.rootUrlsLoading) {
				return;
			}

			dispatch({ type: requestRootUrlsType });

			const url = new URL('/api/crawler/RootUrl', stringLookup.baseUrl);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			if (filter.length !== 0) {
				url.searchParams.append('filter', filter);
			}

			try {
				const rootUrlsResponse = await fetchSecured(url);

				if (rootUrlsResponse.ok) {
					const rootUrlsRes = await rootUrlsResponse.json();
					dispatch({
						type: receiveRootUrlsType,
						rootUrls: rootUrlsRes.data,
						totalRootUrls: rootUrlsRes.total,
					});
				} else {
					dispatch({ type: receiveRootUrlsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveRootUrlsErrorType });
			}
		},
	addRootUrl: (rootUrl) => async (dispatch, getState) => {
		if (getState().configurationRootUrls.addingRootUrl) {
			return;
		}

		dispatch({ type: addRootUrlType });
		const url = new URL('/api/crawler/RootUrl', stringLookup.baseUrl);

		try {
			const rootUrlsResponse = await postSecured(url, rootUrl);

			if (rootUrlsResponse.ok) {
				//const rootUrlsRes = await rootUrlsResponse.json();
				dispatch({ type: addRootUrlCompleteType });
			} else {
				dispatch({ type: addRootUrlCompleteType });
			}
		} catch (err) {
			dispatch({ type: addRootUrlCompleteType });
		}
	},
	deleteRootUrl: (id) => async (dispatch, getState) => {
		if (getState().configurationRootUrls.deletingRootUrl) {
			return;
		}

		dispatch({ type: deleteRootUrlType });

		const url = new URL(`/api/crawler/RootUrl/${id}`, stringLookup.baseUrl);

		try {
			const deleteResponse = await deleteSecured(url);

			if (deleteResponse.ok) {
				dispatch({ type: deleteRootUrlCompleteType });
			} else {
				dispatch({ type: deleteRootUrlCompleteType });
			}
		} catch (err) {
			dispatch({ type: deleteRootUrlCompleteType });
		}
	},
	getTwitterHandleSuggestion:
		(searchString) => async (dispatch, getState) => {
			if (getState().configurationRootUrls.getTwitterHandleSuggestion) {
				return;
			}

			dispatch({ type: requestTwitterHandleSuggestionType });

			const url = new URL(
				'api/crawler/RootUrl/handle-suggestion',
				stringLookup.baseUrl
			);

			url.searchParams.append('searchString', searchString);

			try {
				const res = await fetchSecured(url);

				if (res.ok) {
					const data = await res.json();

					dispatch({
						type: receiveTwitterHandleSuggestionType,
						twitterHandleSuggestions: data,
					});
				} else {
					dispatch({ type: receiveTwitterHandleSuggestionErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveTwitterHandleSuggestionErrorType });
			}
		},

	getDynamicHosts: (page, pageSize) => async (dispatch, getState) => {
		if (getState().configurationRootUrls.dynamicHostsLoading) {
			return;
		}

		dispatch({ type: requestDynamicHostsType });

		const url = new URL('/api/v1/dynamiccrawl', stringLookup.baseUrl);
		url.searchParams.append('page', page);
		url.searchParams.append('pageSize', pageSize);

		try {
			const dynamicHostsResponse = await fetchSecured(url);

			if (dynamicHostsResponse.ok) {
				const dynamicHostsRes = await dynamicHostsResponse.json();
				dispatch({
					type: receiveDynamicHostsType,
					dynamicHosts: dynamicHostsRes.data,
					totalDynamicHosts: dynamicHostsRes.total,
				});
			} else {
				dispatch({ type: receiveDynamicHostsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveDynamicHostsErrorType });
		}
	},

	addDynamicHost: (crawlTemplateReq) => async (dispatch, getState) => {
		if (getState().configurationRootUrls.addingDynamicHost) {
			return;
		}

		// const dynamicHost = { template };

		dispatch({ type: addDynamicHostType });
		const url = new URL('/api/v1/dynamiccrawl', stringLookup.baseUrl);

		try {
			const dynamicHostsResponse = await postSecured(
				url,
				crawlTemplateReq
			);

			if (dynamicHostsResponse.ok) {
				dispatch({ type: addDynamicHostCompleteType });
			} else {
				dispatch({ type: addDynamicHostCompleteType });
			}
		} catch (err) {
			dispatch({ type: addDynamicHostCompleteType });
		}
	},

	editDynamicHost: (id, crawlTemplateReq) => async (dispatch, getState) => {
		if (getState().configurationRootUrls.editingDynamicHost) {
			return;
		}

		// const dynamicHost = { template };

		dispatch({ type: editDynamicHostType });
		const url = new URL(`/api/v1/dynamiccrawl/${id}`, stringLookup.baseUrl);

		try {
			const dynamicHostsResponse = await putSecured(
				url,
				crawlTemplateReq
			);

			if (dynamicHostsResponse.ok) {
				dispatch({ type: editDynamicHostCompleteType });
			} else {
				dispatch({ type: editDynamicHostCompleteType });
			}
		} catch (err) {
			dispatch({ type: editDynamicHostCompleteType });
		}
	},

	deleteDynamicHost: (id) => async (dispatch, getState) => {
		if (getState().configurationRootUrls.deletingDynamicHost) {
			return;
		}

		dispatch({ type: deleteDynamicHostType });
		const url = new URL(`/api/v1/dynamiccrawl/${id}`, stringLookup.baseUrl);

		try {
			const dynamicHostsResponse = await deleteSecured(url);

			if (dynamicHostsResponse.ok) {
				dispatch({ type: deleteDynamicHostCompleteType });
			} else {
				dispatch({ type: deleteDynamicHostCompleteType });
			}
		} catch (err) {
			dispatch({ type: deleteDynamicHostCompleteType });
		}
	},

	getSimulatedLinks:
		(subtopicId, urlTemplate) => async (dispatch, getState) => {
			if (getState().configurationRootUrls.simulatedLinksLoading) {
				return;
			}

			dispatch({ type: requestSimulatedLinksType });

			const url = new URL(
				'/api/v1/dynamiccrawl/simulate',
				stringLookup.baseUrl
			);
			url.searchParams.append('topicId', subtopicId);
			url.searchParams.append('url', urlTemplate.crawlTemplate);
			url.searchParams.append(
				'enclosingQuotesType',
				urlTemplate.enclosingQuotesType
			);
			url.searchParams.append(
				'whiteSpaceValue',
				urlTemplate.whiteSpaceValue
			);

			try {
				const simulatedLinksResponse = await fetchSecured(url);

				if (simulatedLinksResponse.ok) {
					const simulatedLinksRes =
						await simulatedLinksResponse.json();
					dispatch({
						type: receiveSimulatedLinksType,
						simulatedLinks: simulatedLinksRes,
						totalSimulatedLinks: simulatedLinksRes.length,
					});
				} else {
					dispatch({ type: receiveSimulatedLinksErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveSimulatedLinksErrorType });
			}
		},
};

export const reducer = (state, action) => {
	state = state || initialRootUrlsState;

	if (action.type === requestRootUrlsType) {
		return {
			...state,
			rootUrlsLoading: true,
		};
	}
	if (action.type === receiveRootUrlsType) {
		return {
			...state,
			rootUrls: action.rootUrls,
			totalRootUrls: action.totalRootUrls,
			rootUrlsLoading: false,
		};
	}
	if (action.type === receiveRootUrlsErrorType) {
		return {
			...state,
			rootUrlsLoading: false,
		};
	}

	if (action.type === addRootUrlType) {
		return {
			...state,
			addingRootUrl: true,
		};
	}
	if (action.type === addRootUrlCompleteType) {
		return {
			...state,
			addingRootUrl: false,
		};
	}

	if (action.type === deleteRootUrlType) {
		return {
			...state,
			deletingRootUrl: true,
		};
	}
	if (action.type === deleteRootUrlCompleteType) {
		return {
			...state,
			deletingRootUrl: false,
		};
	}

	if (action.type === requestTwitterHandleSuggestionType) {
		return {
			...state,
			twitterHandleSuggestionsLoading: true,
			twitterHandleSuggestionsError: false,
		};
	}
	if (action.type === receiveTwitterHandleSuggestionType) {
		return {
			...state,
			twitterHandleSuggestions: action.twitterHandleSuggestions,
			twitterHandleSuggestionsLoading: false,
			twitterHandleSuggestionsError: false,
		};
	}
	if (action.type === receiveTwitterHandleSuggestionErrorType) {
		return {
			...state,
			twitterHandleSuggestionsLoading: false,
			twitterHandleSuggestionsError: true,
		};
	}

	if (action.type === requestDynamicHostsType) {
		return {
			...state,
			dynamicHostsLoading: true,
		};
	}
	if (action.type === receiveDynamicHostsType) {
		return {
			...state,
			dynamicHosts: action.dynamicHosts,
			totalDynamicHosts: action.totalDynamicHosts,
			dynamicHostsLoading: false,
		};
	}
	if (action.type === receiveDynamicHostsErrorType) {
		return {
			...state,
			dynamicHostsLoading: false,
		};
	}

	if (action.type === addDynamicHostType) {
		return {
			...state,
			addingDynamicHost: true,
		};
	}
	if (action.type === addDynamicHostCompleteType) {
		return {
			...state,
			addingDynamicHost: false,
		};
	}

	if (action.type === editDynamicHostType) {
		return {
			...state,
			editingDynamicHost: true,
		};
	}
	if (action.type === editDynamicHostCompleteType) {
		return {
			...state,
			editingDynamicHost: false,
		};
	}

	if (action.type === deleteDynamicHostType) {
		return {
			...state,
			deletingDynamicHost: true,
		};
	}
	if (action.type === deleteDynamicHostCompleteType) {
		return {
			...state,
			deletingDynamicHost: false,
		};
	}

	if (action.type === requestSimulatedLinksType) {
		return {
			...state,
			simulatedLinks: [],
			simulatedLinksLoading: true,
		};
	}
	if (action.type === receiveSimulatedLinksType) {
		return {
			...state,
			simulatedLinks: action.simulatedLinks,
			totalSimulatedLinks: action.totalSimulatedLinks,
			simulatedLinksLoading: false,
		};
	}
	if (action.type === receiveSimulatedLinksErrorType) {
		return {
			...state,
			simulatedLinksLoading: false,
		};
	}

	return state;
};
