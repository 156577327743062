import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import DeleteConfirmation from "../../../util/DeleteConfirmation";
import SelectContactsDialog from "./SelectContactsDialog";

const MAX_NAME_LENGTH = 30;
const MIN_NAME_LENGTH = 3;

const styles = (theme) => ({
	input: {
		minWidth: 400,
		//  marginLeft: theme.spacing.unit,
		//  marginRight: theme.spacing.unit,
		//  paddingTop: theme.spacing.unit,
	},
	deleteButton: {
		position: "absolute",
		right: theme.spacing.unit,
		top: theme.spacing.unit,
	},
});

class AddOrEditGroupDialog extends React.Component {
	initState = () => {
		this.setState({
			name: this.props.name,
			nameValidationError: this.props.mode === "ADD",
			nameDuplicateError: false,
			deleteRequest: false,
			campaignId: this.props.groupCampaignId,
			campaignValidationError: this.props.mode === "ADD",
			selectContactsOpen: false,
			contactsLoading: false,
		});
	};

	handleNameInputChange = (event) => {
		const nameInput = event.target.value.trim();

		if (
			nameInput.length > MAX_NAME_LENGTH ||
			nameInput.length < MIN_NAME_LENGTH
		) {
			this.setState({
				name: nameInput.trim(),
				nameValidationError: true,
				nameDuplicateError: false,
			});
		} else if (this.props.causesDuplicate(this.state.id, nameInput)) {
			this.setState({
				name: nameInput.trim(),
				nameValidationError: false,
				nameDuplicateError: true,
			});
		} else {
			this.setState({
				name: nameInput.trim(),
				nameValidationError: false,
				nameDuplicateError: false,
			});
		}
	};

	handleCampaignIdChange = (event) => {
		this.setState({ campaignId: event.target.value }, () => {
			if (this.state.campaignId !== null) {
				this.setState({ campaignValidationError: false });
			}
		});
	};

	handleSubmitRequest = (selectedContacts, closeDialog = true) => {
		const { handleAdd, handleEdit } = this.props;
		if (this.props.mode === "ADD") {
			handleAdd(
				this.state.name,
				this.state.campaignId,
				selectedContacts,
				closeDialog
			);
			this.handleSelectContactsClose();
		} else {
			handleEdit(this.props.id, this.state.name, this.state.campaignId);
		}
		this.initState();
	};

	handleDeleteRequest = () => {
		this.setState({ deleteRequest: true });
	};

	handleDeleteRequestClose = () => {
		this.setState({ deleteRequest: false });
	};

	handleDeleteRequestConfirm = () => {
		if (this.props.mode === "EDIT") {
			this.setState({ deleteRequest: false });
			// const {handleDelete} = this.props;
			this.props.handleDelete(this.props.id);
		}
	};

	handleSelectContactsOpen = () => {
		this.setState({ selectContactsOpen: true });
	};

	handleSelectContactsClose = () => {
		this.setState({ selectContactsOpen: false });
	};

	getAllContacts = async (searchString) => {
		this.setState({ contactsLoading: true });
		await this.props.getContacts(searchString);
		this.setState({ contactsLoading: false });
	};

	componentWillMount = async () => {
		this.initState();
	};

	componentDidUpdate = (prevProps) => {
		if (
			prevProps.name !== this.props.name ||
			prevProps.id !== this.props.id ||
			prevProps.open !== this.props.open
		) {
			this.initState();
		}
	};

	render() {
		const {
			classes,
			fullScreen,
			open,
			handleClose,
			title,
			mode,
			campaigns,
			contacts,
		} = this.props;

		const {
			name,
			nameValidationError,
			nameDuplicateError,
			deleteRequest,
			campaignId,
			campaignValidationError,
			selectContactsOpen,
			contactsLoading,
		} = this.state;

		const {
			handleSubmitRequest,
			handleNameInputChange,
			handleCampaignIdChange,
			handleSelectContactsOpen,
			getAllContacts,
			handleDeleteRequest,
			handleDeleteRequestClose,
			handleDeleteRequestConfirm,
			handleSelectContactsClose,
		} = this;

		const that = this;

		return (
			<div>
				<DeleteConfirmation
					open={deleteRequest}
					handleClose={handleDeleteRequestClose}
					handleDeleteConfirm={handleDeleteRequestConfirm.bind(that)}
				/>
				<SelectContactsDialog
					open={selectContactsOpen}
					handleClose={handleSelectContactsClose}
					handleSubmitRequest={handleSubmitRequest}
					contacts={contacts}
					getAllContacts={getAllContacts}
					contactsLoading={contactsLoading}
				/>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle id="responsive-dialog-title">
						{title}
						{mode === "EDIT" && (
							<IconButton
								aria-label="Delete"
								disabled={name === "All" ? true : false}
								onClick={handleDeleteRequest}
								className={classes.deleteButton}
								color="secondary"
							>
								<DeleteIcon />
							</IconButton>
						)}
					</DialogTitle>
					<DialogContent
						style={{ paddingLeft: 24, paddingRight: 24 }}
					>
						<div className={classes.input}>
							<TextField
								required
								error={
									nameValidationError || nameDuplicateError
								}
								onChange={handleNameInputChange}
								id="name"
								label="Group name"
								defaultValue={name}
								className={classes.input}
								disabled={name === "All" ? true : false}
								margin="normal"
								helperText={
									nameDuplicateError
										? "This group is already saved"
										: nameValidationError
										? `Required, not more than ${MAX_NAME_LENGTH} characters`
										: ""
								}
							/>
						</div>
						<div className={classes.input}>
							<TextField
								select
								required
								label="Campaign"
								disabled={name === "All" ? true : false}
								SelectProps={{
									MenuProps: {
										className: classes.menu,
									},
								}}
								style={{ paddingTop: 30, marginTop: 8 }}
								onChange={handleCampaignIdChange}
								value={campaignId}
								className={classes.input}
								// margin="normal"
								error={campaignValidationError}
							>
								{campaigns.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.name}
									</MenuItem>
								))}
							</TextField>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						{mode === "ADD" && (
							<Button
								onClick={handleSelectContactsOpen}
								color="default"
								disabled={
									name === "All" ||
									nameValidationError ||
									nameDuplicateError ||
									campaignValidationError
								}
							>
								Select contacts
							</Button>
						)}
						{mode === "EDIT" && (
							<Button
								onClick={handleSubmitRequest}
								color="default"
								disabled={
									name === "All" ||
									nameValidationError ||
									nameDuplicateError ||
									campaignValidationError
								}
							>
								Save
							</Button>
						)}
						{/* {
              //mode === 'ADD' &&
              <Button  onClick={ () => handleSubmitRequest(false)} 
                       disabled={nameValidationError || nameDuplicateError}>
                Add Other
              </Button>
            } */}
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
export default withStyles(styles)(withMobileDialog()(AddOrEditGroupDialog));
