import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const styles = (theme) => ({
	root: {
		flexShrink: 0,
		color: theme.palette.text.secondary,
		marginLeft: theme.spacing(2.5),
	},
});

class PaginationActions extends React.Component {
	handleFirstPageButtonClick = (event) => {
		this.props.onPageChange(event, 0);
	};

	handleBackButtonClick = (event) => {
		this.props.onPageChange(event, this.props.page - 1);
	};

	handleNextButtonClick = (event) => {
		this.props.onPageChange(event, this.props.page + 1);
	};

	handleLastPageButtonClick = (event) => {
		this.props.onPageChange(
			event,
			Math.max(
				0,
				Math.ceil(this.props.count / this.props.rowsPerPage) - 1
			)
		);
	};

	render() {
		const { classes, count, page, rowsPerPage, theme, disabled } =
			this.props;

		return (
			<div className={classes.root}>
				<IconButton
					onClick={this.handleFirstPageButtonClick}
					disabled={disabled || page === 0}
					aria-label='First Page'
				>
					{theme.direction === 'rtl' ? (
						<LastPageIcon />
					) : (
						<FirstPageIcon />
					)}
				</IconButton>
				<IconButton
					onClick={this.handleBackButtonClick}
					disabled={disabled || page === 0}
					aria-label='Previous Page'
				>
					{theme.direction === 'rtl' ? (
						<KeyboardArrowRight />
					) : (
						<KeyboardArrowLeft />
					)}
				</IconButton>
				<IconButton
					onClick={this.handleNextButtonClick}
					disabled={
						disabled || page >= Math.ceil(count / rowsPerPage) - 1
					}
					aria-label='Next Page'
				>
					{theme.direction === 'rtl' ? (
						<KeyboardArrowLeft />
					) : (
						<KeyboardArrowRight />
					)}
				</IconButton>
				<IconButton
					onClick={this.handleLastPageButtonClick}
					disabled={
						disabled || page >= Math.ceil(count / rowsPerPage) - 1
					}
					aria-label='Last Page'
				>
					{theme.direction === 'rtl' ? (
						<FirstPageIcon />
					) : (
						<LastPageIcon />
					)}
				</IconButton>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(PaginationActions);
