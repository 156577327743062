import { fetchSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const requestTwitterProfileDataType = 'REQUEST_TWITTER_PROFILE_DATA';
const receiveTwitterProfileDataType = 'RECEIVE_TWITTER_PROFILE_DATA';
const receiveTwitterProfileDataErrorType = 'RECEIVE_TWITTER_PROFILE_DATA_ERROR';

const twitterStatsRequestType = 'TWITTER_STATS_REQUEST_TYPE';
const twitterStatsSuccessType = 'TWITTER_STATS_SUCCESS_TYPE';
const twitterStatsErrorType = 'TWITTER_STATS_ERROR_TYPE';

const initialState = {
	twitterProfileLoading: false,
	twitterProfileErrorMsg: '',
	twitterProfileData: {},

	twitterStatsLoading: false,
	twitterStats: null,
	twitterStatsLoadingError: false,
};

export const twitterActionCreators = {
	getTwitterProfile: (twitterHandle) => async (dispatch, getState) => {
		if (getState().twitter.twitterProfileLoading) {
			return;
		}

		dispatch({ type: requestTwitterProfileDataType });

		const url = new URL('/api/crawler/twitter', stringLookup.baseUrl);

		url.searchParams.append('handle', twitterHandle);

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const data = await res.json();

				dispatch({
					type: receiveTwitterProfileDataType,
					twitterProfileData: data,
					twitterProfileErrorMsg: '',
				});
			} else {
				dispatch({
					type: receiveTwitterProfileDataErrorType,
					twitterProfileErrorMsg: res.statusText,
					twitterProfileData: {},
				});
			}
		} catch (err) {
			dispatch({
				type: receiveTwitterProfileDataErrorType,
				twitterProfileData: {},
				twitterProfileErrorMsg: err.message,
			});
		}
	},

	getTwitterStats: (requestPayload) => async (dispatch, getState) => {
		if (getState().twitter.twitterStatsLoading) {
			return;
		}

		dispatch({ type: twitterStatsRequestType });

		try {
			const url = new URL('/api/crawler/twitter/stats', stringLookup.baseUrl);
			url.searchParams.append('startDate', requestPayload.startDate);
			url.searchParams.append('endDate', requestPayload.endDate);

			if (requestPayload.handle) {
				url.searchParams.append('handle', requestPayload.handle);
			}

			const response = await fetchSecured(url);

			if (response.ok) {
				const data = await response.json();

				dispatch({ type: twitterStatsSuccessType, twitterStats: data });
			} else {
				dispatch({ type: twitterStatsErrorType });
			}
		} catch (error) {
			dispatch({ type: twitterStatsErrorType });
		}
	},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === requestTwitterProfileDataType) {
		return {
			...state,
			twitterProfileLoading: true,
		};
	}

	if (action.type === receiveTwitterProfileDataType) {
		return {
			...state,
			twitterProfileLoading: false,
			twitterProfileData: action.twitterProfileData,
			twitterProfileErrorMsg: action.twitterProfileErrorMsg,
		};
	}

	if (action.type === receiveTwitterProfileDataErrorType) {
		return {
			...state,
			twitterProfileLoading: false,
			twitterProfileData: action.twitterProfileData,
			twitterProfileErrorMsg: action.twitterProfileErrorMsg,
		};
	}

	if (action.type === twitterStatsRequestType) {
		return {
			...state,
			twitterStatsLoading: true,
			twitterStatsLoadingError: false,
		};
	}

	if (action.type === twitterStatsSuccessType) {
		return {
			...state,
			twitterStatsLoading: false,
			twitterStats: action.twitterStats,
		};
	}

	if (action.type === twitterStatsErrorType) {
		return {
			...state,
			twitterStatsLoading: false,
			twitterStatsLoadingError: true,
		};
	}

	return state;
};
