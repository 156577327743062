const GLOBAL_START_LOAD = 'GLOBAL_LOADING_START';
const GLOBAL_STOP_LOAD = 'GLOBAL_LOADING_STOP';
const SET_EMBED_STATE = 'SET_EMBED_STATE';

const initialState = {
	loading: false,
	// recurrenceMenuState: null,
	isEmbedding: false,
	embedUrl: '',
	ancestorOrigin: null,
	embedOrganisationId: null,
};

export const setGlobalLoad = (dispatch) => {
	dispatch({ type: GLOBAL_START_LOAD });
};

export const unsetGlobalLoad = (dispatch) => {
	dispatch({ type: GLOBAL_STOP_LOAD });
};

export const globalActionCreators = {
	setEmbedState:
		(state, url, ancestorOrigin = null, embedOrganisationId = null) =>
		(dispatch) => {
			dispatch({
				type: SET_EMBED_STATE,
				isEmbedding: state,
				embedUrl: url,
				ancestorOrigin,
				embedOrganisationId,
			});
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === GLOBAL_START_LOAD) {
		return {
			...state,
			loading: true,
		};
	}

	if (action.type === GLOBAL_STOP_LOAD) {
		return {
			...state,
			loading: false,
		};
	}

	if (action.type === SET_EMBED_STATE) {
		return {
			...state,
			isEmbedding: action.isEmbedding,
			embedUrl: action.embedUrl,
			ancestorOrigin: action.ancestorOrigin,
			embedOrganisationId: action.embedOrganisationId,
		};
	}

	return state;
};
