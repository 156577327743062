import stringLookup from './../stringslookup';
import { fetchSecured, postSecured, deleteSecured } from './../fetchUtils';

const requestShortCodesType = 'REQUEST_CONFIG_SHORTCODES';
const receiveShortCodesType = 'RECEIVE_CONFIG_SHORTCODES';
const receiveShortCodesErrorType = 'RECEIVE_CONFIG_SHORTCODES_ERROR';
const addShortCodeType = "ADD_CONFIG_SHORTCODES'";
const addShortCodeCompleteType = 'ADD_CONFIG_SHORTCODES_COMPLETE';
const deleteShortCodeType = 'DELETE_CONFIG_SHORTCODES';
const deleteShortCodeCompleteType = 'DELETE_CONFIG_SHORTCODES_COMPLETE';
const modifyShortCode400ErrorType = 'MODIFY_CAMPAIGN_400_ERROR_COMPLETE';
const modifyShortCode400ErrorReset = 'MODIFY_CAMPAIGN_400_ERROR_RESET';

const initialShortCodesState = {
	shortCodes: [],
	totalShortCodes: 0,
	shortCodesLoading: false,
	addingShortCode: false,
	deletingShortCode: false,
	validationError: null,
};

export const actionCreators = {
	requestShortCodes: () => async (dispatch, getState) => {
		if (getState().messagingShortCodes.shortCodesLoading) {
			return;
		}

		dispatch({ type: requestShortCodesType });

		const url = new URL('api/messaging/SmsShortCodes', stringLookup.baseUrl);

		try {
			const shortCodesResponse = await fetchSecured(url);

			if (shortCodesResponse.ok) {
				const shortCodesRes = await shortCodesResponse.json();
				dispatch({ type: receiveShortCodesType, shortCodes: shortCodesRes });
			} else {
				dispatch({ type: receiveShortCodesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveShortCodesErrorType });
		}
	},

	addShortCode: (shortCode) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().messagingShortCodes.addingShortCode) {
			return metadata;
		}

		dispatch({ type: addShortCodeType });
		const url = new URL('api/messaging/SmsShortCodes', stringLookup.baseUrl);

		try {
			const shortCodesResponse = await postSecured(url, shortCode);

			if (shortCodesResponse.ok) {
				dispatch({ type: addShortCodeCompleteType });
			} else if (shortCodesResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await shortCodesResponse.json();
				dispatch({
					type: modifyShortCode400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: addShortCodeCompleteType });
			}
		} catch (err) {
			dispatch({ type: addShortCodeCompleteType });
		}
	},

	deleteShortCode: (shortCode) => async (dispatch, getState) => {
		const metadata = {};
		if (getState().messagingShortCodes.deletingShortCode) {
			return metadata;
		}

		dispatch({ type: deleteShortCodeType });

		const url = new URL('api/messaging/SmsShortCodes', stringLookup.baseUrl);

		try {
			const deleteResponse = await deleteSecured(url, shortCode);

			if (deleteResponse.ok) {
				dispatch({ type: deleteShortCodeCompleteType });
			} else if (deleteResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await deleteResponse.json();
				dispatch({
					type: modifyShortCode400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: deleteShortCodeCompleteType });
			}
		} catch (err) {
			dispatch({ type: deleteShortCodeCompleteType });
		}
	},

	resetValidationMessage: () => async (dispatch, getState) => {
		dispatch({ type: modifyShortCode400ErrorReset });
	},
};

export const reducer = (state, action) => {
	state = state || initialShortCodesState;

	if (action.type === requestShortCodesType) {
		return {
			...state,
			shortCodesLoading: true,
		};
	}

	if (action.type === receiveShortCodesType) {
		return {
			...state,
			shortCodes: action.shortCodes,
			totalShortCodes: action.totalShortCodes,
			shortCodesLoading: false,
		};
	}
	if (action.type === receiveShortCodesErrorType) {
		return {
			...state,
			shortCodesLoading: false,
		};
	}

	if (action.type === addShortCodeType) {
		return {
			...state,
			addingShortCode: true,
		};
	}

	if (action.type === addShortCodeCompleteType) {
		return {
			...state,
			addingShortCode: false,
		};
	}

	if (action.type === deleteShortCodeType) {
		return {
			...state,
			deletingShortCode: true,
		};
	}

	if (action.type === deleteShortCodeCompleteType) {
		return {
			...state,
			deletingShortCode: false,
		};
	}

	if (action.type === modifyShortCode400ErrorType) {
		return {
			...state,
			validationError: action.validationError,
		};
	}

	return state;
};
