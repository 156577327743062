import stringLookup from './../stringslookup';
import {
	fetchSecured,
	postSecured,
	deleteSecured,
	putSecured,
} from './../fetchUtils';

const requestTopicsType = 'REQUEST_CONFIG_TOPICS';
const receiveTopicsType = 'RECEIVE_CONFIG_TOPICS';
const receiveTopicsErrorType = 'RECEIVE_CONFIG_TOPICS_FETCH_ERROR';

const addTopicType = 'ADD_CONFIG_TOPICS';
const addTopicCompleteType = 'ADD_CONFIG_TOPICS_COMPLETE';

const editTopicType = 'EDIT_CONFIG_TOPICS';
const editTopicCompleteType = 'EDIT_CONFIG_TOPICS_COMPLETE';

const deleteTopicType = 'DELETE_CONFIG_TOPICS';
const deleteTopicCompleteType = 'DELETE_CONFIG_TOPICS_COMPLETE';

const modifyTopic400ErrorType = 'MODIFY_TOPICS_400_Error_COMPLETE';
const modifyTopic400ErrorReset = 'MODIFY_TOPICS_400_Error_Reset';

const initialTopicsState = {
	topics: [],
	totalTopics: 0,
	topicsLoading: false,
	addingTopic: false,
	editingTopic: false,
	deletingTopic: false,
	validationError: null,
};

export const topicsActionCreators = {
	requestTopics:
		(
			page,
			pageSize,
			filter = '',
			topics = [],
			subtopics = [],
			organisationId = null
		) =>
		async (dispatch, getState) => {
			if (getState().configurationTopics.topicsLoading) {
				return;
			}

			dispatch({ type: requestTopicsType });

			const url = new URL('/api/crawler/Topic', stringLookup.baseUrl);
			if (organisationId !== null && organisationId !== 'all') {
				url.searchParams.append('organisationId', organisationId);
			}

			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);

			if (filter.length !== 0) {
				url.searchParams.append('filter', filter);
			}

			if (topics.length !== 0) {
				if (topics.includes('none')) {
					url.searchParams.append('none', true);
				} else {
					topics.forEach((topic) => {
						url.searchParams.append('Topics', topic);
					});
				}
			}

			if (subtopics.length !== 0) {
				subtopics.forEach((subtopics) => {
					url.searchParams.append('Subtopics', subtopics);
				});
			}

			try {
				const topicsResponse = await fetchSecured(url);

				if (topicsResponse.ok) {
					const topicsRes = await topicsResponse.json();
					dispatch({
						type: receiveTopicsType,
						topics: topicsRes.data,
						totalTopics: topicsRes.total,
					});
				} else {
					dispatch({ type: receiveTopicsErrorType });
				}
			} catch (err) {
				dispatch({ type: receiveTopicsErrorType });
			}
		},
	editTopic:
		(topic, mode = 'ADD') =>
		async (dispatch, getState) => {
			const metadata = {};
			if (getState().configurationTopics.addingTopic) {
				return metadata;
			}

			dispatch({ type: addTopicType });
			const url = new URL('/api/crawler/topic', stringLookup.baseUrl);

			try {
				const topicsResponse =
					mode === 'ADD'
						? await postSecured(url, topic)
						: await putSecured(url, topic);

				if (topicsResponse.ok) {
					//const topicsRes = await topicsResponse.json();
					dispatch({ type: addTopicCompleteType });
				} else if (topicsResponse.status === 400) {
					metadata._400Error = true;

					const _400ResMessage = await topicsResponse.json();
					dispatch({
						type: modifyTopic400ErrorType,
						validationError: _400ResMessage.message,
					});
				} else {
					dispatch({ type: addTopicCompleteType });
				}
			} catch (err) {
				dispatch({ type: addTopicCompleteType });
			}

			return metadata;
		},
	deleteTopic: (id) => async (dispatch, getState) => {
		if (getState().configurationTopics.deletingTopic) {
			return;
		}

		dispatch({ type: deleteTopicType });

		const url = new URL(`/api/crawler/Topic/${id}`, stringLookup.baseUrl);

		try {
			const deleteResponse = await deleteSecured(url);

			if (deleteResponse.ok) {
				dispatch({ type: deleteTopicCompleteType });
			} else {
				dispatch({ type: deleteTopicCompleteType });
			}
		} catch (err) {
			dispatch({ type: deleteTopicCompleteType });
		}
	},
	resetValidationMessage: () => async (dispatch, getState) => {
		dispatch({ type: modifyTopic400ErrorReset });
	},
};

export const reducer = (state, action) => {
	state = state || initialTopicsState;

	if (action.type === requestTopicsType) {
		return {
			...state,
			topicsLoading: true,
		};
	}

	if (action.type === receiveTopicsType) {
		return {
			...state,
			topics: action.topics,
			totalTopics: action.totalTopics,
			topicsLoading: false,
			validationError: null,
		};
	}
	if (action.type === receiveTopicsErrorType) {
		return {
			...state,
			topicsLoading: false,
		};
	}

	if (action.type === addTopicType) {
		return {
			...state,
			addingTopic: true,
		};
	}

	if (action.type === addTopicCompleteType) {
		return {
			...state,
			addingTopic: false,
		};
	}

	if (action.type === deleteTopicType) {
		return {
			...state,
			deletingTopic: true,
		};
	}

	if (action.type === deleteTopicCompleteType) {
		return {
			...state,
			deletingTopic: false,
		};
	}

	if (action.type === modifyTopic400ErrorType) {
		return {
			...state,
			validationError: action.validationError,
			addingTopic: false,
		};
	}

	if (action.type === modifyTopic400ErrorReset) {
		return {
			...state,
			validationError: null,
		};
	}

	return state;
};
