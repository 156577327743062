import stringLookup from './../stringslookup';
import { fetchSecured } from '../fetchUtils';
import { setGlobalLoad, unsetGlobalLoad } from './GlobalStore';

const REQUEST_KEYWORD_TIMELINE_REPORT_TYPE = 'REQUEST_KEYWORD_TIMELINE_REPORT';
const RECEIVE_KEYWORD_TIMELINE_REPORT_TYPE = 'RECEIVE_KEYWORD_TIMELINE_REPORT';
const RECEIVE_KEYWORD_TIMELINE_REPORT_ERROR_TYPE =
	'RECEIVE_KEYWORD_TIMELINE_REPORT_ERROR';

const REQUEST_KEYWORD_TIMELINE_COUNT_TYPE = 'REQUEST_KEYWORD_TIMELINE_COUNT';
const RECEIVE_KEYWORD_TIMELINE_COUNT_TYPE = 'RECEIVE_KEYWORD_TIMELINE_COUNT';
const RECEIVE_KEYWORD_TIMELINE_COUNT_ERROR_TYPE =
	'RECEIVE_KEYWORD_TIMELINE_COUNT_ERROR';

const REQUEST_KEYWORD_SUMMARY_REPORT_TYPE = 'REQUEST_KEYWORD_SUMMARY_REPORT';
const RECEIVE_KEYWORD_SUMMARY_REPORT_TYPE = 'RECEIVE_KEYWORD_SUMMARY_REPORT';
const RECEIVE_KEYWORD_SUMMARY_REPORT_ERROR_TYPE =
	'RECEIVE_KEYWORD_SUMMARY_REPORT_ERROR';

const initialState = {
	reportFetching: false,
	report: {},
	summaryFetching: false,
	summary: null,
	documentCountFetching: false,
	keywordTimelineCount: {},
};

export const keywordTimelineActionCreators = {
	loadKeywordTimelines:
		(
			scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			if (scoreType == null) {
				return;
			}

			dispatch({ type: REQUEST_KEYWORD_TIMELINE_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL('/api/analytics/keywordstimeline', stringLookup.baseUrl);

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			url.searchParams.append('scoreType', scoreType);
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('keywordTimelineItemsLimit', 10);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subtopics', s);
			});

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const reportResponse = await fetchSecured(url, 'keyword_timeline_report');
				if (reportResponse.ok) {
					const data = await reportResponse.json();

					const report = {};
					data.forEach((x) => {
						report[x.keyword] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_KEYWORD_TIMELINE_REPORT_TYPE,
						keywordTimelineReport: report,
					});
				} else {
					dispatch({
						type: RECEIVE_KEYWORD_TIMELINE_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_KEYWORD_TIMELINE_REPORT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadKeywordTimelinesCount:
		(
			// scoreType,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter,
			searchString
		) =>
		async (dispatch, getState) => {
			// if (scoreType == null) {
			// 	return;
			// }

			dispatch({ type: REQUEST_KEYWORD_TIMELINE_COUNT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/keywordstimeline/count',
				stringLookup.baseUrl
			);

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			// url.searchParams.append("scoreType", scoreType);
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('keywordTimelineItemsLimit', 10);

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subtopics', s);
			});

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			try {
				const reportResponse = await fetchSecured(url, 'keyword_timeline_count');
				if (reportResponse.ok) {
					const data = await reportResponse.json();

					const keywordCount = {};
					data.forEach((x) => {
						keywordCount[x.keyword] = x.scoreHistogram;
					});

					dispatch({
						type: RECEIVE_KEYWORD_TIMELINE_COUNT_TYPE,
						keywordTimelineCount: keywordCount,
					});
				} else {
					dispatch({
						type: RECEIVE_KEYWORD_TIMELINE_COUNT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_KEYWORD_TIMELINE_COUNT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},

	loadKeywordSummary:
		(
			// scoreType,
			searchString,
			mediaTypeFilter,
			topicsFilters,
			subtopicFilters,
			keywordFilters,
			startDate,
			endDate,
			dateFilter,
			page,
			pageSize,
			orderBy,
			sortDirection,
			categoryFilters,
			relatedSubtopicFilter,
			NLPFilter
		) =>
		async (dispatch, getState) => {
			// if (scoreType == null) {
			// 	return;
			// }
			dispatch({ type: REQUEST_KEYWORD_SUMMARY_REPORT_TYPE });
			setGlobalLoad(dispatch);

			const url = new URL(
				'/api/analytics/keywordstimeline/summary',
				stringLookup.baseUrl
			);

			keywordFilters.forEach((k) => {
				url.searchParams.append('keywords', k);
			});

			categoryFilters.forEach((c) => {
				url.searchParams.append('categories', c);
			});

			topicsFilters.forEach((t) => {
				url.searchParams.append('topics', t);
			});

			subtopicFilters.forEach((s) => {
				url.searchParams.append('subtopics', s);
			});

			url.searchParams.append('startDate', startDate.toISOString());
			url.searchParams.append('endDate', endDate.toISOString());
			url.searchParams.append('mediaType', mediaTypeFilter);
			url.searchParams.append('page', page);
			url.searchParams.append('pageSize', pageSize);
			// url.searchParams.append("scoreType", scoreType);

			if (dateFilter) {
				url.searchParams.append('date', dateFilter);
			}

			if (orderBy) {
				url.searchParams.append('orderBy', orderBy);
			}

			if (sortDirection) {
				url.searchParams.append('order', sortDirection);
			}

			if (searchString) {
				url.searchParams.append('searchString', searchString);
			}

			if (relatedSubtopicFilter) {
				url.searchParams.append('relatedSubtopics', relatedSubtopicFilter.id);
			}

			if (NLPFilter) {
				url.searchParams.append('nlptexts', NLPFilter);
			}

			try {
				const reportResponse = await fetchSecured(url, 'keywordsSummary');
				if (reportResponse.ok) {
					const data = await reportResponse.json();
					dispatch({
						type: RECEIVE_KEYWORD_SUMMARY_REPORT_TYPE,
						summary: data,
					});
				} else {
					dispatch({
						type: RECEIVE_KEYWORD_SUMMARY_REPORT_ERROR_TYPE,
					});
				}
			} catch (err) {
				dispatch({ type: RECEIVE_KEYWORD_SUMMARY_REPORT_ERROR_TYPE });
			}

			unsetGlobalLoad(dispatch);
		},
};

export const reducer = (state, action) => {
	state = state || initialState;

	if (action.type === REQUEST_KEYWORD_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: true,
			report: {},
		};
	}
	if (action.type === RECEIVE_KEYWORD_TIMELINE_REPORT_ERROR_TYPE) {
		return {
			...state,
			reportFetching: false,
		};
	}
	if (action.type === RECEIVE_KEYWORD_TIMELINE_REPORT_TYPE) {
		return {
			...state,
			reportFetching: false,
			report: action.keywordTimelineReport,
		};
	}

	if (action.type === REQUEST_KEYWORD_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: true,
			keywordTimelineCount: {},
		};
	}
	if (action.type === RECEIVE_KEYWORD_TIMELINE_COUNT_ERROR_TYPE) {
		return {
			...state,
			documentCountFetching: false,
		};
	}
	if (action.type === RECEIVE_KEYWORD_TIMELINE_COUNT_TYPE) {
		return {
			...state,
			documentCountFetching: false,
			keywordTimelineCount: action.keywordTimelineCount,
		};
	}

	if (action.type === REQUEST_KEYWORD_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			summaryFetching: true,
			summary: null,
		};
	}
	if (action.type === RECEIVE_KEYWORD_SUMMARY_REPORT_TYPE) {
		return {
			...state,
			summary: action.summary,
			summaryFetching: false,
		};
	}
	if (action.type === RECEIVE_KEYWORD_SUMMARY_REPORT_ERROR_TYPE) {
		return {
			...state,
			summaryFetching: false,
		};
	}

	return state;
};
