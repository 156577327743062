import { fetchSecured } from '../fetchUtils';
import stringLookup from '../stringslookup';

const requestGoogleApiKeyType = 'REQUEST_GOOGLE_API_KEY_TYPE';
const receiveGoogleApiKeySuccessType = 'RECEIVE_GOOGLE_API_KEY_SUCCESS_TYPE';
const receiveGoogleApiKeyErrorType = 'RECEIVE_GOOGLE_API_KEY_ERROR_TYPE';

const requestMapBoxApiKeyType = 'REQUEST_MAP_BOX_API_KEY_TYPE';
const receiveMapBoxApiKeySuccessType = 'RECEIVE_MAP_BOX_API_KEY_SUCCESS_TYPE';
const receiveMapBoxApiKeyErrorType = 'RECEIVE_MAP_BOX_API_KEY_ERROR_TYPE';

const initialState = {
	googleApiKeyLoading: false,
	googleApiKey: null,
	googleApiKeyError: false,

	mapBoxApiKeyLoading: false,
	mapBoxApiKey: null,
	mapBoxApiKeyError: false,
};

export const KeysActionCreators = {
	getGoogleApiKey: () => async (dispatch, getState) => {
		if (getState().keys.googleApiKeyLoading) {
			return;
		}

		dispatch({
			type: requestGoogleApiKeyType,
		});

		const url = new URL('/api/external/apikeys', stringLookup.baseUrl);
		url.searchParams.append('keyName', 'Google');

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const resPayload = await res.json();

				dispatch({
					type: receiveGoogleApiKeySuccessType,
					googleApiKey: resPayload.key,
				});
			} else {
				dispatch({
					type: receiveGoogleApiKeyErrorType,
				});
			}
		} catch (error) {
			dispatch({
				type: receiveGoogleApiKeyErrorType,
			});
		}
	},

	getMapBoxApiKey: () => async (dispatch, getState) => {
		if (getState().keys.mapBoxApiKeyLoading) {
			return;
		}

		dispatch({
			type: requestMapBoxApiKeyType,
		});

		const url = new URL('/api/external/apikeys', stringLookup.baseUrl);
		url.searchParams.append('keyName', 'MapBox');

		try {
			const res = await fetchSecured(url);

			if (res.ok) {
				const resPayload = await res.json();

				dispatch({
					type: receiveMapBoxApiKeySuccessType,
					mapBoxApiKey: resPayload.key,
				});
			} else {
				dispatch({
					type: receiveMapBoxApiKeyErrorType,
				});
			}
		} catch (error) {
			dispatch({
				type: receiveMapBoxApiKeyErrorType,
			});
		}
	},
};

const keysReducer = (state = initialState, action) => {
	if (action.type === requestGoogleApiKeyType) {
		return {
			...state,
			googleApiKeyError: false,
			googleApiKeyLoading: true,
		};
	}

	if (action.type === receiveGoogleApiKeySuccessType) {
		return {
			...state,
			googleApiKeyError: false,
			googleApiKeyLoading: false,
			googleApiKey: action.googleApiKey,
		};
	}

	if (action.type === receiveGoogleApiKeyErrorType) {
		return {
			...state,
			googleApiKeyError: true,
			googleApiKeyLoading: false,
		};
	}

	if (action.type === requestMapBoxApiKeyType) {
		return {
			...state,
			mapBoxApiKeyError: false,
			mapBoxApiKeyLoading: true,
		};
	}

	if (action.type === receiveMapBoxApiKeySuccessType) {
		return {
			...state,
			mapBoxApiKeyError: false,
			mapBoxApiKeyLoading: false,
			mapBoxApiKey: action.mapBoxApiKey,
		};
	}

	if (action.type === receiveMapBoxApiKeyErrorType) {
		return {
			...state,
			mapBoxApiKeyError: true,
			mapBoxApiKeyLoading: false,
		};
	}

	return state;
};

export default keysReducer;
