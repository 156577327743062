import stringLookup from '../stringslookup';
import { fetchSecured, postSecured, deleteSecured, patchSecured } from '../fetchUtils';

const requestCampaignsType = 'REQUEST_CONFIG_CAMPAIGNS';
const receiveCampaignsType = 'RECEIVE_CONFIG_CAMPAIGNS';
const receiveCampaignsErrorType = 'RECEIVE_CONFIG_CAMPAIGNS_ERROR';
const requestShortCodesType = 'REQUEST_SHORTCODES';
const receiveShortCodesType = 'RECEIVE_SHORTCODES';
const receiveShortCodesErrorType = 'RECEIVE_SHORTCODES_ERROR';
const addCampaignType = "ADD_CONFIG_CAMPAIGNS'";
const addCampaignCompleteType = 'ADD_CONFIG_CAMPAIGNS_COMPLETE';
const editCampaignType = 'EDIT_CONFIG_CAMPAIGNS';
const editCampaignCompleteType = 'EDIT_CONFIG_CAMPAIGNS_COMPLETE';
const deleteCampaignType = 'DELETE_CONFIG_CAMPAIGNS';
const deleteCampaignCompleteType = 'DELETE_CONFIG_CAMPAIGNS_COMPLETE';
const modifyCampaign400ErrorType = 'MODIFY_CAMPAIGN_400_ERROR_COMPLETE';
const modifyCampaign400ErrorReset = 'MODIFY_CAMPAIGN_400_ERROR_RESET';

const initialCampaignsState = {
	campaigns: [],
	totalCampaigns: 0,
	campaignsLoading: false,
	addingCampaign: false,
	deletingCampaign: false,
	availableShortCodes: [],
	validationError: null,
};

export const actionCreators = {
	requestCampaigns: (page, pageSize) => async (dispatch, getState) => {
		if (getState().messagingCampaigns.campaignsLoading) {
			return;
		}

		dispatch({ type: requestCampaignsType });

		const url = new URL('api/messaging/campaigns', stringLookup.baseUrl);
		url.searchParams.append('page', page);
		url.searchParams.append('pageSize', pageSize);

		try {
			const campaignsResponse = await fetchSecured(url);

			if (campaignsResponse.ok) {
				const campaignsRes = await campaignsResponse.json();
				dispatch({
					type: receiveCampaignsType,
					campaigns: campaignsRes.items,
					totalCampaigns: campaignsRes.totalcount,
				});
			} else {
				dispatch({ type: receiveCampaignsErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveCampaignsErrorType });
		}
	},

	requestAvailableShortCodes: () => async (dispatch, getState) => {
		if (getState().messagingCampaigns.campaignsLoading) {
			return;
		}

		dispatch({ type: requestShortCodesType });

		const url = new URL(
			'api/messaging/campaigns/availableshortcodes',
			stringLookup.baseUrl
		);

		try {
			const shortCodesResponse = await fetchSecured(url);

			if (shortCodesResponse.ok) {
				const shortCodesRes = await shortCodesResponse.json();
				dispatch({
					type: receiveShortCodesType,
					availableShortCodes: shortCodesRes,
				});
			} else {
				dispatch({ type: receiveShortCodesErrorType });
			}
		} catch (err) {
			dispatch({ type: receiveShortCodesErrorType });
		}
	},

	addCampaign: (campaign) => async (dispatch, getState) => {
		const metadata = {};

		if (getState().messagingCampaigns.addingCampaign) {
			return;
		}

		dispatch({ type: addCampaignType });
		const url = new URL('api/messaging/campaigns', stringLookup.baseUrl);

		try {
			const campaignsResponse = await postSecured(url, campaign);

			if (campaignsResponse.ok) {
				//const campaignsRes = await campaignsResponse.json();
				dispatch({ type: addCampaignCompleteType });
			} else if (campaignsResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await campaignsResponse.json();
				dispatch({
					type: modifyCampaign400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: addCampaignCompleteType });
			}
		} catch (err) {
			dispatch({ type: addCampaignCompleteType });
		}
	},

	editCampaign: (campaign, id) => async (dispatch, getState) => {
		const metadata = {};

		if (getState().messagingCampaigns.editingCampaign) {
			return metadata;
		}

		dispatch({ type: editCampaignType });
		const url = new URL(`api/messaging/campaigns/${id}`, stringLookup.baseUrl);

		try {
			const campaignsResponse = await patchSecured(url, campaign);

			if (campaignsResponse.ok) {
				dispatch({ type: editCampaignCompleteType });
			} else if (campaignsResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await campaignsResponse.json();
				dispatch({
					type: modifyCampaign400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: editCampaignCompleteType });
			}
		} catch (err) {
			dispatch({ type: editCampaignCompleteType });
		}

		return metadata;
	},

	deleteCampaign: (id) => async (dispatch, getState) => {
		const metadata = {};

		if (getState().messagingCampaigns.deletingCampaign) {
			return metadata;
		}

		dispatch({ type: deleteCampaignType });

		const url = new URL(`api/messaging/campaigns/${id}`, stringLookup.baseUrl);

		try {
			const deleteResponse = await deleteSecured(url, id);

			if (deleteResponse.ok) {
				dispatch({ type: deleteCampaignCompleteType });
			} else if (deleteResponse.status === 400) {
				metadata._400Error = true;
				const _400ResMessage = await deleteResponse.json();
				dispatch({
					type: modifyCampaign400ErrorType,
					validationError: _400ResMessage.message,
				});
			} else {
				dispatch({ type: deleteCampaignCompleteType });
			}
		} catch (err) {
			dispatch({ type: deleteCampaignCompleteType });
		}

		return metadata;
	},

	resetValidationMessage: () => async (dispatch, getState) => {
		dispatch({ type: modifyCampaign400ErrorReset });
	},
};

export const reducer = (state, action) => {
	state = state || initialCampaignsState;

	if (action.type === requestCampaignsType) {
		return {
			...state,
			campaignsLoading: true,
		};
	}

	if (action.type === receiveCampaignsType) {
		return {
			...state,
			campaigns: action.campaigns,
			totalCampaigns: action.totalCampaigns,
			campaignsLoading: false,
		};
	}
	if (action.type === receiveCampaignsErrorType) {
		return {
			...state,
			campaignsLoading: false,
		};
	}

	if (action.type === receiveShortCodesType) {
		return {
			...state,
			availableShortCodes: action.availableShortCodes,
		};
	}

	if (action.type === addCampaignType) {
		return {
			...state,
			addingCampaign: true,
		};
	}

	if (action.type === addCampaignCompleteType) {
		return {
			...state,
			addingCampaign: false,
		};
	}

	if (action.type === deleteCampaignType) {
		return {
			...state,
			deletingCampaign: true,
		};
	}

	if (action.type === deleteCampaignCompleteType) {
		return {
			...state,
			deletingCampaign: false,
		};
	}

	if (action.type === modifyCampaign400ErrorType) {
		return {
			...state,
			validationError: action.validationError,
		};
	}

	return state;
};
